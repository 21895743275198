<template>
  <form class="lottoCreationTable" @submit.prevent>
    <BaseItemButton
      v-if="!isTabMode"
      class="lottoCreationTable__button"
      :subtitle="product.vendor"
      :itemId="product.id"
      :title="product.name"
      :isClickable="false"
      @handle-click="selectProduct"
      :isError="isOverloaded"
    ></BaseItemButton>

    <div>
      <p class="lottoCreationTable__batch">
        <span>Identificativo lotto:</span><span class="lottoCreationTable__batchNr">{{ batchNumber }}</span>
      </p>
      <p class="lottoCreationTable__infoKg">Assegna {{ kgLotto }} kg di {{ product.name }} agli ordini in corso che le contengono:</p>
      <div class="lottoCreationTable__assignment">
        <BaseSmartButton extraClass="lottoCreationTable__smartBtn" :isActive="smartAssignmentShown && selectedProduct === product.id" @handle-click="showSmartAssignment(product.id)"
          >Assegnazione Smart</BaseSmartButton
        >
        <p v-if="!isTabMode" class="lottoCreationTable__assignmentInfo">
          Assegnati <span class="u-bold-text">{{ allAssignedKg }} </span>su <span class="u-bold-text">{{ kgLotto }}</span> kg
        </p>
      </div>
      <div class="lottoCreationTable__table">
        <div class="lottoCreationTable__tools">
          <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { '  u-selectAllBtn--selected': allOrdersSelected }]"></span><span>Seleziona tutto </span></p>
          <p v-if="isTabMode" class="lottoCreationTable__assignmentInfo">
            <span class="u-bold-text">{{ allAssignedKg }} </span>su <span class="u-bold-text">{{ kgLotto }}</span> kg
          </p>
        </div>
        <TableOrders
          :orders="ordersData"
          :columns="['col', 'col', 'col']"
          hideShipping
          :isLoading="!allDataReady"
          shortDate
          noOrdersInfoDisabled
          :allOrdersSelected="allOrdersSelected"
          @toggle-select-all="toggleSelectAll"
          name="ordersList"
        ></TableOrders>
        <SmartAssignment v-if="smartAssignmentShown && selectedProduct === product.id" @close-smart-assignment="hideSmartAssignment" @start-smart-assignment="startSmartAssignment"></SmartAssignment>
      </div>
    </div>
    <p v-if="allDataReady && ordersData.length == 0" class="u-text-warning lottoCreationTable__info">Nessun ordine trovato.</p>
  </form>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseSmartButton from '@bc/BaseSmartButton';
import TableOrders from '@c/common/TableOrders';
import SmartAssignment from '@c/vendor/SmartAssignment';
import { getLottoOrdersPreviews } from '@gq/getLottoOrdersPreviews.gql';
import { getBoxesContent } from '@gq/getBoxesContent.gql';
import { mapActions, mapGetters } from 'vuex';
import { checkDateInTimeRange, checkIfJsonIsValid, convertWeightToKg, getShortProductId } from '@u/helperFunctions.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

const boxTag = 'cassetta';

export default {
  name: 'LottoCreationTable',
  components: {
    BaseItemButton,
    BaseSmartButton,
    TableOrders,
    SmartAssignment
  },
  mixins: [sleepMixin],
  props: {
    /**
     * This prop is used to pass the name of the selected vendor
     */
    selectedVendor: { type: String, required: true },
    /**
     * This prop is used to pass a string with the starting date to retrieve orders data
     */
    startingDate: { type: String, required: true },
    /**
     * This prop is used to pass an object with the details of the product
     */
    product: { type: Object, required: true }
  },
  data() {
    return {
      selectedProduct: '',
      allOrdersSelected: false,
      fullyLoaded: false,
      allDataReady: false,
      ordersData: [],
      kgAssegnati: 0,
      isOverloaded: false,
      kgLotto: this.product.quantita,
      currentKg: 0,
      batchNumber: this.product.numeroDDT,
      smartAssignmentShown: false,
      smartAssignmentType: '',
      smartTimeRange: [],
      nodes: {},
      boxesContents: [],
      boxesIds: []
    };
  },
  apollo: {
    // Call when component is rendered
    orders() {
      return {
        query: getLottoOrdersPreviews,
        variables: {
          queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND tag:${this.product.id} AND created_at:>=${this.startingDate}`
        }
      };
    },
    nodes() {
      return {
        query: getBoxesContent,
        skip: true,
        variables: {
          boxInfo: 'box_info',
          boxContent: 'content',
          ids: this.boxesIds
        }
      };
    }
  },
  computed: {
    ...mapGetters(['batchAssociatedOrders']),
    isTabMode() {
      return this.$store.getters.isTabMode;
    },
    selections() {
      return this.ordersData.filter(order => order.isChecked);
    },
    allAssignedKg() {
      return this.currentKg + this.kgAssegnati;
    }
  },

  methods: {
    ...mapActions(['completeStep', 'addRegisteredOrders', 'addAlert', 'removeStepCompleteness']),
    selectProduct(value) {
      this.selectedProduct = value;
    },
    showSmartAssignment(value) {
      this.selectedProduct = value;
      this.smartAssignmentShown = true;
    },

    hideSmartAssignment() {
      this.selectedProduct = '';
      this.smartAssignmentShown = false;
    },

    startSmartAssignment(value, timeRange) {
      this.smartAssignmentType = value;
      this.smartTimeRange = timeRange;
      this.smartAssignmentShown = false;
    },

    toggleChecked(orderId) {
      const updatedOrdersData = this.ordersData;

      // Find selected input
      const selectedInput = updatedOrdersData.find(order => order.id === orderId);

      // Toggle selected input isChecked value
      selectedInput.isChecked = !selectedInput.isChecked;

      this.$set(this.ordersData, updatedOrdersData);
    },
    toggleSelectAll() {
      const selectedOrders = this.ordersData;

      selectedOrders.forEach(order => (order.isChecked = !this.allOrdersSelected));
      this.allOrdersSelected = !this.allOrdersSelected;

      this.ordersData = [...selectedOrders];
    },

    async fetchMoreOrders() {
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND tag:${this.product.id} AND created_at:>=${this.startingDate}`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          return {
            orders: newOrders
          };
        }
      });
    },
    selectOrdersChronologically() {
      const selectedOrders = this.ordersData;
      let totalWeight = this.currentKg;

      // Select all orders until the batch won't be completed
      selectedOrders.forEach(order => {
        totalWeight += order.totalProductWeight;
        if (totalWeight <= this.kgLotto) {
          order.isChecked = true;
        } else {
          order.isChecked = false;
        }
      });

      this.ordersData = [...selectedOrders];
    },
    selectOrdersByTimeRange() {
      let totalWeight = this.currentKg + this.kgAssegnati;

      // Select orders from the selected timeRange
      this.ordersData
        .filter(order => checkDateInTimeRange(order.date, this.smartTimeRange))
        .forEach(order => {
          // Select all orders until the batch won't be completed
          totalWeight += order.totalProductWeight;
          if (totalWeight <= this.kgLotto) {
            order.isChecked = true;
          } else {
            order.isChecked = false;
          }
        });
    },
    selectPremiumOrders() {
      let totalWeight = this.currentKg + this.kgAssegnati;

      this.ordersData
        // Select orders with Premium shipping
        .filter(order => order.spedizione === 'premium')
        .forEach(order => {
          // Select all orders until the batch won't be completed
          totalWeight += order.totalProductWeight;
          if (totalWeight <= this.kgLotto) {
            order.isChecked = true;
          } else {
            order.isChecked = false;
          }
        });
    },
    selectOrdersCompletedWithThisAssignment() {
      //isAssignmentCompletingOrder

      let totalWeight = this.currentKg + this.kgAssegnati;

      this.ordersData
        // Select orders with Premium shipping
        .filter(order => order.isAssignmentCompletingOrder)
        .forEach(order => {
          // Select all orders until the batch won't be completed
          totalWeight += order.totalProductWeight;
          // Select the order if totalWeight
          order.isChecked = totalWeight <= this.kgLotto;
        });
    },
    async calculateKgFromBoxes(boxesOrders) {
      if (boxesOrders.length === 0) {
        return [];
      }
      // Find the boxes ids
      let boxesIds = [];

      boxesOrders.forEach(order =>
        order.node.lineItems.edges.forEach(lineItem => {
          if (lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.currentQuantity > 0) {
            boxesIds = [...boxesIds, lineItem.node.product.id];
          }
        })
      );

      if (boxesIds.length === 0) {
        return [];
      }

      this.boxesIds = boxesIds;

      this.$apollo.queries.nodes.start();
      // Call apollo to get the boxes content
      const response = await this.$apollo.queries.nodes.refetch({
        ids: boxesIds,
        boxInfo: 'box_info',
        boxContent: 'content'
      });

      const boxesContents =
        (response &&
          response.data.nodes.map(node => {
            return {
              id: node.id,
              title: node.title,
              variants:
                node.variants && node.variants.edges
                  ? node.variants.edges.map(variant => {
                      const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';

                      const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';

                      return {
                        id: variant.node.id,
                        content: jsonVariantContent
                      };
                    })
                  : []
            };
          })) ||
        [];

      this.boxesContents = boxesContents || [];

      // Get only lineItems that include boxes
      const ordersWithBoxLineItems = boxesOrders.map(boxesOrder => {
        boxesOrder.node.filteredLineItems = boxesOrder.node.lineItems.edges.filter(
          lineItem => lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.currentQuantity > 0
        );
        return boxesOrder;
      });

      // Calculate totalProductWeight for each order

      return ordersWithBoxLineItems.map(order => {
        let totalKgFromBox = 0;
        order.node.filteredLineItems.forEach(lineItem => {
          // Get box content
          const boxContent = boxesContents.find(content => content.id === lineItem.node.product.id);
          const boxContentVariants = boxContent ? boxContent.variants : [];

          // Find product box content in variants (if exists)
          const orderBoxVariant = boxContentVariants.length !== 0 ? boxContentVariants.find(variant => variant.id === lineItem.node.variant.id) : null;
          const variantWithSelectedProduct = orderBoxVariant ? orderBoxVariant.content.find(content => content.productId === this.product.id) : '';

          if (variantWithSelectedProduct) {
            totalKgFromBox += lineItem.node.currentQuantity * variantWithSelectedProduct.quantityInKg;
          }
        });
        return {
          orderId: order.node.id,
          totalKgFromBox
        };
      });
    },

    async calculateOrdersKg(orders) {
      // 1. Get only unarchived orders
      const unarchivedOrders = orders.edges.filter(order => {
        // archiviation
        const foundVendorArchiviationNode = order.node.archiviation.edges.find(vendorNode => vendorNode.node.key === this.selectedVendor);
        return foundVendorArchiviationNode && foundVendorArchiviationNode.node.value !== 'true';
      });

      if (unarchivedOrders.length === 0) {
        this.allDataReady = true;
        return;
      }

      // 2. Find lineItems containing current product
      let results = unarchivedOrders.map(order => {
        let orderVariants = [];

        let orderProductWeight = 0;

        const validLineItems = order.node.lineItems.edges.filter(lineItem => {
          return lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.variant && lineItem.node.variant.id && lineItem.node.currentQuantity > 0;
        });

        validLineItems.forEach(lineItem => {
          // If line item includes current product, add its weight to total product weight
          if (lineItem.node.product.id === this.product.id) {
            const itemWeight = lineItem.node.variant ? convertWeightToKg(lineItem.node.variant.weight, lineItem.node.variant.weightUnit) : 0;
            orderProductWeight += lineItem.node.currentQuantity * itemWeight;
            orderVariants = [...orderVariants, lineItem.node.variant.title];
          }
        });
        // Get all the products ids that had already been associated with a production batch
        const batchesProductsIds = order.node.orderBatches.edges
          .map(batchInfo => {
            // If the key is a product id (so can be converted to a number), return the product id
            return Number(batchInfo.node.key) ? `gid://shopify/Product/${batchInfo.node.key}` : '';
          })
          .filter(productId => productId);

        const combinedProductOrderId = `${this.product.id}_${order.node.id}`;
        // Check if the item id is included in the array of the orders already associated with the production batch
        const isOrderAssociated = this.$store.getters.batchAssociatedOrders.includes(combinedProductOrderId);

        return {
          id: combinedProductOrderId,
          productId: getShortProductId(this.product.id),
          orderId: order.node.id,
          name: order.node.name,
          orderNumber: parseInt(order.node.name.split('#')[1]),
          date: order.node.createdAt,
          paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
          lineItems: validLineItems,
          totalProductWeight: orderProductWeight,
          productVariant: orderVariants.length !== 0 ? orderVariants.join(', ') : `${orderProductWeight} kg`,
          productTitle: this.product.name,
          nrOfProducts: validLineItems.length,
          batchesProductsIds,
          isChecked: order.isChecked || isOrderAssociated || this.allOrdersSelected || false,
          isDisabled: order.isDisabled || isOrderAssociated,
          spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard'
        };
      });

      // 2. Check if there are some orders containing boxes
      // Find the orders that include at least one box
      const boxesOrders = [...unarchivedOrders].filter(
        order => [...order.node.lineItems.edges].filter(lineItem => lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.currentQuantity > 0).length !== 0
      );

      try {
        // Get kg of product sold from boxes
        const kgFromBoxes = await this.calculateKgFromBoxes(boxesOrders);

        // Add boxesKg to each order and check if assigning this order to a batch,
        // makes the order ready to be sent

        results.forEach(resultOrder => {
          // If the resultOrder
          let orderWithBoxKg = null;
          if (kgFromBoxes.length !== 0) {
            kgFromBoxes.find(order => order.orderId === resultOrder.orderId);
          }

          if (orderWithBoxKg) {
            const extraKgFromBoxes = orderWithBoxKg.totalKgFromBox;
            resultOrder.totalProductWeight += extraKgFromBoxes;
            resultOrder.productVariant = `${(resultOrder.totalProductWeight += extraKgFromBoxes)} kg`;
          }

          // Check if all the products (apart from the current one) are already associated with a batch
          let orderProductsIds = new Set([]);
          resultOrder.lineItems.forEach(lineItem => {
            if (lineItem.node.product.id !== this.product.id) orderProductsIds.add(lineItem.node.product.id);
            // If the product is a box, get its products ids
            if (lineItem.node.product.tags.includes(boxTag)) {
              // Find a box
              const foundBox = this.boxesContents.find(box => box.id === lineItem.node.product.id) || {};
              // Find box variant
              const foundVariant = (foundBox.variants && foundBox.variants.find(variant => variant.id === lineItem.node.variant.id)) || {};
              // Get ids of the products of the found box variant
              const contentIds = (foundVariant.content && foundVariant.content.map(contentProduct => contentProduct.productId)) || [];
              // Add contentIds to orderProductsIds
              contentIds.forEach(contentId => orderProductsIds.add(contentId));
            }
          });

          // Check if all orderProductsIds are included in batchesProductsIds
          let isAssignmentCompletingOrder = true;

          orderProductsIds.forEach(productId => {
            isAssignmentCompletingOrder = isAssignmentCompletingOrder && resultOrder.batchesProductsIds.includes(productId);
          });

          resultOrder.isAssignmentCompletingOrder = isAssignmentCompletingOrder;
        });

        // Disable the orders already associated with the batch
        results.forEach(order => {
          order.isDisabled = this.batchAssociatedOrders.includes(order.id);
          order.isChecked = this.batchAssociatedOrders.includes(order.id);
        });

        // Remove the orders with 0kg of the product from the list of orders
        let filteredResults = [...results].filter(result => result.totalProductWeight !== 0 && result.lineItems.length > 0);
        this.ordersData = [...filteredResults];
        this.allDataReady = true;
      } catch (err) {
        // Error
      }
    }
  },
  watch: {
    orders(val) {
      if (val && val.edges) {
        this.fullyLoaded = false;

        // Check if there is the second page

        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          this.fullyLoaded = true;
          // If there are no orders including current product - return
          if (val.edges.length === 0) {
            this.allDataReady = true;
            return;
          }
          this.calculateOrdersKg(val);
        }
      }
    },
    selections(val) {
      if (val.length === 0) {
        this.isOverloaded = false;
      }

      // Calculate total weight of selections
      // Exclude disabled orders (they are already calculated)
      let totalWeight = 0;
      val.filter(selection => !selection.isDisabled).forEach(selectedOrder => (totalWeight += selectedOrder.totalProductWeight));

      this.kgAssegnati = totalWeight;

      // Allow next step if the lotto is completed
      if (totalWeight > this.kgLotto) {
        this.isOverloaded = true;
      } else {
        this.isOverloaded = false;
      }

      this.addRegisteredOrders([val, this.product.id]);
    },

    allAssignedKg(val) {
      // Alert if too many orders have been selected
      if (val > this.kgLotto) {
        this.removeStepCompleteness(3);
        this.addAlert({ msg: `Troppi ordini selezionati. La capacità del lotto è di ${this.kgLotto} kg.`, type: 'error' });
      } else {
        this.completeStep(3);
      }
    },

    smartAssignmentType(val) {
      switch (val) {
        case 'cronologico':
          this.selectOrdersChronologically();
          break;
        case 'intervalloDiTempo':
          this.selectOrdersByTimeRange();
          break;
        case 'spedizione':
          this.selectPremiumOrders();
          break;
        case 'otherAssignment':
          this.selectOrdersCompletedWithThisAssignment();
          break;
      }
    },
    product(val) {
      this.orders = [];
      this.fullyLoaded = false;
      this.allDataReady = false;
      // Refetch orders with a new product id
      this.$apollo.queries.orders.refetch({
        queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND tag:${val.id} AND created_at:>=${this.startingDate}`
      });
    }
  },

  provide() {
    return {
      handleChange: this.toggleChecked
    };
  },
  updated() {
    document.body.style.overflow = 'auto';
    this.currentKg = this.product.current;
  },
  mounted() {
    this.completeStep(3);
  },
  beforeDestroy() {
    this.fullyLoaded = false;
    this.allDataReady = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.lottoCreationTable {
  flex-basis: 30%;

  &:not(:last-child) {
    margin-right: 2.9rem;

    @include respond('tab-port') {
      margin-right: 0;
      margin-bottom: 2.9rem;
    }
  }

  @include respond('tab-port') {
    margin-right: 0;
    flex-basis: 100%;
  }

  &__button {
    width: 100%;
  }

  &__batch {
    margin-top: 1.6rem;
    background-color: $color-grey-light;
    border-radius: 2px;
    @include flex-parent-space-between;
    @include default-font-size;
    color: $color-dark-blue;
    padding: 0.6rem 0.9rem;

    @include respond('tab-port') {
      margin-top: 0;
    }
  }

  &__batchNr {
    @include bold-text;
  }

  &__infoKg {
    @include default-font-size;
    line-height: 2.2rem;
    color: $color-dark-blue;
    margin-top: 1.8rem;

    @include respond('tab-port') {
      margin-top: calculateMobRem(18px);
    }
  }

  &__assignment {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 3.2rem;
  }

  &__assignmentInfo {
    margin-bottom: 0;
    margin-left: 1.5rem;
  }

  &__table {
    margin-top: 2rem;
    position: relative;
    @include respond('tab-port') {
      margin-top: calculateMobRem(27px);
    }
  }

  &__tools {
    margin-bottom: 2rem;

    @include respond('tab-port') {
      @include flex-parent-space-between;
    }
  }

  &__smartBtn {
    @include respond('tab-port') {
      width: 100%;
    }
  }

  &__info {
    margin-top: 1.5rem;
  }
}
</style>
