<template>
  <div class="home">
    <div class="page-scroll home__col1" v-if="!orderDetailsShown">
      <BaseCard fullCard hideMobile>
        <BaseLoadingSpinner v-if="!fullyLoaded || !startingDate"></BaseLoadingSpinner>
        <WeekOrders v-else :orders="formattedOrders"></WeekOrders>
      </BaseCard>
    </div>
    <div :class="['page-scroll', 'home__col2', { 'u-hidden': orderDetailsShown && isTabMode }]">
      <BaseCard hideMobile fullCard>
        <div class="home__headerCol2">
          <BaseHeading :level="2">Lista dei nuovi ordini</BaseHeading>
          <span class="home__headerNrOfOrders">{{ currentSetOfOrders.length }} ordini</span>
        </div>
        <BaseLoadingSpinner v-if="!fullyLoaded || !startingDate"></BaseLoadingSpinner>
        <VendorOrdersList v-else :orders="formattedOrders" @refetch-orders="refetchOrders"></VendorOrdersList>
        <div class="home__buttons">
          <BaseButton extraClass="home__button" @handle-click="$router.push('/archivio-ordini')">Archivio ordini</BaseButton>
          <BaseButton extraClass="home__button" @handle-click="$router.push('/prodotti')">I miei prodotti</BaseButton>
        </div>
      </BaseCard>
    </div>
    <div class="page-scroll home__col3" v-if="orderDetailsShown">
      <BaseCard hideMobile fullCard>
        <div class="home__heading">
          <BaseHeading :level="2" extraStyle="margin-bottom:4rem">Dettaglio ordine</BaseHeading>
          <p class="u-linkBack" @click="goBack"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
        </div>
        <SwiperOrders v-if="isTabMode"></SwiperOrders>
        <Order v-else :orderId="currentOrder"></Order>
      </BaseCard>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a vendor's home page
 *
 * @displayName HomeVendors
 */
import BaseCard from '@bc/BaseCard';
import WeekOrders from '@c/vendor/WeekOrders';
import BaseHeading from '@bc/BaseHeading';
import VendorOrdersList from '@c/vendor/VendorOrdersList';
import BaseButton from '@bc/BaseButton.vue';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseIcon from '@bc/BaseIcon';
import SwiperOrders from '@c/common/SwiperOrders.vue';
import Order from '@c/common/Order';
import { getXMonthsAgoDate, checkIfJsonIsValid, getNumericDate, convertWeightToKg } from '@u/helperFunctions';
import { mapGetters, mapActions } from 'vuex';
import { getOrdersOfTheWeekByVendor } from '@gq/getOrdersOfTheWeekByVendor.gql';

export default {
  name: 'HomeVendor',
  components: {
    BaseButton,
    BaseCard,
    BaseHeading,
    BaseIcon,
    BaseLoadingSpinner,
    Order,
    SwiperOrders,
    VendorOrdersList,
    WeekOrders
  },
  data() {
    return {
      ordersLoaded: false,
      startingDate: '',
      showVendorsOrder: false,
      formattedOrders: [],
      fullyLoaded: false
    };
  },

  computed: {
    ...mapGetters(['isMobileMode', 'currentSetOfOrders', 'orderDetailsShown', 'isTabMode', 'vendorOrdersListShown', 'currentVendor', 'currentVendors', 'currentOrder'])
  },
  methods: {
    ...mapActions(['hideVendorOrdersList', 'hideOrderDetails']),
    forceAllDataLoaded() {
      this.fullyLoaded = true;
    },
    goBack() {
      this.hideOrderDetails();
    },
    async fetchMoreOrders() {
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND created_at:>=${this.startingDate}`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          return {
            orders: newOrders
          };
        }
      });
    },
    refetchOrders() {
      this.fullyLoaded = false;
      this.$apollo.queries.orders.start();
      this.$apollo.queries.orders.refetch({
        queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND created_at:>=${this.startingDate}`
      });
    }
  },

  apollo: {
    orders() {
      // Calculate last 2 months period
      const date2MonthAgo = getXMonthsAgoDate(2);
      // Set starting date to the date 2 months ago
      const numDate2MonthsAgo = getNumericDate(date2MonthAgo);
      this.startingDate = numDate2MonthsAgo;

      return {
        query: getOrdersOfTheWeekByVendor,
        skip: true,
        variables: {
          queryString: `financial_status:PAID,PARTIALLY_REFUNDED AND created_at:>=${numDate2MonthsAgo}`
        },
        error() {
          this.forceAllDataLoaded();
        }
      };
    }
  },

  watch: {
    orders(val) {
      if (val && val.edges) {
        // Check if there is next page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          this.fullyLoaded = true;

          if (val.edges.length === 0) {
            this.forceAllDataLoaded();
            return;
          }

          // CALCULATE ORDERS DATA
          /**
           * Looping through all the vendor orders.
           * For every order maps its line items for better information format.
           */
          const ordersResults = val.edges.map(order => {
            const { tags, lineItems } = order.node;
            // Remove invalid lineItems
            const validLineItems = lineItems.edges.filter(
              lineItem => lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.variant && lineItem.node.variant.id && lineItem.node.currentQuantity > 0
            );

            const orderBatches = order.node.orderBatches.edges.map(batchInfo => {
              const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
              return {
                key: batchInfo.node.key,
                value: jsonBatchValue
              };
            });
            // Find only valid batches and take their numbers
            const orderBatchesNumbers = orderBatches.filter(batch => Number(batch.key)).map(batch => batch.value.ddt_number);

            // Check if the current vendor's line items are archived
            let isArchived = true;
            if (order.node.archiviation && order.node.archiviation.edges) {
              const vendorNodes = order.node.archiviation.edges.filter(orderNode => orderNode.node && this.currentVendors.includes(orderNode.node.key));
              if (vendorNodes.length !== 0) vendorNodes.forEach(vendorNode => (isArchived = isArchived && vendorNode && vendorNode.node.value && vendorNode.node.value === 'true'));
            }

            return {
              archiviation: order.node.archiviation ? order.node.archiviation.edges : [],
              clientData: {
                firstName: order.node.customer ? order.node.customer.firstName : '---',
                lastName: order.node.customer ? order.node.customer.lastName : '---'
              },
              date: order.node.createdAt,
              id: order.node.id,
              isArchived,
              isChecked: order.isChecked || false,
              label: order.node.label ? order.node.label.edges : [],
              lineItems:
                validLineItems.length > 0
                  ? validLineItems.map(item => {
                      const { product, variant, currentQuantity } = item.node;
                      return {
                        product: product && product.title,
                        productId: product && product.id,
                        quantity: currentQuantity,
                        variantId: variant && variant.id,
                        weight: variant && convertWeightToKg(variant.weight, variant.weightUnit),
                        weightUnit: 'Kg'
                      };
                    })
                  : [],
              name: order.node.name,
              // calculate line items of current vendor
              nrOfProducts: validLineItems.length > 0 ? validLineItems.filter(item => this.currentVendors.includes(item.node.vendor)).length : 0,
              orderBatchesNumbers,
              paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
              spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard',
              tags,
              vendors: this.currentVendors.filter(vendor => validLineItems.find(item => item.node.vendor === vendor))
            };
          });

          // Get only orders that have not been archived yet and that have some valid line items
          const activeOrders = ordersResults.filter(order => !order.isArchived && order.nrOfProducts > 0);

          this.formattedOrders = activeOrders;
        }
      }
    }
  },

  provide() {
    return {
      goBack: this.goBack,
      handleArchiveStatus: this.refetchOrders
    };
  },

  created() {
    this.refetchOrders();
  },

  mounted() {
    window.addEventListener('load', this.handleScreenWidth);

    window.addEventListener('resize', this.handleScreenWidth);

    this.$store.commit({ type: 'hideOrderDetails' });
  },
  beforeDestroy() {
    this.ordersLoaded = false;
    this.showVendorsOrder = false;
    this.formattedOrders = [];
    this.fullyLoaded = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.home {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 11rem 2.4rem 2.4rem 2.4rem;
  }

  @include respond('phone') {
    padding: 11rem 0 0 0;
  }

  &__col1 {
    margin-right: 0.5rem;
    padding: 4.5rem 2rem 4.5rem 2rem;
    text-align: left;
    flex-basis: 45rem;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100vh;
    overflow-y: auto;

    @include respond('tab-port') {
      padding: 0 2rem;
      margin: 0 0 2.4rem 0;
      width: 100%;
      height: fit-content;
      overflow: visible;
    }

    @include respond('phone') {
      padding: 0 1rem;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-land') {
      max-width: 50vw;
    }

    @include respond('tab-port') {
      padding: 0 2rem 4.5rem 2rem;
      margin: 0 0 2.4rem 0;
      max-width: unset;
      height: fit-content;
    }

    @include respond('phone') {
      padding: 0 1rem 4.5rem 1rem;
    }
  }

  &__col3 {
    margin-left: 0.5rem;
    flex-grow: 0;
    width: 46rem;
    height: 100vh;
    overflow-y: auto;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      margin-left: 0;
      width: 80%;
      align-self: center;
      height: fit-content;
      overflow-y: visible;
      padding: 0 2rem 4.5rem 2rem;
    }

    @include respond('phone') {
      width: 100%;
      padding: 0 1rem 4.5rem 1rem;
    }
  }

  &__headerCol2 {
    @include flex-parent-space-between;
    margin-bottom: 4rem;

    @include respond('tab-land') {
      margin-bottom: 2rem;
    }
  }

  &__headerNrOfOrders {
    font-size: 1.2rem;
    color: $color-dark-blue;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(12px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(12px);
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__span {
    font-weight: 700;
    font-size: 3rem;

    @include respond('tab-port') {
      font-size: 3.75rem;
    }
  }

  &__buttons {
    display: none;
    margin-top: 3rem;
    @include respond('tab-port') {
      @include flex-parent-space-between;
    }
  }

  &__button {
    width: 100%;
  }

  &__button:not(:last-child) {
    margin-right: calculateMobRem(19px);
  }
}
</style>
