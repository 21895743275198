import { render, staticRenderFns } from "./ProductsMobileTab.vue?vue&type=template&id=fef5e862&scoped=true&"
import script from "./ProductsMobileTab.vue?vue&type=script&lang=js&"
export * from "./ProductsMobileTab.vue?vue&type=script&lang=js&"
import style0 from "./ProductsMobileTab.vue?vue&type=style&index=0&id=fef5e862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fef5e862",
  null
  
)

export default component.exports