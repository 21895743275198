<template>
  <div class="horizontal-scroll barChart">
    <CChartHorizontalBar :style="chartStyle" :datasets="computedDatasets" :labels="labels" :options="computedOptions" />
  </div>
</template>

<script>
/**
 * This component uses CoreUI CChartHorizontalBar to display a horizontal bar chart
 *  * Detailed documentation: https://www.chartjs.org/docs/latest/charts/bar.html
 *
 * @displayName BaseHorizontalBarChart
 */
import { CChartHorizontalBar } from '@coreui/vue-chartjs';
import { deepObjectsMerge } from '@coreui/utils/src';
export default {
  name: 'BaseHorizontalBarChart',
  components: { CChartHorizontalBar },
  props: {
    /**
     * This prop is used to pass labels of the chart. You can pass an array of strings or one of the string values
     * "months" or "indexes". Months mode will display months from January.
     * Indexes mode will assign numbers from 1.
     */
    labels: {
      type: [Array, String],
      default: 'months',
      validator: function (value) {
        if (!Array.isArray(value)) {
          // The value must match one of these strings
          return ['months', 'indexes'].indexOf(value) !== -1;
        }
        return true;
      }
    },
    /**
     * This prop is used to pass the datasets used in the chart.
     * Each dataset should receive an array of data and a label
     * Additionally you can pass backgroundColor (or array of background colors)
     * borderColor/-s and borderWidth
     */
    datasets: {
      type: Array,
      required: true,
      default() {
        return [
          {
            label: 'Ordini ricevuti',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          },
          {
            label: 'Ordini inviati',
            data: [30, 20, 10, 30, 10, 40, 45, 80, 40, 80, 12, 11]
          }
        ];
      }
    },
    /**
     * This prop is used to pass additional styles of the chart
     * To set height to a fixed value maintainAspectRatio should be off
     */
    extraStyle: { type: String },
    /**
     * This prop is used to pass additional options
     */
    options: {
      type: Object,
      default() {
        return {
          maintainAspectRatio: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                display: true
              }
            ],
            yAxes: [
              {
                display: true
              }
            ]
          }
        };
      }
    }
  },
  computed: {
    /**
     * This value is used to overwrite the default dataset values: backgroundColor, borderColor, borderWidth
     * labels and data
     */
    computedDatasets() {
      const newDataset = this.datasets.map(dataset => {
        return {
          label: dataset.label || 'items',
          data: dataset.data,
          backgroundColor: dataset.backgroundColor || 'rgba(243, 156, 18, 1)',
          borderColor: dataset.borderColor || 'rgba(243, 156, 18, 1)',
          borderWidth: dataset.borderWidth || 1
        };
      });
      return newDataset;
    },
    /**
     * This value is used to overwrite the default options.
     * Switch on/off aspect ratio, legend on the top, and x and y axes visibiity
     */
    defaultOptions() {
      return {
        maintainAspectRatio: false,

        legend: {
          display: false
        },

        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 10,
                fontColor: '#B4BBD0'
              },
              gridLines: {
                display: true,
                color: '#F5F6FA',
                zeroLineColor: 'transparent'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: 12,
                fontColor: '#B4BBD0'
              },
              gridLines: {
                display: true,
                color: '#F5F6FA',
                zeroLineColor: 'transparent'
              }
            }
          ]
        }
      };
    },
    computedOptions() {
      return deepObjectsMerge(this.defaultOptions, this.options || {});
    },
    chartStyle() {
      return `position: relative; ${this.extraStyle}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.barChart {
  max-width: 100vw;
  overflow-x: auto;
  padding-bottom: 2rem;
}
</style>

<docs>
### BaseHorizontalBarChart  
```js
<BaseHorizontalBarChart></BaseHorizontalBarChart>
```
</docs>
