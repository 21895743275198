<template>
  <BaseLoadingSpinner v-if="$apollo.loading || !fullyLoaded"></BaseLoadingSpinner>
  <form v-else @submit.prevent>
    <BaseTopBar :title="vendor" :subtitle="`${ordersData.length} ordini`" @go-back-fn="goBack"></BaseTopBar>
    <BaseOrdersTools
      :searchOptions="['ID del prodotto', 'Ordine', 'Lotto']"
      :showSelectAll="isTabMode"
      @toggle-select-all="toggleSelectAll"
      @search-data="searchOrders"
      :isDisabled="selectedOrders.length === 0"
      :allOrdersSelected="allOrdersSelected"
      :selectedOrders="selectedOrders"
      extraClass="orders__tools"
      @handle-archive-status="handleArchiveStatus"
    ></BaseOrdersTools>
    <div class="horizontal-scroll orders__container">
      <TableOrders
        :orders="filteredOrdersData"
        :columns="['col', 'col', 'col', 'col-short', 'col-short', 'col']"
        :filters="topFilters"
        :allOrdersSelected="allOrdersSelected"
        @toggle-select-all="toggleSelectAll"
        @handle-filter-change="handleFilterChange"
        hideMobSelectAll
      ></TableOrders>
    </div>
  </form>
</template>
<script>
/**
 * This component loads a container used to display the list of vendor's orders
 *
 * @displayName AdminVendorOrdersList
 */
import { mapGetters, mapActions } from 'vuex';
import BaseTopBar from '@bc/BaseTopBar';
import BaseOrdersTools from '@bc/BaseOrdersTools';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import TableOrders from '@c/common/TableOrders';
import { getOrdersPreviewsByVendor } from '@gq/getOrdersPreviewsByVendor.gql';
import { styleShortDate, checkIfJsonIsValid, sortItemsByDateFromPresentToPast, getXMonthsAgoDate, getNumericDate } from '@u/helperFunctions';
import { searchOrdersMixin } from '@c/mixins/searchMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

// Filters titles
const paymentDetailsFilter = 'Dettaglio pagamento';
const shippingFilter = 'Spedizione';
const dateFilter = 'Data ordine';

export default {
  name: 'AdminVendorOrdersList',
  components: {
    BaseTopBar,
    BaseOrdersTools,
    TableOrders,
    BaseLoadingSpinner
  },
  mixins: [searchOrdersMixin, sleepMixin],
  props: {
    /**
     * This prop is used to pass an array of two dates (selected time period)
     */

    selectedTimePeriod: { type: Array, required: true },

    /**
     * This prop is used to pass an array of orderArchiviationInput
     */

    orderInputToBeArchived: { type: Array }
  },
  data() {
    return {
      vendor: this.$store.getters.currentVendor,
      allOrdersSelected: false,
      orders: [],
      ordersData: [],
      selectedOrders: [],
      filteredOrdersData: [],
      fullyLoaded: false,
      startingDate: '',

      topFilters: [
        { name: 'Cliente' },
        {
          name: paymentDetailsFilter,
          filters: [
            {
              value: 'Non pagato',
              isSelected: true
            },
            {
              value: 'Pagato',
              isSelected: true
            }
          ]
        },
        {
          name: shippingFilter,
          filters: [
            {
              value: 'Standard',
              isSelected: true
            },
            {
              value: 'Premium',
              isSelected: true
            },
            {
              value: 'Appuntamento',
              isSelected: true
            }
          ]
        },
        {
          name: dateFilter,
          filters: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['currentSetOfOrders', 'isTabMode', 'currentVendor', 'throttledErrors'])
  },

  methods: {
    ...mapActions(['set', 'hideOrderDetails', 'hideVendorOrdersList', 'showVendorOrdersList', 'setDataIsLoadingMsg', 'removeThrottledError']),
    toggleSelectAll() {
      const selectedOrders = this.filteredOrdersData;

      selectedOrders.forEach(order => (order.isChecked = !this.allOrdersSelected));
      this.allOrdersSelected = !this.allOrdersSelected;
      this.filteredOrdersData = [...selectedOrders];
      this.getSelectedOrders();
    },

    toggleChecked(orderId) {
      const updatedOrdersData = this.filteredOrdersData;

      // Find selected input
      const selectedInput = updatedOrdersData.find(order => order.id === orderId);

      // Toggle selected input isChecked value
      selectedInput.isChecked = !selectedInput.isChecked;
      this.filteredOrdersData = [...updatedOrdersData];
      this.getSelectedOrders();
    },

    handleArchiveStatus(archiviationInput) {
      let updatedOrdersData = this.ordersData;
      let updatedFilteredOrdersData = this.filteredOrdersData;

      archiviationInput.forEach(input => {
        // Remove archive orders from ordersData
        updatedOrdersData = updatedOrdersData.filter(order => {
          return !(order.id === input.id);
        });

        // Remove archive orders from filteredOrdersData
        updatedFilteredOrdersData = updatedFilteredOrdersData.filter(order => {
          return !(order.id === input.id);
        });
      });

      this.ordersData = [...updatedOrdersData];
      this.filteredOrdersData = [...updatedFilteredOrdersData];
      this.set(['currentSetOfOrders', [...updatedFilteredOrdersData]]);

      if ([...updatedFilteredOrdersData].length !== 0) {
        const currentOrderId = [...updatedFilteredOrdersData][0].id;
        this.set(['currentOrder', currentOrderId]);
      } else {
        this.set(['currentOrder', '']);
        this.set(['orderDetailsShown', false]);
      }

      this.getSelectedOrders();
      this.fetchOrders();
    },

    goBack() {
      this.hideVendorOrdersList();
      this.hideOrderDetails();
    },
    handleSetOfOrders(type) {
      if (type === 'set') {
        this.set(['currentSetOfOrders', this.ordersData]);
      } else {
        this.set(['currentSetOfOrders', []]);
      }
    },
    handleFilterChange(name, value) {
      let updatedTopFilters = this.topFilters;
      let selectedFilters = updatedTopFilters.find(filter => filter.name === name);
      let selectedFilter = selectedFilters.filters.find(filter => filter.value === value);
      selectedFilter.isSelected = !selectedFilter.isSelected;
      this.topFilters = updatedTopFilters;
    },

    getSelectedOrders() {
      let selections = [];

      if (this.filteredOrdersData.length !== 0) {
        selections = this.filteredOrdersData.filter(order => order.isChecked);
      }

      this.selectedOrders = selections;

      return selections;
    },

    async fetchMoreOrders() {
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: `vendor:${this.$store.getters.currentVendor} AND created_at:>=${this.startingDate}`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          return {
            orders: newOrders
          };
        }
      });
    },

    fetchOrders() {
      this.$apollo.queries.orders({ queryString: `vendor:${this.$store.getters.currentVendor}` });
    },
    displayLoadingPopup() {
      // Show loading popup
      this.setDataIsLoadingMsg('Caricamento dati...');
    },

    async retryAndRemoveError(err) {
      // Remove error
      this.$store.commit('removeThrottledError', err);
      await this.sleep(2000 + Math.random() * 500);

      // Refetch the query
      this.$apollo.queries[err.path].refetch(err.variables);
    }
  },
  apollo: {
    // Call when component is rendered
    orders() {
      // Calculate last 2 months period
      const date2MonthAgo = getXMonthsAgoDate(2);
      // Set starting date to the date 2 months ago
      const numDate2MonthsAgo = getNumericDate(date2MonthAgo);

      this.startingDate = numDate2MonthsAgo;

      return {
        query: getOrdersPreviewsByVendor,
        variables: {
          queryString: `vendor:${this.$store.getters.currentVendor} AND created_at:>=${numDate2MonthsAgo}`
        },
        manual: true,
        result({ data, loading }) {
          if (!loading && data) {
            this.orders = data.orders;
          }
        },
        error() {
          this.orders = [];
          this.ordersData = [];
          this.fullyLoaded = true;
        }
      };
    }
  },

  watch: {
    orders(val, oldVal) {
      if (this.selectedTimePeriod && this.selectedTimePeriod[0] !== null && this.orders.edges && this.orders.edges.length !== 0) {
        // Check if there is the second page

        if (val.pageInfo.hasNextPage) {
          this.fullyLoaded = false;
          this.fetchMoreOrders(oldVal);
        } else {
          this.fullyLoaded = true;
        }

        if (this.fullyLoaded) {
          let results = [];
          if (this.orders.edges && this.orders.edges.length !== 0) {
            results = this.orders.edges.map(order => {
              const orderBatches = order.node.orderBatches.edges.map(batchInfo => {
                const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
                return {
                  key: batchInfo.node.key,
                  value: jsonBatchValue
                };
              });

              // Remove line items without vendor's field
              const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.currentQuantity > 0) || [];

              // Find only valid batches and take their numbers
              const orderBatchesNumbers = orderBatches.filter(batch => Number(batch.key)).map(batch => batch.value.ddt_number);

              // Check if the vendor's part of the order is archived
              let isArchived = true;
              if (order.node.archiviation && order.node.archiviation.edges) {
                const vendorNode = order.node.archiviation.edges.find(orderNode => orderNode.node.key === this.vendor);
                isArchived = isArchived && vendorNode && vendorNode.node.value === 'true';
              }
              return {
                id: order.node.id,
                name: order.node.name,
                nrOfProducts: validLineItems.length,
                vendors: validLineItems.length > 0 ? validLineItems.map(item => item.node.vendor) : [],
                tags: order.node.tags,
                clientData: {
                  firstName: order.node.shippingAddress ? order.node.shippingAddress.firstName : '---',
                  lastName: order.node.shippingAddress ? order.node.shippingAddress.lastName : '---'
                },
                paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
                orderBatchesNumbers,
                spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard',
                date: order.node.createdAt,
                isChecked: order.isChecked || false,
                archiviation: order.node.archiviation ? order.node.archiviation.edges : [],
                label: order.node.label ? order.node.label.edges : [],
                isArchived,
                vendor: this.vendor
              };
            });
          }

          // Get only orders that have not yet been archived and that have some valid line items
          const activeOrders = results.filter(order => !order.isArchived && order.nrOfProducts > 0);

          // Sort results by date
          const sortedOrders = sortItemsByDateFromPresentToPast({ arr: activeOrders, key: 'date' });

          this.ordersData = sortedOrders;
          this.filteredOrdersData = sortedOrders;
          if (results.length > 0) {
            this.set(['currentSetOfOrders', sortedOrders]);
          }

          // Get all the orders dates
          const datesArr = sortedOrders.map(order => styleShortDate(order.date));
          const uniqueDates = [...new Set(datesArr)];
          const uniqueDateFilters = uniqueDates.map(date => {
            return {
              value: date,
              isSelected: true
            };
          });
          // Update topFilters
          this.topFilters.find(filters => filters.name === dateFilter).filters = uniqueDateFilters;
        }
      }
    },
    topFilters: {
      deep: true,
      handler(val) {
        let filteredOrders = this.ordersData;
        /**
         * 1. valore dei filtri applicati
         * 2. ciclare ordini e prendere solo quelli con il valore del filtro
         * 3. aggiornare elenco
         */

        // PAYMENT FILTERS
        // 1.
        const paymentFilters = val.find(item => item.name === paymentDetailsFilter).filters;
        const selectedPaymentFilters = paymentFilters.filter(filter => filter.isSelected);
        const selectedPaymentFiltersValues = selectedPaymentFilters.length !== 0 ? selectedPaymentFilters.map(filter => filter.value) : [];
        // 2.
        filteredOrders = filteredOrders.filter(order => {
          // if all there are no filters selected or all are selected, show all the results
          if (selectedPaymentFiltersValues.length === paymentFilters.length) return order;
          if (selectedPaymentFiltersValues.includes('Pagato')) return order.paymentStatus === 'PAID';
          if (selectedPaymentFiltersValues.includes('Non pagato')) return order.paymentStatus !== 'PAID';
          return;
        });

        // SHIPPING FILTERS
        // 1.
        const shippingFilters = val.find(item => item.name === shippingFilter).filters;
        const selectedShippingFilters = shippingFilters.filter(filter => filter.isSelected);
        const selectedShippingFiltersValues = selectedShippingFilters.length !== 0 ? selectedShippingFilters.map(filter => filter.value) : [];
        // 2.
        filteredOrders = filteredOrders.filter(order => {
          if (
            // if all there are no filters selected or all are selected, show all the results
            selectedShippingFiltersValues.length === shippingFilters.length
          ) {
            return order;
          } else {
            return selectedShippingFiltersValues.includes(order.spedizione);
          }
        });

        // DATE FILTERS
        // 1.
        const dateFilters = val.find(item => item.name === dateFilter).filters;
        const selectedDateFilters = dateFilters.filter(filter => filter.isSelected);
        const selectedDateFiltersValues = selectedDateFilters.length !== 0 ? selectedDateFilters.map(filter => filter.value) : [];
        // 2.
        filteredOrders = filteredOrders.filter(order => {
          // if all there are no filters selected or all are selected, show all the results
          if (selectedDateFiltersValues.length === dateFilters.length) {
            return order;
          } else {
            return selectedDateFiltersValues.includes(styleShortDate(order.date));
          }
        });

        // Get only unarchived orders
        filteredOrders = filteredOrders.filter(order => !order.isArchived);

        // 3.
        this.filteredOrdersData = filteredOrders;
        this.set(['currentSetOfOrders', filteredOrders]);
      }
    },

    orderInputToBeArchived(input) {
      this.handleArchiveStatus(input);
    },

    // THROTTLING

    throttledErrors(val) {
      if (val && val.length > 0) {
        this.displayLoadingPopup();
        val.forEach(error => {
          // Retry in few seconds
          this.retryAndRemoveError(error);
        });
      }
    }
  },

  provide() {
    return {
      handleChange: this.toggleChecked
    };
  },

  unmounted() {
    this.handleSetOfOrders('clear');
  }
};
</script>
<style lang="scss" scoped>
.orders {
  @import '@s/_variables.scss';
  @import '@s/_mixins.scss';

  &__container {
    min-height: 50vh;
    overflow-x: auto;
    @include respond('tab-land') {
      margin-top: 2rem;
    }
  }

  &__filter {
    @include respond('phone') {
      display: none;
    }
  }

  &__tools {
    background-color: $color-white;
    margin-bottom: 2.7rem;
    padding-bottom: 1.5rem;
    padding-top: 2rem;
    position: sticky;
    top: -4.5rem;
    z-index: 50;
  }
}
</style>
