<template>
  <tr v-if="!isTabMode" class="customerOrdersItem__row">
    <td class="customerOrdersItem__cell1">
      <input type="checkbox" :id="item.id" :checked="item.isChecked" @change="$emit('handle-change', item.id)" class="customerOrdersItem__input" name="ordini_lottoItem" :value="item.id" />
      <label :for="item.id" class="customerOrdersItem__label">
        <span class="customerOrdersItem__btn"></span>
      </label>
    </td>
    <td class="customerOrdersItem__cell">
      <span class="u-bold-text">{{ item.name }}</span>
    </td>
    <td class="customerOrdersItem__cell">{{ item.nrOfProducts }}</td>
    <td class="customerOrdersItem__cell">{{ item.paymentMethod }}</td>
    <td class="customerOrdersItem__cell">{{ orderDate }}</td>
  </tr>
  <li v-else class="customerOrdersItem__li">
    <div class="customerOrdersItem__col1">
      <input type="checkbox" :id="item.id" :checked="item.isChecked" @change="$emit('handle-change', item.id)" class="customerOrdersItem__input" name="ordini_lottoItem" :value="item.id" />
      <label :for="item.id" class="customerOrdersItem__label">
        <span class="customerOrdersItem__btn"></span>
      </label>
    </div>
    <div class="customerOrdersItem__col2">
      <div class="customerOrdersItem__header">
        <BaseHeading :level="6">{{ item.name }}</BaseHeading>
      </div>
      <div class="customerOrdersItem__details">
        <p>Numero di prodotti: {{ item.nrOfProducts }}</p>
        <p>Metodo di pagamento: {{ item.paymentMethod }}</p>
        <p>Data: {{ orderDate }}</p>
      </div>
    </div>
  </li>
</template>
<script>
/**
 * This component loads a lotto item
 *
 * @displayName CustomerOrdersItem
 */

import BaseHeading from '@bc/BaseHeading';
import { styleShortDate } from '@u/helperFunctions.js';
import { mapGetters } from 'vuex';

export default {
  name: 'CustomerOrdersItem',
  components: {
    BaseHeading
  },
  props: {
    /**
     * This prop is used to pass the lotto object to display it as a table row
     */
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isTabMode']),
    orderDate() {
      return styleShortDate(this.item.date);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

$cell-padding: 0.7rem 1rem 1.4rem 1rem;

// cell padding if clip-path is not supported

$cell-padding-no-clip: 0.7rem;
.customerOrdersItem {
  &__row {
    background-color: $color-grey-light;
    font-family: inherit;
    @include default-font-size;
    color: $color-dark-blue;
    border-bottom: 0.7rem solid $color-white;

    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      clip-path: inset(0 0 0.7rem round 3px);

      border-bottom: none;
    }
  }

  &__cell {
    padding: 1rem;
    vertical-align: middle;
    padding: $cell-padding-no-clip;
    position: relative;
    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: $cell-padding;
    }
  }

  &__cell:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    height: 90%;
    width: 0.5px;
    background-color: $color-dark-blue;

    @include respond('tab-port') {
      display: none;
    }
  }

  &__cell1 {
    vertical-align: middle;
    position: relative;
  }

  &__cell1:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    height: 70%;
    width: 1px;
    background-color: $color-grey-light;

    @include respond('tab-port') {
      display: none;
    }
  }

  &__label {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__btn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    @include flex-parent-center-childs;
    margin: 0 1rem;
    background: $color-white;
    border-radius: 3px;
    @include respond('tab-port') {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0;
    }
  }

  &__btn::after {
    content: '';
    display: block;
    height: 1.7rem;
    width: 1.7rem;
    background-image: url('../../assets/SVG/checkmark.svg');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.2s;
    border-radius: 2px;

    @include respond('tab-port') {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  &__input {
    display: none;
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }

  // Mobile

  &__li {
    list-style: none;
    background-color: $color-grey-light;
    border-radius: 3px;
    padding: 1rem 1.375rem;
    display: flex;
    margin-bottom: 1.375rem;
  }

  &__col1 {
    width: 4rem;
    flex-shrink: 0;
  }

  &__col2 {
    flex-grow: 1;
  }
  &__header {
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-dark-blue;
  }

  &__weight {
    font-weight: 400;
  }

  &__details {
    @include default-font-size;
    line-height: 2.75rem;
    margin-top: 1rem;
  }

  &__link {
    text-decoration: underline;
    margin-left: 0.7rem;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__icon {
    color: $color-dark-blue;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__icon:hover,
  &__ddt:hover &__icon {
    color: $color-primary;
  }

  &__ddt:hover &__link {
    color: $color-primary;
  }
}
</style>
