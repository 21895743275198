<template>
  <div class="products">
    <div class="page-scroll products__col1" v-if="!isTabMode || (isTabMode && !selectedItemId)">
      <BaseCard fullCard hideMobile extraClass="products__card1">
        <BaseHeading extraClass="products__heading" :level="2">I miei prodotti</BaseHeading>
        <BaseSearch extraClass="products__search" @search-data="handleSearch"></BaseSearch>
        <BaseLoadingSpinner v-if="$apollo.loading || !productsFullyLoaded"></BaseLoadingSpinner>
        <ul v-else class="products__results">
          <BaseItemButton
            v-for="product in filteredProductsData"
            :key="product.id"
            :title="product.name"
            extraClass="products__itemButton"
            :subtitle="product.vendor"
            :itemId="product.id"
            :isDisabled="!itemSwitchingEnabled"
            :isActive="selectedItemId === product.id"
            @handle-click="value => handleProductSelection(value)"
          ></BaseItemButton>
        </ul>
      </BaseCard>
    </div>
    <div v-if="!selectedItemId" class="page-scroll products__col2">
      <BaseCard fullCard hideMobile extraClass="products__card">
        <BaseHeading v-if="!isTabMode" extraClass="products__heading products__heading--2" :level="2">Statistiche generali</BaseHeading>
        <div :class="['products__filter', { 'datepicker-dark': isTabMode }]">
          <span v-if="!isTabMode" class="products__filterTxt">Visualizzazione dati sul prodotto nel tempo:</span>
          <DatePicker v-model="selectedTimeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
        </div>
        <BaseLoadingSpinner v-if="$apollo.loading"></BaseLoadingSpinner>
        <div v-if="!$apollo.loading && productsData.length !== 0" class="products__charts">
          <VendorProductsStats :products="productsData" :selectedTimeRange="selectedTimeRange"></VendorProductsStats>
        </div>
      </BaseCard>
    </div>
    <div v-else class="page-scroll products__col3">
      <BaseCard fullCard hideMobile extraClass="products__card">
        <ProductSheet></ProductSheet>
      </BaseCard>
    </div>
  </div>
</template>
<script>
import BaseCard from '@bc/BaseCard';
import BaseHeading from '@bc/BaseHeading';
import BaseSearch from '@bc/BaseSearch';
import DatePicker from 'vue2-datepicker';
import BaseItemButton from '@bc/BaseItemButton';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import VendorProductsStats from '@c/vendor/VendorProductsStats';
import ProductSheet from '@c/common/ProductSheet';
import { getVendorProductsNamesWithContent } from '@gq/getVendorProductsNamesWithContent.gql';
import { mapActions, mapGetters } from 'vuex';
import { checkIfJsonIsValid } from '@u/helperFunctions';

export default {
  name: 'ProductsVendor',
  components: {
    BaseCard,
    BaseHeading,
    BaseSearch,
    BaseItemButton,
    DatePicker,
    VendorProductsStats,
    ProductSheet,
    BaseLoadingSpinner
  },
  data() {
    return {
      products: [],
      selectedTimeRange: [],
      productsData: [],
      filteredProductsData: [],
      itemSwitchingEnabled: false,
      productsFullyLoaded: false
    };
  },
  computed: {
    ...mapGetters(['selectedItemId', 'isTabMode', 'isMobileMode'])
  },
  methods: {
    ...mapActions(['set']),
    handleSearch(value) {
      const filteredResults = this.productsData.filter(product => product.name.toLowerCase().startsWith(value.toLowerCase()));

      this.filteredProductsData = [...filteredResults];
    },
    handleProductSelection(id) {
      if (this.itemSwitchingEnabled && id !== this.selectedItemId) {
        this.itemSwitchingEnabled = false;
        this.set(['selectedItemId', id]);
      }
    },
    setItemDataLoaded(value) {
      this.itemSwitchingEnabled = value;
    },
    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.fetchMore({
        variables: {
          cursor: lastCursor,
          boxInfo: 'box_info',
          boxContent: 'content',
          productInfo: 'product_info',
          type: 'type'
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    }
  },
  apollo: {
    products() {
      return {
        query: getVendorProductsNamesWithContent,
        variables: {
          boxInfo: 'box_info',
          boxContent: 'content',
          productInfo: 'product_info',
          type: 'type'
        }
      };
    }
  },
  watch: {
    products(val) {
      if (val.edges) {
        // Check if there is the second page

        if (val.pageInfo.hasNextPage) {
          this.productsFullyLoaded = false;
          this.fetchMoreProducts();
          return;
        }

        if (val.edges.length === 0) {
          this.itemSwitchingEnabled = true;
        } else {
          const results = val.edges.map(product => {
            return {
              id: product.node.id,
              name: product.node.title,
              tags: product.node.tags,
              type: product.node.type ? product.node.type.value : '',
              variants: product.node.variants.edges.map(variant => {
                const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';
                const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';
                return {
                  id: variant.node.id,
                  content: jsonVariantContent
                };
              }),
              vendor: product.node.vendor
            };
          });

          const productsResults = results.filter(({ type }) => type !== 'farmer');

          this.itemSwitchingEnabled = true;
          this.productsData = productsResults;
          this.filteredProductsData = productsResults;
        }

        this.productsFullyLoaded = true;
      }
    }
  },

  provide() {
    return {
      setItemDataLoaded: this.setItemDataLoaded
    };
  },
  created() {
    const dayToday = new Date();
    const dayOneWeekBefore = new Date(dayToday.setDate(dayToday.getDate() - 7));
    const initialTimeRange = [dayOneWeekBefore, new Date()];
    this.selectedTimeRange = initialTimeRange;
  },
  beforeDestroy() {
    this.set(['selectedItemId', '']);
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.products {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 8rem 0 0 0;
    margin-bottom: calculateMobRem(24px);
  }

  &__col1 {
    flex-basis: 45rem;
    flex-shrink: 0;
    padding: 4.5rem 2rem 4.5rem 2rem;
    height: 100vh;
    overflow-y: auto;
    margin-right: 0.5rem;

    @include respond('tab-port') {
      padding: 2rem 0 0 0;
      margin: 0;
      width: 100%;
      height: fit-content;
      overflow: visible;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      margin-left: 0;
      margin-right: 0;

      padding: 0 0 4.5rem 0;
      height: fit-content;
    }
  }

  &__col3 {
    flex-grow: 1;
    margin-left: 0.5rem;
    height: 100vh;
    overflow-y: auto;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      margin-left: 0;
      width: 80%;
      align-self: center;
      height: fit-content;
      overflow-y: visible;
      padding: 0 0 4.5rem 0;
    }

    @include respond('phone') {
      margin-left: 0;
      width: 100%;
    }
  }

  &__card1 {
    @include respond('tab-port') {
      padding: 0 calculateMobRem(24px);
    }
  }

  &__card {
    padding: 0;

    @include respond('tab-port') {
      padding: 0 calculateMobRem(24px);
    }
  }

  &__heading {
    margin-bottom: 4rem;

    @include respond('tab-port') {
      margin-bottom: calculateTabLandRem(19px);
    }

    @include respond('tab-port') {
      padding: 1rem 0;
      margin-bottom: calculateMobRem(19px);
    }

    &--2 {
      padding: 3.5rem 3.5rem 0 3.5rem;
    }
  }

  &__search {
    margin-bottom: 4.2rem;
    flex-grow: 0;

    @include respond('tab-port') {
      margin-bottom: calculateMobRem(30px);
    }
  }

  &__results {
    @include respond('tab-port') {
      padding-bottom: calculateMobRem(29px);
      border-bottom: 2px solid $color-grey-light;
      margin-bottom: 0;
    }
  }

  &__itemButton:not(:last-child) {
    margin-bottom: 1.5rem;

    @include respond('tab-land') {
      margin-bottom: calculateTabLandRem(15px);
    }

    @include respond('tab-port') {
      margin-bottom: calculateMobRem(15px);
    }
  }

  &__filter {
    background-color: $color-dark-blue;
    padding: 2.4rem 4.5rem;
    position: sticky;
    top: -4.5rem;
    left: 0;
    z-index: 1;
    @include flex-parent-space-between;

    @include respond('tab-port') {
      background-color: $color-white;
      position: static;
      padding: 3.75rem 0;
    }
  }

  &__filterTxt {
    color: $color-white;
    flex-basis: 60%;
  }

  &__charts {
    padding: 0.5rem 4.5rem 4.5rem 4.5rem;

    @include respond('tab-port') {
      padding: 0;
    }
  }
}
</style>
