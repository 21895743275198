<template>
  <button
    :class="['button-secondary', { 'button-secondary--small': isSmall }, { 'button-secondary--light': isLight }, extraClass]"
    @click="$emit('handle-click')"
    :style="extraStyle"
    :disabled="disabled"
  >
    <BaseIcon :extraClass="btnIconClass" :icon="btnIcon"></BaseIcon><span class="button-secondary__span"><slot></slot></span>
  </button>
</template>

<script>
/**
 * This component loads a button used to add new document
 * @displayName BaseButtonSecondary
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseButtonSecondary',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used disable the button
     */
    disabled: { type: Boolean },
    /**
     * This prop is used to make the button lighter
     */
    isLight: { type: Boolean, default: false },
    /**
     * This prop is used to make the button smaller
     */
    isSmall: { type: Boolean, default: false },
    /**
     * This prop is used to pass the icon of the button. Icons should be defined in assets/symbol-defs.svg
     */
    btnIcon: { type: String },
    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  computed: {
    btnIconClass() {
      let iconClassList = ['button-secondary__icon'];
      if (this.isSmall) {
        iconClassList = [...iconClassList, 'button-secondary__icon--iconSmall'];
      }
      if (this.isLight) {
        iconClassList = [...iconClassList, 'button-secondary__icon--iconDark'];
      }

      return iconClassList.join(' ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_functions.scss';
@import '@s/_mixins.scss';

.button-secondary {
  &,
  &:link,
  &:visited {
    color: $color-white;
    font-size: 1.8rem;
    padding: 1.6rem;
    text-decoration: none;
    outline: none !important;
    background-color: $color-secondary;
    transition: all 0.5s linear;
    border: none;
    width: fit-content;
    border-radius: 4px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @include respond('tab-land') {
      padding: 1rem;
      font-size: calculateTabLandRem(18px);
    }

    @include respond('tab-port') {
      padding: 1rem;
      font-size: calculateMobRem(18px);
      min-width: calculateMobRem(370px);
    }

    @include respond('phone') {
      padding: 1rem;
      font-size: calculateMobRem(18px);
      min-width: unset;
      width: 100%;
    }
  }

  &:hover,
  &:active {
    background-color: rgba($color-secondary, 0.8);
  }

  &--small {
    @include default-font-size;
    border-radius: 3px;
    padding: 1.2rem;
  }

  &--light {
    background-color: rgba($color-secondary, 0.15);
    color: $color-secondary;

    &:hover,
    &:active {
      background-color: rgba($color-secondary, 0.1);
      color: $color-secondary;
    }
  }

  &__icon {
    color: $color-white;
    height: 2rem !important;
    width: 2rem !important;
    margin-right: 1.2rem;
    flex-shrink: 0;

    &--iconSmall {
      height: 1.2rem !important;
      width: 1.2rem !important;
    }

    &--iconDark {
      color: $color-secondary;
    }
  }
}
</style>

<docs>
### BaseButtonSecondary simple
```js
<BaseButtonSecondary>Inserisci un nuovo lotto di produzione</BaseButtonSecondary>
```
 
</docs>
