import Vue from 'vue';
import Vuex from 'vuex';
import { getNumericDate, getShortProductId } from '@u/helperFunctions.js';
Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: true,
  vendorOrdersListShown: false,
  currentVendor: '',
  orderDetailsShown: false,
  currentOrder: '',
  currentSetOfOrders: [],
  screenWidth: 0,
  selectedItemId: '',
  selectedView: 'Prodotti',
  userRole: 'vendor', // 'vendor', 'hub' or 'admin'
  isLottoDisplayed: false,
  currentLotto: '',
  currentLottoVendor: '',
  isCustomerPopupDisplayed: false,
  currentCustomerId: '',
  shopProducts: [],
  shopVendors: [],
  productsNames: [],
  shopOrderedProducts: [],
  user: {},
  sortedProductsData: [],
  sortedArchiveProducts: [],

  // GESTIONE LOTTI
  selectedVendor: '',
  registeredProducts: [],
  registeredOrders: [],
  batchAssociatedOrders: [],
  activeStep: 0,
  steps: [
    { title: 'Selezione del produttore', completed: false, disabled: false },
    { title: 'Dettagli prodotti', completed: false, disabled: false },
    { title: 'Collegamento agli ordini ricevuti', completed: false, disabled: false },
    { title: 'Ordini pronti per la spedizione', completed: false, disabled: false }
  ],
  areSettingsDisplayed: false,
  dataIsLoadingMsg: '',
  productionBatches: [],
  disableBatchEditing: false,
  isArchiveModalLoading: false,

  // GESTIONE ERRORI
  throttledErrors: [],
  alerts: []
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  showVendorOrdersList(state, vendor) {
    state.vendorOrdersListShown = true;
    state.currentVendor = vendor;
  },
  hideVendorOrdersList() {
    state.vendorOrdersListShown = false;
    state.currentVendor = '';
  },
  showOrderDetails(state, orderId) {
    if (state.currentOrder === orderId) {
      // If the user clicked current order, close the order details
      state.orderDetailsShown = false;
      state.currentOrder = '';
    } else {
      state.orderDetailsShown = true;
      state.currentOrder = orderId;
    }
  },

  showPermanentOrderDetails(state, orderId) {
    state.orderDetailsShown = true;
    state.currentOrder = orderId;
  },
  hideOrderDetails(state) {
    state.orderDetailsShown = false;
    state.currentOrder = '';
  },

  handleScreenWidth(state) {
    state.screenWidth = parseInt(window.innerWidth);
    // Hide page scrollbar on desktop
    if (state.screenWidth > 992) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  },

  showLotto(state, payload) {
    const nrLotto = payload[0];
    const currentLottoVendor = payload[1];
    state.currentLotto = nrLotto;
    state.currentLottoVendor = currentLottoVendor;
    state.isLottoDisplayed = true;
  },

  hideLotto() {
    state.currentLotto = '';
    state.isLottoDisplayed = false;
  },

  showCustomerPopup(state, clientId) {
    if (!clientId) return;
    state.currentCustomerId = clientId;
    state.isCustomerPopupDisplayed = true;
  },

  hideCustomerPopup() {
    state.currentCustomerId = '';
    state.isCustomerPopupDisplayed = false;
  },

  setCurrentOrders(state, payload) {
    state.currentSetOfOrders = payload[0];
    state.currentOrder = payload[1];
  },

  /**
   * Mutation used to push a notification to the alerts array
   *
   * @param {Object} payload - an object including alert message and type (success/error/info)
   */

  addAlert(state, payload) {
    const newAlert = {
      msg: payload.msg,
      id: Date.now() + Math.floor(Math.random() * 100000),
      type: payload.type || 'info'
    };

    state.alerts.push(newAlert);
    setTimeout(() => {
      // Remove notification
      state.alerts = state.alerts.filter(item => item.id !== newAlert.id);
    }, 30000);
  },

  /**
   * Mutation used to remove a notification from the alerts array
   *
   * @param {String} payload - the alert ID
   */

  removeAlert(state, payload) {
    state.alerts = state.alerts.filter(item => item.id !== payload);
  },

  // GESTIONE LOTTI
  /**
   * Mutation used to update the array of registered products
   *
   * @param {Object} payload - an object containing product data
   */

  updateBatchProducts(state, payload) {
    const { id, quantita, dataRaccolta, dataDDT, numeroDDT, comune, provincia, foglio, particella } = payload;
    // Check if provided production batch number exists.
    const floatProductId = getShortProductId(id);
    let existingProductionBatch = state.productionBatches.find(batch => batch.ddt_number === numeroDDT);

    const isoCollectionDate = getNumericDate(dataRaccolta);
    const isoDDTdate = getNumericDate(dataDDT);
    const batchProductData = {
      id: floatProductId,
      grams: Number(quantita) * 1000
    };

    // Check if the product is already registered
    let existingBatchProduct = existingProductionBatch.products.find(product => product.id === floatProductId);
    if (existingBatchProduct) {
      existingBatchProduct.grams = Number(batchProductData.grams);
    } else {
      existingProductionBatch.products = [...existingProductionBatch.products, batchProductData];
    }

    existingProductionBatch = {
      collection_date: isoCollectionDate,
      products: [existingProductionBatch.products],
      ddt_number: numeroDDT,
      ddt_date: isoDDTdate,
      location_name: comune,
      province: provincia,
      town: comune,
      sheet: foglio,
      compartment: particella
    };

    // Update all the registered products with the same batch data
    state.registeredProducts
      .filter(product => product.numeroDDT === numeroDDT)
      .forEach(filteredProduct => {
        filteredProduct.dataRaccolta = dataRaccolta;
        filteredProduct.provincia = provincia;
        filteredProduct.foglio = foglio;
        filteredProduct.comune = comune;
        filteredProduct.particella = particella;
        filteredProduct.dataDDT = dataDDT;
      });
  },

  /**
   * Mutation used to add a production batch to the registered production batches
   *
   * @param {Object} payload - an object containing the information about new production batch
   */

  addProductionBatch(state, payload) {
    payload.products.forEach(product => (product.grams = product.grams * 1000));
    state.productionBatches = [...state.productionBatches, payload];
  },

  /**
   * Mutation used to update the id of a production batch
   *
   * @param {Array} payload - an object containing ddt_number of the batch
   * to be updated and the name of the property that should be added and its value
   */

  updateProductionBatchProperty(state, payload) {
    const ddt_number = payload[0];
    const property = payload[1];
    const value = payload[2];
    state.productionBatches.find(batch => batch.ddt_number === ddt_number)[property] = value;
  },

  /**
   * Mutation used to update the array of registered products
   *
   * @param {Array} payload - an array containing two params 1st the name of the selected vendor
   * 2nd the object with the product details
   */

  addRegisteredProduct(state, payload) {
    const vendor = payload[0];
    const productData = payload[1];

    const { id, dataRaccolta, dataDDT, numeroDDT, comune, provincia, foglio, particella, current } = productData;

    let existingLottoProduct = state.registeredProducts.find(product => product.id === id);
    if (existingLottoProduct) {
      // If product already exists, update its data and all the products from the same lotto

      existingLottoProduct = {
        vendor: vendor,
        ...productData
      };

      state.registeredProducts
        .filter(product => product.numeroDDT === numeroDDT)
        .forEach(filteredProduct => {
          filteredProduct.dataRaccolta = dataRaccolta;
          filteredProduct.provincia = provincia;
          filteredProduct.foglio = foglio;
          filteredProduct.comune = comune;
          filteredProduct.particella = particella;
          filteredProduct.dataDDT = dataDDT;
        });
    } else {
      // If not, add it to registeredProducts array

      state.registeredProducts = [
        ...state.registeredProducts,
        {
          vendor: vendor,
          ...productData
        }
      ];
    }
  },

  /**
   * Mutation used to update a property of a registered product
   *
   * @param {Array} payload - an object containing the id of the product
   * to be updated, the name of the property that should be added and its value
   */

  updateRegisteredProductProperty(state, payload) {
    const product_id = payload[0];
    const property = payload[1];
    const value = payload[2];
    state.registeredProducts.find(product => product.id === product_id)[property] = value;
  },

  /**
   * Mutation used to update the array of registered orders (= orders already associated with a production batch)
   *
   * @param {Array} payload - an array containing two params
   * 1st the array of the selected orders
   * 2nd the id of the product associated with this production batch
   */

  addRegisteredOrders(state, payload) {
    const orders = payload[0];
    const productId = payload[1];

    const existingProductOrders = state.registeredOrders.find(registeredOrder => registeredOrder.productId === productId);

    if (existingProductOrders) {
      // Update exisitng product orders
      existingProductOrders.orders = orders;
    } else {
      // Push product object to registeredOrders
      const productOrders = {
        productId,
        orders
      };

      state.registeredOrders = [...state.registeredOrders, productOrders];
    }
  },

  /**
   * Mutation used to update the array of registered orders (= orders already associated with a production batch)
   *
   * @param {Array} payload - an array containing the orders that should replace current orders
   */

  updateRegisteredOrders(state, payload) {
    state.registeredOrders = payload;
  },

  /**
   * Mutation used to set the step to completed
   *
   * @param {Number} stepNumber - the number of step that should be set to completed
   */

  completeStep(state, stepNumber) {
    state.steps[stepNumber - 1].completed = true;
  },

  /**
   * Mutation used to remove step completeness
   *
   * @param {Number} stepNumber - the number of step that should be set to incompleted
   */

  removeStepCompleteness(state, stepNumber) {
    state.steps[stepNumber - 1].completed = false;
  },

  /**
   * Mutation used to set the step to complete and go to the next step
   *
   * @param {Number} stepNumber - the number of step that should be set to completed
   */

  completeStepAndGoNext(state, stepNumber) {
    state.steps[stepNumber - 1].completed = true;
    state.activeStep = stepNumber;
  },

  /**
   * Mutation used to disable or enable the step
   *
   * @param {Number} stepNumber - the number of step that should be set to disabled
   * @param {Boolean} value - true to disable the step or false to enable it
   */

  disableEnableStep(state, payload) {
    const stepNumber = payload[0];
    const value = payload[1];
    state.steps[stepNumber - 1].disabled = value;
  },

  /**
   * Mutation used to reset the completeness of the steps
   */

  resetLottoCreationData(state) {
    state.steps = [
      { title: 'Selezione del produttore', completed: false, disabled: false },
      { title: 'Dettagli prodotti', completed: false, disabled: false },
      { title: 'Collegamento agli ordini ricevuti', completed: false, disabled: false },
      { title: 'Ordini pronti per la spedizione', completed: false, disabled: false }
    ];
    state.registeredProducts = [];
    state.productionBatches = [];
    state.registeredOrders = [];
    state.batchAssociatedOrders = [];
    state.activeStep = 0;
    state.selectedVendor = '';
  },
  openSettings() {
    state.areSettingsDisplayed = true;
  },

  toggleSettings() {
    state.areSettingsDisplayed = !state.areSettingsDisplayed;
  },

  closeSettings() {
    state.areSettingsDisplayed = false;
  },

  /**
   * Mutation used to set the notification about loading state
   * If the message is set, the little popup will be displayed
   * to inform the user about the loading
   */
  setDataIsLoadingMsg(state, msg) {
    state.dataIsLoadingMsg = msg;
    setTimeout(() => {
      // Remove loading popup
      state.dataIsLoadingMsg = '';
    }, 3000);
  },

  /**
   * Mutation used to a new error to throttledErrors array
   */
  pushThrottledError(state, error) {
    state.throttledErrors = [...state.throttledErrors, error];
  },

  /**
   * Mutation used to remove an error from throttledErrors array
   */
  removeThrottledError(state, throttledError) {
    const filteredErrors = state.throttledErrors.filter(err => err.id !== throttledError.id);
    // if (filteredErrors.length === 0) {
    //   // Remove loading popup
    //   state.dataIsLoadingMsg = '';
    // }
    state.throttledErrors = [...filteredErrors];
  },

  /**
   * Mutation used to update the user saved on the store
   *
   * @param {Object} user - Object containing information about the logged user (ex.: email, role)
   */
  updateUser(state, user) {
    state.user = user;
  }
};

const getters = {
  currentVendors: (state, getters) => {
    if (getters.isUserAdmin) return [];
    return state.user.vendors;
  },

  isMobileMode: state => state.screenWidth < 640,

  isTabMode: state => state.screenWidth < 992,

  isTabLandMode: state => state.screenWidth < 1200,

  isSidebarShown: state => state.sidebarShow,

  minimize: state => state.sidebarMinimize,

  currentOrder: state => state.currentOrder,

  currentSetOfOrders: state => state.currentSetOfOrders,

  currentVendor: (state, getters) => {
    if (getters.isUserVendor) return state.user.vendors[0];
    return state.currentVendor;
  },

  vendorOrdersListShown: state => state.vendorOrdersListShown,

  orderDetailsShown: state => state.orderDetailsShown,

  isLottoDisplayed: state => state.isLottoDisplayed,

  currentLotto: state => state.currentLotto,

  currentLottoVendor: state => state.currentLottoVendor,

  isCustomerPopupDisplayed: state => state.isCustomerPopupDisplayed,

  currentCustomerId: state => state.currentCustomerId,

  selectedItemId: state => state.selectedItemId,

  selectedView: state => state.selectedView,
  /**
   * Getter used to retrieve the user from the state
   */
  user: state => state.user,
  /**
   * Getter used to retrieve the logged user role (admin or vendor)
   */
  userRole: state => state.user.role,

  /**
   * Getter used to check if the user role is 'ADMIN'
   */
  isUserAdmin: state => state.user.role === 'ADMIN',
  /**
   * Getter used to check if the user role is 'PRODUCER'
   */
  isUserVendor: state => state.user.role === 'PRODUCER',

  isAuthenticated: state => state.isAuthenticated,

  screenWidth: state => state.screenWidth,

  shopVendors: state => state.shopVendors,

  shopProducts: state => state.shopProducts,

  shopOrderedProducts: state => state.shopOrderedProducts,

  productsNames: state => state.productsNames,

  /**
   * Getter used to get the products sorted by sales
   */
  sortedProductsData: state => state.sortedProductsData,

  /**
   * Getter used to sort the archive products by sales
   */
  sortedArchiveProducts: state => state.sortedArchiveProducts,

  // GESTIONE LOTTI
  /**
   * Getter used to get the id / name of the selected vendor
   */
  selectedVendor: state => state.selectedVendor,

  /**
   * Getter used to get an array of the registered products
   */
  registeredProducts: state => state.registeredProducts,

  /**
   * Getter used to get an array of order ids that have already been associated
   * with the current production batch and should be removed from the orders list
   */

  batchAssociatedOrders: state => state.batchAssociatedOrders,

  /**
   * Getter used to get an array of orders that have been associated with
   * the production batch, during its creation
   */

  registeredOrders: state => state.registeredOrders,

  /**
   * Getter used to get the index of the active step
   */
  activeStep: state => state.activeStep,

  /**
   * Getter used to get the object with the steps to be completed in the process
   * of creating batch production
   */
  steps: state => state.steps,

  /**
   * Getter used to get the array of the registered production batches
   */
  productionBatches: state => state.productionBatches,

  /**
   * Getter used to disable 1st and 2nd step of the process of batch creation
   */
  disableBatchEditing: state => state.disableBatchEditing,

  areSettingsDisplayed: state => state.areSettingsDisplayed,

  dataIsLoadingMsg: state => state.dataIsLoadingMsg,

  isArchiveModalLoading: state => state.isArchiveModalLoading,

  throttledErrors: state => state.throttledErrors,

  alerts: state => state.alerts
};

const actions = {
  toggleSidebarDesktop: ({ commit }) => {
    commit('toggleSidebarDesktop');
  },
  toggleSidebarMobile: ({ commit }) => {
    commit('toggleSidebarMobile');
  },
  set: ({ commit }, payload) => {
    commit('set', payload);
  },
  showOrderDetails: ({ commit }, payload) => {
    commit('showOrderDetails', payload);
  },
  showPermanentOrderDetails: ({ commit }, payload) => {
    commit('showPermanentOrderDetails', payload);
  },
  showVendorOrdersList: ({ commit }, payload) => {
    commit('showVendorOrdersList', payload);
  },
  hideVendorOrdersList: ({ commit }) => {
    commit('hideVendorOrdersList');
  },

  hideOrderDetails: ({ commit }) => {
    commit('hideOrderDetails');
  },

  handleScreenWidth: ({ commit }) => {
    commit('handleScreenWidth');
  },

  showLotto: ({ commit }, payload) => {
    commit('showLotto', payload);
  },

  hideLotto: ({ commit }) => {
    commit('hideLotto');
  },

  showCustomerPopup: ({ commit }, payload) => {
    commit('showCustomerPopup', payload);
  },

  hideCustomerPopup: ({ commit }) => {
    commit('hideCustomerPopup');
  },

  setTotalWeights: ({ commit }, payload) => {
    commit('setTotalWeights', payload);
  },

  setTotalOrdersNr: ({ commit }, payload) => {
    commit('setTotalOrdersNr', payload);
  },

  setCurrentOrders: ({ commit }, payload) => {
    commit('setCurrentOrders', payload);
  },

  addAlert: ({ commit }, payload) => {
    commit('addAlert', payload);
  },

  removeAlert: ({ commit }, payload) => {
    commit('removeAlert', payload);
  },

  // GESTIONE LOTTI
  /**
   * Action used to update the array of registered products and registered production batches
   *
   * @param {Array} payload - an array containing two or three params
   * 1st the name of the selected vendor
   * 2nd the object with the product details
   * 3rd boolean value. If true don't show alert notifications
   * **/

  addRegisteredProduct: ({ commit }, payload) => {
    const productData = payload[1];
    const disableAlert = payload[2];

    // Check if provided production batch number exists.
    const floatProductId = getShortProductId(productData.id);
    const existingProductionBatch = state.productionBatches.find(batch => batch.ddt_number === productData.numeroDDT);

    const isoCollectionDate = getNumericDate(productData.dataRaccolta);
    const isoDDTdate = getNumericDate(productData.dataDDT);
    const batchProductData = {
      id: floatProductId,
      grams: Number(productData.quantita)
    };

    if (existingProductionBatch) {
      // If so, check if the production batch data match the ones passed in product data
      // Update batch (push or update the product)
      commit('updateBatchProducts', productData);
    } else {
      // Add production batch to productionBatches array
      const newProductionBatch = {
        collection_date: isoCollectionDate,
        products: [batchProductData],
        ddt_number: productData.numeroDDT,
        ddt_date: isoDDTdate,
        location_name: productData.comune,
        province: productData.provincia,
        town: productData.comune,
        sheet: productData.foglio,
        compartment: productData.particella
      };

      commit('addProductionBatch', newProductionBatch);
    }
    const existingRegisteredProduct = state.registeredProducts.find(product => product.id === productData.id);
    commit('addRegisteredProduct', payload);
    if (!disableAlert && !existingRegisteredProduct) {
      if (existingRegisteredProduct) {
        commit('addAlert', { msg: 'Il nuovo prodotto è stato aggiunto con successo.', type: 'success' });
      }
    }
  },

  /**
   * Action used to remove the product from the array of registered products
   *
   * @param {String} payload - the id of the product to be removed
   */

  removeRegisteredProduct: ({ commit }, payload) => {
    // Find the product to be removed
    const filteredProducts = [...state.registeredProducts].filter(product => product.id !== payload);

    // If there are no registered products, remove second step completeness
    if (filteredProducts.length === 0) commit('removeStepCompleteness', 2);
    // Remove the product from a list of registered products
    commit('set', ['registeredProducts', filteredProducts]);
    // Remove the product from production batches
    const floatProductId = getShortProductId(payload);
    if (!state.productionBatches || state.productionBatches.length === 0) return;

    let productBatch = state.productionBatches.find(batch => batch.products.find(product => product.id === floatProductId));
    if (!productBatch) return;

    productBatch.products = productBatch.products.filter(product => product.id !== floatProductId);
    // Remove production batch if there are no associated products
    if (productBatch.products.length === 0) state.productionBatches = state.productionBatches.filter(batch => batch.ddt_number !== productBatch.ddt_number);

    // Remove the product registered orders
    const newRegisteredOrders = [...state.registeredOrders].filter(order => order.productId !== payload);

    commit('updateRegisteredOrders', newRegisteredOrders);
    commit('addAlert', { msg: 'Il prodotto è stato rimosso.', type: 'success' });
  },

  /**
   * Action used to change disableBatchEditing property
   *
   * @param {Boolean} payload - true to disable the property, false to enable it
   */
  handleDisableBatchEditing: ({ commit }, payload) => {
    // Disable batch editing
    commit('set', ['disableBatchEditing', payload]);
    // Disable/enable first and second step
    commit('disableEnableStep', [1, payload]);
    commit('disableEnableStep', [2, payload]);
  },

  addRegisteredProductionBatch: ({ commit }, payload) => {
    commit('addRegisteredProductionBatch', payload);
  },
  addRegisteredOrders: ({ commit }, payload) => {
    commit('addRegisteredOrders', payload);
  },

  completeStep: ({ commit }, payload) => {
    commit('completeStep', payload);
  },
  removeStepCompleteness: ({ commit }, payload) => {
    commit('removeStepCompleteness', payload);
  },
  completeStepAndGoNext: ({ commit }, payload) => {
    commit('completeStepAndGoNext', payload);
  },
  resetLottoCreationData: ({ commit }) => {
    commit('resetLottoCreationData');
  },

  updateProductionBatchProperty: ({ commit }, payload) => {
    commit('updateProductionBatchProperty', payload);
  },

  updateRegisteredProductProperty: ({ commit }, payload) => {
    commit('updateRegisteredProductProperty', payload);
  },

  openSettings: ({ commit }, payload) => {
    commit('openSettings', payload);
  },

  toggleSettings: ({ commit }, payload) => {
    commit('toggleSettings', payload);
  },

  closeSettings: ({ commit }) => {
    commit('closeSettings');
  },

  setDataIsLoadingMsg: ({ commit }, payload) => {
    commit('setDataIsLoadingMsg', payload);
  },

  pushThrottledError: ({ commit, payload }) => {
    commit('pushThrottledError', payload);
  },

  removeThrottledError: ({ commit, payload }) => {
    commit('removeThrottledError', payload);
  },

  /**
   * Action used to trigger the user update mutation
   *
   * @param {Object} user - Object containing information about the logged user (ex.: email, role)
   */
  updateUser({ commit }, user) {
    commit('updateUser', user);
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
