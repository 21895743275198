<template>
  <div class="order">
    <BaseLoadingSpinner v-if="$apollo.loading || !dataLoaded"></BaseLoadingSpinner>
    <div v-if="dataLoaded && orderData.name" :class="['order__box', { 'order__box--success': isPaid }]">
      <div :class="['order__header', { 'order__header--success': isPaid }]">
        <span>{{ orderData.name }}</span>
        <span class="order__date">{{ orderDate }}</span>
      </div>
      <div class="order__vendors">
        <div :class="['order__vendorItems', { 'order__vendorItems--success': isPaid }]" v-for="(lineItem, index) in lineItemsData" :key="index">
          <h4 v-if="isUserAdmin || currentVendors.length > 1" class="order__h4">
            Produttore: <span class="order__span">{{ lineItem[0].vendor }}</span>
          </h4>
          <div class="order__details">
            <h3 class="order__h3">Prodotti:</h3>
            <ul class="order__ul">
              <li class="order__li" v-for="(item, index) in lineItem" :key="index">
                <p class="order__text">
                  {{ item.amount }}x <span class="order__span">{{ item.product }}</span> - {{ item.variantTitle }}
                  <span v-if="item.customAttributesString" class="u-italic-text">({{ item.customAttributesString }})</span>
                </p>
                <p class="order__text">
                  SKU: <span>{{ item.sku }}</span>
                </p>
                <div class="order__lotto">
                  Lotto di produzione:
                  <ul v-if="item.batches.length !== 0" class="order__lottoUl">
                    <li v-for="(batch, index) in item.batches" :key="index" class="order__link" @click="showLotto([batch.batchKey, item.vendor])">{{ batch.batchNr }}</li>
                  </ul>
                  <span v-else>---</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="order__paymentDetails">
        <p class="order__p">
          Tipo di spedizione: <span :class="{ 'u-bold-text': !isUserAdmin }">{{ orderData.spedizione }}</span>
        </p>
        <p class="order__p" v-if="isUserAdmin">Totale pagato: {{ orderData.total }} €</p>
        <p class="order__p" v-if="isUserAdmin">Metodo di pagamento: {{ paymentMethod }}</p>
        <p class="order__p">ID Ordine: {{ orderShortId }}</p>
      </div>
      <div class="order__clientDetails" v-if="isUserAdmin">
        <p class="order__pClient">Contatti cliente:</p>
        <p :class="orderData.clientData.id ? 'order__pClient order__link' : 'order__pClient'" @click="showCustomerPopup(orderData.clientData.id)">{{ clientName }}</p>
        <p class="order__pClient">{{ orderData.clientData.address }}</p>
        <p class="order__pClient">{{ orderData.clientData.phone }}</p>
        <p class="order__pClient">
          <a :href="`mailto:${orderData.clientData.email}`" class="order__link">{{ orderData.clientData.email }}</a>
        </p>
      </div>
      <div class="order__buttons">
        <p v-if="isTabMode" class="order__linkBack" @click="goBack"><BaseIcon icon="icon-chevron-left"></BaseIcon> Indietro</p>
        <div class="order__tools">
          <BasePdfButton :ordersIds="[orderData.id]" btnIcon="icon-file-text" extraClass="order__tool"></BasePdfButton>
          <BaseDdtButton :selectedOrders="[orderData]" @archive-orders="archiveOrderAfterDownloadingDdt" :isArchive="isArchive" extraClass="order__tool"></BaseDdtButton>
          <BaseActionButton v-if="isArchive" :isLoading="isArchiveLoading" btnIcon="icon-Unarchive" isBlue @handle-click="reactivateOrders([orderData.name], [orderId])"></BaseActionButton>
          <BaseActionButton v-else :isLoading="isArchiveLoading" btnIcon="icon-Archive" isBlue @handle-click="checkDdtAndArchiveOrder"></BaseActionButton>
        </div>
      </div>
    </div>
    <Portal v-if="archiveWarningDisplayed">
      <BaseModal confirmTxt="Vai al download della Lettera di Vettura" heading="Attenzione!" columnBtns @close-fn="archiveWarningDisplayed = false" @confirm-fn="downloadDdtAndArchive">{{
        warningTxt
      }}</BaseModal>
    </Portal>
  </div>
</template>
<script>
/**
 * This component displays the details of one order
 *
 * @displayName Order
 */
import { mapActions } from 'vuex';
import BasePdfButton from '@bc/BasePdfButton';
import BaseDdtButton from '@bc/BaseDdtButton';
import BaseActionButton from '@bc/BaseActionButton';
import BaseIcon from '@bc/BaseIcon';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseModal from '@bc/BaseModal';
import { getOrderDetailsById } from '@gq/getOrderDetailsById.gql';
import { getBoxesContent } from '@gq/getBoxesContent.gql';
import { styleShortDate, styleHours, checkIfJsonIsValid, getFullProductId } from '@u/helperFunctions';
import { mapGetters } from 'vuex';
import { archiveOrders } from '@gm/archiveOrders.gql';
import { unarchiveOrders } from '@gm/unarchiveOrders.gql';
import { archiveOrdersMixin, unarchiveOrdersMixin } from '@c/mixins/archiveMixins.js';
import { orderCurVendorsMixin } from '@c/mixins/orderCurVendorsMixin.js';
import { ddtGeneratedMixin } from '@c/mixins/ddtMixins.js';
import { Portal } from '@linusborg/vue-simple-portal';

const boxTag = 'cassetta';

export default {
  name: 'Order',
  mixins: [archiveOrdersMixin, unarchiveOrdersMixin, ddtGeneratedMixin, orderCurVendorsMixin],
  components: {
    BaseIcon,
    BaseLoadingSpinner,
    BasePdfButton,
    BaseActionButton,
    BaseDdtButton,
    Portal,
    BaseModal
  },
  props: {
    /**
     * This prop is used to pass the order id / number
     */
    orderId: { type: String, required: true },
    /**
     * This prop is used to show "ri-attiva ordine" button instead of "archivia ordine"
     */
    isArchive: { type: Boolean }
  },
  data() {
    return {
      // must be uppercase for json-graphql-server
      order: {},
      dataLoaded: false,
      boxesContents: [],
      orderData: {},
      isArchiveLoading: false,
      archiveWarningDisplayed: false,
      // eslint-disable-next-line quotes
      warningTxt: "Non è mai stata scaricata la lettera di vettura dell'ordine selezionato. Procedi con la creazione ed il download della Lettera di Vettura per poter archiviare l'ordine"
    };
  },
  computed: {
    ...mapGetters(['isTabMode', 'currentVendor', 'currentVendors', 'isUserAdmin', 'isUserVendor', 'currentSetOfOrders']),
    clientName() {
      // Get client first and last name. If they don't exist take company, email or phone
      const { firstName, lastName, company, email, phone } = this.orderData.clientData;

      let name = [];
      if (firstName && firstName !== '---') name = [...name, firstName];
      if (lastName && lastName !== '---') name = [...name, lastName];
      if (name.length === 0 && company) name = [company];
      if (name.length === 0 && email) name = [email];
      if (name.length === 0 && phone) name = [phone];

      return name.join(' ');
    },
    isPaid() {
      return this.orderData.paymentStatus === 'PAID';
    },

    // Check if the user is admin
    isUserAdmin() {
      return this.$store.getters.isUserAdmin;
    },

    // Set currentOrderId to the currentOrder from the state
    currentOrderId() {
      return this.$store.getters.currentOrder;
    },
    // Get the vendors for which order's actions should be performed
    curOrderVendors() {
      if (!this.orderData.id) return [];
      return this.getOrderCurVendors(this.orderData.vendors);
    },
    // Convert date to date string ss/mm/yyyy
    orderDate() {
      return styleShortDate(this.orderData.date);
    },

    orderShortId() {
      return this.orderData.id ? this.orderData.id.split('Order/')[1] : '';
    },

    // Style time 00:00
    orderTime() {
      return styleHours(this.orderData.date);
    },
    // Sort lineItems by vendor
    lineItemsData() {
      let orderedLineItems;

      if (this.orderData.name) {
        const lineItems = this.orderData.lineItems;

        // Get all vendors if admin or search by vendor
        const loggedVendors = this.currentVendors.length > 1 ? this.currentVendors.filter(hubVendor => this.orderData.vendors.includes(hubVendor)) : this.currentVendors;
        const vendors = this.isUserAdmin ? this.orderData.vendors : loggedVendors;

        // Sort lineItems by vendor
        orderedLineItems = [...vendors].map(vendor => lineItems.filter(lineItem => lineItem.vendor === vendor));
      }

      return orderedLineItems;
    },
    paymentMethod() {
      if (!this.orderData.methodOfPayment || this.orderData.methodOfPayment.length === 0) return 'altro';

      let methodsArr = [];

      methodsArr = this.orderData.methodOfPayment.map(paymentMethod => {
        let method;
        switch (paymentMethod) {
          case 'manual':
            method = 'Bonifico';
            break;

          case 'paypal':
            method = 'Paypal';
            break;

          case 'stripe':
            method = 'Carta di credito';
            break;

          default:
            method = paymentMethod;
        }

        return method;
      });

      return methodsArr.join(', ');
    },
    selectedOrders() {
      return [this.orderData];
    }
  },

  inject: ['goBack', 'handleArchiveStatus'],

  methods: {
    ...mapActions(['showLotto', 'hideLotto', 'showCustomerPopup', 'addAlert', 'set']),
    async getBoxesContent(orderBoxes) {
      const orderBoxesIds = orderBoxes.map(item => item.node.product.id);

      // Get boxes content
      // Call apollo to get the boxes content
      const uniqBoxesIds = [...new Set(orderBoxesIds)];
      this.$apollo.queries.nodes.start();
      const response = await this.$apollo.queries.nodes.refetch({
        ids: uniqBoxesIds,
        boxInfo: 'box_info',
        boxContent: 'content'
      });

      let boxesContents = response.data.nodes.map(node => {
        if (!node) return;
        return {
          id: node.id,
          title: node.title,
          variants:
            node.variants && node.variants.edges && node.variants.edges.length > 0
              ? node.variants.edges.map(variant => {
                  const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';

                  const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';
                  return {
                    id: variant.node.id,
                    content: jsonVariantContent
                  };
                })
              : []
        };
      });

      this.boxesContents = boxesContents.filter(box => box !== undefined);
      this.calculateOrderData();
    },
    calculateOrderData() {
      let result;
      if (this.order.name) {
        const orderBatches =
          this.order.orderBatches.edges.length !== 0
            ? this.order.orderBatches.edges.map(batchInfo => {
                const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
                return {
                  key: batchInfo.node.key,
                  value: jsonBatchValue
                };
              })
            : [];

        // Remove invalid lineItems
        const validLineItems = this.order.lineItems.edges.filter(
          lineItem => lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.variant && lineItem.node.variant.id && lineItem.node.currentQuantity > 0
        );

        result = {
          id: this.order.id,
          name: this.order.name,
          nrOfProducts: validLineItems.length,
          vendors: validLineItems.length > 0 ? [...new Set(validLineItems.map(lineItem => lineItem.node.vendor))] : [],
          clientData: {
            firstName: this.order.customer ? this.order.customer.firstName : '---',
            lastName: this.order.customer ? this.order.customer.lastName : '---',
            company: this.order.shippingAddress ? this.order.shippingAddress.company : '',
            address: this.order.shippingAddress
              ? `${this.order.shippingAddress.address1} ${this.order.shippingAddress.address2} ${this.order.shippingAddress.company}, ${this.order.shippingAddress.zip} ${this.order.shippingAddress.city} (${this.order.shippingAddress.provinceCode}), ${this.order.shippingAddress.country}`
              : 'unknown',
            phone: this.order.shippingAddress ? this.order.shippingAddress.phone : '---',
            email: this.order.email,
            id: this.order.customer ? this.order.customer.id : ''
          },
          orderBatches,
          paymentStatus: this.order.fullyPaid || this.order.displayFinancialStatus === 'PARTIALLY_REFUNDED' || this.order.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
          spedizione: this.order.shippingLine ? this.order.shippingLine.code : 'Standard',
          date: this.order.createdAt,
          methodOfPayment: this.order.paymentGatewayNames,
          total: this.order.currentTotalPriceSet.shopMoney.amount,
          tags: this.order.tags,
          archiviation: this.order.archiviation ? this.order.archiviation.edges : [],
          label: this.order.label ? this.order.label.edges : [],
          lineItems: validLineItems.map(item => {
            let lineItemBatches = [];
            // Get box content, find lineItem variant and get the ids of the product
            const box = this.boxesContents.find(box => box.id === item.node.product.id);

            if (box && item.node.product.tags.includes(boxTag) && item.node.variant && item.node.variant.id) {
              const boxVariant = box.variants ? box.variants.find(variant => variant.id === item.node.variant.id) : '';
              const boxProductsIds = boxVariant && boxVariant.content ? boxVariant.content.map(content => content.productId) : [];

              boxProductsIds.forEach(productId => {
                const foundBatch = orderBatches.find(batch => getFullProductId(batch.key) === productId);
                if (foundBatch && foundBatch.value.batch_id && foundBatch.value.ddt_number)
                  lineItemBatches = [...lineItemBatches, { batchNr: foundBatch.value.ddt_number, batchKey: foundBatch.value.batch_id }];
              });
            } else {
              const lineItemBatch = orderBatches.find(batch => getFullProductId(batch.key) === item.node.product.id);
              if (lineItemBatch && lineItemBatch.value.ddt_number && lineItemBatch.value.batch_id)
                lineItemBatches = [...lineItemBatches, { batchNr: lineItemBatch.value.ddt_number, batchKey: lineItemBatch.value.batch_id }];
            }
            let customAttributesString = '';

            if (item.node.customAttributes && item.node.customAttributes.length !== 0) {
              customAttributesString = item.node.customAttributes.map(attr => attr.value).join(' | ');
            }

            return {
              product: item.node.name,
              sku: item.node.sku,
              variantTitle: item.node.variant ? item.node.variant.title : item.node.name,
              amount: item.node.currentQuantity,
              vendor: item.node.vendor,
              batches: lineItemBatches,
              customAttributes: item.node.customAttributes || [],
              customAttributesString,
              orderNr: this.order.name,
              id: item.node.id
            };
          })
        };
      }

      this.orderData = result;
      this.dataLoaded = true;
    },
    // If there is current vendor selected, archive/unarchive only his part of the order
    // Otherwise archive/unarchive the whole order
    calculateOrderArchiviationInput() {
      let orderArchiviationInput = [];

      this.orderData.archiviation.forEach(vendorNode => {
        if (vendorNode.node.value !== 'true' && this.curOrderVendors.includes(vendorNode.node.key)) {
          orderArchiviationInput = [
            ...orderArchiviationInput,
            {
              id: this.orderData.id,
              vendor: vendorNode.node.key
            }
          ];
        }
      });

      return orderArchiviationInput;
    },
    // Change order status to unarchived
    async reactivateOrder() {
      this.isArchiveLoading = true;
      let orderActivationInput = this.calculateOrderArchiviationInput();

      const response = await this.$apollo.mutate({
        mutation: unarchiveOrders,
        variables: {
          orders: orderActivationInput
        }
      });

      if (!response.data.unarchiveOrders.error) {
        this.handleArchiveStatus(orderActivationInput);
        this.addAlert({ msg: `L'ordine ${this.orderData.name} è stato correttamente riaperto.` });
        this.isArchiveLoading = false;
      } else {
        this.addAlert({ msg: 'Qualcosa è andato storto.', type: 'error' });
        this.isArchiveLoading = false;
      }
    },
    // Change order status to archived
    async archiveOrderAfterDownloadingDdt(orders) {
      await this.archiveOrders(orders);
      this.set(['orderDetailsShown', false]);
    },
    checkDdtAndArchiveOrder() {
      // Check if all the selected orders have ddt already generated
      const ddtGenerated = this.checkIfDdtGenerated();
      if (this.isUserVendor && !ddtGenerated) {
        this.archiveWarningDisplayed = true;
        return;
      }
      this.archiveOrders([this.orderData]);
    },
    downloadDdtAndArchive() {
      this.archiveWarningDisplayed = false;
      document.querySelector('.order__tool > .ddt-btn').click();
    }
  },
  apollo: {
    // Call when component is rendered
    order() {
      return {
        query: getOrderDetailsById,
        skip: true,
        variables: {
          id: this.orderId
        }
      };
    },
    nodes() {
      return {
        query: getBoxesContent,
        skip: true,
        variables: {
          boxInfo: 'box_info',
          boxContent: 'content',
          ids: []
        },
        error() {
          // Unblock other actions and show empty charts
          this.dataLoaded = true;
        }
      };
    }
  },

  watch: {
    // Call apollo if currentOrderId = orderId
    currentOrderId: {
      handler(val) {
        if (val === this.orderId && ((this.isTabMode && !this.order.name) || !this.isTabMode)) {
          this.$apollo.queries.order.start();
          this.$apollo.queries.order.refetch({
            id: this.currentOrderId
          });
        }
      }
    },
    // If order.id exists set dataLoaded to true
    order: {
      handler(val) {
        if (val.id) {
          // Check if any of the order products is a box and if so, get its content
          const orderBoxes = [...val.lineItems.edges].filter(lineItem => lineItem.node.product && lineItem.node.product.tags.includes(boxTag));

          if (orderBoxes.length !== 0) {
            this.getBoxesContent(orderBoxes);
          } else {
            this.calculateOrderData();
          }
        }
      },
      deep: true
    }
  },

  beforeMount() {
    // Call apollo only if currentOrderId = orderId
    if (this.orderId !== this.$store.getters.currentOrder) {
      this.$apollo.queries.order.stop();
    } else {
      this.$apollo.queries.order.start();
      this.dataLoaded = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.order {
  font-family: 'Apercu Pro', sans-serif;
  @include default-font-size;
  font-weight: 400;
  margin-bottom: 2rem;
  min-width: 31rem;
  min-height: 70vh;

  @include respond('tab-port') {
    min-width: 31rem;
  }

  &__box {
    border: 3px solid $color-dark-blue;
    border-radius: 5px;
    color: $color-dark-blue;

    &--success {
      border: 3px solid $color-primary;
    }
  }

  &__header {
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    @include flex-parent-space-between;
    color: $color-white;
    background-color: $color-dark-blue;
    padding: 2.3rem 3rem;

    &--success {
      background-color: $color-primary;
    }
  }

  &__date {
    font-family: 'Apercu Pro', sans-serif;
    font-size: $default-font-size;
    font-weight: 400;
  }

  &__vendorItems {
    background-color: $color-grey-light;
    padding: 3rem;
    border-bottom: 3px solid $color-dark-blue;

    &--success {
      background-color: rgba($color-primary-light, 0.25);
      border-bottom: 3px solid $color-primary;
    }
  }

  &__h4 {
    font-size: 1.8rem;
    padding: 0;
    margin-bottom: 1rem;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(18px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(18px);
    }
  }

  &__span {
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
  }

  &__details {
    background-color: $color-white;
    padding: 1rem;
    border-radius: 2px;
  }

  &__h3 {
    @include default-font-size;
    padding: 0;
    margin-bottom: 1rem;
  }

  &__ul {
    list-style: none;
  }

  &__li:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 0.5rem;
  }

  &__lotto {
    font-size: 1.2rem;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(12px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(12px);
    }
  }

  &__lottoUl {
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
  }

  &__link {
    color: $color-blue;
    text-decoration: underline;
    transition: color 0.5s;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &__link:hover {
    color: $color-dark-blue;
  }

  &__paymentDetails {
    padding: 3.6rem 3rem 3rem 3rem;
  }

  &__p {
    margin-bottom: 0.7rem;
  }

  &__clientDetails {
    margin: 0rem 0 7rem 0;
    padding: 3rem;
  }

  &__pClient {
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3rem;
    @include respond('tab-port') {
      justify-content: space-between;
    }
  }

  &__tools {
    display: flex;
  }

  &__tool {
    margin-right: 1rem;
  }

  &__linkBack {
    color: $color-primary;
    @include bold-text;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }
}
</style>
