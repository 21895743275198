<template>
  <div class="productsVendorsTabs">
    <div class="productsVendorsTabs__tabs">
      <div
        :class="['productsVendorsTabs__tab', { 'productsVendorsTabs__tab--active': isProductsView }, { 'productsVendorsTabs__tab--disabled': !itemSwitchingEnabled }]"
        @click="handleViewChange('Prodotti')"
      >
        <BaseHeading :level="2" :extraClass="!isProductsView ? 'productsVendorsTabs__inactive' : ''">Prodotti</BaseHeading>
      </div>
      <div
        :class="['productsVendorsTabs__tab', { 'productsVendorsTabs__tab--active': isVendorsView }, { 'productsVendorsTabs__tab--disabled': !itemSwitchingEnabled }]"
        @click="handleViewChange('Produttori')"
      >
        <BaseHeading :level="2" :extraClass="!isVendorsView ? 'productsVendorsTabs__inactive' : ''">Produttori</BaseHeading>
      </div>
    </div>
    <div class="productsVendorsTabs__content" v-if="!isTabMode">
      <VendorsTab v-if="isVendorsView" :isNarrow="selectedItemId !== ''" :itemSwitchingEnabled="itemSwitchingEnabled"></VendorsTab>
      <ProductsTab v-if="isProductsView" :isNarrow="selectedItemId !== ''" :itemSwitchingEnabled="itemSwitchingEnabled"></ProductsTab>
    </div>
    <div class="productsVendorsTabs__content" v-else>
      <VendorsMobileTab v-if="isVendorsView" :itemSwitchingEnabled="itemSwitchingEnabled"></VendorsMobileTab>
      <ProductsMobileTab v-if="isProductsView" :itemSwitchingEnabled="itemSwitchingEnabled"></ProductsMobileTab>
    </div>
  </div>
</template>
<script>
import BaseHeading from '@bc/BaseHeading.vue';
import ProductsTab from '@c/admin/ProductsTab.vue';
import VendorsTab from '@c/admin/VendorsTab.vue';
import ProductsMobileTab from '@c/admin/ProductsMobileTab.vue';
import VendorsMobileTab from '@c/admin/VendorsMobileTab.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductsVendorsTabs',
  components: {
    BaseHeading,
    ProductsTab,
    VendorsTab,
    ProductsMobileTab,
    VendorsMobileTab
  },
  props: {
    /**
     * If set to false disable all the actions
     */
    itemSwitchingEnabled: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters(['selectedItemId', 'selectedView', 'isTabMode', 'shopProducts', 'shopVendors']),
    isProductsView() {
      return this.selectedView === 'Prodotti';
    },
    isVendorsView() {
      return this.selectedView === 'Produttori';
    }
  },
  methods: {
    ...mapActions(['set']),
    handleViewChange(view) {
      if (this.itemSwitchingEnabled) {
        this.set(['selectedView', view]);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
.productsVendorsTabs {
  &__tabs {
    padding-top: 3.5rem;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: -4.5rem;
    left: 0;
    background-color: $color-white;
    z-index: 15;
  }

  &__tab {
    text-align: center;
    width: 100%;
    cursor: pointer;
    padding: 0 0 1rem 0;
    position: relative;
    transition: color 0.5s, border 0.2s;

    &::after,
    &--active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 3px;
    }

    &::after {
      height: 1px;
      background-color: $color-blue-light;
    }

    &--active::after {
      height: 4px;
      background-color: $color-dark-blue;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__inactive {
    color: $color-blue-light !important;
  }

  &__content {
    margin-top: 2rem;
  }
}
</style>
