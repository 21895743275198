<template>
  <li
    :class="['itemBtn', { 'itemBtn--nohover': !isClickable }, { 'itemBtn--active': isActive }, { 'itemBtn--error': isError }, { 'itemBtn--disabled': isDisabled }, extraClass]"
    @click="isClickable && $emit('handle-click', itemId)"
    :style="extraStyle"
  >
    <span class="itemBtn__title">{{ title }}</span>
    <span class="itemBtn__subtitle">{{ subtitle }}</span>
  </li>
</template>
<script>
/**
 * This component loads a list's item with a button used to show the item's details
 *
 * @displayName BaseItemButton
 */

export default {
  name: 'BaseItemButton',
  props: {
    /**
     * This prop is used to pass the item's name
     */
    title: { type: String, required: true },

    /**
     * This prop is used to pass the item's id
     */
    itemId: { type: String, required: true },

    /**
     * This prop is used to pass additional info about the item
     */
    subtitle: { type: String },

    /**
     * This prop is used to set the button's active state
     */
    isActive: { type: Boolean, default: false },

    /**
     * This prop is used to set the button's error state
     */
    isError: { type: Boolean, default: false },

    /**
     * This prop is used to disable the button
     */
    isDisabled: { type: Boolean, default: false },

    /**
     * If set to false, the button won't have hover effect
     */
    isClickable: { type: Boolean, default: true },

    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  computed: {
    isTabMode() {
      return this.$store.getters.isTabMode;
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.itemBtn {
  color: $color-dark-blue;
  @include default-font-size;
  padding: 1.8rem;
  border: 2px solid $color-grey-light;
  border-radius: 3px;
  @include flex-parent-space-between;
  position: relative;
  cursor: pointer;

  &--nohover {
    cursor: auto;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &::after,
  &--active::after,
  &--nohover::after,
  &--error::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 3px solid $color-dark-blue;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &--nohover::after {
    opacity: 0;
  }

  &--error::after {
    opacity: 1;
    border: 3px solid $color-flame-pea;
  }

  &--active::after {
    opacity: 1;
  }

  &:hover::after {
    opacity: 1;
  }

  &--nohover:hover::after {
    opacity: 0;
  }

  &--disabled:hover::after {
    opacity: 0;
  }

  &__title {
    padding-right: 1.5rem;
  }

  &__subtitle {
    font-size: 1.4rem;
    color: $color-blue-light;

    @include respond('tab-land') {
      font-size: 1.56rem;
    }

    @include respond('tab-port') {
      font-size: 1.75rem;
    }
  }
}
</style>

<docs>
### BaseItemButton simple
```js
<BaseItemButton title="Hello" subtitle="world" itemId="234" ></BaseItemButton>
```
 
</docs>
