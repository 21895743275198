<template>
  <BaseLoadingSpinner v-if="!allDataReady"></BaseLoadingSpinner>
  <form v-else @submit.prevent>
    <BaseLegend v-if="isTabLandMode"></BaseLegend>
    <BaseOrdersTools
      extraStyle="margin-bottom: 2rem"
      :showSelectAll="isTabMode"
      @toggle-select-all="toggleSelectAll"
      :allOrdersSelected="allOrdersSelected"
      @search-data="searchOrders"
      :isDisabled="selectedOrders.length === 0"
      :selectedOrders="selectedOrders"
      :searchOptions="searchOptions"
      @handle-archive-status="handleArchiveStatus"
    ></BaseOrdersTools>

    <div v-if="!isTabMode" class="ordersList__legend">
      <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { 'u-selectAllBtn u-selectAllBtn--selected': allOrdersSelected }]"></span><span>Seleziona tutto</span></p>
      <BaseLegend v-if="!isTabLandMode"></BaseLegend>
    </div>
    <p class="u-text-warning" v-if="filteredOrdersData.length === 0">Nessun ordine trovato.</p>
    <AdminVendorOrders
      v-for="(vendorOrders, index) in filteredOrdersData"
      :orders="vendorOrders"
      :vendor="filteredVendorsArr[index]"
      :key="index"
      :name="`vendor_${filteredVendorsArr[index]}`"
    ></AdminVendorOrders>
  </form>
</template>

<script>
/**
 * This component loads a container used to display the list of orders
 *
 * @displayName OrdersList
 */
import { mapGetters, mapActions } from 'vuex';
import AdminVendorOrders from '@c/admin/AdminVendorOrders';
import BaseOrdersTools from '@bc/BaseOrdersTools';
import BaseLegend from '@bc/BaseLegend.vue';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { getOrdersPreviews } from '@gq/getOrdersPreviews.gql';
import { checkIfJsonIsValid, getXMonthsAgoDate, getNumericDate } from '@u/helperFunctions.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

export default {
  name: 'OrdersList',

  components: {
    AdminVendorOrders,
    BaseOrdersTools,
    BaseLegend,
    BaseLoadingSpinner
  },
  mixins: [sleepMixin],
  props: {
    /**
     * This prop is used to pass an array of two dates (selected time period)
     */

    selectedTimePeriod: { type: Array, required: true },
    /**
     * This prop is used to pass an array of orderArchiviationInput)
     */

    orderInputToBeArchived: { type: Array }
  },

  data() {
    return {
      isLoading: true,
      error: null,
      allOrdersSelected: false,
      orders: [],
      vendorsArr: [],
      filteredVendorsArr: [],
      ordersData: [],
      filteredOrdersData: [],
      selectedOrders: [],
      fullyLoaded: false,
      allDataReady: false,
      searchOptions: ['Ordine', 'Produttore', 'Lotto'],
      retry: 0,
      startingDate: ''
    };
  },

  // Call when app starts
  apollo: {
    // Get all orders from the last week
    orders() {
      // Calculate last 2 months period
      const date2MonthAgo = getXMonthsAgoDate(2);
      // Set starting date to the date 2 months ago
      this.startingDate = getNumericDate(date2MonthAgo);

      return {
        query: getOrdersPreviews,
        skip: true,
        variables: {
          queryString: `created_at:>=${this.startingDate}`
        },
        manual: true,
        result({ data, loading }) {
          if (!loading && data) {
            //this.orders = data.orders;
            if (data.orders.pageInfo.hasNextPage) {
              const lastCursor = data.orders.edges[data.orders.edges.length - 1].cursor;

              this.fetchMoreOrders(lastCursor);
            } else {
              this.fullyLoaded = true;
              this.orders = data.orders;
            }
          }
        }
      };
    }
  },

  computed: {
    ...mapGetters(['isTabMode', 'isTabLandMode', 'throttledErrors', 'currentSetOfOrders'])
  },
  methods: {
    ...mapActions(['setDataIsLoadingMsg', 'removeThrottledError', 'set']),
    async fetchOrders() {
      this.$apollo.queries.orders.start();
      this.$apollo.queries.orders.refetch({
        queryString: `created_at:>=${this.startingDate}`
      });
    },

    async fetchMoreOrders(lastCursor) {
      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: `created_at:>=${this.startingDate}`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];

          return {
            orders: newOrders
          };
        }
      });
    },
    filterByIdOrdine(value) {
      const filtered_orders = [];

      this.ordersData.forEach(vendorOrders => {
        filtered_orders.push(
          vendorOrders.filter(order => {
            if (`${value}`.charAt(0) !== '#') value = `#${value}`;
            return !value || order.name.startsWith(value);
          })
        );
      });

      this.filteredOrdersData = [...filtered_orders];
    },

    filterByProduttore(value) {
      // Find vendors based on search query
      const queryVendors = this.vendorsArr.filter(vendor => vendor.toLowerCase().indexOf(value.toLowerCase()) !== -1);

      // If there are any vendors found, find their indexes
      if (queryVendors.length !== 0) {
        // Find indexes of all queryVendors
        const indexes = queryVendors.map(queryVendor => this.vendorsArr.findIndex(vendor => vendor.toLowerCase() === queryVendor.toLowerCase()));

        // Find orders of selected vendors
        const queryOrders = indexes.map(index => this.ordersData[index]);

        // Set filteredOrders to the orders of the selected vendors
        this.filteredOrdersData = queryOrders;
        this.filteredVendorsArr = queryVendors;
      } else {
        // If there are no vendors that match the query show empty results
        this.filteredOrdersData = [];
        this.filteredVendorsArr = [];
      }
    },

    filterByBatchNr(value) {
      let filteredResults = [...this.ordersData].map(result => result.filter(order => order.orderBatchesNumbers.includes(value)));
      this.filteredOrdersData = filteredResults;
    },

    searchOrders(value, option) {
      this.filteredVendorsArr = this.vendorsArr;
      if (!value) {
        // Show all results
        this.filteredOrdersData = this.ordersData;
        return;
      }

      switch (option) {
        case 'Ordine':
          this.filterByIdOrdine(value);
          break;
        case 'Produttore':
          this.filterByProduttore(value);
          break;
        case 'Lotto':
          this.filterByBatchNr(value);
      }
    },
    toggleSelectAll() {
      const selectedOrders = this.filteredOrdersData;

      selectedOrders.forEach(vendorOrders => vendorOrders.forEach(order => (order.isChecked = !this.allOrdersSelected)));
      this.allOrdersSelected = !this.allOrdersSelected;

      this.filteredOrdersData = [...selectedOrders];
      this.getSelectedOrders();
    },

    toggleChecked(orderId) {
      const updatedOrdersData = this.filteredOrdersData;
      // Find an array with the the orders of selected vendor
      const selectedVendorOrders = updatedOrdersData.find(vendorOrders => vendorOrders.find(order => order.taggedId === orderId));

      // Find index of this array
      const selectedVendorOrdersIndex = updatedOrdersData.findIndex(vendorOrders => vendorOrders.find(order => order.taggedId === orderId));

      // Find selected input
      const selectedInput = selectedVendorOrders.find(order => order.taggedId === orderId);

      // Toggle selected input isChecked value
      selectedInput.isChecked = !selectedInput.isChecked;

      // this.$set(this.filteredOrdersData, selectedVendorOrdersIndex, selectedVendorOrders);
      this.filteredOrdersData[selectedVendorOrdersIndex] = [...selectedVendorOrders];
      this.getSelectedOrders();
    },

    handleArchiveStatus(archiviationInput) {
      let updatedOrdersData = this.ordersData;
      let updatedFilteredOrdersData = this.filteredOrdersData;
      let updatedCurrentSetOfOrder = this.currentSetOfOrders;

      // Archive selected orders
      archiviationInput.forEach(input => {
        // Remove order from ordersData
        updatedOrdersData = updatedOrdersData.map(vendorOrders => {
          return vendorOrders.filter(order => {
            return !(order.id === input.id && order.vendor === input.vendor);
          });
        });

        // Remove order from filteredOrders

        updatedFilteredOrdersData = updatedFilteredOrdersData.map(vendorOrders => {
          return vendorOrders.filter(order => {
            return !(order.id === input.id && order.vendor === input.vendor);
          });
        });

        // Remove order from currentSetOfOrders
        updatedCurrentSetOfOrder = updatedCurrentSetOfOrder.filter(order => {
          return !(order.id === input.id && order.vendor === input.vendor);
        });
      });

      this.ordersData = [...updatedOrdersData];

      this.filteredOrdersData = [...updatedFilteredOrdersData];
      this.set(['currentSetOfOrders', [...updatedCurrentSetOfOrder]]);

      if ([...updatedCurrentSetOfOrder].length !== 0) {
        const currentOrderId = [...updatedCurrentSetOfOrder][0].id;
        this.set(['currentOrder', currentOrderId]);
      } else {
        this.set(['currentOrder', '']);
        this.set(['orderDetailsShown', false]);
      }

      this.getSelectedOrders();
      this.fetchOrders();
    },

    getSelectedOrders() {
      // Find the vendordOrders in which at least one order is checked
      const selectedVendorOrders = this.ordersData.filter(vendorsOrders => vendorsOrders.filter(order => order.isChecked === true).length !== 0);

      // Push selected orders to selectedOrders array
      const selectedOrders = [];
      selectedVendorOrders.forEach(vendorOrders => vendorOrders.forEach(order => order.isChecked && selectedOrders.push(order)));
      this.selectedOrders = selectedOrders;
      return selectedOrders;
    },
    displayLoadingPopup() {
      // Show loading popup
      this.setDataIsLoadingMsg('Caricamento dati...');
    },
    async retryAndRemoveError(err) {
      this.retry++;
      // Remove error
      this.$store.commit('removeThrottledError', err);

      await this.sleep(3000 + Math.random() * 500);

      // Refetch the query
      if (err.path !== 'products') this.$apollo.queries[err.path].refetch(err.variables);
    }
  },
  watch: {
    orders(val) {
      if (val && val.edges) {
        if (val.edges.length === 0) {
          this.allDataReady = true;
          // Load charts data
          this.$emit('enable-charts-fetching');
          return;
        }

        const results = val.edges.map(order => {
          const orderBatches = order.node.orderBatches.edges.map(batchInfo => {
            const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
            return {
              key: batchInfo.node.key,
              value: jsonBatchValue
            };
          });

          // Remove line items without vendor's field
          const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.currentQuantity > 0);

          // Find only valid batches and take their numbers
          const orderBatchesNumbers = orderBatches.filter(batch => Number(batch.key)).map(batch => batch.value.ddt_number);

          // Check if all the parts of the order are archived
          let isArchived = true;
          if (order.node.archiviation && order.node.archiviation.edges) {
            order.node.archiviation.edges.forEach(orderNode => {
              isArchived = isArchived && orderNode.node.value === 'true';
            });
          }
          return {
            id: order.node.id,
            name: order.node.name,
            nrOfProducts: validLineItems.length,
            vendors: validLineItems.length > 0 ? validLineItems.map(item => item.node.vendor) : [],
            clientData: {
              firstName: order.node.customer ? order.node.customer.firstName : '---',
              lastName: order.node.customer ? order.node.customer.lastName : '---'
            },
            paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
            spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard',
            date: order.node.createdAt, //processedAt
            isChecked: order.isChecked || false,
            orderBatchesNumbers,
            isArchived,
            archiviation: order.node.archiviation ? order.node.archiviation.edges : [],
            label: order.node.label ? order.node.label.edges : []
          };
        });

        // Get all vendors
        const vendors = new Set([]);
        results.forEach(order => order.vendors.forEach(vendor => vendors.add(vendor)));

        this.vendorsArr = [...vendors];
        this.filteredVendorsArr = [...vendors];

        // Sort orders by vendor

        const orderedOrders = [...vendors].map(vendor => {
          const filteredVendorResults = results.filter(order => !order.isArchived && order.vendors.includes(vendor));

          const vendorOrders = filteredVendorResults.map(result => {
            // Check if all vendor's lineItems are archived
            let isVendorArchived = true;
            if (result.archiviation && result.archiviation.length !== 0) {
              const vendorNode = result.archiviation.find(orderNode => orderNode.node.key === vendor);
              isVendorArchived = isVendorArchived && vendorNode && vendorNode.node.value === 'true';
            }

            return {
              vendor,
              isVendorArchived,
              taggedId: result.id + '#' + vendor,
              ...result
            };
          });

          // Return only not archived orders that have some valid line items
          return vendorOrders.filter(order => !order.isVendorArchived && order.nrOfProducts > 0);
        });

        this.ordersData = orderedOrders;
        this.filteredOrdersData = orderedOrders;
        this.allDataReady = true;

        // Load charts data
        this.$emit('enable-charts-fetching');
      }
    },

    orderInputToBeArchived(input) {
      this.handleArchiveStatus(input);
    },

    // THROTTLING

    throttledErrors(val) {
      if (val && val.length > 0) {
        this.displayLoadingPopup();
        val.forEach(error => {
          // Retry in few seconds
          if (this.retry <= 10) {
            this.retryAndRemoveError(error);
          } else {
            // Load other data
            this.fullyLoaded = true;
            this.allDataReady = true;
          }
        });
      }
    }
  },

  provide() {
    return {
      handleChange: this.toggleChecked
    };
  },

  created() {
    this.fetchOrders();
  },

  beforeDestroy() {
    this.allDataReady = false;
    this.fullyLoaded = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_mixins.scss';
@import '@s/_variables.scss';

.ordersList {
  &__legend {
    @include flex-parent-space-between;
    flex-wrap: wrap;
  }
}
</style>
