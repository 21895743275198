<template>
  <div :class="['spinner', extraClass, { 'spinner--top': isTopMobile }]" :style="extraStyle">
    <div class="spinner__icon">
      <span class="spinner__item"></span>
      <span class="spinner__item"></span>
      <span class="spinner__item"></span>
      <span class="spinner__item"></span>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a loading spinner
 *
 * @displayName BaseLoadingSpinner
 */

export default {
  name: 'BaseLoadingSpinner',
  props: {
    /**
     * If set to true, the spinner will be on top of the element on mobile (normally it's centered)
     */
    isTopMobile: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the spinner
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.spinner {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 4.5rem;
  min-width: 4.5rem;
  background-color: rgba($color-white, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 800;

  &--top {
    @include respond('tab-port') {
      align-items: flex-start;
      padding-top: 15rem;
    }
  }

  &__icon {
    width: 3rem;
    height: 3rem;
    position: relative;
    animation: loading 2s linear infinite;
  }

  &__item {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }

  &__item:nth-child(1) {
    top: 0;
    left: 0;
    background-color: $color-primary;
  }

  &__item:nth-child(2) {
    top: 0;
    right: 0;
    background-color: $color-secondary;
  }

  &__item:nth-child(3) {
    bottom: 0;
    left: 0;
    background-color: $color-dark-blue;
  }

  &__item:nth-child(4) {
    bottom: 0;
    right: 0;
    background-color: $color-primary-light;
  }
}

@keyframes loading {
  0% {
    width: 3rem;
    height: 3rem;
    transform: rotate(0deg);
    opacity: 0.5;
  }

  8.33% {
    width: 4rem;
    height: 4rem;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  16.66% {
    width: 4rem;
    height: 4rem;
    transform: rotate(90deg);
    opacity: 0.9;
  }

  24.99% {
    width: 3rem;
    height: 3rem;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  33.32% {
    width: 4rem;
    height: 4rem;
    transform: rotate(180deg);
    opacity: 0.5;
  }

  41.65% {
    width: 4rem;
    height: 4rem;
    transform: rotate(180deg);
    opacity: 0.9;
  }

  49.98% {
    width: 3rem;
    height: 3rem;
    transform: rotate(180deg);
    opacity: 0.5;
  }
  58.31% {
    width: 4rem;
    height: 4rem;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  66.64% {
    width: 4rem;
    height: 4rem;
    transform: rotate(270deg);
    opacity: 0.9;
  }

  74.97% {
    width: 3rem;
    height: 3rem;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  83.3% {
    width: 4rem;
    height: 4rem;
    transform: rotate(360deg);
    opacity: 0.5;
  }

  91.63% {
    width: 4rem;
    height: 4rem;
    transform: rotate(360deg);
    opacity: 0.9;
  }

  100% {
    width: 3rem;
    height: 3rem;
    transform: rotate(360deg);
    opacity: 0.5;
  }
}
</style>

<docs>
### BaseLoadingSpinner
```js
<BaseLoadingSpinner></BaseLoadingSpinner>
```
 
 
</docs>
