export const hamburger = [
  '24 24',
  `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Hamburger-menu"  fill="currentColor">
    <path
      d="M10,11.1428571 C10.5522847,11.1428571 11,11.5905724 11,12.1428571 C11,12.6951419 10.5522847,13.1428571 10,13.1428571 L1,13.1428571 C0.44771525,13.1428571 5.11724585e-16,12.6951419 0,12.1428571 C-6.76353751e-17,11.5905724 0.44771525,11.1428571 1,11.1428571 L10,11.1428571 Z M17,5.57142857 C17.5522847,5.57142857 18,6.01914382 18,6.57142857 C18,7.12371332 17.5522847,7.57142857 17,7.57142857 L1,7.57142857 C0.44771525,7.57142857 5.11724585e-16,7.12371332 0,6.57142857 C-6.76353751e-17,6.01914382 0.44771525,5.57142857 1,5.57142857 L17,5.57142857 Z M17,0 C17.5522847,-1.01453063e-16 18,0.44771525 18,1 C18,1.55228475 17.5522847,2 17,2 L1,2 C0.44771525,2 5.11724585e-16,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L17,0 Z"
      id="Menu-Icon"
    ></path>
  </g> </g
>`
];
