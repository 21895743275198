<template>
  <div class="archive">
    <div id="orders-div" class="page-scroll archive__col1" v-if="!isTabMode || (isTabMode && !orderDetailsShown)">
      <BaseCard fullCard hideMobile>
        <div class="archive__stickyTools">
          <div class="archive__heading">
            <BaseHeading :level="2" extraClass="archive__title">Tutti gli ordini</BaseHeading>
            <p v-if="!isTabMode" class="archive__subtitle">Totale ordini trovati: {{ filteredOrdersData.length }}</p>
          </div>
          <BaseOrdersTools
            extraClass="archive__tools"
            isArchive
            @search-data="searchOrders"
            :searchOptions="searchOptions"
            :showSelectAll="isTabMode"
            :isDisabled="selectedOrders.length === 0"
            :allOrdersSelected="allOrdersSelected"
            @toggle-select-all="toggleSelectAll"
            @handle-archive-status="handleArchiveStatus"
            :selectedOrders="selectedOrders"
          ></BaseOrdersTools>
        </div>

        <VendorFilters
          v-if="!$apollo.queries.products.loading && productsFullyLoaded"
          extraClass="archive__filters"
          :products="productsData"
          :selectedProducts="selectedProducts"
          :selectedShippingTypes="selectedShippingTypes"
          :selectedTimeRange="selectedTimeRange"
          :shippingTypesOptions="shippingTypesOptions"
          @handle-filters-selection="handleFiltersSelection"
        ></VendorFilters>
        <TableOrders
          :orders="filteredOrdersData"
          :allOrdersSelected="allOrdersSelected"
          @toggle-select-all="toggleSelectAll"
          :filters="topFilters"
          name="ordersList"
          :removeTheadPadding="!isTabMode"
          :columns="['col', 'col', 'col', 'col']"
          :keepOrder="!isTabMode"
          hideMobSelectAll
          :isLoading="tableLoading"
          :fetchingMore="fetchingMoreOrders"
          showLoadMoreBtn
        ></TableOrders
      ></BaseCard>
    </div>
    <div class="page-scroll archive__col2" v-if="orderDetailsShown">
      <BaseCard fullCard hideMobile>
        <div class="archive__heading">
          <BaseHeading v-if="!isTabMode" :level="2" extraStyle="margin-bottom:4rem">Dettaglio ordine</BaseHeading>
          <p v-if="isTabMode" class="u-linkBack" @click="hideOrderDetails"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
        </div>
        <SwiperOrders v-if="isTabMode" isArchive></SwiperOrders>
        <Order v-else :orderId="currentOrder" isArchive></Order>
      </BaseCard>
    </div>
  </div>
</template>
<script>
import VendorFilters from '@c/vendor/VendorFilters';
import TableOrders from '@c/common/TableOrders';
import BaseCard from '@bc/BaseCard';
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseOrdersTools from '@bc/BaseOrdersTools';
import SwiperOrders from '@c/common/SwiperOrders';
import Order from '@c/common/Order';
import { getVendorProductsNames } from '@gq/getVendorProductsNames.gql';
import { getVendorOrdersPreviews } from '@gq/getVendorOrdersPreviews.gql';
import { mapActions, mapGetters } from 'vuex';
import { getElementOffset, getNumericDate, getNumericDateOfTheNextDate, checkIfJsonIsValid } from '@u/helperFunctions';
import { searchOrdersMixin } from '@c/mixins/searchMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

const filtersNames = {
  standardShipping: 'Standard',
  premiumShipping: 'Premium',
  appointmentShipping: 'Appuntamento'
};

export default {
  name: 'ArchiveVendor',
  components: {
    VendorFilters,
    BaseCard,
    BaseHeading,
    SwiperOrders,
    BaseIcon,
    BaseOrdersTools,
    TableOrders,
    Order
  },
  mixins: [searchOrdersMixin, sleepMixin],
  data() {
    return {
      products: [],
      selectedProducts: [],
      orders: [],
      productsFullyLoaded: false,
      ordersFullyLoaded: false,
      allOrdersSelected: false,
      allDataReady: false,
      searchOptions: ['Ordine', 'ID del prodotto', 'Lotto'],
      productsData: [],
      ordersData: [],
      filteredOrdersData: [],
      topFilters: [{ name: 'Spedizione' }, { name: 'Data ordine' }],
      selectedShippingTypes: [filtersNames.standardShipping, filtersNames.premiumShipping, filtersNames.appointmentShipping],
      shippingTypesOptions: [filtersNames.standardShipping, filtersNames.premiumShipping, filtersNames.appointmentShipping],
      selectedTimeRange: [],
      fetchingMoreOrders: false,
      selectedOrders: []
    };
  },
  computed: {
    ...mapGetters(['orderDetailsShown', 'isTabMode', 'screenWidth', 'currentOrder', 'currentSetOfOrders', 'throttledErrors', 'currentVendor', 'currentVendors']),

    tableLoading() {
      return this.fetchingMoreOrders ? false : !this.allDataReady;
    }
  },
  methods: {
    ...mapActions(['set', 'showOrderDetails', 'hideOrderDetails', 'setCurrentOrders', 'setDataIsLoadingMsg', 'removeThrottledError']),
    toggleChecked(orderId) {
      const updatedOrdersData = this.ordersData;

      // Find the selected order
      const selectedOrder = updatedOrdersData.find(order => order.id === orderId);

      selectedOrder.isChecked = !selectedOrder.isChecked;

      this.allOrders = [...updatedOrdersData];
      this.getSelectedOrders();
    },

    toggleSelectAll() {
      const selectedOrders = this.filteredOrdersData;

      selectedOrders.forEach(order => (order.isChecked = !this.allOrdersSelected));
      this.allOrdersSelected = !this.allOrdersSelected;
      this.filteredOrdersData = [...selectedOrders];
      this.set(['currentSetOfOrders', selectedOrders]);
      this.getSelectedOrders();
    },

    getSelectedOrders() {
      let selections = [];

      if (this.filteredOrdersData.length !== 0) {
        selections = this.filteredOrdersData.filter(order => order.isChecked);
      }
      this.selectedOrders = selections;

      return selections;
    },
    clearTableData() {
      this.orders = {};
      this.ordersData = [];
      this.filteredOrdersData = [];
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.fetchMore({
        variables: {
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    },
    async fetchMoreOrders() {
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      let filtersQuery = '';

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null) {
        const startDate = getNumericDate(this.selectedTimeRange[0]);
        const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);
        filtersQuery = `created_at:>=${startDate} AND created_at:<${endDate}`;
      }

      await this.sleep(2500);

      this.$apollo.queries.orders.fetchMore({
        variables: {
          query: filtersQuery,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.ordersFullyLoaded = true;
            this.fetchingMoreOrders = false;
            return previousResult;
          } else {
            this.ordersFullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          // Set table loading back to orders loading
          this.fetchingMoreOrders = false;

          return {
            orders: newOrders
          };
        }
      });
    },
    handleFiltersSelection(groupName, selections) {
      switch (groupName) {
        case 'shipping_types':
          this.selectedShippingTypes = selections;
          this.filterOrders();
          break;
        case 'time_range':
          this.handleTimeSelection(selections);
          break;
        case 'filters_products':
          this.selectedProducts = selections;
          this.filterOrders();
          break;
      }
    },
    handleOrderDetailsVisibility(results) {
      if (!results) return;

      this.set(['currentSetOfOrders', results]);
      if (results.length !== 0) this.set(['currentOrder', results[0].id]);

      this.set(['orderDetailsShown', !this.isTabMode && results.length > 0]);
    },
    filterOrders() {
      // Get only archived orders
      let filteredResults = this.ordersData.length !== 0 ? this.ordersData.filter(order => order.isArchived) : [];

      // SHIPPING TYPES
      // If not all the filters are selected, remove unselected

      if (filteredResults.length !== 0 && this.shippingTypesOptions.length !== this.selectedShippingTypes.length) {
        // TODO get real names

        //  shippingTypesOptions = ['Standard', 'Premium', 'Appuntamento'],
        const shippingCodes = [filtersNames.standardShipping, filtersNames.premiumShipping, filtersNames.appointmentShipping];

        filteredResults = filteredResults.filter(order => {
          // If nothing is selected, don't show anything
          if (this.selectedShippingTypes.length === 0) return false;

          // find index of the order's shipping type and get the corresponding shipping code
          const orderShippingIndex = shippingCodes.findIndex(code => order.spedizione === code);

          const orderShippingType = this.shippingTypesOptions[orderShippingIndex];

          // If the order's shipping type is selected, return true
          if (orderShippingType && this.selectedShippingTypes.includes(orderShippingType)) return true;

          return false;
        });
      }

      // PRODUCTS SELECTIONS
      // If not all the filters are selected, remove unselected

      if (filteredResults.length !== 0 && this.products && this.products.edges && this.selectedProducts && this.selectedProducts.length !== this.products.edges.length) {
        filteredResults = filteredResults.filter(order => {
          // If nothing is selected, don't show anything
          if (this.selectedProducts.length === 0) return false;
          // If the order includes at least one of the selected product tags, return true
          const includedProducts = this.selectedProducts.filter(product => order.tags.includes(product));

          return includedProducts.length !== 0;
        });
      }

      this.filteredOrdersData = filteredResults;

      this.handleOrderDetailsVisibility(filteredResults);

      this.allDataReady = true;
    },

    handleTimeSelection(timeRange) {
      this.clearTableData();
      this.fetchingMoreOrders = false;
      this.allDataReady = false;
      this.selectedTimeRange = timeRange;
      let startDate = [];
      let endDate = [];

      let filtersQuery = 'financial_status:PAID,PARTIALLY_REFUNDED';

      if (timeRange.length !== 0 && timeRange[0] !== null) {
        startDate = getNumericDate(timeRange[0]);
        endDate = getNumericDateOfTheNextDate(timeRange[1]);
        const dateQuery = `created_at:>=${startDate} AND created_at:<${endDate}`;
        filtersQuery = [filtersQuery, dateQuery].join(' AND ');
      }

      this.$apollo.queries.orders.refetch({
        queryString: filtersQuery
      });
    },
    displayLoadingPopup() {
      // Show loading popup
      this.setDataIsLoadingMsg('Caricamento dati...');
    },
    async retryAndRemoveError(err) {
      // Remove error
      this.$store.commit('removeThrottledError', err);

      await this.sleep(2000 + Math.random() * 500);

      // Refetch the query
      if (err.path !== 'order') {
        this.$apollo.queries[err.path].refetch(err.variables);
      } else {
        this.clearTableData();
        this.itemSwitchingEnabled = true;
      }
    },
    handleArchiveStatus(activationInput) {
      const updatedOrdersData = this.ordersData;

      // Unarchive selected orders
      activationInput.forEach(input => {
        const editedOrders = updatedOrdersData.filter(order => order.id === input.id && this.currentVendors.includes(input.vendor));
        if (editedOrders.length > 0) editedOrders.forEach(editedOrder => (editedOrder.isArchived = false));
      });
      this.ordersData = [...updatedOrdersData];
      this.filterOrders();
      this.getSelectedOrders();
      this.set(['isArchiveModalLoading', false]);
    }
  },
  apollo: {
    // Call when component is rendered
    products() {
      return {
        query: getVendorProductsNames
      };
    },

    orders() {
      return {
        query: getVendorOrdersPreviews,
        variables: {
          queryString: 'financial_status:PAID,PARTIALLY_REFUNDED'
        },
        manual: true,
        result({ data, loading }) {
          if (!loading && data) {
            this.orders = data.orders;
          }
        },
        error() {
          this.clearTableData();
          this.ordersFullyLoaded = true;
        }
      };
    }
  },

  watch: {
    products(val) {
      if (val && val.edges) {
        if (val.edges.length === 0) {
          this.productsFullyLoaded = true;
          return;
        }
        this.productsFullyLoaded = false;

        // Check if there is the second page

        if (val.pageInfo.hasNextPage) {
          this.fetchMoreProducts();
        } else {
          this.productsFullyLoaded = true;
          let results = val.edges.map(product => {
            return {
              id: product.node.id,
              name: product.node.title
            };
          });

          this.productsData = results;
          this.selectedProducts = results.map(product => product.id);
        }
      }
    },
    orders(val) {
      let results;
      const appThis = this;

      if (val && val.edges) {
        if (val.edges.length === 0) {
          appThis.set(['orderDetailsShown', false]);
          this.allDataReady = true;
          this.clearTableData();
          this.ordersFullyLoaded = true;
        } else {
          // Check if there is the second page

          this.ordersFullyLoaded = !val.pageInfo.hasNextPage;

          results = val.edges.map(order => {
            const orderBatches = order.node.orderBatches.edges.map(batchInfo => {
              const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
              return {
                key: batchInfo.node.key,
                value: jsonBatchValue
              };
            });

            // Find only valid batches and take their numbers
            const orderBatchesNumbers = orderBatches.filter(batch => Number(batch.key)).map(batch => batch.value.ddt_number);

            // Check if the current vendor's line items are archived
            let isArchived = true;
            if (order.node.archiviation && order.node.archiviation.edges) {
              const vendorNodes = order.node.archiviation.edges.filter(orderNode => orderNode.node && this.currentVendors.includes(orderNode.node.key));
              if (vendorNodes.length > 0) vendorNodes.forEach(vendorNode => (isArchived = isArchived && vendorNode && vendorNode.node.value && vendorNode.node.value === 'true'));
            }

            // Remove invalid lineItems
            const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.currentQuantity > 0);

            return {
              id: order.node.id,
              name: order.node.name,
              // calculate line items of current vendor
              nrOfProducts: validLineItems.length > 0 ? validLineItems.filter(item => this.currentVendors.includes(item.node.vendor)).length : 0,
              vendors: validLineItems.length > 0 ? this.currentVendors.filter(vendor => validLineItems.find(item => item.node.vendor === vendor)) : [],
              tags: order.node.tags,
              clientData: {
                firstName: order.node.customer ? order.node.customer.firstName : '---',
                lastName: order.node.customer ? order.node.customer.lastName : '---'
              },
              paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
              spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard',
              date: order.node.createdAt,
              isChecked: order.isChecked || false,
              orderBatchesNumbers,
              isArchived,
              archiviation: order.node.archiviation ? order.node.archiviation.edges : [],
              label: order.node.label ? order.node.label.edges : []
            };
          });

          // Get only orders that have been archived and have some valid line items
          const archivedOrders = results.filter(order => order.isArchived && order.nrOfProducts > 0);

          appThis.ordersData = archivedOrders;

          this.filterOrders();
        }
      }
    },

    screenWidth(val) {
      const appThis = this;
      // Set current order once the page is fully loaded
      if (val > 0 && this.ordersData.length !== 0 && !this.currentOrder && !this.isTabMode) {
        appThis.set(['orderDetailsShown', true]);
        appThis.set(['currentOrder', appThis.ordersData[0].id]);
      }
    },

    filteredOrdersData(val) {
      if (val) this.handleOrderDetailsVisibility(val);
    },

    // THROTTLING

    throttledErrors(val) {
      if (val && val.length > 0) {
        this.displayLoadingPopup();
        val.forEach(error => {
          // Retry in few seconds
          this.retryAndRemoveError(error);
        });
      }
    }
  },
  provide() {
    return {
      goBack: this.hideOrderDetails,
      handleChange: this.toggleChecked,
      handleArchiveStatus: this.handleArchiveStatus
    };
  },
  updated() {
    if (this.isTabMode) {
      // Get the position of the load more button
      const loadBtn = document.getElementById('load-more-btn');
      let btnPosition;

      if (loadBtn) {
        btnPosition = getElementOffset(loadBtn);
      }

      let triggered = false;
      window.onscroll = () => {
        if (btnPosition && btnPosition.top - window.pageYOffset < 800 && !triggered && !this.fetchingMoreOrders) {
          triggered = true;
          // Fetch the remaining orders
          if (!this.ordersFullyLoaded) {
            // Prevent table loading
            this.fetchingMoreOrders = true;

            this.fetchMoreOrders();
          }
        }
      };
    } else {
      let triggered = false;

      const ordersDiv = document.getElementById('orders-div');

      ordersDiv.onscroll = () => {
        const divScrollTop = ordersDiv.scrollTop;
        const scrollableHeight = ordersDiv.scrollHeight - ordersDiv.clientHeight;

        if (scrollableHeight - divScrollTop < 50 && !triggered && !this.fetchingMoreOrders) {
          triggered = true;
          // Fetch the remaining orders
          if (!this.ordersFullyLoaded) {
            // Prevent table loading
            this.fetchingMoreOrders = true;

            this.fetchMoreOrders();
          }
        }
      };
    }
  },
  created() {
    this.$apollo.queries.orders.refetch({
      queryString: 'financial_status:PAID,PARTIALLY_REFUNDED'
    });
  },
  beforeDestroy() {
    this.fetchingMoreOrders = false;
    this.allDataReady = false;
    this.ordersFullyLoaded = false;
    this.productsFullyLoaded = false;
    this.clearTableData();
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.archive {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;
  position: relative;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 8rem 0 0 0;
    margin-bottom: calculateMobRem(24px);
  }

  @include respond('phone') {
    padding: 1rem;
  }

  &__stickyTools {
    @include respond('tab-port') {
      position: sticky;
      background-color: $color-white;
      z-index: 50;
      width: 102%;
      top: 4rem;
      left: 0;
      transform: translateX(-2px);
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include respond('tab-port') {
      justify-content: flex-end;
    }

    @include respond('phone') {
      margin-top: 9rem;
    }
  }

  &__title {
    margin-bottom: 4rem;

    @include respond('tab-port') {
      margin-bottom: calculateMobRem(19px);
    }
  }

  &__subtitle {
    @include mini-font-size;
    color: $color-blue-light;
  }

  &__col1 {
    flex-grow: 1;
    padding: 4.5rem 2rem 4.5rem 2rem;
    height: 100vh;
    overflow-y: auto;
    margin-right: 0.5rem;

    @include respond('tab-port') {
      padding: 0;
      margin: 0;
      width: 100%;
      height: fit-content;
      overflow: visible;
    }
  }

  &__col2 {
    flex-grow: 0;
    flex-shrink: 0;
    width: 45rem;
    height: 100vh;
    overflow-y: auto;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      padding: 0 0 4.5rem 0;
      height: fit-content;
      margin: 0 auto;
    }
  }

  &__filters {
    margin-bottom: 3rem;

    @include respond('tab-port') {
      width: 100%;
      margin-top: 2rem;
    }
  }

  &__tools {
    margin-bottom: 2rem;

    @include respond('tab-land') {
      margin-bottom: calculateTabLandRem(20px);
    }

    @include respond('tab-port') {
      margin-bottom: calculateMobRem(20px);
    }
  }
}
</style>
