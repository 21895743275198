<template>
  <component v-bind:is="currentArchivePage"></component>
</template>
<script>
import ArchiveAdmin from '@c/pages/ArchiveAdmin';
import ArchiveVendor from '@c/pages/ArchiveVendor';

export default {
  name: 'Home',
  components: {
    ArchiveAdmin,
    ArchiveVendor
  },
  computed: {
    currentArchivePage() {
      if (this.$store.getters.isUserAdmin) {
        return ArchiveAdmin;
      } else {
        return ArchiveVendor;
      }
    }
  }
};
</script>
