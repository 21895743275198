<template>
  <div :class="extraClass">
    <button class="pdf-btn" :class="['pdf-btn', { 'pdf-btn--noText': !withTxt }, { 'pdf-btn--disabled': isDisabled }]" @click="downloadPDF" :style="extraStyle" :disabled="isDisabled">
      <BaseIcon v-if="btnIcon" :icon="btnIcon" :extraClass="iconClasses"></BaseIcon>
      <span v-if="!isShort" :class="['pdf-btn__span', { 'pdf-btn__span--noText': !withTxt }]"><slot></slot></span>
    </button>

    <div class="pdf-btn__hidden"><OrdersPDF v-if="downloadingPDF" :ordersIds="ordersIds" @end-pdf-loading="endPDFLoading"></OrdersPDF></div>
  </div>
</template>

<script>
import OrdersPDF from '@c/common/OrdersPDF';
import BaseIcon from '@bc/BaseIcon';
import { buttonIconMixin } from '@c/mixins/buttonsMixins.js';

/**
 * This component loads a button used to download pdf file
 * @displayName BasePdfButton
 */
export default {
  name: 'BasePdfButton',
  components: {
    OrdersPDF,
    BaseIcon
  },
  mixins: [buttonIconMixin],
  props: {
    /**
     * This prop is used to pass an array of selected orders IDs
     */
    ordersIds: { type: Array },
    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to disable the button
     */
    isDisabled: { type: Boolean, default: false },

    /**
     * If set to true, only the button icon will be displayed
     */
    isShort: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      downloadingPDF: false
    };
  },

  computed: {
    // Check if any slots have been passed
    withTxt() {
      return this.$slots.default && !this.isShort;
    },
    btnIcon() {
      return !this.isLoading ? 'icon-file-text' : 'icon-spinner';
    }
  },
  methods: {
    downloadPDF() {
      if (this.ordersIds.length === 0) {
        this.$store.commit('addAlert', { msg: 'Seleziona almeno un ordine.' });
      }
      if (!this.isDisabled) {
        this.isLoading = true;
        this.downloadingPDF = true;
      }
    },

    endPDFLoading() {
      this.downloadingPDF = false;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_animations.scss';
@import '@s/_mixins.scss';

.pdf-btn {
  &,
  &:link,
  &:visited {
    align-items: center;
    background-color: rgba($color-primary-light, 0.25);
    border-radius: 4px;
    border: none;
    color: $color-primary;
    display: flex;
    font-weight: 700;
    justify-content: center;
    outline: none !important;
    padding: 0.7rem 1rem 0.7rem 0.5rem;
    text-decoration: none;
    transition: all 0.5s linear;
    width: fit-content;
    height: 100%;

    @include small-font-size;
    @include respond('tab-port') {
      padding: 1rem;
    }
  }

  &:hover,
  &:active {
    background-color: rgba($color-primary-light, 0.5);
  }

  &--noText {
    &,
    &:link,
    &:visited {
      padding: 1rem;
      height: fit-content;
    }
  }

  &--disabled {
    &,
    &:hover,
    &:focus {
      background-color: $color-grey-light;
      color: $color-blue-light;
      cursor: not-allowed;
      transition: all 0.2s linear;
    }
  }

  &__icon {
    fill: currentColor;
    flex-shrink: 0;
    height: 2.2rem !important;
    margin-right: 0.6rem;
    width: 2.2rem !important;

    @include respond('tab-port') {
      margin-right: 0;
    }

    &--noText {
      margin-right: 0;
    }

    &--loading {
      animation: rotate 1s linear infinite;
    }
  }

  &__span {
    &--noText {
      display: none;
    }
    @include respond('tab-port') {
      display: none;
    }
  }

  &__hidden {
    height: 0;
    overflow: hidden;
    width: 0;
  }
}
</style>

<docs>
### BasePdfButton simple
```js
<BasePdfButton>Hello</BasePdfButton>
```
### BaseActionButton without text
```js
<BasePdfButton></BasePdfButton>
```
</docs>
