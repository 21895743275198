import { getOrdersLabels } from '@gq/getOrdersLabels.gql';
import { mapActions } from 'vuex';

export const checkLabelsMixin = {
  apollo: {
    nodes() {
      return {
        query: getOrdersLabels,
        variables: {
          ids: []
        },
        skip: true
      };
    }
  },
  methods: {
    ...mapActions(['addAlert']),
    async getCurrentLabels(ids) {
      if (!ids || ids.length === 0) return;

      this.$apollo.queries.nodes.start();
      const response = await this.$apollo.queries.nodes.refetch({
        ids: ids
      });

      let labelsCreated = [];
      let labelsNotCreated = [];
      if (!response.data.nodes || response.data.nodes.length === 0) return;

      response.data.nodes.forEach(node => {
        const labels = node.label.edges;
        labels.forEach(label => {
          const { key, value } = label.node;
          const labelData = { id: node.id, name: node.name, vendor: key };

          if (value === 'true') {
            labelsCreated.push(labelData);
          } else {
            labelsNotCreated.push(labelData);
          }
        });
      });

      return {
        labelsCreated,
        labelsNotCreated
      };
    }
  }
};
