import Vue from 'vue';
import Home from './views/Home.vue';
import Archive from './views/Archive.vue';
import LottoVendor from './views/LottoVendor.vue';
import BatchesManagement from './views/BatchesManagement.vue';
import ProductsAndVendors from './views/ProductsAndVendors';
import ProductsVendor from './views/ProductsVendor';
import Router from 'vue-router';
import store from './store.js';
import { getCookie, setCookie } from '@/util/cookies';
import jwt_decode from 'jwt-decode';

const router = new Router({
  mode: 'history',
  routes: [
    // Common
    { name: 'Home', path: '/', component: Home, meta: { requiresAuth: true } },
    {
      name: 'logout',
      path: '/logout',
      beforeEnter: async () => {
        await setCookie({ cookieName: 'token', cookieValue: '', expiration: 'Thu, 01 Jan 1970 00:00:00 GMT' });
        window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);
      }
    },
    {
      name: 'Archivio Ordini',
      path: '/archivio-ordini',
      component: Archive,
      meta: { requiresAuth: true }
    },
    { name: 'My profile', path: '/profile', component: Home, meta: { requiresAuth: true } },
    {
      name: 'Notifiche',
      path: '/notifiche',
      meta: { requiresAuth: true }
    },
    { name: 'Impostazioni', path: '/impostazioni', meta: { requiresAuth: true } },

    // Admin
    {
      name: 'Prodotti e produttori',
      path: '/prodotti-e-produttori',
      component: ProductsAndVendors,
      meta: { requiresAuth: true },
      beforeEnter(to, from, next) {
        const isAdmin = store.getters.isUserAdmin;
        isAdmin ? next() : alert('You are not allowed to see this page');
      }
    },
    // Vendor
    {
      name: 'Prodotti',
      path: '/prodotti',
      component: ProductsVendor,
      meta: { requiresAuth: true },
      beforeEnter(to, from, next) {
        const isAdmin = store.getters.isUserAdmin;
        !isAdmin ? next() : alert('You are not allowed to see this page');
      }
    },
    { name: 'Lotto', path: '/lotto', component: LottoVendor, meta: { requiresAuth: true } },
    { name: 'Gestione Lotti', path: '/gestione-lotti', component: BatchesManagement, meta: { requiresAuth: true } }
  ]
});

/**
 * Check if the user is logged in before each route change
 */
router.beforeEach((to, from, next) => {
  /**
   * If the applicaton is running in FE dev env, skip token validation
   */
  if (process.env.VUE_APP_MOCK === 'true') {
    next();
    return;
  }

  /**
   * If the route has not been implemented yet, return false
   */

  if (to.fullPath === '/notifiche' || to.fullPath === '/impostazioni') {
    next(false);
    return;
  }

  // Retrieving the bearer token from the url
  const bearer_token = to.query.__bearer_access_token;
  let decoded_token;

  /**
   * If the bearer token exists, it means we are redirecting from the login page so we need to:
   * 1. set the cookie
   * 2. set the logged user
   * 3. redirect to home page
   */
  if (bearer_token) {
    decoded_token = jwt_decode(bearer_token);

    // 1.
    const expiration = decoded_token.exp;
    setCookie({ cookieName: 'token', cookieValue: to.query.__bearer_access_token, expiration });

    if (decoded_token.vendors) decoded_token.vendors = decoded_token.vendors.map(vendor => decodeURIComponent(vendor));

    // 2.
    store.dispatch('updateUser', { ...decoded_token.profile, role: decoded_token.role, vendors: decoded_token.vendors });

    // 3.
    next({
      name: 'Home'
    });

    return;
  }

  /**
   * If the cookies is not set, and we are not navigating to the login page, we need to redirect the user
   * to the login page
   */
  const token = getCookie('token');
  if (!token) {
    window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);

    return;
  }

  // If the user is logged and the user is not set on the store, set it
  if (Object.keys(store.getters.user).length === 0) {
    decoded_token = jwt_decode(token);
    const formattedVendors = decoded_token.vendors ? decoded_token.vendors.map(_vendor => decodeURIComponent(_vendor.trim())) : [];
    store.dispatch('updateUser', { ...decoded_token.profile, role: decoded_token.role, vendors: formattedVendors });
  }

  next();
});

Vue.use(Router);

export default router;
