<template>
  <tr :class="['order', 'order--firefox', { 'order--success': order.paymentStatus === 'pagato' }]" @click="clickFn">
    <td :class="['order__cell-1', { 'order__cell-1--success': isSuccess }]">
      <input type="checkbox" :id="order.id" class="order__input" :name="name" :value="order.id" :disabled="order.isDisabled" :checked="order.isChecked" @change="handleChange(order.id)" />
      <label :for="order.id" :class="['order__label', { 'order__label--success': isSuccess }]">
        <p :class="['order__btn', { 'order__btn--disabled': order.isDisabled }]"></p>
      </label>
    </td>
    <td :class="['order__id', { 'order__id--active': isActive }, { 'order__idActive--success': isActive && isSuccess }, { 'order__id--success': isSuccess }]">{{ order.name }}</td>
    <td :class="['order__nrOfItems', { 'order__nrOfItems--active': isActive }, { 'order__nrOfItemsActive--success': isActive && isSuccess }, { 'order__nrOfItems--success': isSuccess }]">
      {{ order.nrOfProducts }} {{ order.nrOfProducts === 1 ? 'prodotto' : 'prodotti' }}
    </td>
    <td
      v-if="!hideShipping"
      :class="['order__cell order__hideMobile', { 'order__cell--active': isActive }, { 'order__cellActive--success': isActive && isSuccess }, { 'order__cell--success': isSuccess }]"
    >
      {{ order.spedizione }}
    </td>
    <td
      :class="['order__cell', { 'order__cell--data': !shortDate }, { 'order__cell--active': isActive }, { 'order__cellActive--success': isActive && isSuccess }, { 'order__cell--success': isSuccess }]"
    >
      {{ orderDate }}
    </td>
  </tr>
</template>

<script>
/**
 * This component loads a preview of one order (displayed on the list with all the orders)
 *
 * @displayName VendorOrderPreview
 */
import { mapGetters } from 'vuex';
import { styleShortDate, styleHours } from '@u/helperFunctions';

export default {
  name: 'VendorOrderPreview',

  props: {
    /**
     * This prop is used to pass the details of one order
     */

    order: { type: Object },

    /**
     * This prop is used to pass the name of the group of checkboxes (orders)
     */

    name: { type: String },

    /**
     * If set to true, the order won't be closed when clicking twice on it
     */

    keepOrder: { type: Boolean, default: false },

    /**
     * If set to true, the information about the shipping won't be displayed
     */

    hideShipping: { type: Boolean, default: false },

    /**
     * If set to true, the date will be displayed in shorter format - without the time
     */

    shortDate: { type: Boolean, default: false }
  },

  /**
   * This component inject the handleChange function defined in VendorOrdersList component
   */

  inject: ['handleChange'],

  data() {
    return {
      // capitalize first letter of payment status

      paymentStatus: this.order.paymentStatus ? this.order.paymentStatus.charAt(0).toUpperCase() + this.order.paymentStatus.slice(1) : '',
      originalPaymentStatus: this.order.paymentStatus
    };
  },

  computed: {
    ...mapGetters(['isMobileMode', 'isTabMode', 'isTabLandMode', 'currentOrder']),
    orderDate() {
      const date = !this.isMobileMode && !this.shortDate ? `${styleShortDate(this.order.date)} ${styleHours(this.order.date)}` : styleShortDate(this.order.date);
      return date;
    },
    isActive() {
      return this.currentOrder === this.order.id;
    },
    isSuccess() {
      return this.originalPaymentStatus === 'PAID';
    }
  },

  methods: {
    clickFn(e) {
      if (e.target.closest('td') && !e.target.closest('td').classList.contains('order__cell-1')) {
        this.keepOrder ? this.$store.dispatch('showPermanentOrderDetails', this.order.id) : this.$store.dispatch('showOrderDetails', this.order.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

$cell-padding: 1rem 1rem 1.7rem 1rem;

// cell padding if clip-path is not supported

$cell-padding-no-clip: 1rem 1rem 1rem 1rem;

.order {
  width: 100%;
  background-color: $color-grey-light;
  font-family: inherit;
  @include small-font-size;
  border-bottom: 0.7rem solid $color-white;
  cursor: pointer;

  &--success {
    background-color: rgba($color-primary-light, 0.15);
  }

  @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
    clip-path: inset(0 0 0.75rem round 5px);
    border-bottom: none;
  }

  &__label {
    vertical-align: middle;
    padding: 0;
    z-index: 1;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: 0 0 0.7rem 0;
    }
  }

  &__btn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    @include flex-parent-center-childs;
    margin: 0 1rem;
    background: $color-white;
    border-radius: 3px;
    @include respond('tab-land') {
      height: calculateTabLandRem(20px);
      width: calculateTabLandRem(20px);
    }

    @include respond('tab-port') {
      height: calculateMobRem(20px);
      width: calculateMobRem(20px);
    }

    &--disabled {
      border: 1px solid $color-grey;
    }
  }

  &__btn::after {
    content: '';
    display: block;
    height: 1.7rem;
    width: 1.7rem;
    background-image: url('../../assets/SVG/checkmark.svg');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: 2px center;
    opacity: 0;
    transition: opacity 0.2s;
    border-radius: 2px;

    @include respond('tab-land') {
      height: calculateTabLandRem(17px);
      width: calculateTabLandRem(17px);
    }

    @include respond('tab-port') {
      height: calculateMobRem(17px);
      width: calculateMobRem(17px);
    }
  }

  &__btn--disabled::after {
    background-image: url('../../assets/SVG/checkmark-disabled.svg');
  }

  &__input {
    margin: 0 auto;
    display: block;
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }

  &__id {
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
    vertical-align: middle;
    padding: $cell-padding-no-clip;
    position: relative;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: $cell-padding;
    }
  }

  &__nrOfItems {
    @include mini-font-size;
    color: $color-blue-light;
    vertical-align: middle;
    padding: $cell-padding-no-clip;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(12px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(12px);
    }

    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: $cell-padding;
    }
  }

  &__cell {
    color: $color-dark-blue;
    vertical-align: middle;
    padding: $cell-padding-no-clip;
    position: relative;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: $cell-padding;
    }

    &--data {
      min-width: 14rem;
      text-align: right;

      @include respond('tab-port') {
        min-width: 17rem;
      }
    }
  }

  &__cell:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    height: 65%;
    width: 1px;
    background-color: $color-dark-blue;
    @include respond('phone') {
      display: none;
    }
  }

  &__cell--success:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    height: 65%;
    width: 1px;
    background-color: $color-primary;
    @include respond('phone') {
      display: none;
    }
  }

  &__cell-1 {
    vertical-align: middle;
    position: relative;
    width: 3.6rem;
    padding: 1rem;
    background-color: $color-dark-blue;
    border-top: 1px solid transparent;

    &--success {
      background-color: $color-primary;
    }
  }

  &__icon {
    margin-right: 0.7rem;
    height: 1.7rem;
    width: 1.7rem;
  }

  &__hideMobile {
    @include respond('phone') {
      display: none;
    }
  }

  // Hover effect

  &__id,
  &__nrOfItems,
  &__cell {
    &::before {
      content: '';
      display: block;
      background-color: transparent;
      border-top: 3px solid $color-dark-blue;
      border-bottom: 3px solid $color-dark-blue;
      height: calc(100% - 0.7rem);
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  &__cell:last-child::before {
    border-right: 3px solid $color-dark-blue;
    opacity: 0;
  }

  &__cell--success,
  &__id--success,
  &__nrOfItems--success {
    &::before {
      border-top: 3px solid $color-primary;
      border-bottom: 3px solid $color-primary;
    }
  }

  &__cell--success:last-child::before {
    border-right: 3px solid $color-primary;
  }

  &:hover &__id::before,
  &:hover &__nrOfItems::before,
  &:hover &__cell::before,
  &:hover &__cell:last-child::before {
    opacity: 1;
  }

  // Highlight active element

  &__id--active,
  &__nrOfItems--active,
  &__cell--active {
    &::before {
      content: '';
      display: block;
      background-color: transparent;
      border-top: 3px solid $color-dark-blue;
      border-bottom: 3px solid $color-dark-blue;
      height: calc(100% - 0.7rem);
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
    }
  }

  &__cell--active:last-child::before {
    border-right: 3px solid $color-dark-blue;
    opacity: 1;
  }

  &__cellActive--success,
  &__idActive--success,
  &__nrOfItemsActive--success {
    &::before {
      border-top: 3px solid $color-primary;
      border-bottom: 3px solid $color-primary;
    }
  }

  &__cellActive--success:last-child::before {
    border-right: 3px solid $color-primary;
  }
}

/** FIREFOX */
@-moz-document url-prefix() {
  .order--firefox {
    clip-path: inset(0 0 0.8rem round 5px) !important;
  }
}
</style>
