<template>
  <div>
    <CChartBar :style="chartStyle" :datasets="computedDatasets" :labels="labels" :options="computedOptions" />
  </div>
</template>

<script>
/**
 * This component uses CoreUI CChartBar to display a bar chart
 * Detailed documentation: https://www.chartjs.org/docs/latest/charts/bar.html
 *
 * @displayName BaseBarChart
 */
import { CChartBar } from '@coreui/vue-chartjs';
import { deepObjectsMerge } from '@coreui/utils/src';
import { shortenTxt } from '@u/helperFunctions.js';

export default {
  name: 'BaseBarChart',
  components: { CChartBar },
  props: {
    /**
     * This prop is used to pass labels of the chart. You can pass an array of strings or one of the string values
     * "months" or "indexes". Months mode will display months from January.
     * Indexes mode will assign numbers from 1.
     */
    labels: {
      type: [Array, String],
      default: 'months',
      validator: function (value) {
        if (!Array.isArray(value)) {
          // The value must match one of these strings
          return ['months', 'indexes'].indexOf(value) !== -1;
        }
        return true;
      }
    },
    /**
     * This prop is used to pass the datasets used in the chart.
     * Each dataset should receive an array of data and a label
     * Additionally you can pass backgroundColor (or array of background colors)
     * borderColor/-s and borderWidth
     */
    datasets: {
      type: Array,
      required: true,
      default() {
        return [
          {
            label: 'Ordini ricevuti',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          },
          {
            label: 'Ordini inviati',
            data: [30, 20, 10, 30, 10, 40, 45, 80, 40, 80, 12, 11]
          }
        ];
      }
    },
    /**
     * This prop is used to pass additional styles of the chart
     * To set height to a fixed value maintainAspectRatio should be off
     */
    extraStyle: { type: String },
    /**
     * This prop is used to pass additional options
     */
    options: {
      type: Object,
      default() {
        return {
          maintainAspectRatio: false,

          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                display: false
              }
            ],
            yAxes: [
              {
                display: true,
                beginAtZero: true
              }
            ]
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
              hoverBorderWidth: 3
            }
          }
        };
      }
    }
  },
  computed: {
    /**
     * This value is used to overwrite the default dataset values: backgroundColor, borderColor, borderWidth
     * labels and data
     */
    computedDatasets() {
      const newDataset = this.datasets.map(dataset => {
        return {
          label: dataset.label || 'items',
          data: dataset.data,
          backgroundColor: dataset.backgroundColor || 'rgba(243, 156, 18, 1)',
          borderColor: dataset.borderColor || 'rgba(243, 156, 18, 1)',
          borderWidth: dataset.borderWidth || 1
        };
      });
      return newDataset;
    },
    /**
     * This value is used to overwrite the default options.
     * Switch on/off aspect ratio, legend on the top, and x and y axes visibiity
     */
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },

        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 12,
                fontColor: '#B4BBD0',
                callback: function (value) {
                  return shortenTxt(value, 12);
                }
              },
              gridLines: {
                display: true,
                color: '#F5F6FA',
                zeroLineColor: 'transparent'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: 10,
                fontColor: '#B4BBD0'
              },
              gridLines: {
                display: true,
                color: '#F5F6FA',
                zeroLineColor: 'transparent'
              }
            }
          ]
        }
      };
    },
    computedOptions() {
      return deepObjectsMerge(this.defaultOptions, this.options || {});
    },
    chartStyle() {
      return `position: relative; ${this.extraStyle}`;
    }
  }
};
</script>

<docs>
### BaseBarChart  
```js
<BaseBarChart></BaseBarChart>
```
</docs>
