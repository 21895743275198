<template>
  <button
    :class="['action-btn', { 'action-btn--blue': isBlue, 'action-btn--noText': !withTxt, 'action-btn--disabled': isDisabled }, extraClass]"
    @click="handleClickFn"
    :style="extraStyle"
    :disabled="isDisabled"
  >
    <BaseIcon v-if="btnIcon" :icon="activeIcon" :extraClass="iconClasses"></BaseIcon><span v-if="!isShort" :class="['action-btn__span', { 'action-btn__span--noText': !withTxt }]"><slot></slot></span>
  </button>
</template>

<script>
import BaseIcon from '@bc/BaseIcon';
import { buttonIconMixin } from '@c/mixins/buttonsMixins.js';

/**
 * This component loads a button used to download files
 * @displayName BaseActionButton
 */
export default {
  name: 'BaseActionButton',
  components: {
    BaseIcon
  },
  mixins: [buttonIconMixin],
  props: {
    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to make the button blue
     */
    isBlue: { type: Boolean, default: false },
    /**
     * This prop is used to disable the button
     */
    isDisabled: { type: Boolean },
    /**
     * This prop is used to show the loading state of the button
     */
    isLoading: { type: Boolean, default: false },

    /**
     * This prop is used to pass the icon of the button. Icons should be defined in assets/symbol-defs.svg
     */
    btnIcon: { type: String },

    /**
     * If set to true, only the button icon will be displayed
     */
    isShort: { type: Boolean, default: false }
  },

  computed: {
    // Check if any slots have been passed
    withTxt() {
      return this.$slots.default && !this.isShort;
    },
    activeIcon() {
      return !this.isLoading ? this.btnIcon : 'icon-spinner';
    }
  },
  methods: {
    handleClickFn() {
      this.$emit('handle-click');
    }
  }
};
</script>

<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_animations.scss';
@import '@s/_mixins.scss';

.action-btn {
  &,
  &:link,
  &:visited {
    align-items: center;
    background-color: rgba($color-primary-light, 0.25);
    border-radius: 4px;
    border: none;
    color: $color-primary;
    display: flex;
    font-weight: 700;
    justify-content: center;
    outline: none !important;
    padding: 0.7rem 1rem 0.7rem 0.5rem;
    text-decoration: none;
    transition: all 0.5s linear;
    width: fit-content;

    @include small-font-size;
    @include respond('tab-port') {
      padding: 1rem;
    }
  }

  &:hover,
  &:active {
    background-color: rgba($color-primary-light, 0.5);
  }

  &--noText {
    &,
    &:link,
    &:visited {
      padding: 1rem;
    }
  }

  &--blue {
    color: $color-dark-blue;
    background: $color-grey-light;

    &:hover,
    &:active {
      background-color: $color-grey-light-2;
    }
  }

  &--disabled {
    &,
    &:hover,
    &:focus {
      background-color: $color-grey-light;
      color: $color-blue-light;
      cursor: not-allowed;
      transition: all 0.2s linear;
    }
  }

  &__span {
    &--noText {
      display: none;
    }
    @include respond('tab-port') {
      display: none;
    }
  }
}
</style>

<docs>
### BaseActionButton simple
```js
<BaseActionButton>Hello</BaseActionButton>
```
### BaseActionButton with icon
```js
<BaseActionButton btn-icon="icon-Truck"  extraStyle="margin-bottom: 2rem">Hello </BaseActionButton>
```
</docs>
