import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import store from '../store';
import router from '@/routes.js';

import jwt_decode from 'jwt-decode';
import { getCookie } from '@/util/cookies';

Vue.use(VueApollo);
const base_uri = process.env.VUE_APP_MOCK === 'true' ? process.env.VUE_APP_BASE_DOMAIN : `${process.env.VUE_APP_BASE_DOMAIN}/v1`;

const httpLink = createHttpLink({
  //uri: 'https://orteat.myshopify.com/admin/api/2021-01/graphql.json'
  // uri: 'http://localhost:4000/graphql'
  uri: `${base_uri}/graphql`,
  includeExtensions: true
});

const authLink = setContext((_, { headers }) => {
  const cookie = getCookie('token');

  const decoded_token = jwt_decode(cookie);

  // 1.
  const expiration = decoded_token.exp;
  const cookieExp = expiration * 1000;
  const isTokenExpired = Date.now() >= cookieExp;

  if (isTokenExpired) {
    document.cookie = 'token=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    return;
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getCookie('token')}`
    },
    http: {
      includeExtensions: true
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      const timestamp = Date.now();

      switch (extensions.code) {
        case 'UNAUTHENTICATED':
          router.push('/');
          break;
        // case 'MAX_COST_EXCEEDED':
        //   store.commit('setDataIsLoadingMsg', 'Caricamento dati...');
        //   // Retry the request
        //   // return forward(operation);
        //   break;
        case 'THROTTLED':
          if (!store.getters.throttledErrors.find(err => err.id === `${operation.operationName}_${timestamp}`)) {
            store.commit('pushThrottledError', {
              query: operation.query,
              retry: 0,
              path: path[0],
              variables: operation.variables,
              operationName: operation.operationName,
              timestamp,
              id: `${operation.operationName}_${timestamp}`
            });
          }
          break;
      }
    });
  }
});

const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache()
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
