<template>
  <button :class="['my-btn', extraClass, { 'my-btn--small': isSmall }]" @click="handleClickFn" :style="extraStyle" :disabled="disabled">
    <svg v-if="btnIcon" class="my-btn__icon"><use :href="`${require('@/assets/symbol-defs.svg')}#${btnIcon}`" /></svg><slot></slot>
  </button>
</template>
<script>
/**
 * This component loads a button
 *
 * @displayName BaseButton
 */
export default {
  name: 'BaseButton',
  props: {
    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to make the button smaller
     */
    isSmall: { type: Boolean, default: false },
    /**
     * This prop is used disable the button
     */
    disabled: { type: Boolean },
    /**
     * This prop is used to pass the icon of the button. Icons should be defined in assets/symbol-defs.svg
     */
    btnIcon: { type: String }
  },
  methods: {
    handleClickFn() {
      this.$emit('handle-click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.my-btn {
  &,
  &:link,
  &:visited {
    color: $color-white;
    font-size: $default-font-size;
    padding: 1.5rem 1.2rem;
    text-decoration: none;
    outline: none !important;
    background-color: map-get($theme-colors, 'primary');
    transition: all 0.5s linear;
    border: none;
    width: fit-content;
    border-radius: 3px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover,
  &:active {
    background-color: map-get($theme-colors, 'primary-900');
  }

  &--small {
    @include small-font-size;
    padding: 1.4rem 1rem;
  }

  &__icon {
    fill: currentColor;
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 0.5rem;
  }
}
</style>

<docs>
### BaseButton simple
```js
<BaseButton>Hello</BaseButton>
```
### BaseButton with icon
```js
<BaseButton btn-icon="icon-Truck"  extraStyle="margin-bottom: 2rem">Hello </BaseButton>
```
</docs>
