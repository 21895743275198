<template>
  <component v-bind:is="currentHomepage"></component>
</template>
<script>
import HomeAdmin from '@c/pages/HomeAdmin';
import HomeVendor from '@c/pages/HomeVendor';

export default {
  name: 'Home',
  computed: {
    currentHomepage() {
      if (this.$store.getters.isUserAdmin) {
        return HomeAdmin;
      } else {
        return HomeVendor;
      }
    }
  }
};
</script>
