<template>
  <div :class="['collectionFilter', extraClass]" :style="extraStyle">
    <div v-if="showMinimize" :class="['collectionFilter__minimize', { 'collectionFilter__minimize--collapsed': isMinimized }]">
      <span>Filtri</span>
      <span v-if="!isMinimized" class="collectionFilter__minimizeBtn" @click="isMinimized = !isMinimized"
        ><BaseIcon icon="icon-minus" extraClass="collectionFilter__minimizeIcon"></BaseIcon>Nascondi</span
      >
      <span v-else class="collectionFilter__minimizeBtn" @click="isMinimized = !isMinimized"><BaseIcon icon="icon-plus" extraClass="collectionFilter__minimizeIcon"></BaseIcon>Mostra</span>
    </div>
    <ul v-if="featuredCollections.length !== 0 && !isMinimized" class="collectionFilter__selected">
      <li v-for="(collection, index) in featuredCollections" class="collectionFilter__selectedLi" :key="index" @click="toggleSelection(collection)">
        <div class="collectionFilter__selectedItem">
          <span :class="['collectionFilter__btn', { 'collectionFilter__btn--selected': selections.includes(collection) }]"></span><span class="collectionFilter__title">{{ collection }}</span>
        </div>
      </li>
    </ul>
    <div class="collectionFilter__collections" v-if="!isMinimized">
      <h6 class="collectionFilter__picker" @click="collectionsDisplayed = !collectionsDisplayed">
        <span class="collectionFilter__title">Collezione</span>
        <BaseIcon
          :icon="collectionsDisplayed ? 'icon-arrow-up' : 'icon-arrow-down'"
          :extraClass="collectionsDisplayed ? 'collectionFilter__arrow collectionFilter__arrow--up' : 'collectionFilter__arrow'"
        ></BaseIcon>
      </h6>
      <ul v-if="collectionsDisplayed" class="collectionFilter__collectionsList">
        <li v-for="collection in listedCollections" :key="collection.title">
          <input class="collectionFilter__input" type="checkbox" :value="collection.title" name="collectionsSet" :id="collection.title" v-model="selections" />
          <label class="collectionFilter__label" :for="collection.title"><span class="collectionFilter__btn"></span>{{ collection.title }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a list's item with a button used to show the item's details
 *
 * @displayName BaseCollectionFilter
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseCollectionFilter',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass an array with the collections' names
     */
    collections: { type: Array, required: true },

    /**
     * This prop is used to pass an array with the collections that are featured in the blue box
     */
    featuredCollections: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * This prop is used to pass an array with the selected collections
     */
    selectedCollections: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * If set to true additional option to hide the filters will appear
     */
    showMinimize: {
      type: Boolean,
      default: false
    },

    /**
     * This prop is used to pass an extra class that should be applied to the filter
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },

  data() {
    return {
      collectionsDisplayed: false,
      isMinimized: false,
      selections: this.selectedCollections
    };
  },
  computed: {
    listedCollections() {
      // Hide featuredCollections from the collections list
      return this.collections.filter(collection => !this.featuredCollections.includes(collection.title));
    }
  },
  methods: {
    toggleSelection(collection) {
      // If the collection is selected - remove it from selections
      if (this.selections.includes(collection)) {
        const filteredCollections = this.selections.filter(selection => selection !== collection);
        this.selections = [...filteredCollections];
      } else {
        // otherwise select it
        this.selections = [...this.selections, collection];
      }
    }
  },
  watch: {
    // Emit "handle-selections" each time the selected collections change
    selections(value) {
      this.$emit('handle-selections', value);
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.collectionFilter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @include respond('tab-port') {
    flex-direction: column;
    width: 100%;
    background-color: $color-dark-blue;
    border-radius: 8px;
    padding: 1.625rem;
  }

  &__minimize {
    color: $color-white;
    @include default-font-size;
    width: 100%;
    @include flex-parent-space-between;
    padding-bottom: 3.75rem;

    &--collapsed {
      padding-bottom: 0;
    }
  }

  &__minimizeBtn {
    @include flex-parent-center-childs;
    padding: 2px 7px;
    background-color: $color-white;
    color: $color-dark-blue;
    border-radius: 17px;
    cursor: pointer;
    @include small-font-size;
  }

  &__minimizeIcon {
    height: 1.5rem !important;
    width: 1.5rem !important;
    margin-right: 1.5rem;
  }

  &__selected {
    @include flex-parent-space-between;
    background-color: $color-dark-blue;
    color: $color-white;
    padding: 0.65rem 1.3rem;
    border-radius: 3px;
    list-style: none;

    @include respond('tab-port') {
      width: 100%;
      padding: 0;
      flex-wrap: wrap;
    }
  }

  &__selectedItem {
    @include flex-parent-space-between;
  }

  &__selectedLi {
    cursor: pointer;
  }

  &__selectedLi:not(:last-child) {
    margin-right: 1.3rem;
  }

  &__picker {
    @include flex-parent-space-between;
    margin-left: 1.5rem;
    background-color: $color-dark-blue;
    padding: 0.9rem 1.3rem;
    border-radius: 3px;
    color: $color-white;
    cursor: pointer;

    @include respond('tab-port') {
      width: 100%;
      background-color: $color-white;
      color: $color-dark-blue;
      margin-left: 0;
      margin-top: 1.625rem;
    }
  }

  &__title {
    font-size: 1.4rem;
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
    color: inherit;

    @include respond('tab-land') {
      font-size: 1.56rem;
    }

    @include respond('tab-port') {
      font-size: 1.75rem;
    }
  }

  &__arrow {
    color: $color-blue-light;
    height: 1.1rem !important;
    width: 1.1rem !important;
    margin-left: 0.8rem;
    transform: translateY(0);
    transition: transform 0.3s;

    @include respond('tab-port') {
      color: $color-dark-blue;
    }
  }

  &__picker:hover &__arrow {
    transform: translateY(1px);
  }

  &__picker:hover &__arrow--up {
    transform: translateY(-1px);
  }

  &__collections {
    position: relative;

    @include respond('tab-port') {
      width: 100%;
    }
  }

  &__collectionsList {
    position: absolute;
    z-index: 30;
    top: 3.5rem;
    right: 0;
    background-color: $color-white;
    list-style: none;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: $primary-shadow;
    padding: 1rem;
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    @include small-font-size;
    color: $color-dark-blue;
    align-items: center;
  }

  &__btn {
    height: 1.6rem;
    width: 1.6rem;
    border: 1px solid $color-dark-blue;
    border-radius: 3px;
    margin-right: 1rem;
    background-color: $color-white;
    @include flex-parent-center-childs;

    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }

    &::after,
    &--selected::after {
      content: '';
      display: block;
      height: 1.4rem;
      width: 1.4rem;
      background-image: url('../../assets/SVG/checkmark.svg');
      background-size: 85%;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transition: opacity 0.2s;
      border-radius: 2px;

      @include respond('tab-port') {
        height: 1.9rem;
        width: 1.9rem;
      }
    }

    &--selected::after {
      opacity: 1;
    }
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }
}
</style>

<docs>
### BaseCollectionFilter simple
```js
<BaseCollectionFilter :collections="[{ id: 12355, name: 'Frutta' }, { id: 45655, name: 'Ortaggi' }, { id: 12559, name: 'Cassette' }]" ></BaseCollectionFilter>
```
 
</docs>
