<template>
  <li class="variantItem" :class="['variantItem', extraClass]" :style="extraStyle">
    <p class="variantItem__number">{{ number }}</p>
    <h6 class="variantItem__title">{{ title }}</h6>
  </li>
</template>
<script>
/**
 * This component loads a list item with a number and the title
 *
 * @displayName BaseVariantItem
 */
export default {
  name: 'BaseVariantItem',
  props: {
    /**
     * This prop is used to pass the title of the item
     */
    title: { type: String, required: true },
    /**
     * This prop is used to pass the number of the item
     */
    number: { type: Number },
    /**
     * This prop is used to pass an extra class that should be applied to the block
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.variantItem {
  margin-bottom: 1rem;
  list-style: none;
  display: flex;
  align-items: center;

  &__number {
    @include default-font-size;
    @include bold-text;
    @include flex-parent-center-childs;
    align-self: flex-start;
    background-color: $color-dark-blue;
    border-radius: 4px;
    border: none;
    color: $color-white;
    flex-shrink: 0;
    height: 2.8rem;
    margin: 0 1rem 0 0;
    width: 2.8rem;
  }
  &__title {
    @include default-font-size;
    margin-bottom: 0;
    color: $color-dark-blue;
  }
}
</style>

<docs>
 
### BaseVariantItem  
```js
<BaseVariantItem title="Title" subtitle="subtitle" info="info text" ></BaseVariantItem>
```
</docs>
