<template>
  <div :class="extraClass" :style="extraStyle">
    <h4 class="checkboxGroup__title">{{ title }}</h4>
    <div :class="['checkboxGroup', { 'checkboxGroup--narrow': isNarrow }]">
      <div v-for="(option, index) in options" :key="index" :class="['checkboxGroup__option', { 'checkboxGroup__option--narrow': isNarrow }]">
        <input type="checkbox" :id="option" class="checkboxGroup__input" :value="option" :name="groupName" v-model="selections" />
        <label :for="option" :class="['checkboxGroup__label', { 'checkboxGroup__label--narrow': isNarrow }]"><span class="checkboxGroup__btn"></span>{{ labels ? labels[index] : option }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseCheckboxGroup',
  props: {
    /**
     * This prop is used to pass the title of the checkbox group
     */
    title: { type: String },

    /**
     * This prop is used to pass an array of available options
     */
    options: { type: Array, required: true },

    /**
     * This prop is used to pass an array with the options' labels
     * If not passed, the label will be the same as the option value
     */
    labels: { type: Array },

    /**
     * This prop is used to pass the name of the checkbox group
     */
    groupName: { type: String, required: true },

    /**
     * This prop is used to pass an array of the options that should be selected on component render
     */
    initialSelections: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * If this prop is set to true the options will take the full container's width
     */
    isNarrow: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the checkbox group
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      selections: this.initialSelections
    };
  },
  watch: {
    selections() {
      this.$emit('handle-selections-change', this.groupName, this.selections);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.checkboxGroup {
  @include flex-parent-space-between;
  flex-wrap: wrap;
  padding-right: 1rem;

  @include respond('tab-port') {
    justify-content: flex-start;
  }

  &--narrow {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    @include default-font-size;
    @include bold-text;
    margin-bottom: 2rem;
  }

  &__option {
    width: 50%;
    padding-right: 1rem;

    &--narrow {
      width: 100%;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    font-family: inherit;
    @include default-font-size;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include respond('tab-port') {
      margin-right: 2rem;
    }

    &--narrow {
      margin-right: 2rem;
    }
  }

  &__btn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 1rem;
    flex-shrink: 0;

    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 1.2rem;
      width: 1.2rem;
      background-image: url('../../assets/SVG/checkmark.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }
}
</style>
