<template>
  <div v-if="!$apollo.loading && orders.length !== 0 && allDataReady">
    <div v-for="(vendorOrders, index) in vendorsOrders" :key="index" class="vendor_pdf">
      <VendorOrdersPDF :vendorOrders="vendorOrders" :isLast="index === vendorsOrders.length - 1" @handle-pdf-downloaded="handlePDFDownloaded"></VendorOrdersPDF>
    </div>
  </div>
</template>
<script>
/**
 * This component prepares a document that will be printed as pdf.
 * It includes the details of the selected orders passed in props
 *
 * @displayName OrdersPDF
 */
import { mapGetters } from 'vuex';
import { getOrdersDetails } from '@gq/getOrdersDetails.gql';
import { styleShortDate, styleHours } from '@u/helperFunctions.js';
import VendorOrdersPDF from '@c/common/VendorOrdersPDF';

export default {
  name: 'OrdersPDF',
  components: {
    VendorOrdersPDF
  },

  props: {
    /**
     * This prop is used to pass the orders ids
     */
    ordersIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      nodes: {},
      orders: [],
      vendorsOrders: [],
      pdfsInfo: [],
      allDataReady: false
    };
  },
  computed: {
    ...mapGetters(['currentVendor', 'currentVendors', 'isUserAdmin'])
  },
  methods: {
    startDownloading() {
      this.$emit('handle-orders-loading');
    },
    handlePDFDownloaded(vendor) {
      const foundPDF = this.pdfsInfo.find(vendorObj => vendorObj.vendor === vendor);
      foundPDF.isCompleted = true;
    }
  },

  apollo: {
    // Call when component is rendered
    nodes() {
      return {
        query: getOrdersDetails,
        variables: {
          ids: this.ordersIds
        }
      };
    }
  },
  watch: {
    nodes(val) {
      if (val && val.length !== 0) {
        const results = val.map(node => {
          let nodeVendors = [];

          if (this.isUserAdmin) {
            // On admin account get selected vendor if it exists, or all the order's vendors
            nodeVendors = this.currentVendor ? [this.currentVendor] : node.lineItems.edges.map(item => item.node.vendor);
          } else {
            nodeVendors = this.currentVendors;
          }
          // Remove invalid line items and filter line items to get only the current vendor's line items
          const filteredLineItems = node.lineItems.edges.filter(
            lineItem => lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.currentQuantity > 0 && nodeVendors.includes(lineItem.node.vendor)
          );

          return {
            date: styleShortDate(node.createdAt),
            time: styleHours(node.createdAt),
            name: node.name,
            id: node.id,
            lineItems:
              filteredLineItems.length > 0
                ? filteredLineItems.map(item => {
                    let customAttributesString = '';

                    if (item.node.customAttributes && item.node.customAttributes.length !== 0) {
                      customAttributesString = item.node.customAttributes.map(attr => attr.value).join(' | ');
                    }

                    return {
                      customAttributes: item.node.customAttributes || [],
                      customAttributesString,
                      quantity: item.node.currentQuantity,
                      name: item.node.product ? item.node.product.title : item.node.name,
                      variantName: item.node.variant ? item.node.variant.title : '',
                      vendor: item.node.vendor
                    };
                  })
                : [],
            vendors: filteredLineItems.length > 0 ? filteredLineItems.map(item => item.node.vendor) : [],
            clientData: {
              firstName: node.shippingAddress ? node.shippingAddress.firstName : '---',
              lastName: node.shippingAddress ? node.shippingAddress.lastName : '---',
              company: node.shippingAddress ? node.shippingAddress.company : '',
              address: node.shippingAddress
                ? `${node.shippingAddress.address1} ${node.shippingAddress.address2}, ${node.shippingAddress.zip} ${node.shippingAddress.city}(${node.shippingAddress.provinceCode}) `
                : '---'
            }
          };
        });

        this.orders = results;

        // Split results into vendors
        // Get all vendors
        const vendors = new Set([]);
        results.forEach(order => order.lineItems.forEach(item => vendors.add(item.vendor)));

        // Sort orders by vendor

        const orderedOrders = [...vendors].map((vendor, index) => {
          const vendorOrders = results
            .filter(result => result.vendors.includes(vendor))
            .map(order => {
              const filteredLineItems = order.lineItems.filter(lineItem => lineItem.vendor === vendor);
              return {
                ...order,
                filteredLineItems,
                vendor,
                vendorCSSClass: `vendorPDF__${index}`
              };
            });

          return vendorOrders;
        });

        this.vendorsOrders = orderedOrders;
        this.allDataReady = true;
        this.pdfsInfo = [...vendors].map(vendor => {
          return {
            vendor,
            isCompleted: false
          };
        });

        // this.startDownloading();
      }
    },
    pdfsInfo: {
      deep: true,
      handler(val) {
        // If all completed stop pdf button loading
        if (val.filter(pdf => !pdf.isCompleted).length === 0) this.$emit('end-pdf-loading');
      }
    }
  },
  updated() {
    document.body.style.overflow = 'auto';
  },
  created() {
    'orders pdf created';
  },
  beforeDestroy() {
    this.allDataReady = false;
  }
};
</script>
