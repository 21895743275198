<template>
  <BaseLoadingSpinner v-if="$apollo.loading || !fullyLoaded || !allDataReady"></BaseLoadingSpinner>
  <div v-else class="productsStats__charts">
    <div v-if="isTabMode" class="productsStats__legend">
      <BaseChartLegend extraClass="productsStats__legendItems" :items="productsLegend" :colors="colors"></BaseChartLegend>
    </div>
    <div class="productsStats__heading">
      <BaseHeading :level="4">Totale kg venduti per prodotto</BaseHeading>
      <BaseActionButton v-if="isTabMode && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
      <DownloadExcel v-if="isTabMode && !btnsDisabled" :data="json_kg_sold" :fields="json_fields" header="Kg venduti per prodotto " :name="`kg-venduti-per-prodotto.xls`">
        <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
      </DownloadExcel>
    </div>

    <div class="productsStats__data">
      <div class="productsStats__chart">
        <BaseLineChart :extraStyle="chartStyle" :labels="productLabels" :options="options" :datasets="kgVendutiPerProdotto"></BaseLineChart>
      </div>

      <div v-if="!isTabMode" class="productsStats__legend">
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_kg_sold" :fields="json_fields" header="Kg venduti per prodotto " :name="`kg-venduti-per-prodotto.xls`">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
        <BaseChartLegend :items="productsLegend" :colors="colors"></BaseChartLegend>
      </div>
    </div>
    <div class="productsStats__heading productsStats__heading--2">
      <BaseHeading :level="4">Totale fatturato per prodotto</BaseHeading>
      <BaseActionButton v-if="isTabMode && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
      <DownloadExcel v-if="isTabMode && !btnsDisabled" :data="json_products_earnings" :fields="json_fields" header="Totale fatturato per prodotto " :name="`totale-fatturato-per-prodotto.xls`">
        <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
      </DownloadExcel>
    </div>
    <div class="productsStats__data">
      <div class="productsStats__chart">
        <BaseLineChart :extraStyle="chartStyle" :labels="productLabels" :options="options" :datasets="fatturato"></BaseLineChart>
      </div>

      <div v-if="!isTabMode" class="productsStats__legend">
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_products_earnings" :fields="json_fields" header="Totale fatturato per prodotto " :name="`totale-fatturato-per-prodotto.xls`">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
        <BaseChartLegend :items="productsLegend" :colors="colors"></BaseChartLegend>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeading from '@bc/BaseHeading';
import BaseActionButton from '@bc/BaseActionButton';
import BaseLineChart from '@bc/BaseLineChart';
import BaseChartLegend from '@bc/BaseChartLegend';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { getDaysOfTheWeek, getNumericDate, getDatesArr, getNumericDateOfTheNextDate, convertWeightToKg } from '@u/helperFunctions';
import { mapGetters } from 'vuex';
import { getVendorProductsOrdersWithWeights } from '@gq/getVendorProductsOrdersWithWeights.gql';
import { sleepMixin } from '@c/mixins/sleepMixin.js';
import { fetchMoreOrdersMixin } from '@c/mixins/graphqlMixins.js';

const boxTag = 'cassetta';

export default {
  name: 'VendorProductsStats',
  components: {
    BaseHeading,
    BaseActionButton,
    BaseLineChart,
    BaseChartLegend,
    BaseLoadingSpinner
  },
  mixins: [fetchMoreOrdersMixin, sleepMixin],
  props: {
    /**
     * This prop is used to pass the time range for the charts
     */
    selectedTimeRange: { type: Array },
    /**
     * This prop is used to pass an array with all the vendor's products
     */
    products: { type: Array, required: true }
  },
  data() {
    return {
      fullyLoaded: false,
      allDataReady: false,
      orders: {},

      // Colors of the chart's lines
      colors: [
        '#F39C12',
        '#27AE60',
        '#3475E0',
        '#987FD5',
        '#E15A39',
        '#FDD75E',
        '#8CE7F1',
        '#7BE5A2',
        '#FFF073',
        '#87FF72',
        '#F3C7FB',
        '#FF6A6E',
        '#6AB8FE',
        '#A86AFE',
        '#DAF7A6',
        '#EE1A6D',
        '#F1FE71',
        '#FEDE71',
        '#5553CB',
        '#CB53A5'
      ],
      options: {
        maintainAspectRatio: false,
        // Charts labels styling
        legend: {
          display: false
        }
      },
      dataFatturato: [],
      kgVenduti: [],
      productsWithOrdersArr: []
    };
  },
  apollo: {
    // Call when component is rendered
    orders() {
      return {
        query: getVendorProductsOrdersWithWeights,
        variables: {
          queryString: this.queryString
        },
        error() {
          // Error
          this.forceAllDataLoaded();
        }
      };
    }
  },

  computed: {
    ...mapGetters(['isTabMode']),
    btnsDisabled() {
      return !this.orders || this.orders.length === 0;
    },
    // Get an array of days of the week from the selected time range
    productLabels() {
      let labels = [];
      if (this.selectedTimeRange && this.selectedTimeRange[0] !== null) {
        labels = getDaysOfTheWeek(this.selectedTimeRange);
      }
      return labels;
    },

    productsLegend() {
      let productsLegend = [];
      if (this.productsWithOrdersArr && this.productsWithOrdersArr.length !== 0) {
        productsLegend = this.productsWithOrdersArr.map(productData => productData.product.name);
      }
      return productsLegend;
    },

    fatturato() {
      let results = [];
      if (this.dataFatturato.length !== 0 && this.productsWithOrdersArr.length !== 0) {
        results = this.productsWithOrdersArr.map((product, index) => ({
          label: product.product.name,
          data: this.dataFatturato[index],
          borderColor: this.colors[index],
          backgroundColor: this.colors[index]
        }));
      }
      return results;
    },

    kgVendutiPerProdotto() {
      let results = [];
      if (this.kgVenduti.length !== 0 && this.productsWithOrdersArr.length !== 0) {
        results = this.productsWithOrdersArr.map((product, index) => ({
          label: product.product.name,
          data: this.kgVenduti[index],
          borderColor: this.colors[index],
          backgroundColor: this.colors[index]
        }));
      }
      return results;
    },
    chartStyle() {
      return !this.isTabMode ? 'height: 30rem; width: 100%; ' : 'width: 100%; height: 37rem;';
    },
    numericTimeRange() {
      const startDate = getNumericDate(this.selectedTimeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);
      return [startDate, endDate];
    },

    queryString() {
      return `created_at:>=${this.numericTimeRange[0]} AND created_at:<=${this.numericTimeRange[1]}`;
    },

    // EXCELS
    json_fields() {
      const productsArr = this.productsLegend.map((product, index) => {
        const obj = {};
        obj[product] = 'product' + index;

        return obj;
      });

      let mergedObj = { Data: 'date' };

      productsArr.forEach(product => {
        mergedObj = { ...mergedObj, ...product };
      });

      return mergedObj;
    },
    json_kg_sold() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.kgVenduti.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        const productsCodes = this.productsLegend.map((product, index) => 'product' + index);

        json = arrOfDays.map((date, dayIndex) => {
          const obj = { date: date };

          productsCodes.forEach((code, productIndex) => {
            obj[code] = this.kgVenduti[productIndex][dayIndex];
          });

          return obj;
        });
      }

      return json;
    },

    json_products_earnings() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.dataFatturato.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        const productsCodes = this.productsLegend.map((product, index) => 'product' + index);

        json = arrOfDays.map((date, dayIndex) => {
          const obj = { date: date };

          productsCodes.forEach((code, productIndex) => {
            obj[code] = this.dataFatturato[productIndex][dayIndex];
          });

          return obj;
        });
      }

      return json;
    }
  },
  methods: {
    clearChartsData() {
      this.dataFatturato = [];
      this.kgVenduti = [];
      this.orders = [];
      this.productsWithOrdersArr = [];
    },
    forceAllDataLoaded() {
      this.fullyLoaded = true;
      this.allDataReady = true;
      this.clearChartsData();
    },

    generateBoxesSalesForExcels(orders) {
      // Calculate boxes sales and generate the data for the excels
      // Find shop products that are boxes
      const boxes = this.products.filter(product => product.tags.includes(boxTag));

      const boxesProductsStats = boxes.map(box => {
        let boxProducts = [];

        orders.edges
          .filter(order => order.node.tags.includes(box.id))
          .forEach(filteredOrder => {
            const boxLineItems = filteredOrder.node.lineItems.edges.filter(lineItem => lineItem.node.product && lineItem.node.product.id === box.id && lineItem.node.currentQuantity > 0);
            if (boxLineItems.length !== 0) {
              boxLineItems.forEach(boxLineItem => {
                // Get box variant
                const boxVariantId = boxLineItem.node.variant ? boxLineItem.node.variant.id : '';
                // Get variant content
                const boxVariant = box.variants ? box.variants.find(variant => variant.id === boxVariantId) : '';
                const boxVariantContent = boxVariant ? boxVariant.content : [];
                if (boxVariantContent.length !== 0) {
                  // Add products and its sales
                  boxVariantContent.forEach(variantProduct => {
                    const existingProduct = boxProducts.find(product => product.id === variantProduct.productId);
                    if (existingProduct) {
                      existingProduct.totalKgSold += variantProduct.quantityInKg * boxLineItem.node.currentQuantity;
                    } else {
                      boxProducts = [
                        ...boxProducts,
                        {
                          id: variantProduct.productId,
                          title: variantProduct.productName,
                          totalKgSold: variantProduct.quantityInKg * boxLineItem.node.currentQuantity
                        }
                      ];
                    }
                  });
                }
              });
            }
          });
        return {
          ...box,
          boxProducts
        };
      });

      this.boxesProductsStats = boxesProductsStats;
    }
  },
  watch: {
    // Call apollo when selectedTimeRange changes
    selectedTimeRange(val) {
      if (val) {
        if (val[0] !== null) {
          this.fullyLoaded = false;
          this.allDataReady = false;
          this.clearChartsData();

          // Get orders
          this.$apollo.queries.orders.start();
          this.$apollo.queries.orders.refetch({
            queryString: this.queryString
          });
        } else {
          this.forceAllDataLoaded();
        }
      }
    },
    orders(val) {
      if (val && val.edges) {
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          if (!this.selectedTimeRange || this.selectedTimeRange[0] === null || val.edges.length === 0) {
            // Show empty charts
            this.forceAllDataLoaded();
          } else {
            this.fullyLoaded = true;
            // 1. Split orders into arrays with the orders of one product
            if (this.products && this.products.length !== 0) {
              const productsOrdersArr = this.products.map(product => {
                return {
                  product,
                  orders: val.edges.filter(order => order.node.lineItems.edges.filter(item => item.node.product && item.node.product.id === product.id && item.node.currentQuantity > 0).length !== 0)
                };
              });

              // Get only products with orders
              const productsWithOrdersArr = productsOrdersArr.filter(product => product.orders.length !== 0);
              this.productsWithOrdersArr = productsWithOrdersArr;

              // 2. Split orders into arrays with the orders placed in the same day
              const arrOfDays = getDatesArr(this.selectedTimeRange);
              const arrOfProductsOrdersDividedByDay = productsWithOrdersArr.map(product => {
                return arrOfDays.map(date => {
                  const result =
                    product.orders.length !== 0
                      ? product.orders.filter(item => {
                          const numDate = getNumericDate(item.node.createdAt);
                          return numDate === date;
                        })
                      : [];
                  return result;
                });
              });

              // 3. Create an array of arrays with the total earnings in the selected time period

              const earningsArrOfArrays = arrOfProductsOrdersDividedByDay.map((productOrders, index) => {
                return productOrders.map(dayArr => {
                  if (dayArr.length === 0) {
                    return 0;
                  } else {
                    let totalEarnings = 0;
                    dayArr.forEach(order => {
                      order.node.lineItems.edges
                        .filter(item => item.node.product && item.node.product.id === productsWithOrdersArr[index].product.id && item.node.currentQuantity > 0)
                        .forEach(
                          filteredLineItem => (totalEarnings += Number(filteredLineItem.node.originalTotalSet.shopMoney.amount) - Number(filteredLineItem.node.totalDiscountSet.shopMoney.amount))
                        );
                    });

                    return totalEarnings;
                  }
                });
              });

              // 3. Create an array of arrays with the total weight of sold products in the selected time period

              const weightArrOfArrays = arrOfProductsOrdersDividedByDay.map((productOrders, index) => {
                return productOrders.map(dayArr => {
                  if (dayArr.length === 0) {
                    return 0;
                  } else {
                    let totalWeight = 0;

                    dayArr.forEach(order => {
                      order.node.lineItems.edges
                        .filter(item => item.node.product && item.node.product.id === productsWithOrdersArr[index].product.id && item.node.currentQuantity > 0)
                        .forEach(filteredLineItem => {
                          let itemWeight = 0;
                          const itemVariant = filteredLineItem.node.variant;
                          if (itemVariant) {
                            itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);
                          }

                          totalWeight += Number(itemWeight);
                        });
                    });

                    return totalWeight;
                  }
                });
              });

              this.dataFatturato = earningsArrOfArrays;
              this.kgVenduti = weightArrOfArrays;

              this.allDataReady = true;

              this.generateBoxesSalesForExcels(val);
            }
          }
        }
      }
    }
  },

  beforeDestroy() {
    this.fullyLoaded = false;
    this.addDataReady = false;
    this.productsWithOrdersArr = [];
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.productsStats {
  &__heading {
    margin: 4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include respond('tab-port') {
      margin: 0 0 calculateMobRem(16px) 0;
    }

    &--2 {
      margin: calculateMobRem(40px) 0 calculateMobRem(16px) 0;
    }
  }

  &__data {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__chart {
    margin-right: 2rem;
    flex-grow: 1;

    @include respond('tab-port') {
      margin-right: 0;
      width: 100%;
    }
  }

  &__legend {
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__legendItems {
    padding-top: 0;
  }
}
</style>
