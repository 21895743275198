<template>
  <ul :class="['legend', extraClass]" :style="extraStyle">
    <li v-for="(item, index) in items" :key="index" class="legend__item">
      <span class="legend__symbol" :style="{ backgroundColor: colors[index] }"></span><span class="legend__text">{{ item }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'BaseChartLegend',
  props: {
    /**
     * This prop is used to pass an array of the legend items titles
     */
    items: { type: Array },
    /**
     * This prop is used to pass an array of colors
     */
    colors: {
      type: Array,
      default() {
        return [
          '#F39C12',
          '#27AE60',
          '#3475E0',
          '#987FD5',
          '#E15A39',
          '#FDD75E',
          '#8CE7F1',
          '#7BE5A2',
          '#FFF073',
          '#87FF72',
          '#F3C7FB',
          '#FF6A6E',
          '#6AB8FE',
          '#A86AFE',
          '#DAF7A6',
          '#EE1A6D',
          '#F1FE71',
          '#FEDE71',
          '#5553CB',
          '#CB53A5'
        ];
      }
    },
    /**
     * This prop is used to pass an extra class that should be applied to the legend
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.legend {
  padding: 2rem 0;
  display: flex;
  align-items: flex-start;
  list-style: none;
  flex-direction: column;

  &__item {
    @include mini-font-size;
    @include flex-parent-space-between;
  }

  &__item:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__symbol {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $color-primary;
    border-radius: 3px;
    margin-right: 1rem;
    flex-shrink: 0;
  }
}
</style>
<docs>
### BaseLegend simple
```js
<BaseChartLegend></BaseChartLegend>
```
 
 
</docs>
