<template>
  <div class="swiper">
    <Swiper ref="mySwiper" :options="swiperOptions" @slideChange="onSlideChange">
      <SwiperSlide v-for="(order, index) in currentOrders" :key="index + order.id"><Order :orderId="order.id" :isArchive="isArchive"></Order></SwiperSlide>

      <div v-if="isTabMode" class="swiper-pagination" slot="pagination"></div>
    </Swiper>
  </div>
</template>

<script>
/**
 * This component loads a swiper with all the orders from currentSetOfOrders and details of the current order
 *
 * @displayName SwiperOrders
 */
import { mapGetters, mapActions } from 'vuex';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import Order from '@c/common/Order';

SwiperCore.use([Pagination]);

export default {
  name: 'SwiperOrders',
  props: {
    /**
     * This prop is used to show "ri-attiva ordine" button instead of "archivia ordine"
     */
    isArchive: { type: Boolean }
  },
  components: {
    Swiper,
    SwiperSlide,
    Order
  },
  directives: {
    swiper: directive
  },

  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        slidesPerView: 1,
        spaceBetween: 100,
        centeredSlides: true
      }
    };
  },
  computed: {
    ...mapGetters(['currentSetOfOrders', 'currentOrder', 'minimize', 'isTabMode']),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    orderId() {
      return this.$store.getters.currentOrder;
    },
    currentOrders() {
      return this.$store.getters.currentSetOfOrders;
    }
  },

  methods: {
    ...mapActions(['set', 'hideOrderDetails', 'hideVendorOrdersList']),
    onSlideChange() {
      const curOrderId = this.currentSetOfOrders[this.swiper.activeIndex].id;

      // Set current order
      this.set(['currentOrder', curOrderId]);
    },
    goBack() {
      this.hideVendorOrdersList();
      this.hideOrderDetails();
    }
  },
  watch: {
    orderId(val) {
      // find the index of currentOrders in currentSetOfOrders f
      const index = this.currentSetOfOrders.findIndex(orderObj => orderObj.id === val);

      this.swiper.slideTo(index, 1000, this.onSlideChange);
    }
  },

  mounted() {
    // find the index of currentOrders in currentSetOfOrders
    const index = this.currentSetOfOrders.findIndex(orderObj => orderObj.id === this.currentOrder);

    this.swiper.slideTo(index, 1000, this.onSlideChange);
  }
};
</script>
