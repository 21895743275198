<template>
  <div :class="['input__group', extraClass]" :style="extraStyle">
    <label class="input__label" :id="id">{{ label }} <span v-if="isRequired" class="input__required">*</span></label>
    <div v-if="type !== 'date'" :class="['input__wrapper', { 'input__wrapper--short': isShort }]">
      <input
        :class="['input', { 'input--short': isShort }]"
        :id="id"
        :type="type"
        :value="initialValue"
        :step="type === 'number' && 0.1"
        :min="type === 'number' && 0"
        :disabled="isDisabled"
        @input="e => $emit('handle-change', e.target.value)"
      />
      <p v-if="extraTxt" class="input__extraTxt">{{ extraTxt }}</p>
    </div>
    <div v-if="type === 'date'" class="input__datepicker datepicker-form">
      <DatePicker v-model="selectedDate" :disabled="isDisabled" :formatter="formatDate" prefix-class="xmx" placeholder="   /     /  "></DatePicker>
    </div>
  </div>
</template>
<script>
/**
 * This component loads an input form element
 *
 * @displayName BaseInput
 */
import DatePicker from 'vue2-datepicker';
import { styleShortDate } from '@u/helperFunctions.js';

export default {
  name: 'BaseInput',
  components: {
    DatePicker
  },
  props: {
    /**
     * This prop is used to pass the type of the input
     */
    type: { type: String, default: 'text' },
    /**
     * This prop is used to pass the label of the input
     */
    label: { type: String },
    /**
     * This prop is used to pass the id of the input
     */
    id: { type: String, required: true },
    /**
     * This prop is used to pass the initial value of the input
     */
    initialValue: { type: [String, Number, Date] },
    /**
     * If set to true this field will be required
     */
    isRequired: { type: Boolean, default: false },
    /**
     * If set to true this field will be shorter
     */
    isShort: { type: Boolean, default: false },
    /**
     * If set to true this field will be disabled
     */
    isDisabled: { type: Boolean, default: false },
    /**
     * This prop is used to pass extra text that will be added after input
     */
    extraTxt: { type: String },
    /**
     * This prop is used to pass an extra class that should be applied to the input
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      selectedDate: this.initialValue,
      formatDate: {
        stringify: date => {
          return styleShortDate(date);
        }
      }
    };
  },

  watch: {
    selectedDate(val) {
      this.$emit('handle-change', val);
    },
    initialValue(val) {
      this.selectedDate = val;
    }
  }
};
</script>
<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.input {
  @include default-font-size;
  font-family: inherit;
  color: $color-text;
  padding: 0.2rem 0.4rem;
  background-color: $color-white;
  border: 1px solid $color-blue-light;
  border-radius: 2px;
  outline: none;
  display: inline-block;
  transition: all 0.3s;
  width: 100%;
  margin-left: auto;

  &:focus {
    outline: none;
    border: 1px solid $color-primary;
  }

  &:invalid {
    border: 1px solid $color-flame-pea;
  }

  &:disabled {
    background-color: transparent;
  }

  &__wrapper {
    max-width: 19rem;
    display: flex;
    align-items: center;
    @include respond('tab-port') {
      max-width: calculateMobRem(200px);
    }

    &--short {
      max-width: 11.8rem;
      @include respond('tab-port') {
        max-width: calculateMobRem(118px);
      }
    }
  }

  &__group {
    width: 100%;
    padding: 1rem 0;
    @include flex-parent-space-between;
  }

  &__group:not(:last-child) {
    margin-bottom: 1rem;

    @include respond('tab-port') {
      margin-bottom: 0;
    }
  }

  &__label {
    @include default-font-size;
    display: inline-block;
    transition: all 0.3s;
    padding-right: 1rem;
  }

  &__required {
    color: $color-flame-pea;
  }

  &__extraTxt {
    margin-bottom: 0;
    margin-left: 1rem;
  }

  &__datepicker {
    max-width: 11.8rem;
    @include respond('tab-port') {
      max-width: calculateMobRem(118px);
    }
  }
}
</style>
