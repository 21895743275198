export const ddtGeneratedMixin = {
  methods: {
    checkIfDdtGenerated() {
      let ddtGenerated = true;
      // Check if DDT has been generated for all the orders
      this.selectedOrders.forEach(selectedOrder => {
        const orderCurVendors = this.getOrderCurVendors(selectedOrder.vendors);

        if (selectedOrder.vendor) {
          // If the order's vendor is not included in the array of current vendors, return
          if (!orderCurVendors.includes(selectedOrder.vendor)) return;
          // Check if DDT has been generated for the current vendor
          const orderLabel = selectedOrder.label.find(labelNode => labelNode.node.key === selectedOrder.vendor);
          ddtGenerated = ddtGenerated && orderLabel && orderLabel.node.value === 'true';
        } else {
          // Check if DDT has been generated for all the vendors
          selectedOrder.vendors.forEach(vendor => {
            // If the order's vendor is not included in the array of current vendors, return
            if (!orderCurVendors.includes(vendor)) return;

            const orderLabel = selectedOrder.label.find(labelNode => labelNode.node.key === vendor);
            ddtGenerated = ddtGenerated && orderLabel && orderLabel.node.value === 'true';
          });
        }
      });

      return ddtGenerated;
    }
  }
};
