<template>
  <div class="loadingInfo">
    <BaseIcon icon="icon-spinner" extraClass="loadingInfo__icon"></BaseIcon>
    <p class="loadingInfo__text">
      {{ msg }}
    </p>
  </div>
</template>
<script>
import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'LoadingInfo',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass the message that should be displayed
     * in a notification alert
     */
    msg: { type: String, required: true }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_animations.scss';
@import '@s/_mixins.scss';

.loadingInfo {
  background-color: rgba($color-dark-blue, 0.9);
  border-radius: 8px;
  box-shadow: $shadow-right;
  padding: 1.7rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  @include flex-parent-space-between;

  &__icon {
    color: $color-white;
    animation: rotate 1s linear infinite;
    margin-right: 1rem;
  }

  &__text {
    @include default-font-size;
    color: $color-white;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
