<template>
  <div class="lottoCreation">
    <div class="lottoCreation__col">
      <p class="lottoCreation__description">Il lotto di produzione per la tracciabilità dei prodotti è stato correttamente assegnato agli ordini selezionati.</p>
      <!-- <p class="lottoCreation__description">È possibile stampare la lettera di vettura dei seguenti ordini:</p> -->

      <div class="lottoCreation__table">
        <div class="lottoCreation__tools">
          <p class="u-selectAll lottoCreation__selectAll" @click="toggleSelectAll">
            <span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': allOrdersSelected }]"></span><span>Seleziona tutto</span>
          </p>
          <div class="lottoCreation__buttons">
            <BaseOrdersExcelBtn
              :isDisabled="isDisabled"
              :selectedOrdersIds="reselectedOrdersIds"
              :isSmall="true"
              header="L’elenco degli ordini completi"
              name="elenco-ordini-completi.xls"
            ></BaseOrdersExcelBtn>
            <BasePdfButton :isDisabled="isDisabled" extraClass="lottoCreation__button" :ordersIds="reselectedOrdersIds"></BasePdfButton>
            <BaseActionButton :isDisabled="isDisabled" btnIcon="icon-Truck"></BaseActionButton>
          </div>
        </div>

        <TableOrders
          :orders="ordersArr"
          :columns="['col', 'col', 'col']"
          hideShipping
          shortDate
          :allOrdersSelected="allOrdersSelected"
          @toggle-select-all="toggleSelectAll"
          name="ordersList"
        ></TableOrders>
      </div>
    </div>
  </div>
</template>
<script>
import TableOrders from '@c/common/TableOrders';
import BaseActionButton from '@bc/BaseActionButton';
import BasePdfButton from '@bc/BasePdfButton';
import BaseOrdersExcelBtn from '@bc/BaseOrdersExcelBtn';

export default {
  name: 'LottoCreation4',
  components: {
    TableOrders,
    BaseActionButton,
    BasePdfButton,
    BaseOrdersExcelBtn
  },
  props: {
    /**
     * This prop is used to pass an array of the selectedOrders
     * (orders that should be displayed in the table and can be again selected)
     */
    selectedOrders: { type: Array, required: true }
  },
  data() {
    return {
      allOrdersSelected: false,
      reselectedOrders: []
    };
  },
  computed: {
    ordersArr() {
      let arr = [];
      this.selectedOrders.forEach(productOrders => (arr = [...arr, ...productOrders.orders]));
      return arr;
    },
    reselectedOrdersIds() {
      const selections = this.ordersArr.filter(order => order.isChecked);
      const selectionsIds = selections.map(selection => selection.id.split('_')[1]);
      return selectionsIds;
    },
    isDisabled() {
      return this.reselectedOrdersIds.length === 0;
    }
  },

  methods: {
    toggleChecked(orderId) {
      const updatedOrdersData = this.ordersArr;

      // Find selected input
      const selectedInput = updatedOrdersData.find(order => order.id === orderId);

      // Toggle selected input isChecked value
      selectedInput.isChecked = !selectedInput.isChecked;

      this.$set(this.ordersArr, updatedOrdersData);
    },
    toggleSelectAll() {
      const selectedOrders = this.ordersArr;
      selectedOrders.forEach(order => (order.isChecked = !this.allOrdersSelected));
      this.allOrdersSelected = !this.allOrdersSelected;

      this.$set(this.ordersArr, selectedOrders);
    }
  },
  provide() {
    return {
      handleChange: this.toggleChecked
    };
  },
  created() {
    const selectedOrders = this.ordersArr;
    selectedOrders.forEach(order => (order.isChecked = false));
    this.$set(this.ordersArr, selectedOrders);
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.lottoCreation {
  display: flex;

  &__col {
    flex-basis: 30%;

    &:not(:last-child) {
      margin-right: 2.9rem;
    }

    @include respond('tab-port') {
      flex-basis: 100%;
    }
  }

  &__description:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__tools {
    @include flex-parent-space-between;
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    margin-right: 0.5rem;
  }

  &__table {
    margin-top: 6rem;
    position: relative;
  }

  &__selectAll {
    margin-bottom: 0;
  }
}
</style>
