// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags export
export const archive = [
  '24 24',
  `
<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Icon/Archive"  fill="currentColor">
    <g id="clipboard" transform="translate(4.000000, 2.000000)">
      <path
        d="M4,1 C4.55228475,1 5,1.44771525 5,2 C5,2.51283584 4.61395981,2.93550716 4.11662113,2.99327227 L4,3 L2,3 C1.48716416,3 1.06449284,3.38604019 1.00672773,3.88337887 L1,4 L1,18 C1,18.5128358 1.38604019,18.9355072 1.88337887,18.9932723 L2,19 L14,19 C14.5128358,19 14.9355072,18.6139598 14.9932723,18.1166211 L15,18 L15,4 C15,3.48716416 14.6139598,3.06449284 14.1166211,3.00672773 L14,3 L12,3 C11.4477153,3 11,2.55228475 11,2 C11,1.48716416 11.3860402,1.06449284 11.8833789,1.00672773 L12,1 L14,1 C15.5976809,1 16.9036609,2.24891996 16.9949073,3.82372721 L17,4 L17,18 C17,19.5976809 15.75108,20.9036609 14.1762728,20.9949073 L14,21 L2,21 C0.402319117,21 -0.903660879,19.75108 -0.994907307,18.1762728 L-1,18 L-1,4 C-1,2.40231912 0.248919964,1.09633912 1.82372721,1.00509269 L2,1 L4,1 Z"
        id="Path"
      ></path>
      <path
        d="M11,-1 L5,-1 C3.8954305,-1 3,-0.1045695 3,1 L3,3 C3,4.1045695 3.8954305,5 5,5 L11,5 C12.1045695,5 13,4.1045695 13,3 L13,1 C13,-0.1045695 12.1045695,-1 11,-1 Z M5,1 L11,1 L11,3 L5,3 L5,1 Z"
        id="Rectangle"
      ></path>
    </g>
  </g>  
>`
];
