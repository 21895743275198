<template>
  <BaseLoadingSpinner v-if="$apollo.loading"></BaseLoadingSpinner>
  <div v-else class="customerOrders">
    <div v-if="!isTabMode" class="customerOrders__header">
      <BaseHeading :level="2">{{ title }}</BaseHeading>
      <p class="customerOrders__linkBack" @click="$emit('close-fn')"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
    </div>
    <div v-else class="customerOrders__mobileHeader">
      <BaseTopBar :title="title" @go-back-fn="$emit('close-fn')"></BaseTopBar>
      <div class="customerOrders__buttons">
        <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': allSelected }]"></span><span>Seleziona tutto</span></p>

        <DownloadExcel v-if="selectedCustomerOrders.length !== 0" :data="customer_json_selected_orders" :fields="customer_orders_json_fields" :name="excelName" :header="excelHeader">
          <BaseActionButton btnIcon="icon-Group" extraClass="tools__icon">Excel</BaseActionButton></DownloadExcel
        >
        <BaseActionButton v-else btnIcon="icon-Group" isDisabled extraClass="tools__icon">Excel</BaseActionButton>
      </div>
    </div>
    <div class="customerOrders__buttons" v-if="!isTabMode">
      <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': allSelected }]"></span><span>Seleziona tutto</span></p>
      <DownloadExcel v-if="selectedCustomerOrders.length !== 0" :data="customer_json_selected_orders" :fields="customer_orders_json_fields" :name="excelName" :header="excelHeader">
        <BaseActionButton btnIcon="icon-Group" extraClass="tools__icon">Excel</BaseActionButton></DownloadExcel
      >
      <BaseActionButton v-else btnIcon="icon-Group" isDisabled extraClass="tools__icon">Excel</BaseActionButton>
    </div>
    <form @submit.prevent class="customerOrders__form">
      <table class="customerOrders__table" v-if="!isTabMode">
        <col />
        <col />
        <col />
        <col />
        <col />

        <thead>
          <tr class="customerOrders__head">
            <th class="customerOrders__th1"></th>
            <th class="customerOrders__th">Numero d'ordine</th>
            <th class="customerOrders__th">Numero di prodotti</th>
            <th class="customerOrders__th">Metodo di pagamento</th>
            <th class="customerOrders__th">Data</th>
          </tr>
        </thead>

        <tbody>
          <CustomerOrdersItem v-for="order in clientOrdersData" :item="order" :key="order.id" @handle-change="toggleChecked(id)"></CustomerOrdersItem>
        </tbody>
      </table>
      <ul v-else class="customerOrders__mobileList">
        <CustomerOrdersItem v-for="order in clientOrdersData" :item="order" :key="order.id" @handle-change="toggleChecked(id)"></CustomerOrdersItem>
      </ul>
    </form>
  </div>
</template>
<script>
/**
 * This component loads the selected customer orders details
 *
 * @displayName CustomerOrders
 */
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseActionButton from '@bc/BaseActionButton';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import CustomerOrdersItem from '@c/admin/CustomerOrdersItem';
import BaseTopBar from '@bc/BaseTopBar';
import { getCustomerOrders } from '@gq/getCustomerOrders.gql';
import { styleDate } from '@u/helperFunctions';
import JsonExcel from 'vue-json-excel';

Vue.component('DownloadExcel', JsonExcel);

export default {
  name: 'CustomerOrders',
  components: {
    BaseHeading,
    BaseIcon,
    BaseActionButton,
    CustomerOrdersItem,
    BaseTopBar,
    BaseLoadingSpinner
  },
  props: {
    /**
     * This prop is used to pass the id of the seelcted client
     */
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allSelected: false,
      customer: [],
      clientOrdersData: [],
      selectedCustomerOrders: [],
      title: '',
      // EXCELS
      customer_orders_json_fields: {
        'Numero ordine': 'orderNr',
        'ID ordine': 'orderId',
        'Numero di prodotti': 'nrOfProducts',
        'Metodo di pagamento': 'paymentMethod',
        'Data ordine': 'date'
      }
    };
  },
  computed: {
    ...mapGetters(['isTabMode']),
    // EXCELS DATA

    customer_json_selected_orders() {
      let json = [];
      const selections = this.getSelectedOrders();

      if (selections.length !== 0) {
        json = selections.map(order => {
          return {
            orderNr: order.name,
            orderId: order.id,
            nrOfProducts: order.nrOfProducts,
            paymentMethod: order.paymentMethod,
            date: styleDate(order.date)
          };
        });
      }
      return json;
    },
    excelName() {
      return `ordini_selezionati_${this.customer.firstName}_${this.customer.lastName}.xls`;
    },
    excelHeader() {
      return `Ordini selezionati per ${this.customer.firstName} ${this.customer.lastName}`;
    }
  },
  apollo: {
    // Call when component is rendered

    customer() {
      return {
        query: getCustomerOrders,
        variables: {
          id: this.clientId
        }
      };
    }
  },
  methods: {
    toggleChecked(orderId) {
      const updatedOrdersData = this.clientOrdersData;

      // Find selected order
      let selectedOrder = updatedOrdersData.find(order => order.id === orderId);

      // Toggle selected order isChecked
      selectedOrder.isChecked = !selectedOrder.isChecked;

      this.clientOrdersData = updatedOrdersData;
      this.getSelectedOrders();
    },

    toggleSelectAll() {
      const selectedOrders = this.clientOrdersData;
      selectedOrders.forEach(order => (order.isChecked = !this.allSelected));
      this.allSelected = !this.allSelected;

      this.clientOrdersData = selectedOrders;
      this.getSelectedOrders();
    },
    getSelectedOrders() {
      const selections = this.clientOrdersData.filter(order => order.isChecked);

      this.selectedCustomerOrders = selections;

      return selections;
    }
  },
  watch: {
    customer(val) {
      if (val && val.length !== 0) {
        const orders = val.orders.edges.map(order => {
          // Remove invalid line items
          const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.currentQuantity > 0 && lineItem.product && lineItem.product.id);

          return {
            id: order.node.id,
            name: order.node.name,
            nrOfProducts: validLineItems.length,
            paymentMethod: order.node.paymentGatewayNames.join(', '),
            date: order.node.createdAt,
            isChecked: order.isChecked || false
          };
        });

        this.clientOrdersData = orders.filter(order => order.nrOfProducts > 0);

        this.title = `${this.customer.firstName} ${this.customer.lastName}`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

$cell-padding: 1rem 1rem 1.7rem 1rem;

// cell padding if clip-path is not supported

$cell-padding-no-clip: 1rem 1rem 1rem 1rem;

.customerOrders {
  @include respond('tab-port') {
    background-color: $color-white;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 24rem 3.125rem 3.125rem 3.125rem;
    overflow-y: auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1.45rem;
    border-bottom: 2px solid $color-grey-light;
  }

  &__mobileHeader {
    position: fixed;
    top: 0;
    padding: 9rem 3.125rem 0 3.125rem;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 200;
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
    @include small-font-size;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }

  &__buttons {
    @include flex-parent-space-between;
    padding: 3rem 1.375rem;
  }

  &__form {
    max-height: 60vh;
    overflow-y: auto;

    @include respond('tab-port') {
      height: 100%;
      overflow-y: visible;
      max-height: unset;
    }
  }
  &__table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
  }

  &__th {
    color: $color-blue-light;
    background-color: $color-white;
    position: sticky;
    top: 0;
    font-weight: 400;
    padding: 1.3rem 1.5rem;
    vertical-align: middle;
    border-bottom: 1rem solid $color-white;
    @include small-font-size;
    z-index: 10;
  }

  &__th:not(:last-child) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #b4bbd0;
      z-index: 300;
    }
  }

  &__th1 {
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1rem solid $color-white;
  }

  &__mobileList {
    padding-bottom: 2rem;
  }
}
</style>
