export const logout = [
  '24 24',
  `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Icon/Logout">
    
    <g id="log-out" transform="translate(3.000000, 3.000000)"  fill="currentColor">
        <path d="M6,-1 C6.55228475,-1 7,-0.55228475 7,0 C7,0.512835839 6.61395981,0.935507161 6.11662113,0.993272269 L6,1 L2,1 C1.48716416,1 1.06449284,1.38604019 1.00672773,1.88337887 L1,2 L1,16 C1,16.5128358 1.38604019,16.9355072 1.88337887,16.9932723 L2,17 L6,17 C6.55228475,17 7,17.4477153 7,18 C7,18.5128358 6.61395981,18.9355072 6.11662113,18.9932723 L6,19 L2,19 C0.402319117,19 -0.903660879,17.75108 -0.994907307,16.1762728 L-1,16 L-1,2 C-1,0.402319117 0.248919964,-0.903660879 1.82372721,-0.994907307 L2,-1 L6,-1 Z" id="Path"></path>
        <path d="M12.2928932,3.29289322 C12.6533772,2.93240926 13.2206082,2.90467972 13.6128994,3.20970461 L13.7071068,3.29289322 L18.7071068,8.29289322 C19.0675907,8.65337718 19.0953203,9.22060824 18.7902954,9.61289944 L18.7071068,9.70710678 L13.7071068,14.7071068 C13.3165825,15.0976311 12.6834175,15.0976311 12.2928932,14.7071068 C11.9324093,14.3466228 11.9046797,13.7793918 12.2097046,13.3871006 L12.2928932,13.2928932 L16.585,9 L12.2928932,4.70710678 C11.9324093,4.34662282 11.9046797,3.77939176 12.2097046,3.38710056 L12.2928932,3.29289322 Z" id="Path"></path>
        <path d="M18,8 C18.5522847,8 19,8.44771525 19,9 C19,9.51283584 18.6139598,9.93550716 18.1166211,9.99327227 L18,10 L6,10 C5.44771525,10 5,9.55228475 5,9 C5,8.48716416 5.38604019,8.06449284 5.88337887,8.00672773 L6,8 L18,8 Z" id="Path"></path>
    </g>
</g>
</g>`
];
