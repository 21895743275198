<template>
  <div class="lotto">
    <BaseCard hideMobile extraClass="lotto__card">
      <p v-if="!isTabMode" class="u-linkBack lotto__linkBack" @click="handleClose"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
      <BaseProgressBar extraClass="lotto__progressBar" :steps="steps" :active="activeStep" @select-step="handleStepSelection"></BaseProgressBar>

      <div>
        <div v-if="activeStep === 0 || activeStep === 1" class="lotto__content lotto__content--step1">
          <LottoCreation1
            :vendorProducts="vendorProductsData"
            :registeredProducts="registeredProducts"
            :activeStep="activeStep"
            :selectedVendor="selectedVendor"
            :selectedProduct="selectedProduct"
            :selectedProductData="selectedProductData"
            :hubVendors="hubVendors"
          ></LottoCreation1>
        </div>
        <div v-if="activeStep === 2" class="lotto__content lotto__content--step3">
          <LottoCreationTable
            v-for="(product, index) in registeredProductsChunks[activeChunkNumber]"
            :key="index"
            :product="product"
            :selectedVendor="selectedVendor"
            :startingDate="startingDate"
          ></LottoCreationTable>
        </div>
        <div v-if="activeStep === 3" class="lotto__content lotto__content--step4">
          <LottoCreation4 :selectedOrders="registeredOrders"></LottoCreation4>
        </div>
        <div class="lotto__paginationButtons">
          <p v-if="activeStep === 0 && isTabMode" class="lotto__back" @click="$router.push('/')"><BaseIcon icon="icon-chevron-left"></BaseIcon>Indietro</p>
          <BasePagination
            extraClass="lotto__pagination"
            @go-back-fn="handlePreviousPage"
            @go-next-fn="handleNextPage"
            :pages="4"
            :currentPage="activeStep + 1"
            :isLoading="isNextBtnLoading"
            :enableNextPage="steps[activeStep].completed"
            :disablePreviousPage="disablePreviousPage"
          ></BasePagination>
          <p v-if="activeStep === 3 && isTabMode" @click="$router.push('/')" class="lotto__end">Fine</p>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script>
import BaseCard from '@bc/BaseCard';
import BaseProgressBar from '@bc/BaseProgressBar';
import BasePagination from '@bc/BasePagination';
import BaseIcon from '@bc/BaseIcon';
import LottoCreation1 from '@c/vendor/LottoCreation1';
import LottoCreationTable from '@c/vendor/LottoCreationTable';
import LottoCreation4 from '@c/vendor/LottoCreation4';
import { getLottoProducts } from '@gq/getLottoProducts.gql';
import { createNewBatch } from '@gm/createNewBatch.gql';
import { associateBatchOrders } from '@gm/associateBatchOrders.gql';
import { mapActions, mapGetters } from 'vuex';
import { createChunks, getXMonthsAgoDate, getNumericDate } from '@u/helperFunctions.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

const quantityField = 'quantita';

export default {
  name: 'LottoVendor',
  components: {
    BaseCard,
    BaseProgressBar,
    BasePagination,
    BaseIcon,
    LottoCreation1,
    LottoCreationTable,
    LottoCreation4
  },
  mixins: [sleepMixin],
  data() {
    return {
      fullyLoaded: true,
      activeChunkNumber: 0,
      selectedProduct: '',
      selectedProductData: {
        prodotto: '',
        id: '',
        name: '',
        quantita: 0,
        dataRaccolta: '',
        dataDDT: '',
        numeroDDT: '',
        provincia: '',
        foglio: '',
        comune: '',
        particella: '',
        ddt: null,
        completed: false,
        current: 0
      },
      products: [],
      vendorProductsData: [],
      selectedOrders: [],
      startingDate: '',
      requiredFields: ['prodotto', quantityField, 'ddt', 'numeroDDT', 'dataDDT', 'comune', 'dataRaccolta'],
      isNextBtnLoading: false
    };
  },
  apollo: {
    products() {
      return {
        query: getLottoProducts,
        skip: true,
        variables: {
          query: `vendor:${this.selectedVendor}`
        }
      };
    }
  },
  computed: {
    ...mapGetters([
      'isTabMode',
      'selectedVendor',
      'registeredProducts',
      'activeStep',
      'steps',
      'productionBatches',
      'registeredOrders',
      'disableBatchEditing',
      'batchAssociatedOrders',
      'currentVendors'
    ]),
    hubVendors() {
      return this.activeStep === 0 ? this.$store.getters.currentVendors : [this.selectedVendor];
    },
    registeredProductsChunks() {
      return createChunks(this.registeredProducts, 3);
    },
    disablePreviousPage() {
      return this.disableBatchEditing && this.activeStep === 2 && this.activeChunkNumber === 0;
    }
  },
  methods: {
    ...mapActions([
      'set',
      'addRegisteredProduct',
      'completeStep',
      'removeStepCompleteness',
      'completeStepAndGoNext',
      'resetLottoCreationData',
      'addAlert',
      'updateProductionBatchProperty',
      'updateRegisteredProductProperty',
      'handleDisableBatchEditing'
    ]),
    checkIfRequiredFieldsCompleted(productData) {
      let isCompleted = true;

      this.requiredFields.forEach(field => {
        if (field === quantityField) {
          isCompleted = productData[field] && productData[field] > 0 && isCompleted;
        } else {
          isCompleted = productData[field] && productData[field].length !== 0 && isCompleted;
        }
      });
      return isCompleted;
    },

    /**
     * Handling the click on 'close' button.
     * Two available cases:
     * 1. the user has landed in the lotto vendor from 'gestione lotti'. If so, return to it.
     * 2. the user has landed in the lotto vendor from the creation of a new one. If so, return to home.
     *
     * The router.go(-1) will cover both cases
     */
    handleClose() {
      this.$router.go(-1);
    },

    handleStepSelection(value) {
      if (this.disableBatchEditing && (value === 1 || value === 0)) {
        this.addAlert({ msg: 'Il lotto è stato creato. Non sarà possibile modificarlo' });
        return;
      }
      this.set(['activeStep', value]);
    },
    handlePreviousPage() {
      if (this.activeStep === 2) {
        // Check if there is previous chunk of products
        if (this.registeredProductsChunks.length > 1 && this.activeChunkNumber > 0) {
          return this.activeChunkNumber--;
        }

        if (this.disableBatchEditing) {
          this.addAlert({ msg: 'Il lotto è stato creato. Non sarà possibile modificarlo' });
          return;
        }
      }

      this.set(['activeStep', this.activeStep - 1]);
    },
    async createBatch(productionBatch) {
      const { collection_date, products, ddt_number, ddt_date, location_name, province, town, sheet, compartment } = productionBatch;

      try {
        const result = await this.$apollo.mutate({
          // Query
          mutation: createNewBatch,
          // Parameters
          variables: {
            collection_date,
            products,
            ddt_number,
            ddt_date,
            location_name,
            province,
            town,
            sheet,
            compartment,
            vendor: this.selectedVendor
          }
        });

        // Update batch info
        this.updateProductionBatchProperty([ddt_number, 'id', result.data.newBatch.id]);
        return result.data.newBatch.id;
      } catch (err) {
        if (err.message) this.addAlert({ msg: err.message, type: 'error' });
      }
    },
    async createBatches() {
      let batchesIds = [];
      // If batches had been created, skip this step
      if (this.disableBatchEditing) return;

      for (const productionBatch of this.productionBatches) {
        const id = await this.createBatch(productionBatch);
        batchesIds = [...batchesIds, id];
      }

      const successMsg = this.productionBatches.length > 1 ? 'Lotti di produzione creati con successo. ' : 'Lotto di produzione creato con successo.';
      this.addAlert({ msg: successMsg, type: 'success' });

      return batchesIds;
    },

    async associateOrders() {
      try {
        let batchOrdersDetails = [];
        this.registeredOrders.forEach(registeredProductWithOrders =>
          registeredProductWithOrders.orders.forEach(registeredOrder => {
            const { totalProductWeight, productVariant, productId, productTitle, orderNumber, orderId, id } = registeredOrder;

            // Find batchId based on product id

            const foundBatch = this.productionBatches.find(batch => batch.products.find(batchProduct => batchProduct.id === productId));

            if (!foundBatch || !foundBatch.id) {
              return;
            }
            // If order has not yet been associated with the batch, add it to the batchOrdersDetails array
            if (!this.batchAssociatedOrders.includes(id)) {
              batchOrdersDetails = [
                ...batchOrdersDetails,
                {
                  orderNumber: orderNumber,
                  orderId: orderId,
                  batchId: foundBatch.id,
                  productName: productTitle,
                  productId: productId,
                  productVariant,
                  // Convert kg to grams
                  weight: totalProductWeight * 1000,
                  quantity: 1
                }
              ];
            }
          })
        );

        if (batchOrdersDetails.length === 0) return 'no-orders-ok';

        const response = await this.$apollo.mutate({
          // Query
          mutation: associateBatchOrders,
          // Parameters
          variables: { associations: batchOrdersDetails, vendor: this.selectedVendor }
        });

        return response.data.newAssociation.message;
      } catch (err) {
        this.addAlert({ msg: err.message, type: 'error' });
        this.isNextBtnLoading = false;
      }
    },
    async createBatchAndAssociateOrders() {
      try {
        await this.createBatches();
        // Disable first and second step
        this.handleDisableBatchEditing(true);

        const response = await this.associateOrders();
        if (response === 'ok') {
          const successMsg =
            this.productionBatches.length > 1 ? 'Gli ordini sono stati associati con successo ai lotti di produzione.' : 'Gli ordini sono stati associati con successo al lotto di produzione.';

          this.addAlert({ msg: successMsg, type: 'success' });

          // Add orders to batchAssociatedOrders and remove them from the orders list
          let batchAssociatedOrdersIds = [];
          this.registeredOrders.forEach(productOrders => productOrders.orders.forEach(order => (batchAssociatedOrdersIds = [...batchAssociatedOrdersIds, order.id])));
          this.set(['batchAssociatedOrders', batchAssociatedOrdersIds]);

          // Add current to registered products
          // Find product registered orders
          this.registeredOrders.forEach(registeredOrders => {
            // Calculate product total
            let totalWeightAssigned = 0;

            registeredOrders.orders.forEach(order => (totalWeightAssigned += order.totalProductWeight));

            this.updateRegisteredProductProperty([registeredOrders.productId, 'current', totalWeightAssigned]);
          });

          // Unblock everything
          this.isNextBtnLoading = false;
          this.set(['activeStep', this.activeStep + 1]);
        } else if (response === 'no-orders-ok') {
          this.addAlert({ msg: 'Nessun ordine è stato associato. ' });
          this.isNextBtnLoading = false;
          this.set(['activeStep', this.activeStep + 1]);
        } else {
          const errorMsg = JSON.parse(response).error;

          if (errorMsg) this.addAlert({ msg: errorMsg, type: 'error' });
          this.isNextBtnLoading = false;
        }
      } catch (err) {
        this.isNextBtnLoading = false;
      }
    },
    handleNextPage() {
      if (this.steps[this.activeStep].completed) {
        if (this.activeStep === 1 && this.selectedProductData.prodotto) {
          // If all the fields of the current product has been completed, add it to registered products
          // Else, disregard this product and go to the next page
          if (this.checkIfRequiredFieldsCompleted(this.selectedProductData)) {
            this.selectedProductData.id = this.selectedProductData.prodotto;
            this.selectedProductData.name = this.vendorProductsData.find(product => product.id === this.selectedProductData.prodotto).name;
            this.selectedProductData.completed = true;
            this.addRegisteredProduct([this.selectedVendor, this.selectedProductData]);
          }
          this.resetData('full');
        } else if (this.activeStep === 2) {
          // Check if there is more products to load
          if (this.registeredProductsChunks.length > 1 && this.activeChunkNumber < this.registeredProductsChunks.length - 1) {
            return this.activeChunkNumber++;
          }

          // Else create lotto and go to the final page
          this.isNextBtnLoading = true;

          this.createBatchAndAssociateOrders();
          return;
        }

        this.set(['activeStep', this.activeStep + 1]);
      }
    },

    handleProductDataChange(productId, property, value) {
      let existingRegisteredProduct = this.registeredProducts.find(product => product.id === productId);

      // Check if the product is registered
      if (existingRegisteredProduct) {
        existingRegisteredProduct[property] = value;
        // If there are empty required fields on registered product don't let the user go to the next step
        if (!this.checkIfRequiredFieldsCompleted(existingRegisteredProduct)) {
          this.removeStepCompleteness(2);
        } else {
          this.completeStep(2);
        }
      } else {
        this.selectedProductData[property] = value;

        if (this.checkIfRequiredFieldsCompleted(this.selectedProductData)) {
          this.completeStep(2);
        }
      }
    },

    handleSelectProduct(value) {
      this.selectedProduct = value;
    },

    selectVendor(value) {
      if (value && this.activeStep === 0) {
        this.set(['selectedVendor', value]);
        this.fullyLoaded = false;
        this.$apollo.queries.products.start();
        this.$apollo.queries.products.refetch({
          query: `vendor:${value}`
        });
      }
    },

    resetData(type) {
      this.selectedProductData.quantita = 0;
      this.selectedProductData.name = '';
      this.selectedProductData.id = '';
      this.selectedProductData.provincia = '';
      this.selectedProductData.foglio = '';
      this.selectedProductData.comune = '';
      this.selectedProductData.particella = '';
      this.selectedProductData.completed = false;
      this.selectedProductData.current = 0;
      this.selectedProductData.dataRaccolta = null;
      this.selectedProduct = '';
      if (type === 'full') {
        this.selectedProductData.ddt = null;
        this.selectedProductData.dataDDT = null;
        this.selectedProductData.numeroDDT = '';
      }
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.fetchMore({
        variables: {
          query: `vendor:'${this.selectedVendor}'`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    }
  },
  watch: {
    selectedVendor() {
      this.resetData();
      this.selectedProduct = '';
    },

    products(val, oldVal) {
      if (val && val.edges) {
        this.fullyLoaded = false;

        // Check if there is the second page

        if (val.pageInfo.hasNextPage) {
          this.fetchMoreProducts(oldVal);
        } else {
          this.fullyLoaded = true;

          if (val.edges.length === 0) {
            this.fullyLoaded = true;
            return;
          }

          // Exclude cassette
          // get the info and save the product
          const calculatedProducts = val.edges
            .filter(productOrBox => !productOrBox.node.tags.includes('cassetta'))
            .map(product => {
              return {
                name: product.node.title,
                id: product.node.id,
                completed: false,
                quantita: 0,
                dataRaccolta: '',
                dataDDT: '',
                numeroDDT: '',
                provincia: '',
                foglio: '',
                comune: '',
                documentoTrasporto: '',
                particella: ''
              };
            });

          this.vendorProductsData = calculatedProducts;
        }
      }
    },

    fullyLoaded(val) {
      if (val && this.selectedVendor) {
        !this.isTabMode ? this.completeStepAndGoNext(1) : this.completeStep(1);
      }
    },

    selectedProductData: {
      deep: true,

      handler(val) {
        if (this.checkIfRequiredFieldsCompleted(val)) {
          this.completeStep(2);
        }
      }
    }
  },
  provide() {
    return {
      selectedProduct: this.selectedProduct,
      selectedProductData: this.selectedProductData,
      resetData: this.resetData,
      handleSelectProduct: this.handleSelectProduct,
      selectVendor: this.selectVendor,
      handleProductDataChange: this.handleProductDataChange,
      removeStepCompleteness: this.removeStepCompleteness,
      checkIfRequiredFieldsCompleted: this.checkIfRequiredFieldsCompleted
    };
  },
  created() {
    this.$apollo.queries.products.skip = true;

    // Set selectedTimePeriod to the last 2 months period
    const date2MonthsAgo = getXMonthsAgoDate(2);
    this.startingDate = getNumericDate(date2MonthsAgo);
  },

  updated() {
    document.body.style.overflow = 'auto';
  },

  beforeDestroy() {
    // Reset data
    this.resetData('full');
    this.resetLottoCreationData();

    this.isNextBtnLoading = false;
    this.handleDisableBatchEditing(false);

    document.body.style.overflow = 'hidden';
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.lotto {
  padding: 4.5rem;

  @include respond('tab-port') {
    padding: 10rem 3rem 3rem 3rem;
  }

  &__card {
    min-height: calc(100vh - 9rem);
    margin-bottom: 0;
    position: relative;
    padding-bottom: 14.4rem;

    @include respond('tab-port') {
      padding: 0;
      min-height: calc(100vh - 13rem);
      padding-bottom: calculateMobRem(91px);
    }
  }

  &__progressBar {
    padding-right: 14rem;

    @include respond('tab-port') {
      padding-right: 0;
      margin-bottom: calculateMobRem(30px);
    }
  }

  &__linkBack {
    position: absolute;
    top: 4.5rem;
    right: 3.5rem;
  }

  &__content {
    padding-top: 5.7rem;
    padding-bottom: 3rem;

    &--step3 {
      padding-top: 4.5rem;
      display: flex;

      @include respond('tab-port') {
        flex-direction: column;
      }
    }

    &--step4 {
      padding-top: 4.5rem;
    }

    @include respond('tab-port') {
      padding-top: 0;
    }
  }

  &__paginationButtons {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 3rem;

    @include respond('tab-port') {
      // margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 0 0 0;
      // width: 100%;
    }
  }

  &__back {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @include small-font-size;
    @include bold-text;
    transition: all 0.5s;

    &:hover {
      color: map-get($theme-colors, 'primary-900');
    }
  }

  &__end {
    background-color: $color-primary;
    color: $color-white;
    padding: 1.4rem 1.7rem;
    border-radius: 4px;
    margin-bottom: 0;
    margin-left: auto;
    @include small-font-size;
    @include bold-text;
    align-self: flex-end;

    transition: all 0.5s;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: map-get($theme-colors, 'primary-900');
    }
  }
}
</style>
