import { getFullProductId } from '@u/helperFunctions.js';

export const searchOrdersMixin = {
  methods: {
    searchOrders(value, option) {
      if (!value) {
        this.filteredOrdersData = this.ordersData;
        return;
      }
      let filteredResults = this.ordersData;
      const check = (order, value) => {
        const { tags } = order;
        return tags.some(tag => {
          const val = getFullProductId(value);
          return tag.indexOf(val) !== -1;
        });
      };
      switch (option) {
        case 'Ordine':
          // Adding hashtag if not added by the user
          if (`${value}`.charAt(0) !== '#') value = `#${value}`;
          filteredResults = filteredResults.filter(order => order.name.startsWith(value));
          break;
        case 'ID del prodotto':
          filteredResults = filteredResults.filter(order => check(order, value));
          break;
        case 'Lotto':
          filteredResults = filteredResults.filter(order => order.orderBatchesNumbers.includes(value));
          break;

        default:
          filteredResults = [];
      }

      this.filteredOrdersData = filteredResults;
    }
  }
};
