<template>
  <div class="vendorsCharts">
    <BaseLoadingSpinner v-if="$apollo.loading || !fullyLoaded" isTopMobile></BaseLoadingSpinner>
    <div v-else>
      <div class="vendorsCharts__heading">
        <BaseHeading :level="4">Totale fatturato per produttore</BaseHeading>
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_earnings_per_vendor" :header="time_range_excel_header" :fields="earnings_json_fields" name="totale-fatturato-per-produttore.xls">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>

      <BaseBarChart v-if="!isTabMode" extraStyle="height: 30rem" :labels="vendorsEarningsLabels" :options="options" :datasets="fatturato"></BaseBarChart>
      <BaseHorizontalBarChart v-else :labels="vendorsEarningsLabels" :options="options" :datasets="fatturato"></BaseHorizontalBarChart>
      <div class="vendorsCharts__heading">
        <BaseHeading :level="4">Totale Kg venduti per produttore</BaseHeading>
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_kg_sold_per_vendor" :header="time_range_excel_header" :fields="kg_json_fields" name="kg-venduti-per-produttore.xls">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>
      <BaseBarChart v-if="!isTabMode" extraStyle="height: 30rem" :labels="vendorsWeightLabels" :options="options" :datasets="kgVenduti"></BaseBarChart>
      <BaseHorizontalBarChart v-else :labels="vendorsWeightLabels" :options="options" :datasets="kgVenduti"></BaseHorizontalBarChart>
    </div>
  </div>
</template>
<script>
import BaseHeading from '@bc/BaseHeading';
import BaseActionButton from '@bc/BaseActionButton';
import BaseBarChart from '@bc/BaseBarChart';
import BaseHorizontalBarChart from '@bc/BaseHorizontalBarChart';
import { getNumericDate, styleDate, getNumericDateOfTheNextDate, sortItemsFromZtoA, convertWeightToKg } from '@u/helperFunctions';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { fetchMoreOrdersMixin } from '@c/mixins/graphqlMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';
import { getVendorProductsOrdersWithWeights } from '@gq/getVendorProductsOrdersWithWeights.gql';

export default {
  name: 'VendorsCharts',
  props: {
    timeRange: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  mixins: [sleepMixin, fetchMoreOrdersMixin],
  components: {
    BaseHeading,
    BaseActionButton,
    BaseBarChart,
    BaseHorizontalBarChart,
    BaseLoadingSpinner
  },
  data() {
    return {
      fatturato: [
        {
          label: '€',
          data: []
        }
      ],
      kgVenduti: [
        {
          label: 'Kg',
          data: []
        }
      ],
      shop: [],
      fullyLoaded: false,
      orders: [],
      vendorsEarningsLabels: [],
      vendorsWeightLabels: [],
      sortedVendorsKgArrOfObjects: [],
      sortedVendorsEarningsArrOfObjects: [],

      // EXCELS
      earnings_json_fields: {
        Produttore: 'vendor',
        'Totale fatturato (€)': 'earnings'
      },
      kg_json_fields: {
        Produttore: 'vendor',
        'Kg venduti': 'kgSold'
      }
    };
  },
  computed: {
    btnsDisabled() {
      return !this.orders || this.orders.length === 0;
    },
    vendors() {
      return this.$store.getters.shopVendors;
    },

    isTabMode() {
      return this.$store.getters.isTabMode;
    },

    options() {
      return {
        maintainAspectRatio: this.isTabMode ? true : false
      };
    },

    numericTimeRange() {
      const startDate = getNumericDate(this.timeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.timeRange[1]);
      return [startDate, endDate];
    },

    queryString() {
      return `created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]}`;
    },

    // EXCELS DATA
    json_earnings_per_vendor() {
      let json = [];

      if (this.sortedVendorsEarningsArrOfObjects.length !== 0 && this.fatturato[0].data.length !== 0) {
        json = this.sortedVendorsEarningsArrOfObjects.map(vendor => {
          return {
            vendor: vendor.vendor,
            earnings: vendor.earnings
          };
        });
      }

      return json;
    },

    time_range_excel_header() {
      return this.timeRange.length !== 0 ? '' : 'Periodo di tempo: ' + styleDate(this.timeRange[0]) + ' - ' + styleDate(this.timeRange[1]);
    },

    json_kg_sold_per_vendor() {
      let json = [];

      if (this.sortedVendorsKgArrOfObjects.length !== 0 && this.kgVenduti[0].data.length !== 0) {
        json = this.sortedVendorsKgArrOfObjects.map(vendor => {
          return {
            vendor: vendor.vendor,
            kgSold: vendor.kg
          };
        });
      }

      return json;
    }
  },
  methods: {
    clearChartsData() {
      this.fatturato = [
        {
          label: '€',
          data: []
        }
      ];
      this.kgVenduti = [
        {
          label: 'Kg',
          data: []
        }
      ];

      this.orders = {};
    }
  },
  apollo: {
    orders() {
      return {
        query: getVendorProductsOrdersWithWeights,
        variables: {
          queryString: this.queryString
        },
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.orders = data.orders;
          }
        },
        error() {
          this.fullyLoaded = true;
          this.orders = {};
        }
      };
    }
  },
  watch: {
    // Call apollo when timeRange changes
    timeRange(val) {
      if (val) {
        if (val[0] !== null) {
          this.fullyLoaded = false;
          this.$apollo.queries.orders.start();
          this.$apollo.queries.orders.refetch({
            queryString: this.queryString
          });
        } else {
          this.clearChartsData();
        }
      }
    },

    orders(val) {
      if (val && val.edges) {
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          if (this.timeRange[0] === null || val.edges.length === 0) {
            this.clearChartsData();
            this.fullyLoaded = true;
            this.$emit('set-charts-data-loaded');
            return;
          }

          const vendorOrdersArr = this.vendors.map(vendor => {
            return val.edges.filter(order => {
              const orderVendors = order.node.lineItems.edges.map(item => {
                if (item.node.currentQuantity > 0) return item.node.vendor;
              });
              return orderVendors.includes(vendor);
            });
          });

          // 2. Create an array with the total earnings of each vendor in the selected time period
          const earningsArr = vendorOrdersArr.map((ordersArr, index) => {
            if (ordersArr.length === 0) {
              return 0;
            } else {
              let totalEarnings = 0;
              ordersArr.forEach(order => {
                // 3. Filter lineItems by product id and get the sum (= total product earnings)

                order.node.lineItems.edges
                  .filter(item => item.node.vendor && item.node.vendor === this.vendors[index] && item.node.currentQuantity > 0)
                  .forEach(filteredLineItem => (totalEarnings += Number(filteredLineItem.node.originalTotalSet.shopMoney.amount) - Number(filteredLineItem.node.totalDiscountSet.shopMoney.amount)));
              });

              return totalEarnings;
            }
          });

          // 4. Create an array with the total weight sold of each product in the selected time period
          const weightArr = vendorOrdersArr.map((ordersArr, index) => {
            if (ordersArr.length === 0) {
              return 0;
            } else {
              let totalWeight = 0;
              ordersArr.forEach(order => {
                // 5. Filter lineItems by product id and get the sum (= total product earnings)
                order.node.lineItems.edges
                  .filter(item => item.node.vendor && item.node.vendor === this.vendors[index] && item.node.currentQuantity > 0)
                  .forEach(filteredLineItem => {
                    let itemWeight = 0;
                    const itemVariant = filteredLineItem.node.variant;
                    if (itemVariant) {
                      itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);
                    }

                    totalWeight += Number(itemWeight);
                  });
              });

              return totalWeight;
            }
          });

          // Sort the results from the largest to the smallest

          const vendorsArrOfObjects = this.vendors.map((vendor, index) => {
            return {
              vendor,
              kg: weightArr[index],
              earnings: earningsArr[index]
            };
          });

          // Sort weight charts data
          const sortedVendorsKgArrOfObjects = sortItemsFromZtoA({ arr: [...vendorsArrOfObjects], key: 'kg' });
          this.sortedVendorsKgArrOfObjects = sortedVendorsKgArrOfObjects;

          // Remove vendors with no sales
          const sortedVendorsWithSales = sortedVendorsKgArrOfObjects.filter(vendor => vendor.kg > 0);

          this.vendorsWeightLabels = sortedVendorsWithSales.map(vendor => vendor.vendor);
          const sortedWeightArr = sortedVendorsWithSales.map(vendor => vendor.kg);

          // Sort earnings charts data
          const sortedVendorsEarningsArrOfObjects = sortItemsFromZtoA({ arr: [...vendorsArrOfObjects], key: 'earnings' });
          this.sortedVendorsEarningsArrOfObjects = sortedVendorsEarningsArrOfObjects;

          // Remove vendors with no earnings
          const sortedVendorsWithEarnings = sortedVendorsEarningsArrOfObjects.filter(vendor => vendor.earnings > 0);

          this.vendorsEarningsLabels = sortedVendorsWithEarnings.map(vendor => vendor.vendor);
          const sortedEarningsArr = sortedVendorsWithEarnings.map(vendor => vendor.earnings);

          this.fatturato = [
            {
              label: '€',
              data: sortedEarningsArr
            }
          ];
          this.kgVenduti = [
            {
              label: 'Kg',
              data: sortedWeightArr
            }
          ];

          this.$emit('set-charts-data-loaded');
          this.fullyLoaded = true;
        }
      }
    }
  },

  beforeDestroy() {
    this.fullyLoaded = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
.vendorsCharts {
  min-height: 70vh;

  &__heading {
    margin: 4rem 0;
    @include flex-parent-space-between;
  }
}
</style>
