<template>
  <div class="archive">
    <div :class="['archive__headerMobile', { 'u-hidden': orderDetailsShown && isTabMode }]">
      <BaseHeading :level="2">Tutti gli ordini</BaseHeading>
      <BaseOrdersTools
        extraClass="archive__tools"
        @search-data="searchOrders"
        :searchOptions="searchOptions"
        isFullWidth
        showSelectAll
        isArchive
        :isDisabled="btnsDisabled"
        @toggle-select-all="toggleSelectAll"
        :allOrdersSelected="allOrdersSelected"
        :selectedOrders="selectedOrders"
        @handle-archive-status="handleArchiveStatus"
      ></BaseOrdersTools>
    </div>

    <div v-if="!orderDetailsShown" id="orders-div" :class="[{ 'page-scroll': !isTabMode }, 'archive__col1']">
      <BaseCard fullCard hideMobile>
        <form @submit.prevent>
          <div class="archive__header">
            <BaseHeading :level="2">Tutti gli ordini</BaseHeading>
            <BaseOrdersTools
              extraClass="archive__tools"
              :isFullWidth="true"
              :isDisabled="btnsDisabled"
              :selectedOrders="selectedOrders"
              :searchOptions="searchOptions"
              @search-data="searchOrders"
              isArchive
              @handle-archive-status="handleArchiveStatus"
            ></BaseOrdersTools>
          </div>
          <Filters
            :selectedValueRange="selectedValueRange"
            :selectedOrderStatuses="selectedOrderStatuses"
            :selectedPaymentMethods="selectedPaymentMethods"
            :selectedShippingTypes="selectedShippingTypes"
            :selectedFiltersView="selectedFiltersView"
            :selectedTimeRange="selectedTimeRange"
            :orderStatusesOptions="orderStatusesOptions"
            :paymentMethodsOptions="paymentMethodsOptions"
            :shippingTypesOptions="shippingTypesOptions"
            @handle-filters-selection="handleFiltersSelection"
          ></Filters>

          <div class="archive__body">
            <div class="archive__itemsBox">
              <ul class="archive__items" v-if="!isTabMode || (isTabMode && isVendorView && selectedVendor === '') || (isTabMode && isProductView && selectedProduct === '')">
                <BaseHeading :level="2" extraClass="archive__itemsH2">{{ itemTitle }}</BaseHeading>
                <BaseLoadingSpinner v-if="$apollo.queries.shop.loading || $apollo.queries.products.loading || (isProductView && !productsReady)"></BaseLoadingSpinner>
                <li
                  v-else-if="isVendorView"
                  v-for="vendor in vendors"
                  :key="vendor.id"
                  :class="['archive__item', { 'archive__item--active': selectedVendor === vendor.id }, { 'archive__item--disabled': !itemSwitchingEnabled }]"
                  @click="handleVendorSelection(vendor.id)"
                >
                  <span class="archive__itemName">{{ vendor.name }}</span>
                  <BaseIcon icon="icon-arrow-right" extraClass="archive__itemArrow"></BaseIcon>
                </li>
                <li
                  v-else
                  v-for="product in sortedArchiveProducts"
                  :key="product.id"
                  :class="['archive__item', { 'archive__item--active': selectedProduct === product.id }, { 'archive__item--disabled': !itemSwitchingEnabled }]"
                  @click="handleProductSelection(product.id)"
                >
                  <span class="archive__itemName">{{ product.name }}</span>
                  <BaseIcon icon="icon-arrow-right" extraClass="archive__itemArrow"></BaseIcon>
                </li>
              </ul>
              <BaseItemSelection v-else-if="selectedFiltersView === 'Produttore'" :items="vendors" :currentItem="selectedVendor" @handle-selection="handleVendorSelection"></BaseItemSelection>
              <BaseItemSelection v-else :items="sortedArchiveProducts" :currentItem="selectedProduct" @handle-selection="handleProductSelection"></BaseItemSelection>
            </div>
            <div class="archive__results horizontal-scroll">
              <TableOrders
                :orders="filteredOrdersData"
                :columns="['col', 'col', 'col', 'col-short', 'col-short', 'col-short']"
                :filters="topFilters"
                :allOrdersSelected="allOrdersSelected"
                :isLoading="tableLoading"
                :fetchingMore="fetchingMoreOrders && !loadEverythingAtOnce"
                @toggle-select-all="toggleSelectAll"
                showMethodOfPayment
                showLoadMoreBtn
              ></TableOrders>
            </div>
          </div>
        </form>
      </BaseCard>
    </div>

    <div v-else id="orders-div" :class="[{ 'page-scroll': !isTabMode }, , 'archive__col2', { 'u-hidden': orderDetailsShown && isTabMode }]">
      <BaseCard fullCard hideMobile>
        <BaseTopBar
          :title="selectedVendor || 'Tutti i produttori'"
          :subtitle="ordersData.length === 1 ? `1 ordine` : `${ordersData.length} ordini`"
          extraStyle="margin-bottom: 2rem"
          @go-back-fn="goBack"
        ></BaseTopBar>

        <BaseOrdersTools
          isFullWidth
          isArchive
          @handle-archive-status="handleArchiveStatus"
          :isDisabled="btnsDisabled"
          :selectedOrders="selectedOrders"
          :searchOptions="searchOptions"
          @search-data="searchOrders"
        ></BaseOrdersTools>

        <Filters
          isNarrow
          initialCollapsed
          :selectedValueRange="selectedValueRange"
          :selectedOrderStatuses="selectedOrderStatuses"
          :selectedPaymentMethods="selectedPaymentMethods"
          :selectedShippingTypes="selectedShippingTypes"
          :selectedFiltersView="selectedFiltersView"
          :selectedTimeRange="selectedTimeRange"
          :orderStatusesOptions="orderStatusesOptions"
          :paymentMethodsOptions="paymentMethodsOptions"
          :shippingTypesOptions="shippingTypesOptions"
          @handle-filters-selection="handleFiltersSelection"
        ></Filters>
        <div class="archive__results archive__results--left u-margin-top-small">
          <TableOrders
            :orders="filteredOrdersData"
            :columns="['col', 'col', 'col', 'col-short', 'col-short', 'col-short']"
            :filters="topFilters"
            :allOrdersSelected="allOrdersSelected"
            @toggle-select-all="toggleSelectAll"
            showMethodOfPayment
            :isLoading="tableLoading"
            :fetchingMore="fetchingMoreOrders && !loadEverythingAtOnce"
            showLoadMoreBtn
          ></TableOrders>
        </div>
      </BaseCard>
    </div>

    <div class="page-scroll archive__col3" v-if="orderDetailsShown">
      <BaseCard fullCard hideMobile>
        <div class="archive__heading">
          <BaseHeading :level="2" extraStyle="margin-bottom:4rem">Dettaglio ordine</BaseHeading>
          <p class="archive__linkBack" @click="goBack"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
        </div>
        <SwiperOrders v-if="isTabMode" isArchive></SwiperOrders>
        <Order v-else :orderId="currentOrder" isArchive></Order>
      </BaseCard>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a page that shows the admin orders with the filders
 *
 * @displayName ArchiveAdmin
 */

import { mapActions, mapGetters } from 'vuex';
import BaseCard from '@bc/BaseCard.vue';
import BaseHeading from '@bc/BaseHeading.vue';
import BaseOrdersTools from '@bc/BaseOrdersTools.vue';
import BaseIcon from '@bc/BaseIcon';
import BaseItemSelection from '@bc/BaseItemSelection';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseTopBar from '@bc/BaseTopBar';
import SwiperOrders from '@c/common/SwiperOrders';
import Order from '@c/common/Order';
import Filters from '@c/admin/Filters';
import TableOrders from '@c/common/TableOrders';
import { getVendors } from '@gq/getVendors.gql';
import { getFilteredOrders } from '@gq/getFilteredOrders.gql';
import { getProductsNames } from '@gq/getProductsNames.gql';
import { getNumericDate, getElementOffset, getNumericDateOfTheNextDate, checkIfJsonIsValid, calculateLastMonthPeriod, sortItemsFromZtoA, sortItemsFromAtoZ } from '@u/helperFunctions.js';
import { searchOrdersMixin } from '@c/mixins/searchMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

const filtersNames = {
  paid: 'Pagato',
  unpaid: ' Non pagato',
  standardShipping: 'Standard',
  premiumShipping: 'Premium',
  appointmentShipping: 'Appuntamento',
  creditCardPayment: 'Carta di credito',
  paypalPayment: 'Paypal',
  transferPayment: 'Bonifico',
  otherPayment: 'Altro'
};

export default {
  name: 'ArchiveAdmin',
  components: {
    BaseCard,
    BaseHeading,
    BaseOrdersTools,
    Filters,
    BaseIcon,
    BaseItemSelection,
    SwiperOrders,
    Order,
    BaseTopBar,
    TableOrders,
    BaseLoadingSpinner
  },
  mixins: [searchOrdersMixin, sleepMixin],
  data() {
    return {
      shop: [],
      // Set to true if all the data are loaded
      itemSwitchingEnabled: false,
      orders: [],
      products: [],
      productsData: [],
      productsFullyLoaded: false,
      productsReady: false,
      // Set to true if all the orders are loaded.
      // Don't show loading icon at the end of the table
      ordersFullyLoaded: true,
      // All the data retrieved from the database
      ordersData: [],
      // The orders with filters applied. These data is shown in the table
      filteredOrdersData: [],
      allOrdersSelected: false,
      selectedVendor: '',
      selectedOrders: [],
      topFilters: [{ name: 'Cliente' }, { name: 'Metodo di pagamento' }, { name: 'Spedizione' }, { name: 'Data ordine' }],
      searchOptions: ['Ordine', 'Lotto'],
      selectedProduct: '',
      selectedValueRange: [0, 1000],
      selectedOrderStatuses: [filtersNames.paid],
      selectedPaymentMethods: [filtersNames.creditCardPayment, filtersNames.paypalPayment, filtersNames.transferPayment, filtersNames.otherPayment],
      selectedShippingTypes: [filtersNames.standardShipping, filtersNames.premiumShipping, filtersNames.appointmentShipping],
      orderStatusesOptions: [filtersNames.paid, filtersNames.unpaid],
      paymentMethodsOptions: [filtersNames.creditCardPayment, filtersNames.paypalPayment, filtersNames.transferPayment, filtersNames.otherPayment],
      shippingTypesOptions: [filtersNames.standardShipping, filtersNames.premiumShipping, filtersNames.appointmentShipping],
      selectedFiltersView: 'Produttore',
      selectedTimeRange: [],
      fetchingMoreOrders: false,
      // If set to true fetchMore will be called as many times as needed to load all the requested data
      loadEverythingAtOnce: false
    };
  },
  apollo: {
    // Call when component is rendered
    shop() {
      return {
        query: getVendors
      };
    },
    products() {
      return {
        query: getProductsNames,
        variables: {
          namespace: 'product_info',
          key: 'type'
        },
        error() {
          this.clearTableData();
          this.itemSwitchingEnabled = true;
        }
      };
    },
    orders() {
      // Load the latest 50 orders
      return {
        query: getFilteredOrders,
        manual: true,
        result({ data, loading }) {
          if (!loading && data) {
            this.orders = data.orders;
          }
        },
        error() {
          this.clearTableData();
          this.itemSwitchingEnabled = true;
        }
      };
    }
  },
  computed: {
    ...mapGetters(['isTabMode', 'isLottoDisplayed', 'orderDetailsShown', 'throttledErrors', 'currentVendor', 'sortedArchiveProducts', 'currentOrder']),
    btnsDisabled() {
      return this.selectedOrders.length === 0;
    },
    tableLoading() {
      return this.fetchingMoreOrders ? false : !this.itemSwitchingEnabled;
    },
    vendors() {
      let vendors = [];

      if (this.shop.productVendors) {
        const allVendors = this.shop.productVendors.edges.map(vendor => {
          return {
            id: vendor.node,
            name: vendor.node
          };
        });

        vendors = allVendors.filter(vendor => vendor.name !== 'Cooperativa Sociale “Si Può Fare”');
      }
      return vendors;
    },

    numericTimeRange() {
      const startDate = getNumericDate(this.selectedTimeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);

      return [startDate, endDate];
    },

    itemTitle() {
      if (this.selectedFiltersView === 'Produttore') {
        return !this.isTabMode ? 'Produttori' : 'Seleziona un produttore';
      } else {
        return !this.isTabMode ? 'Prodotti' : 'Seleziona un prodotto';
      }
    },
    isVendorView() {
      return this.selectedFiltersView === 'Produttore';
    },
    isProductView() {
      return this.selectedFiltersView === 'Prodotto';
    },
    filtersQuery() {
      let filtersQuery = '';

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null) {
        filtersQuery = `created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]}`;
      }

      if (this.isVendorView && this.selectedVendor) {
        const vendorQuery = 'vendor:' + this.selectedVendor;
        filtersQuery = filtersQuery ? [filtersQuery, vendorQuery].join(' AND ') : vendorQuery;
      }

      if (this.isProductView && this.selectedProduct) {
        const productQuery = 'tag:' + this.selectedProduct;
        filtersQuery = filtersQuery ? [filtersQuery, productQuery].join(' AND ') : productQuery;
      }

      return filtersQuery;
    }
  },

  methods: {
    ...mapActions(['showLotto', 'hideLotto', 'set', 'setDataIsLoadingMsg', 'removeThrottledError']),

    resetData() {
      this.fetchingMoreOrders = false;
      this.filteredOrdersData = [];
      this.itemSwitchingEnabled = false;
      this.orders = [];
      this.ordersData = [];
      this.ordersFullyLoaded = true;
      this.selectedOrders = [];
      this.selectedProduct = '';
      this.selectedVendor = '';
    },

    handleArchiveStatus(activationInput) {
      const updatedOrdersData = this.ordersData;
      // Unarchive selected orders
      activationInput.forEach(input => {
        // Find order
        const editedOrder = updatedOrdersData.find(order => {
          if (order.vendor) {
            return order.id === input.id && order.vendor === input.vendor;
          } else {
            return order.id === input.id;
          }
        });
        if (editedOrder) editedOrder.isArchived = false;
      });

      this.ordersData = [...updatedOrdersData];
      this.filterOrders();
      this.getSelectedOrders();
      this.fetchOrders();
    },

    toggleChecked(orderId) {
      const updatedOrdersData = this.filteredOrdersData;

      // Find the selected order
      const selectedOrder = updatedOrdersData.find(order => order.id === orderId);

      selectedOrder.isChecked = !selectedOrder.isChecked;

      this.filteredOrdersData = [...updatedOrdersData];
      this.getSelectedOrders();
    },
    toggleSelectAll() {
      this.allOrdersSelected = !this.allOrdersSelected;

      let results = [];

      if (this.filteredOrdersData.length !== 0) {
        results = this.filteredOrdersData.map(order => {
          // Add isChecked and value to each order from the database
          return {
            ...order,
            isChecked: this.allOrdersSelected
          };
        });
      }

      this.filteredOrdersData = [...results];
      this.getSelectedOrders();
      this.set(['currentSetOfOrders', results]);
    },

    clearTableData() {
      this.ordersFullyLoaded = true;
      this.ordersData = [];
      this.orders = [];
      this.filteredOrdersData = [];
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      await this.sleep(2500);

      this.$apollo.queries.products.fetchMore({
        variables: {
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    },

    fetchOrders() {
      this.$apollo.queries.orders.refetch({
        query: this.filtersQuery
      });
    },

    async fetchMoreOrders() {
      this.itemSwitchingEnabled = false;
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      await this.sleep(2500);

      this.$apollo.queries.orders.fetchMore({
        variables: {
          query: this.filtersQuery,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.ordersFullyLoaded = true;
            this.itemSwitchingEnabled = true;
            this.fetchingMoreOrders = false;
            return previousResult;
          } else {
            this.ordersFullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          // Set table loading back to orders loading
          this.fetchingMoreOrders = false;

          return {
            orders: newOrders
          };
        }
      });
    },

    handleFiltersSelection(groupName, selections) {
      switch (groupName) {
        case 'filters_view':
          this.handleViewSelection(selections);
          break;
        case 'payment_methods':
          this.selectedPaymentMethods = selections;
          this.filterOrders();
          break;
        case 'order_statuses':
          this.selectedOrderStatuses = selections;
          this.filterOrders();
          break;
        case 'shipping_types':
          this.selectedShippingTypes = selections;
          this.filterOrders();
          break;
        case 'time_range':
          this.handleTimeSelection(selections);
          break;
        case 'value_range':
          this.selectedValueRange = selections;
          this.filterOrders();
          break;
      }
    },
    // Set selected vendor and reload the data
    async handleVendorSelection(vendor) {
      if (this.itemSwitchingEnabled) {
        this.itemSwitchingEnabled = false;
        this.ordersFullyLoaded = false;
        this.selectedVendor = vendor;
        this.orders = [];
        this.ordersData = [];
        this.filteredOrdersData = [];
        let filtersQuery = `vendor:${vendor}`;
        if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null) {
          filtersQuery = `created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]} AND vendor:${vendor}`;
        }

        await this.$apollo.queries.orders.refetch({
          query: filtersQuery
        });
        this.selectedVendor = vendor;
        this.set(['currentVendor', vendor]);
      }
    },

    // Set selected product and reload the data
    async handleProductSelection(productId) {
      if (this.itemSwitchingEnabled) {
        this.itemSwitchingEnabled = false;
        this.ordersFullyLoaded = false;
        this.orders = [];
        this.ordersData = [];
        this.filteredOrdersData = [];
        const productTag = productId;
        let filtersQuery = `tag:'${productTag}'`;
        if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null) {
          filtersQuery = `created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]} AND tag:'${productTag}'`;
        }

        await this.$apollo.queries.orders.refetch({
          query: filtersQuery
        });

        this.selectedProduct = productId;

        const selectedProduct = this.productsData.find(product => product.id === productId);
        const selectedProductVendor = selectedProduct ? selectedProduct.vendor : '';
        this.set(['currentVendor', selectedProductVendor]);
      }
    },

    filterOrders() {
      this.itemSwitchingEnabled = false;

      // Get only archived orders
      let filteredResults = this.ordersData.filter(order => order.isArchived);

      // PAYMENT STATUS
      // If not all the filters are selected, remove unselected

      if (this.selectedOrderStatuses.length !== this.orderStatusesOptions.length) {
        filteredResults = filteredResults.filter(order => {
          // If all the filters are selected, show all the orders
          if (this.selectedOrderStatuses.includes('Pagato') && this.selectedOrderStatuses.includes('Non pagato')) return true;
          // If both are unselected, don't show anything
          if (!this.selectedOrderStatuses.includes('Pagato') && !this.selectedOrderStatuses.includes('Non pagato')) return false;

          // Remove paid orders
          if (this.selectedOrderStatuses.includes('Pagato')) return order.paymentStatus === 'PAID';

          // Remove unpaid orders
          if (this.selectedOrderStatuses.includes('Non pagato')) return order.paymentStatus !== 'PAID';

          return;
        });
      }

      // PAYMENT TYPE
      // If not all the filters are selected, remove unselected
      if (filteredResults.length !== 0 && this.paymentMethodsOptions.length !== this.selectedPaymentMethods.length) {
        const gatewayNames = ['stripe', 'paypal', 'manual'];
        // TODO check if for all the credit cards payment method is stripe
        // const creditCards = ['AMERICAN_EXPRESS', 'VISA', 'MAESTRO', 'MASTERCARD', 'DANKORT', 'DINERS_CLUB', 'DISCOVER'];

        filteredResults = filteredResults.filter(order => {
          // If nothing is selected, don't show anything
          if (this.selectedPaymentMethods.length === 0) return false;

          // If the order payment method is one of the credit cards and credit card payment is selected, return true
          // if (creditCards.includes(order.metodoPagamento && this.selectedPaymentMethods.includes(filtersNames.creditCardPayment))) return true;

          // find index of the order's payment method and get the corresponding gateway's name
          const orderGatewayIndex = gatewayNames.findIndex(method => order.metodoPagamento === method);

          // If the order has a different payment method but the option "Altro" is selected, return true
          if (orderGatewayIndex < 0 && this.selectedPaymentMethods.includes(filtersNames.otherPayment)) return true;

          let orderPaymentMethod = this.paymentMethodsOptions[orderGatewayIndex];

          // If the order's payment method is selected, return true
          if (orderPaymentMethod && this.selectedPaymentMethods.includes(orderPaymentMethod)) return true;

          return;
        });
      }

      // SHIPPING TYPES
      // If not all the filters are selected, remove unselected
      if (filteredResults.length !== 0 && this.shippingTypesOptions.length !== this.selectedShippingTypes.length) {
        // TODO get real names
        const shippingCodes = ['Consegna (1-4 giorni)', 'Premium', 'Appuntamento'];

        filteredResults = filteredResults.filter(order => {
          // If nothing is selected, don't show anything
          if (this.selectedShippingTypes.length === 0) return false;

          // find index of the order's shipping type and get the corresponding shipping code
          const orderShippingIndex = shippingCodes.findIndex(code => order.spedizione === code);

          const orderShippingType = this.shippingTypesOptions[orderShippingIndex];

          // If the order's shipping type is selected, return true
          if (orderShippingType && this.selectedShippingTypes.includes(orderShippingType)) return true;

          return false;
        });
      }

      // ORDER VALUE

      if (filteredResults.length !== 0) {
        filteredResults = filteredResults.filter(order => {
          const orderTotal = Number(order.total);
          if (this.selectedValueRange[1] === 2000) {
            return orderTotal >= this.selectedValueRange[0];
          }
          return orderTotal >= this.selectedValueRange[0] && orderTotal <= this.selectedValueRange[1];
        });
      }

      this.filteredOrdersData = filteredResults;

      this.set(['currentSetOfOrders', filteredResults]);
      this.itemSwitchingEnabled = true;
    },

    async handleViewSelection(selection) {
      this.selectedFiltersView = selection;
      this.orders = [];
      this.ordersData = [];
      this.itemSwitchingEnabled = false;
      this.selectedProduct = '';
      this.selectedVendor = '';
      // Reset time range
      this.selectedTimeRange = [];
      //  Switch to products view
      // Load the products data if they are not loaded yet
      if (this.sortedArchiveProducts.length === 0 && selection === 'Prodotto') {
        this.productsReady = false;
        const lastMonthPeriod = calculateLastMonthPeriod();
        //const lastMonthPeriod = [new Date('2021-04-01'), new Date('2021-06-01')];

        const startDate = getNumericDate(lastMonthPeriod[0]);
        const endDate = getNumericDateOfTheNextDate(lastMonthPeriod[1]);
        // Load the data
        this.loadEverythingAtOnce = true;
        this.$apollo.queries.orders.refetch({ query: `created_at:>=${startDate} AND created_at:<${endDate}` });
      } else {
        this.productsReady = true;
        // Pass empty query to overwrite previous query's variables
        this.$apollo.queries.orders.refetch({ query: '' });
      }
    },

    handleTimeSelection(timeRange) {
      this.itemSwitchingEnabled = false;
      this.selectedTimeRange = timeRange;

      this.orders = [];

      this.$apollo.queries.orders.refetch({
        query: this.filtersQuery
      });
    },

    goBack() {
      this.$store.commit('hideOrderDetails');
    },
    getSelectedOrders() {
      let selections = [];

      if (this.filteredOrdersData.length !== 0) {
        selections = this.filteredOrdersData.filter(order => order.isChecked);
      }

      this.selectedOrders = selections;
      return selections;
    },

    calculateProductsStats(orders) {
      // If there are no orders, return products in alphabetical order
      if (orders.length === 0) {
        const orderedProducts = sortItemsFromAtoZ({ arr: this.productsData, key: 'name' });
        this.set(['sortedArchiveProducts', orderedProducts]);
        this.productsReady = true;
        return;
      }
      // Calculate total earnings for each product
      const productsWithEarnings = this.productsData.map(product => {
        let earnings = 0;
        orders.forEach(order => {
          // Remove invalid line items
          const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.currentQuantity > 0);

          validLineItems.forEach(lineItem => {
            if (lineItem.node.product && lineItem.node.product.id === product.id) {
              const lineItemTotal = Number(lineItem.node.originalTotalSet.shopMoney.amount) - Number(lineItem.node.totalDiscountSet.shopMoney.amount);
              earnings += lineItemTotal;
            }
          });
        });
        return {
          ...product,
          earnings
        };
      });

      const sortedProductsWithEarnings = sortItemsFromZtoA({ arr: productsWithEarnings, key: 'earnings' });

      // Save products to store
      this.set(['sortedArchiveProducts', sortedProductsWithEarnings]);

      // Show products
      this.productsReady = true;

      // Set loadEverythingAtOnce back to false

      this.loadEverythingAtOnce = false;
    },

    // THROTTLING
    displayLoadingPopup() {
      // Show loading popup
      this.setDataIsLoadingMsg('Caricamento dati...');
    },
    async retryAndRemoveError(err) {
      // Remove error
      this.$store.commit('removeThrottledError', err);
      this.itemSwitchingEnabled = false;
      await this.sleep(2000 + Math.random() * 500);

      // Refetch the query
      if (err.path !== 'order') {
        this.$apollo.queries[err.path].refetch(err.variables);
      } else {
        this.clearTableData();
        this.itemSwitchingEnabled = true;
      }
    }
  },

  watch: {
    orders(val) {
      if (val && val.edges) {
        let results;
        const appThis = this;

        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.ordersFullyLoaded = false;
          if (this.loadEverythingAtOnce) {
            this.fetchMoreOrders();
            return;
          }
        } else {
          if (this.loadEverythingAtOnce) this.calculateProductsStats(val.edges);
          this.ordersFullyLoaded = true;
        }

        if (val.edges.length === 0) {
          // There are no orders, show empty table
          this.clearTableData();
          this.itemSwitchingEnabled = true;
          this.ordersData = [];
          this.filteredOrdersData = [];
          return;
        }

        results = this.orders.edges.map(order => {
          const orderBatches = order.node.orderBatches.edges.map(batchInfo => {
            const jsonBatchValue = checkIfJsonIsValid(batchInfo.node.value) ? JSON.parse(batchInfo.node.value) : '';
            return {
              key: batchInfo.node.key,
              value: jsonBatchValue
            };
          });

          // Find only valid batches and take their numbers
          const orderBatchesNumbers = orderBatches.filter(batch => Number(batch.key)).map(batch => batch.value.ddt_number);

          // Remove invalid line items
          const validLineItems = order.node.lineItems.edges.filter(lineItem => lineItem.node.vendor && lineItem.node.product && lineItem.node.product.id && lineItem.node.currentQuantity > 0);

          let isArchived = true;
          if (order.node.archiviation && order.node.archiviation.edges) {
            // 1. If there is selected vendor check if his part of the order is archived
            if (this.selectedVendor) {
              const vendorNode = order.node.archiviation.edges.find(vendorNode => vendorNode.node.key === this.selectedVendor);

              isArchived = isArchived && vendorNode.node && vendorNode.node.value === 'true';
              // 2. If there selected product check if its vendor's part of the order is archived
            } else if (this.selectedProduct) {
              const currentProduct = this.productsData.find(product => product.id === this.selectedProduct);

              if (currentProduct && currentProduct.vendor) {
                const productVendorNode = order.node.archiviation.edges.find(vendorNode => {
                  const vendorKey = vendorNode.node && vendorNode.node.key ? vendorNode.node.key.toLowerCase() : '';
                  const currentProductVendor = currentProduct.vendor.toLowerCase();
                  return vendorKey === currentProductVendor;
                });
                isArchived = isArchived && productVendorNode && productVendorNode.node && productVendorNode.node.value === 'true';
              }
              // 3. If there is no vendor or product selected check if at least one part of the order is archived
            } else {
              isArchived = false;
              order.node.archiviation.edges.forEach(orderNode => {
                isArchived = isArchived || orderNode.node.value === 'true';
              });
            }
          }

          let orderVendor = this.selectedVendor;
          if (this.selectedProduct) {
            // Find selected product and get its vendor
            const selectedProduct = this.productsData.find(product => product.id === this.selectedProduct);
            orderVendor = selectedProduct.vendor;
          }

          return {
            id: order.node.id,
            name: order.node.name,
            nrOfProducts: validLineItems.length,
            vendors: validLineItems.length > 0 ? validLineItems.map(item => item.node.vendor) : [],
            clientData: {
              firstName: order.node.customer ? order.node.customer.firstName : '---',
              lastName: order.node.customer ? order.node.customer.lastName : '---'
            },
            paymentStatus: order.node.fullyPaid || order.node.displayFinancialStatus === 'PARTIALLY_REFUNDED' || order.node.displayFinancialStatus === 'PAID' ? 'PAID' : 'UNPAID',
            spedizione: order.node.shippingLine ? order.node.shippingLine.code : 'Standard',
            date: order.node.createdAt,
            metodoPagamento: order.node.paymentGatewayNames[0],
            total: order.node.currentTotalPriceSet.shopMoney.amount,
            isChecked: order.isChecked || false,
            orderBatchesNumbers,
            archiviation: order.node.archiviation ? order.node.archiviation.edges : [],
            label: order.node.label ? order.node.label.edges : [],
            isArchived,
            vendor: orderVendor
          };
        });

        appThis.ordersData = results.filter(order => order.nrOfProducts > 0);
        this.filterOrders();
      }
    },

    products(val, oldVal) {
      this.productsFullyLoaded = false;

      // Check if there is the second page

      if (val.pageInfo.hasNextPage) {
        this.fetchMoreProducts(oldVal);
      } else {
        this.productsFullyLoaded = true;
        const results = val.edges.map(product => {
          return {
            id: product.node.id,
            name: product.node.title,
            vendor: product.node.vendor,
            type: product.node.type ? product.node.type.value : ''
          };
        });

        this.productsData = results.filter(product => product.type !== 'farmer');
      }
    },

    ordersData(val) {
      if (val.length > 0) {
        this.$store.commit('set', ['currentSetOfOrders', val]);
      }
    },

    orderDetailsShown(val) {
      // Deactivate page scrolling if the order is displayed
      if (val && !this.isTabMode) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },

    // THROTTLING

    throttledErrors(val) {
      if (val && val.length > 0) {
        this.displayLoadingPopup();
        val.forEach(error => {
          // Retry in few seconds
          this.retryAndRemoveError(error);
        });
      }
    }
  },

  provide() {
    return {
      handleChange: this.toggleChecked,
      goBack: this.goBack,
      handleArchiveStatus: this.handleArchiveStatus
    };
  },

  mounted() {
    // Activate page scrolling
    document.body.style.overflow = 'auto';
  },

  updated() {
    // Activate page scrolling on desktop (if the order is not displayed)
    if (!this.isTabMode) {
      document.body.style.overflow = 'hidden';

      let triggered = false;
      const ordersDiv = document.getElementById('orders-div');
      ordersDiv.onscroll = () => {
        const divScrollTop = ordersDiv.scrollTop;
        const scrollableHeight = ordersDiv.scrollHeight - ordersDiv.clientHeight;
        if (scrollableHeight - divScrollTop < 50 && !triggered && !this.fetchingMoreOrders && this.orders.edges) {
          triggered = true;
          // Fetch the remaining orders
          if (!this.ordersFullyLoaded) {
            // Prevent table loading
            this.fetchingMoreOrders = true;

            this.fetchMoreOrders();
          }
        }
      };
    } else {
      document.body.style.overflow = 'auto';
      // Get the position of the load more button
      const loadBtn = document.getElementById('load-more-btn');
      let btnPosition;

      if (loadBtn) {
        btnPosition = getElementOffset(loadBtn);
      }

      let triggered = false;

      window.onscroll = () => {
        if (btnPosition && btnPosition.top - window.pageYOffset < 800 && !triggered && !this.fetchingMoreOrders && this.orders.edges) {
          triggered = true;
          // Fetch the remaining orders
          if (!this.ordersFullyLoaded) {
            // Prevent table loading
            this.fetchingMoreOrders = true;

            this.fetchMoreOrders();
          }
        }
      };
    }
  },
  beforeDestroy() {
    this.$store.commit('hideOrderDetails');
    this.$set(this.ordersData, []);
    this.$store.commit('set', ['currentSetOfOrders', []]);
    this.$store.commit('set', ['currentOrder', '']);
    document.body.style.overflow = 'hidden';
    this.resetData();
    this.loadEverythingAtOnce = false;
    this.productsFullyLoaded = false;
    this.productsReady = false;
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.archive {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 7rem 2.4rem 2.4rem 2.4rem;
  }

  @include respond('phone') {
    padding: 7rem 1rem 1rem 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: -1rem;
    z-index: 50;
    background-color: white;
    padding-top: 3.5rem;
    transform: translateY(-3.5rem);

    @include respond('tab-port') {
      display: none;
    }
  }

  &__headerMobile {
    // remove padding archive
    margin-left: -1rem;
    padding: 9rem 3rem 3rem 3rem;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: $color-white;
    z-index: 100;
    display: none;

    @include respond('tab-port') {
      display: block;
    }
  }

  &__tools {
    width: 70%;
    margin-bottom: 1rem;
    @include respond('tab-port') {
      margin-top: 2rem;
      width: 100%;
    }

    &--fullWidth {
      width: 100%;
    }
  }

  &__col1 {
    flex-grow: 1;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      border: none !important;
      box-shadow: none !important;
      padding: 19rem 0 0 0;
    }

    @include respond('phone') {
      padding: 22rem 0 0 0;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 100vh;
    padding: 4.5rem 2rem 4.5rem 2rem;
    overflow-y: auto;

    @include respond('tab-port') {
      border: none !important;
      box-shadow: none !important;
      height: unset;
      padding: 0 2rem 4.5rem 2rem;
      margin: 0;
    }
  }

  &__col3 {
    margin-left: 0.5rem;
    padding: 4.5rem 2rem 4.5rem 2rem;
    width: 46rem;
    flex-grow: 0;
    height: 100vh;
    overflow-y: auto;

    @include respond('tab-port') {
      margin-left: 0;
      width: 80%;
      height: fit-content;
      align-self: center;
    }

    @include respond('phone') {
      margin-left: 0;
      padding: 3.5rem 0;
      width: 100%;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    width: 100%;

    @include respond('tab-port') {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__itemsBox {
    flex-basis: 35%;
    flex-shrink: 0;
    position: relative;
    @include respond('tab-port') {
      width: 100%;
    }
  }

  &__results {
    flex-grow: 1;
    margin-left: 2.2rem;
    overflow-x: auto;

    &--left {
      margin-left: 0;
    }

    @include respond('tab-port') {
      margin-top: 2rem;
      margin-left: 0;
      width: 100%;
    }
  }

  &__items {
    list-style: none;
    background-color: rgba($color-blue-light, 0.4);
    border-radius: 8px;
    padding: 2rem 0;

    @include respond('tab-port') {
      background-color: transparent;
      border-radius: 0px;
      width: 60%;
      margin: 0 auto;
    }

    @include respond('phone') {
      width: 80%;
    }

    @include respond('miniphone') {
      width: 100%;
    }
  }

  &__itemsH2 {
    padding-left: 4.5rem;
    margin-bottom: 2rem;

    @include respond('tab-port') {
      padding-left: 0;
    }
  }

  &__item {
    padding: 2rem 4.5rem 2rem 3.9rem;
    border-left: 6px solid transparent;
    cursor: pointer;
    position: relative;
    transition: border 0s;
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color-bg-blur, 0.15);
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.2s ease-in-out 0.2s;
      @include respond('tab-port') {
        display: none;
      }
    }

    &:hover::after {
      opacity: 1;
      height: 100%;
      transform: scaleX(1);
    }

    &:hover {
      border-left: 6px solid $color-dark-blue;
      transition: border 0.5s;

      @include respond('tab-port') {
        border-left: none;
      }
    }

    @include respond('tab-port') {
      border-radius: 0px;
      border-left: none;
      padding: 1rem 0;
      font-size: 1.75rem;
      @include flex-parent-space-between;
    }

    &:not(:last-child) {
      @include respond('tab-port') {
        border-bottom: 2px solid $color-grey-light;
      }
    }

    &--active {
      border-left: 6px solid $color-dark-blue;
      transition: border 0.5s;

      @include respond('tab-port') {
        border-left: none;
      }
    }

    &--active::after {
      opacity: 1;
      height: 100%;
      transform: scaleX(1);
    }

    &--disabled {
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
        border-left: 6px solid transparent;
      }
      &:hover::after {
        opacity: 0;
        transform: scaleX(0);
      }
    }
  }

  &__itemName {
    @include respond('tab-port') {
      background-color: $color-grey-light;
      color: $color-dark-blue;
      border-radius: 4px;
      display: inline-block;
      padding: 0.4rem 1rem;
      cursor: pointer;
      transition: background-color 0.5s;
    }
  }

  &__item:hover &__itemName {
    @include respond('tab-port') {
      background-color: $color-blue-light;
    }
  }

  &__itemArrow {
    display: none;

    @include respond('tab-port') {
      display: inline-block;
      color: $color-blue-light;
      height: 1.3rem;
      width: 1.3rem;
      transform: translateX(0);
      transition: transform 0.3s, color 0.5s;
    }
  }

  &__item:hover &__itemArrow {
    @include respond('tab-port') {
      transform: translateX(3px);
      color: $color-grey;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }
}
</style>
