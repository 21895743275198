/**
 * Style date object
 *
 * @param {Date}  - date object
 * @returns {String} - string with the date and day of the week, example: "sabato 4 dic 2021"
 */

export const styleDate = date => {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const styledDate = new Date(date).toLocaleDateString('it-IT', options);
  return styledDate;
};

/**
 * Style date object
 *
 * @param {Date}  - date object
 * @returns {String} - numeric format of the date, example: "4/5/2021"
 */

export const styleShortDate = date => {
  const fullDate = new Date(date);
  const numMonth = `${fullDate.getMonth() + 1 < 10 ? '0' : ''}${fullDate.getMonth() + 1}`;
  const numDayOfTheMonth = `${fullDate.getDate() < 10 ? '0' : ''}${fullDate.getDate()}`;

  return `${numDayOfTheMonth}/${numMonth}/${fullDate.getFullYear()}`;
};

/**
 * Style time
 *
 * @param {Date}  - date object
 * @returns {String} - time, example: "14:07"
 */

export const styleHours = date => {
  const hourString = `${new Date(date).getHours()}:${new Date(date).getMinutes() < 10 ? '0' : ''}${new Date(date).getMinutes()}`;
  return hourString;
};

/**
 * Get numeric version of the date YYYY-MM-DD
 * @param {Date}  - date object
 * @returns {String} - numeric format of the date, example: "2021-05-04"
 */

export const getNumericDate = date => {
  const fullDate = new Date(date);
  const numMonth = `${fullDate.getMonth() + 1 < 10 ? '0' : ''}${fullDate.getMonth() + 1}`;
  const numDayOfTheMonth = `${fullDate.getDate() < 10 ? '0' : ''}${fullDate.getDate()}`;

  return `${fullDate.getFullYear()}-${numMonth}-${numDayOfTheMonth}`;
};

/**
 * Get the date exactly X months before the current date, where x is the number of months passed as an argument
 * @param {Number}  - number of months
 * @returns {String} - date X months ago, for example Tue May 18 2021 13:57:42 GMT+0200 (Ora legale dell’Europa centrale)
 */

export const getXMonthsAgoDate = numberOfMonths => {
  const dayToday = new Date();
  const dateXMonthsBefore = new Date(dayToday.setMonth(dayToday.getMonth() - numberOfMonths));
  return dateXMonthsBefore;
};

/**
 * Get arrays with the days of the selected time range
 * @param {Array}  - array of two dates
 * @returns {Array} - array of dates included in the provided time range
 */
export const getDatesArr = timeRange => {
  const startDate = timeRange[0];
  const endDate = timeRange[1];

  Date.prototype.addDays = function (days) {
    let dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  };

  const datesArr = [];

  let currentDate = startDate;

  while (currentDate <= endDate) {
    datesArr.push(getNumericDate(currentDate));
    currentDate = currentDate.addDays(1);
  }

  return datesArr;
};

/**
 * Get days of the week of the selected time range
 * @param {Array}  - array of two dates
 * @returns {Array} - array with the names of the days in the provided time range
 */

export const getDaysOfTheWeek = timeRange => {
  const daysOfTheWeek = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

  const startDate = timeRange[0];
  const endDate = timeRange[1];

  Date.prototype.addDays = function (days) {
    let dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  };

  const datesArr = [];

  let currentDate = startDate;

  while (currentDate <= endDate) {
    datesArr.push(currentDate);
    currentDate = currentDate.addDays(1);
  }

  Date.prototype.getDayName = function () {
    return daysOfTheWeek[this.getDay()];
  };

  const datesOfTheWeekArray = datesArr.map(date => date.getDayName());

  return datesOfTheWeekArray;
};

/**
 * Shorten text to the number of characters passed as a second argument
 * @param {String}  - full text
 * @param {Number} - number of characters we want to keep
 * @returns {String} - full text shorten to the number of characters passed as a second argument
 */

export const shortenTxt = (txt, limit) => {
  let newTxt = [];

  if (txt.length > limit) {
    txt.split(' ').reduce((acc, cur) => {
      //  with (newTxt.length - 1) add also spaces
      if (acc + cur.length <= limit - newTxt.length) {
        newTxt.push(cur);
      }
      return acc + cur.length;
    }, 0);

    // return the result
    return newTxt.join(' ') + '...';
  }
  return txt;
};

/**
 * This function encodes a URI by replacing each instance of certain characters by one, two, three,
 * or four escape sequences representing the UTF-8 encoding of the character
 */

export const getURIString = text => {
  return encodeURIComponent(text.trim());
};

/**
 * Split an array into chunks of numEl elements
 * @param {Array} - array that we want to split into chunks
 * @param {Number} - number of elements  of one chunk
 * @returns {Array} - an array splited into chunks of numEl elements
 */

export const createChunks = (arr, numEl) => {
  const nrOfChunks = Math.ceil(arr.length / numEl);

  return new Array(nrOfChunks).fill('').map((_, index) => arr.slice(index * numEl, (index + 1) * numEl));
};

/**
 * Check if the provided date is included in the provided time range
 * @param {Date} - selected date
 * @param {Array} - array of two dates
 * @returns {Boolean} - returns true if the date is included in the time range or false if it is not
 */

export const checkDateInTimeRange = (date, timeRange) => {
  Date.prototype.addDays = function (days) {
    let dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  };

  const startDate = new Date(timeRange[0]).getTime();
  // Add one day because the whole endDate should be included
  const endDate = new Date(timeRange[1]).addDays(1).getTime();

  const selectedDate = new Date(date).getTime();

  if (selectedDate >= startDate && selectedDate <= endDate) {
    // the provided date is included in the selected time range
    return true;
  } else {
    // the provided date is NOT included in the selected time range
    return false;
  }
};

/**
 * Get the element position
 * @param {Object} - selected element
 */

export const getElementOffset = el => {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset
  };
};

/**
 * Check if the JSON string is valid
 * @param {String} - json string
 * @returns {Boolean} - returns true if the is valid, returns false if not
 */

export const checkIfJsonIsValid = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Calculate last week period (from Monday to Sunday)
 * @returns {Array} - returns an array with two days:
 * The last Monday date and the next Sunday date
 */

export const calculateLastWeekPeriod = () => {
  // Calculate the difference between today.getDay() and 1 (Monday)
  const dayToday = new Date();
  const daysAfterMonday = dayToday.getDay() - 1;
  // Get the last Monday date
  const lastMonday = new Date(dayToday.setDate(dayToday.getDate() - daysAfterMonday));

  const lastMondayCopy = new Date(lastMonday);
  const nextSunday = new Date(lastMondayCopy.setDate(lastMondayCopy.getDate() + 6));

  lastMonday.setHours(0, 0, 0);
  nextSunday.setHours(0, 0, 0);

  return [lastMonday, nextSunday];
};

/**
 * Calculate last month period
 * @returns {Array} - returns an array with two days:
 * The date one week ago and today's date
 */

export const calculateLastMonthPeriod = () => {
  const dayToday = new Date();
  const dayOneMonthBefore = new Date(dayToday.setDate(dayToday.getDate() - 31));
  dayOneMonthBefore.setHours(0, 0, 0);
  const dayTomorrow = new Date();
  dayTomorrow.setDate(dayTomorrow.getDate() + 1);
  dayTomorrow.setHours(0, 0, 0);

  return [dayOneMonthBefore, dayTomorrow];
};

/**
 * Get numeric date of the next date of the provided date
 * @returns {String}
 */

export const getNumericDateOfTheNextDate = date => {
  const startingDay = new Date(date);
  startingDay.setDate(startingDay.getDate() + 1);

  return getNumericDate(startingDay);
};

/**
 * Sort items from the largest to the smallest, by the key passed in the second argument
 * @param {Object} - object containing two properties
 * 1. arr - an array of objects
 * 2. key -  the key by which the array should be sorted
 * @returns {Arr} - returns an array of objects sorted based on "key" value
 */
export const sortItemsFromZtoA = params => {
  const { arr, key } = params;
  return arr.sort((a, b) => {
    return b[key] - a[key];
  });
};

/**
 * Sort items from the smallest to the largest, by the key passed in the second argument
 * @param {Object} - object containing two properties
 * 1. arr - an array of objects
 * 2. key -  the key by which the array should be sorted
 * @returns {Arr} - returns an array of objects sorted based on "key" value
 */
export const sortItemsFromAtoZ = params => {
  const { arr, key } = params;
  return arr.sort((a, b) => {
    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  });
};

/**
 * Sort items from the most recent to the oldest date, by the key passed in the second argument
 * @param {Object} - object containing two properties
 * 1. arr - an array of objects
 * 2. key -  the key with the date
 * @returns {Arr} - returns an array of objects sorted by date
 */
export const sortItemsByDateFromPresentToPast = params => {
  const { arr, key } = params;
  return arr.sort((a, b) => {
    return new Date(b[key]) - new Date(a[key]);
  });
};

/**
 * Sort array items from the smallest to the largest
 * @param {Array} - array of numbers
 * @returns {Arr} - returns sorted array
 */
export const sortArrayElementsFromAtoZ = arr => {
  return arr.sort((a, b) => a - b);
};

/*
 * Convert weight to kg
 * @param {Number} - weight
 * @param {String} - initial weight unit (KILOGRAMS/GRAMS/POUNDS/OUNCES)
 * @returns {Number} - returns the weight in kg
 */

export const convertWeightToKg = (weight, initialWeightUnit) => {
  let weightInKg = 0;

  switch (initialWeightUnit) {
    case 'KILOGRAMS':
      weightInKg = weight;
      break;
    case 'GRAMS':
      weightInKg = weight * 0.001;
      break;
    case 'POUNDS':
      weightInKg = weight * 0.4535923;
      break;
    case 'OUNCES':
      weightInKg = weight * 0.0283495;
      break;
    default:
      weightInKg = weight;
  }

  return Math.round(weightInKg * 100) / 100;
};

/*
 * Convert final part of product id to full product id
 * @param {Number} - product id (the final part)
 * @returns {String} - returns the full product id
 */

export const getFullProductId = id => {
  return `gid://shopify/Product/${id}`;
};

/*
 * Convert full product id to short id (only the final part)
 * @param {String} - full product id
 * @returns {Number} - returns the final part of product id
 */

export const getShortProductId = id => {
  return Number(id.split('Product/')[1]);
};

/*
 * Convert full order id to short id (only the final part)
 * @param {String} - full product id
 * @returns {Number} - returns the final part of product id
 */

export const getShortOrderId = id => {
  return Number(id.split('Order/')[1]);
};

/*
 * Given an array of orders, build a string with every orders number separated by '-'
 * @param {Array} - orders
 * @returns {String} - formatted string with every orders' number separated by '-'
 */

export const getOrdersNumberStringed = orders => {
  // Looping through every order to extract the number, removing the '#'
  const mappedNumbers = orders.map(({ name = '' }) => name.substring(1));

  // Joining the array of orders' number to concatenate them with '-'
  const formattedNumbers = mappedNumbers.join('-');

  return formattedNumbers;
};

/*
 * Convert string to number
 * @param {Number} - a number
 * @returns {String} - returns a string that is recognized by excel as a number (so for example 23,5 instead of 23.5)
 */
export const convertNrToExcelNr = str => {
  const stringNumber = str.toString();
  return stringNumber.replace('.', ',');
};
