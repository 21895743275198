<template>
  <p v-if="!region" class="u-text-warning">Provenienza sconosciuta.</p>
  <figure v-else :class="['baseMap', extraClass]" :style="extraStyle"><img :src="require('@/assets/MappaITA/' + region + '.svg')" :alt="region" style="width: 100%; height: auto" /></figure>
</template>
<script>
/**
 * This component loads a map with Italian regions. The one passed in props will be highlighted
 *
 * @displayName BaseMap
 */

export default {
  name: 'BaseMap',
  props: {
    /**
     * This prop is used to pass the name of the region that should be highlighted on the map
     */
    region: { type: String, required: true },
    /**
     * This prop is used to pass an extra class that should be applied to the map
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>
<docs>
### BaseMap simple
```js
<BaseMap region="Lombardia"></BaseMap>
```
 
 
</docs>
