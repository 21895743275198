export const fetchMoreOrdersMixin = {
  methods: {
    fetchMoreOrders() {
      const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: this.queryString,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            this.allDataReady = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];
          return {
            orders: newOrders
          };
        }
      });
    }
  }
};
