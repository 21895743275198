<template>
  <form @submit.prevent :class="['lottoProduct__form', { 'lottoProduct__form--collapsed': !showDetails }]">
    <div>
      <div :class="['lottoProduct__heading', { 'lottoProduct__heading--collapsed': !showDetails }, { 'lottoProduct__heading--registered': isRegistered && !showDetails }]" @click="exitCollapse">
        <BaseHeading :level="6">{{ vendor }}</BaseHeading>

        <p v-if="isTabMode && showDetails" class="lottoProduct__required">* dato obbligatorio</p>
        <span v-if="!showDetails" class="lottoProduct__product">{{ shortenedTitle }}</span>
      </div>

      <div v-if="showDetails" @click="collapseDetails" class="lottoProduct__close">
        <BaseIcon icon="icon-trash-o" extraClass="lottoProduct__closeIcon remove_product" @handle-click="removeProduct(productData.id)"></BaseIcon>
      </div>
    </div>
    <div v-if="showDetails" class="lottoProduct__formRow lottoProduct__formRow--1">
      <div class="lottoProduct__formCol datepicker-dark">
        <BaseSelect isRequired :options="products" :selected="editedProductId" label="Prodotto" defaultDisabledOption="Seleziona un prodotto" @handle-change="handleSelectProduct"></BaseSelect>
        <BaseInput
          label="Quantità"
          id="quantita"
          type="number"
          :initialValue="productData.quantita"
          isRequired
          :isDisabled="!editedProductId"
          isShort
          extraTxt="Kg"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'quantita', value)"
        ></BaseInput>
        <hr v-if="isTabMode" class="lottoProduct__line" />
        <BaseInput
          label="Data di raccolta"
          id="dataRaccolta"
          isRequired
          type="date"
          :initialValue="productData.dataRaccolta"
          :isDisabled="!editedProductId"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'dataRaccolta', value)"
        ></BaseInput>
      </div>
      <div class="lottoProduct__formCol">
        <BaseInput
          label="Data del DDT"
          id="dataDDT"
          isRequired
          :initialValue="productData.dataDDT"
          :isDisabled="!editedProductId"
          type="date"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'dataDDT', value)"
        ></BaseInput>
        <BaseInput
          label="Numero del DDT"
          id="numeroDDT"
          isRequired
          isShort
          :initialValue="productData.numeroDDT"
          :isDisabled="!editedProductId"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'numeroDDT', value.toUpperCase())"
        ></BaseInput>
        <BaseFileUpload
          label="Carica il file in pdf del documento di trasporto (DDT):"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'ddt', value)"
          isRequired
          :isDisabled="!editedProductId || !productData.numeroDDT || !productData.dataDDT"
          :DDTnumber="productData.numeroDDT"
          :DDTdate="productData.dataDDT"
          :vendor="vendor"
          extraClass="lottoProduct__fileUpload"
          :initialFile="productData.ddt"
        ></BaseFileUpload>
      </div>
    </div>
    <div v-if="showDetails" class="lottoProduct__formRow">
      <div class="lottoProduct__formCol">
        <BaseInput
          label="Provincia"
          id="provincia"
          :initialValue="productData.provincia"
          :isDisabled="!editedProductId"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'provincia', value)"
        ></BaseInput>
        <BaseInput
          label="Foglio"
          id="foglio"
          :initialValue="productData.foglio"
          :isDisabled="!editedProductId"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'foglio', value)"
        ></BaseInput>
      </div>
      <div class="lottoProduct__formCol">
        <BaseInput
          label="Comune"
          id="comune"
          :initialValue="productData.comune"
          :isDisabled="!editedProductId"
          isRequired
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'comune', value)"
        ></BaseInput>
        <BaseInput
          label="Particella"
          id="particella"
          :initialValue="productData.particella"
          :isDisabled="!editedProductId"
          @handle-change="value => $emit('handle-product-data-change', editedProductId, 'particella', value)"
        ></BaseInput>
      </div>
    </div>
  </form>
</template>
<script>
import BaseSelect from '@bc/BaseSelect';
import BaseInput from '@bc/BaseInput';
import BaseIcon from '@bc/BaseIcon';
import BaseHeading from '@bc/BaseHeading';
import BaseFileUpload from '@bc/BaseFileUpload';
import { shortenTxt } from '@u/helperFunctions.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LottoProduct',
  components: {
    BaseSelect,
    BaseHeading,
    BaseInput,
    BaseFileUpload,
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass an array of the vendor's products
     */
    products: { type: Array, required: true },
    /**
     * This prop is used to pass the name of the selected vendor
     */
    vendor: { type: String, required: true },
    /**
     * This prop is used to pass the initial values of the product properties
     */
    productData: { type: Object, required: true },
    /**
     * This prop is used to pass the id of the edited product
     */
    editedProductId: { type: String },
    /**
     * If set to true, the component will be collapsed on load
     */
    initialCollapse: { type: Boolean, default: false },
    /**
     * Is set to true the edit mode will be available
     */
    isRegistered: { type: Boolean, default: false },
    /**
     * Is set to true the edit mode will be active
     */
    isEditMode: { type: Boolean, default: false }
  },
  data() {
    return {
      isCompleted: false,
      isCollapsed: this.initialCollapse
    };
  },
  computed: {
    ...mapGetters(['isTabMode', 'isMobileMode']),
    showDetails() {
      // Show details if the product is not registered and the edit mode is not active
      // Or if the product is registered, the edit mode is active and it is not set to collapsed
      return (!this.isRegistered && !this.isEditMode) || (this.isRegistered && this.isEditMode && this.editedProductId === this.productData.prodotto && !this.isCollapsed);
    },
    shortenedTitle() {
      return this.isMobileMode ? shortenTxt(`${this.productData.name}, ${this.productData.quantita} Kg`, 15) : shortenTxt(`${this.productData.name}, ${this.productData.quantita} Kg`, 28);
    }
  },
  methods: {
    ...mapActions(['removeStepCompleteness', 'addAlert', 'addRegisteredProduct', 'removeRegisteredProduct']),
    handleSelectProduct(value) {
      this.productData.prodotto = value;
      this.$emit('reset-data');
      this.$emit('select-product', value);
    },
    collapseDetails(e) {
      if (e.target.closest('.remove_product')) return;
      if (this.isRegistered) {
        // Check if all the required fields are filled out
        if (this.checkIfRequiredFieldsCompleted(this.productData)) {
          // Hide details and leave edit mode
          this.$emit('handle-edit-mode', '');
          this.isCollapsed = true;
          this.addRegisteredProduct([this.vendor, this.productData]);
        } else {
          this.removeStepCompleteness(2);
          this.addAlert({ msg: 'Seleziona prodotto, quantità, data di raccolta, data del DDT, comune e carica il file del DDT per poter aggiungere un nuovo prodotto.' });
        }
      }
    },
    exitCollapse() {
      if (this.isRegistered) {
        // Enter edit mode and show details
        this.$emit('handle-edit-mode', this.productData.prodotto);
        this.isCollapsed = false;
      }
    },
    removeProduct(productId) {
      // Clear the form and remove the registered product (if there is any)
      if (!this.isEditMode) this.$emit('reset-data');
      this.removeRegisteredProduct(productId);
    }
  },
  inject: ['checkIfRequiredFieldsCompleted']
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.lottoProduct {
  &__form {
    background-color: $color-grey-light;
    border-radius: 4px;
    padding: 2.5rem;
    margin-bottom: 1.8rem;
    position: relative;

    &--collapsed {
      margin-bottom: 1.9rem;
      padding: 1.8rem 2.5rem;
    }
  }

  &__formRow {
    display: flex;

    &--1 {
      padding-bottom: 2.65rem;
      border-bottom: 1px solid $color-dark-blue;
      @include respond('tab-port') {
        padding-bottom: 2rem;
      }
    }

    @include respond('tab-port') {
      flex-direction: column;
    }
  }

  &__formRow:last-child {
    padding-top: 2.65rem;
  }

  &__formCol {
    width: 50%;

    @include respond('tab-port') {
      width: 100%;
    }
  }

  &__formCol:not(:last-child) {
    margin-right: 7rem;
    @include respond('tab-port') {
      margin-right: 0;
    }
  }

  &__headingBox {
    @include flex-parent-space-between;
  }

  &__heading {
    @include flex-parent-space-between;
    margin-bottom: 1.9rem;

    &--collapsed {
      margin-bottom: 0;
    }

    &--registered {
      cursor: pointer;
    }
  }

  &__required {
    color: $color-flame-pea;
    @include default-font-size;
    margin-top: 1.5rem;
  }

  &__product {
    @include default-font-size;
    color: $color-blue-light;
  }

  &__line {
    width: 100%;
    height: 1px;
    border-color: $color-dark-blue;
    margin-top: calculateMobRem(24px);
    margin-bottom: calculateMobRem(24px);
  }

  &__fileUpload {
    @include respond('tab-port') {
      margin-top: calculateMobRem(20px);
    }
  }

  &__close {
    position: absolute;
    top: -4px;
    right: 0;
    cursor: pointer;
    padding: 2.5rem;
    width: 100%;
    text-align: right;
  }

  &__closeIcon {
    color: $color-dark-blue !important;
    &:hover {
      color: $color-flame-pea !important;
    }
  }
}
</style>
