<template>
  <div class="productsAndVendors">
    <transition name="col1">
      <div class="page-scroll productsAndVendors__col1" v-if="selectedItemId === '' && !isTabMode">
        <BaseCard fullCard>
          <div class="datepicker-dark">
            <DatePicker v-model="selectedTimeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
          </div>
          <BaseLoadingSpinner v-if="$apollo.queries.products.loading || (!isProductsView && !vendorsFullyLoaded) || (isProductsView && !productsFullyLoaded) || !products.edges"></BaseLoadingSpinner>
          <div v-else>
            <VendorsCharts v-if="!isProductsView" :timeRange="selectedTimeRange"></VendorsCharts>
            <ProductsCharts v-else :timeRange="selectedTimeRange"></ProductsCharts>
          </div>
        </BaseCard>
      </div>
    </transition>

    <div v-if="!isTabMode || selectedItemId === ''" :class="['page-scroll', 'productsAndVendors__col2', { 'productsAndVendors__col2--narrow': selectedItemId !== '' }]">
      <BaseCard fullCard hideMobile extraClass="productsAndVendors__card2">
        <BaseLoadingSpinner v-if="$apollo.queries.products.loading || (!isProductsView && !vendorsFullyLoaded) || (isProductsView && !productsFullyLoaded) || !products.edges"></BaseLoadingSpinner>
        <ProductsVendorsTabs v-else :itemSwitchingEnabled="itemSwitchingEnabled"></ProductsVendorsTabs>
      </BaseCard>
    </div>

    <div class="page-scroll productsAndVendors__col3" v-if="selectedItemId !== ''">
      <BaseCard fullCard hideMobile extraClass="productsAndVendors__card ">
        <BaseLoadingSpinner v-if="(!isProductsView && !vendorsFullyLoaded) || (isProductsView && !productsFullyLoaded)"></BaseLoadingSpinner>
        <div v-else>
          <ProductSheet v-if="isProductsView"></ProductSheet>
          <AdminVendorSheet v-else></AdminVendorSheet>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script>
import BaseCard from '@bc/BaseCard.vue';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import ProductsVendorsTabs from '@c/admin/ProductsVendorsTabs';
import DatePicker from 'vue2-datepicker';
import VendorsCharts from '@c/admin/VendorsCharts';
import ProductsCharts from '@c/admin/ProductsCharts';
import ProductSheet from '@c/common/ProductSheet';
import AdminVendorSheet from '@c/admin/AdminVendorSheet';
import { getVendors } from '@gq/getVendors.gql';
import { getProducts } from '@gq/getProducts.gql';
import { mapGetters, mapActions } from 'vuex';
import { getXMonthsAgoDate, checkIfJsonIsValid } from '@u/helperFunctions';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

export default {
  name: 'ProductsAndVendors',
  components: {
    BaseCard,
    DatePicker,
    ProductsVendorsTabs,
    VendorsCharts,
    ProductsCharts,
    ProductSheet,
    AdminVendorSheet,
    BaseLoadingSpinner
  },
  mixins: [sleepMixin],
  data() {
    return {
      selectedTimeRange: [],
      shop: {},
      products: {},
      productsFullyLoaded: false,
      vendorsFullyLoaded: false,
      itemDataLoaded: true,
      retry: 0
    };
  },
  apollo: {
    // Call when component is rendered
    shop() {
      return {
        query: getVendors,
        skip: true
      };
    },
    products() {
      return {
        query: getProducts,
        manual: true,
        skip: true,
        result({ data, loading }) {
          if (!loading && data) {
            if (data.products.pageInfo.hasNextPage) {
              const lastCursor = data.products.edges[data.products.edges.length - 1].cursor;

              this.fetchMoreProducts(lastCursor);
            } else {
              this.products = data.products;
              this.productsFullyLoaded = true;
            }
          }
        },
        variables: {
          namespace: 'product_info',
          key: 'type',
          boxInfo: 'box_info',
          boxContent: 'content'
        }
      };
    }
  },
  computed: {
    ...mapGetters(['selectedItemId', 'selectedView', 'isTabMode', 'throttledErrors']),
    itemSwitchingEnabled() {
      return this.productsFullyLoaded && this.itemDataLoaded;
    },
    isProductsView() {
      return this.selectedView === 'Prodotti';
    }
  },
  methods: {
    ...mapActions(['set', 'setDataIsLoadingMsg']),
    async fetchProducts() {
      await this.sleep(2000);
      this.$apollo.queries.products.start();
      this.$apollo.queries.products.refetch({
        namespace: 'product_info',
        key: 'type',
        boxInfo: 'box_info',
        boxContent: 'content'
      });
    },

    async fetchMoreProducts(lastCursor) {
      //   const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      await this.sleep(5000);

      this.$apollo.queries.products.fetchMore({
        variables: {
          namespace: 'product_info',
          key: 'type',
          cursor: lastCursor,
          boxInfo: 'box_info',
          boxContent: 'content'
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];

          return {
            products: newProducts
          };
        }
      });
    },
    setItemDataLoaded(value) {
      this.itemDataLoaded = value;
    },

    // THROTTLING
    displayLoadingPopup() {
      // Show loading popup
      this.setDataIsLoadingMsg('Caricamento dati...');
    },
    async retryAndRemoveError(err) {
      // Remove error
      this.$store.commit('removeThrottledError', err);
      this.itemDataLoaded = false;

      await this.sleep(3000 + Math.random() * 500);

      // Refetch the query
      if (err.path !== 'node') {
        this.$apollo.queries[err.path].refetch(err.variables);
      } else {
        this.itemDataLoaded = true;
        this.productsFullyLoaded = true;
      }
    }
  },
  watch: {
    shop(val) {
      let vendors;
      if (val.productVendors) {
        vendors = val.productVendors.edges.map(vendor => vendor.node);
        const filteredVendors = vendors.filter(vendor => vendor !== 'Cooperativa Sociale “Si Può Fare”');
        this.set(['shopVendors', filteredVendors]);
        this.vendorsFullyLoaded = true;
      }
    },
    productsFullyLoaded(val) {
      if (val) {
        this.productsFullyLoaded = true;
        let results = [];
        if (this.productsFullyLoaded) {
          this.products.edges.forEach(product => {
            // Select only collections containing products (exclude farmers)
            if (product.node.type && product.node.type.value !== 'farmer') {
              results = [
                ...results,
                {
                  title: product.node.title,
                  id: product.node.id,
                  vendor: product.node.vendor,
                  tags: product.node.tags,
                  collections: product.node.collections.edges.map(collection => collection.node.title),
                  variants: product.node.variants.edges.map(variant => {
                    const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';
                    const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';
                    return {
                      id: variant.node.id,
                      content: jsonVariantContent
                    };
                  })
                }
              ];
            }
          });

          this.set(['shopProducts', results]);
        }
      }
    },
    selectedView(val) {
      if (val && !this.isProductsView) {
        this.$apollo.queries.shop.start();
        this.$apollo.queries.shop.refetch();
      }
    },
    // THROTTLING

    throttledErrors(val) {
      if (val && val.length > 0) {
        if (this.retry < 10) {
          this.displayLoadingPopup();
          val.forEach(error => {
            // Retry in few seconds
            this.retryAndRemoveError(error);
          });
        } else {
          // Unblock other actions
          this.itemDataLoaded = true;
          this.productsFullyLoaded = true;
        }
      }
    }
  },

  provide() {
    return {
      setItemDataLoaded: this.setItemDataLoaded
    };
  },

  created() {
    const initialTimeRange = [getXMonthsAgoDate(3), new Date()];
    this.selectedTimeRange = initialTimeRange;

    this.fetchProducts();
  },

  beforeDestroy() {
    this.$store.commit('set', ['selectedItemId', '']);
    this.$store.commit('set', ['selectedView', 'Prodotti']);
    this.selectedTimeRange = [];
    this.productsFullyLoaded = false;
    this.vendorsFullyLoaded = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.productsAndVendors {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 8rem 0 0 0;
  }

  &__col1 {
    flex-basis: 45rem;
    flex-shrink: 0;
    padding: 4.5rem 2rem 4.5rem 2rem;
    height: 100vh;
    overflow-y: auto;
    margin-right: 0.5rem;

    @include respond('tab-port') {
      padding: 0 2rem 4.5rem 2rem;
      margin: 0 0 2.4rem 0;
      width: 100%;
      height: fit-content;
      overflow: visible;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    padding: 4.5rem 2rem 4.5rem 2rem;

    &--narrow {
      flex-basis: 45rem;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 0.5rem;
    }

    @include respond('tab-port') {
      margin-left: 0;
      margin-right: 0;

      padding: 0 2rem 4.5rem 2rem;
      height: fit-content;
    }
  }

  &__col3 {
    flex-grow: 1;
    margin-left: 0.5rem;
    height: 100vh;
    overflow-y: auto;
    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      margin-left: 0;
      width: 80%;
      align-self: center;
      height: fit-content;
      overflow-y: visible;
      padding: 0 2rem 4.5rem 2rem;
    }

    @include respond('phone') {
      margin-left: 0;
      width: 100%;
      padding: 0 0 4.5rem 0;
    }
  }

  &__card {
    padding: 0;

    @include respond('tab-port') {
      padding: 0 3rem;
    }
  }

  &__card2 {
    padding-top: 0;

    @include respond('tab-port') {
      padding: 0 1rem;
    }
  }
}
</style>
