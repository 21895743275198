<template>
  <div class="vendorsTab">
    <div :class="['vendorsTab__tools', { 'vendorsTab__tools--narrow': isNarrow }]">
      <BaseSearch @search-data="handleSearch"></BaseSearch>
    </div>
    <ul :class="['vendorsTab__results', { 'vendorsTab__results--narrow': isNarrow }]">
      <BaseItemButton
        v-for="vendor in filteredVendors"
        :extraClass="!isNarrow ? 'vendorsTab__item' : 'vendorsTab__item vendorsTab__item--narrow'"
        :key="vendor"
        :title="vendor"
        :isDisabled="!itemSwitchingEnabled"
        :itemId="vendor"
        :isActive="selectedItemId === vendor"
        @handle-click="value => handleVendorSelection(value)"
      ></BaseItemButton>
    </ul>
  </div>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseSearch from '@bc/BaseSearch';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VendorsTab',
  components: {
    BaseItemButton,
    BaseSearch
  },
  props: {
    /**
     * This prop should be set to true if the vendors tab takes up 2/3
     * or less of the page width (on desktop)
     */
    isNarrow: { type: Boolean, default: false },
    /**
     * If set to false disable all the actions
     */
    itemSwitchingEnabled: { type: Boolean, default: true }
  },

  data() {
    return {
      filteredVendors: this.$store.getters.shopVendors
    };
  },

  computed: {
    ...mapGetters(['selectedItemId', 'shopVendors'])
  },

  methods: {
    ...mapActions(['set']),
    handleVendorSelection(itemId) {
      if (this.selectedItemId === itemId) {
        this.setItemDataLoaded(true);
        return;
      }
      if (this.itemSwitchingEnabled && this.selectedItemId !== itemId) {
        this.setItemDataLoaded(false);
        this.set(['selectedItemId', itemId]);
        this.set(['currentVendor', itemId]);
      }
    },
    handleSearch(value) {
      this.filteredVendors = this.shopVendors.filter(vendor => vendor.toLowerCase().startsWith(value.toLowerCase()));
    }
  },
  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded']
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.vendorsTab {
  &__results {
    list-style: none;
    @include flex-parent-space-between;
    flex-wrap: wrap;

    &--narrow {
      flex-direction: column;
    }
  }

  &__tools {
    width: 50%;
    @include flex-parent-space-between;
    margin-bottom: 2rem;

    &--narrow {
      width: 100%;
    }
  }

  &__item {
    margin-bottom: 1.5rem;
    width: 48%;
    &--narrow {
      width: 100%;
    }
  }
}
</style>
