export const profileHP = [
  '32 32.00058',
  `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="Profile_HP" fill-rule="nonzero">
    <g id="Orteat-OMS-Account_HP">
      <rect id="Rectangle" fill="#27AE60" x="0" y="0" width="32" height="32" rx="6"></rect>
      <path d="M0,6 L0,12.85 C3.08264193,15.309084 6.92737863,16.614243 10.87,16.54 C19,16.54 25.5,8.27 26.64,0 L6,0 C2.6862915,0 0,2.6862915 0,6 Z" id="Path" fill="#7BE5A2" opacity="0.6"></path>
      <path
        d="M24,10 L17.21,6.17 C16.4607008,5.73976076 15.5392992,5.73976076 14.79,6.17 L8,10 C7.25152707,10.4297452 6.79,11.2269283 6.79,12.09 L6.79,19.83 C6.76066675,20.7215203 7.22616065,21.5563317 8,22 L14.77,25.87 C15.520372,26.2961972 16.439628,26.2961972 17.19,25.87 L24,22 C24.7484729,21.5702548 25.21,20.7730717 25.21,19.91 L25.21,12.13 C25.2244948,11.252668 24.7610124,10.4367857 24,10 Z"
        id="Path"
        fill="#27AE60"
      ></path>
      <path
        d="M24.25,9.84 L17.25,5.84 C16.477619,5.38925117 15.522381,5.38925117 14.75,5.84 L7.75,9.84 C6.97806178,10.2856763 6.50180641,11.1086456 6.5,12 L6.5,20 C6.50180641,20.8913544 6.97806178,21.7143237 7.75,22.16 L14.75,26.16 C15.5218076,26.6128639 16.4781924,26.6128639 17.25,26.16 L24.25,22.16 C25.0219382,21.7143237 25.4981936,20.8913544 25.5,20 L25.5,12 C25.4981936,11.1086456 25.0219382,10.2856763 24.25,9.84 L24.25,9.84 Z M15.25,6.7 C15.7146477,6.43409653 16.2853523,6.43409653 16.75,6.7 L23.75,10.7 C23.82,10.7 23.87,10.8 23.93,10.84 L20.51,12.84 L12.51,8.25 L15.25,6.7 Z M11.55,8.82 L19.55,13.4 L16.04,15.4 L8.07,10.84 C8.13,10.84 8.18,10.74 8.25,10.7 L11.55,8.82 Z M8.25,21.3 C7.78858021,21.0289117 7.50371647,20.5351479 7.5,20 L7.5,12 C7.49027142,11.8968956 7.49027142,11.7931044 7.5,11.69 L15.5,16.3 L15.5,25.4 L15.25,25.3 L8.25,21.3 Z M24.5,20 C24.4962835,20.5351479 24.2114198,21.0289117 23.75,21.3 L16.75,25.3 L16.5,25.4 L16.5,16.3 L24.5,11.69 C24.5097286,11.7931044 24.5097286,11.8968956 24.5,12 L24.5,20 Z"
        id="Shape"
        fill="#7BE5A2"
      ></path>
    </g>
  </g> </g
>`
];
