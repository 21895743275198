export const prodotti = [
  '24 24',
  `
<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Icon/Prodotti-e-produttori">
        <g id="package" transform="translate(3.000000, 2.000000)"  fill="currentColor">
            <path d="M3.63371828,1.7083934 C3.8899084,1.26413309 4.43547586,1.09082927 4.89516816,1.28923683 L4.99955579,1.34166748 L13.9995558,6.53166748 C14.47799,6.80756452 14.6421788,7.4190708 14.3662817,7.89750498 C14.1100916,8.34176529 13.5645241,8.51506912 13.1048318,8.31666156 L13.0004442,8.26423091 L4.00044421,3.07423091 C3.52201003,2.79833386 3.35782124,2.18682758 3.63371828,1.7083934 Z" id="Path"></path>
            <path d="M7.5,-0.598076211 L0.5,3.40192348 C-0.427251593,3.93727199 -0.998901251,4.9262259 -1,5.99692348 L-1,13.9979492 C-0.998901251,15.0696725 -0.427251593,16.0586264 0.5,16.5939749 L7.50386106,20.5961923 C8.42820323,21.129873 9.57179677,21.129873 10.5,20.5939746 L17.4961389,16.5961923 C18.4272516,16.0586264 18.9989013,15.0696725 19,13.9989749 L19,5.99794919 C18.9989013,4.9262259 18.4272516,3.93727199 17.5,3.40192348 L10.4961389,-0.60029395 C9.57179677,-1.1339746 8.42820323,-1.1339746 7.5,-0.598076211 Z M9.49999947,1.1339746 L16.5038611,5.13619233 C16.8090846,5.3124244 16.9996345,5.64207571 16.9999995,5.9989749 L16.9999995,13.9979492 C16.9996345,14.3538227 16.8090846,14.683474 16.4999995,14.8619235 L9.50386106,18.8597061 C9.19059892,19.0405566 8.80940108,19.0405566 8.49999947,18.8619238 L1.49613894,14.8597061 C1.19091543,14.683474 1.00036555,14.3538227 0.999999474,13.9969235 L0.999999474,5.99794919 C1.00036555,5.64207571 1.19091543,5.3124244 1.49613894,5.13619233 L8.49613894,1.13619233 C8.80940108,0.955341801 9.19059892,0.955341801 9.49999947,1.1339746 Z" id="Path"></path>
            <path d="M17.2292764,4.09234194 C17.7073381,3.81579995 18.3190653,3.97916395 18.5956073,4.45722564 C18.8523962,4.90114006 18.7298785,5.46030684 18.3283029,5.75933793 L18.2307236,5.82355645 L9.50072356,10.8735564 C9.22967601,11.030348 8.90339533,11.0499469 8.6185395,10.9323533 L8.49927644,10.8735564 L-0.230723556,5.82355645 C-0.708785242,5.54701446 -0.872149239,4.93528732 -0.595607256,4.45722564 C-0.338818271,4.01331121 0.206982406,3.84074327 0.66640673,4.03977057 L0.770723556,4.09234194 L9,8.852 L17.2292764,4.09234194 Z" id="Path"></path>
            <path d="M9,8.99794919 C9.51283584,8.99794919 9.93550716,9.38398938 9.99327227,9.88132807 L10,9.99794919 L10,20.0779492 C10,20.6302339 9.55228475,21.0779492 9,21.0779492 C8.48716416,21.0779492 8.06449284,20.691909 8.00672773,20.1945703 L8,20.0779492 L8,9.99794919 C8,9.44566444 8.44771525,8.99794919 9,8.99794919 Z" id="Path"></path>
        </g>
    </g>
</g>
`
];
