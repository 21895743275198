export const fetchMoreCollectionsMixin = {
  methods: {
    async fetchMoreCollections(lastCursor) {
      await this.sleep(2000);
      this.$apollo.queries.collections.fetchMore({
        variables: {
          namespace: 'collection_info',
          key: 'type',
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.collections.edges.length === 0) {
            this.collectionsFullyLoaded = true;
            return previousResult;
          } else {
            this.collectionsFullyLoaded = !fetchMoreResult.collections.pageInfo.hasNextPage;
          }

          const newCollections = fetchMoreResult.collections;
          newCollections.edges = [...previousResult.collections.edges, ...newCollections.edges];

          return {
            collections: newCollections
          };
        }
      });
    }
  }
};

export const collectionsWatchMixin = {
  watch: {
    collections(val) {
      if (val && val.edges) {
        let results = [];
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          const lastCursor = val.edges[val.edges.length - 1].cursor;
          this.fetchMoreCollections(lastCursor);
        } else {
          if (val.edges.length === 0) {
            this.collectionsFullyLoaded = true;
          }

          val.edges.forEach(collection => {
            // Select only collections containing products (exclude farmers)
            if (
              collection.node.collectionType &&
              (collection.node.collectionType.value === 'products' || collection.node.collectionType.value === 'subscriptions') &&
              collection.node.productsCount > 0
            ) {
              results = [
                ...results,
                {
                  title: collection.node.title,
                  id: collection.node.id,
                  productsCount: collection.node.productsCount
                }
              ];
            }
          });
          this.collectionsFullyLoaded = true;
          this.collectionsData = results;
        }
      }
    }
  }
};
