const boxTag = 'cassetta';

export const generateBoxesSalesMixin = {
  methods: {
    generateBoxesSalesForExcels(orders) {
      // Calculate boxes sales and generate the data for the excels
      // Find shop products that are boxes
      const boxes = this.shopProducts.filter(product => product.tag && product.tags.includes(boxTag));

      const boxesProductsStats = boxes.map(box => {
        let boxProducts = [];

        orders.edges
          .filter(order => order.node.tags.includes(box.id))
          .forEach(filteredOrder => {
            const boxLineItems = filteredOrder.node.lineItems.edges.filter(lineItem => lineItem.node.product && lineItem.node.product.id === box.id && lineItem.node.currentQuantity > 0);
            boxLineItems.forEach(boxLineItem => {
              // Get box variant
              const boxVariantId = (boxLineItem.node && boxLineItem.node.variant && boxLineItem.node.variant.id) || '';
              // Get variant content
              const boxVariant = box.variants ? box.variants.find(variant => variant.id === boxVariantId) : '';
              const boxVariantContent = boxVariant ? boxVariant.content : [];

              // Add products and its sales
              if (boxVariantContent.length !== 0) {
                boxVariantContent.forEach(variantProduct => {
                  const existingProduct = boxProducts.find(product => product.id === variantProduct.productId);
                  if (existingProduct) {
                    existingProduct.totalKgSold += variantProduct.quantityInKg * boxLineItem.node.currentQuantity;
                  } else {
                    boxProducts = [
                      ...boxProducts,
                      {
                        id: variantProduct.productId,
                        title: variantProduct.productName,
                        totalKgSold: variantProduct.quantityInKg * boxLineItem.node.currentQuantity
                      }
                    ];
                  }
                });
              }
            });
          });
        return {
          ...box,
          boxProducts
        };
      });

      this.boxesProductsStats = boxesProductsStats;
    }
  }
};
