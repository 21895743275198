<template>
  <div class="vendorOrders">
    <div class="vendorOrders__heading">
      <h6 class="vendorOrders__vendor" @click="showVendorProfile">{{ vendor }}</h6>

      <div class="vendorOrders__arrow" @click="toggleDetails">
        <span class="vendorOrders__id">{{ orders.length }} {{ orders.length === 1 ? 'ordine' : 'ordini' }}</span>
        <BaseIcon v-if="collapse" icon="icon-arrow-up" extraClass="vendorOrders__icon"></BaseIcon>
        <BaseIcon v-else icon="icon-arrow-down" extraClass="vendorOrders__icon"></BaseIcon>
      </div>
    </div>

    <CCollapse :show="collapse">
      <div class="vendorOrders__container">
        <TableOrders :orders="orders" :columns="['col', 'col', 'col', 'col', 'col', 'col']" :name="name"></TableOrders>
      </div>
    </CCollapse>
  </div>
</template>

<script>
/**
 * This component loads a list of a vendor's orders
 *
 * @displayName AdminVendorOrders
 */
import { mapGetters, mapActions } from 'vuex';
import { CCollapse } from '@coreui/vue';
import BaseIcon from '@bc/BaseIcon';
import TableOrders from '@c/common/TableOrders';

export default {
  name: 'AdminVendorOrders',
  components: {
    CCollapse,
    BaseIcon,
    TableOrders
  },
  props: {
    /**
     * This prop is used to pass the vendor's orders.
     * Each order has is an object with the following values: id, vendor,
     * date, nrOfProducts, status, paymentStatus
     */
    orders: { type: Array },
    /**
     * This prop is used to pass vendor's name
     */
    vendor: { type: String },
    /**
     * This prop is used to pass the name of the group of checkboxes (orders)
     */
    name: { type: String }
  },

  data() {
    return {
      collapse: false,
      innerCollapse: false
    };
  },

  computed: {
    ...mapGetters(['currentSetOfOrders'])
  },

  methods: {
    ...mapActions(['set', 'hideOrderDetails', 'hideVendorOrdersList', 'showVendorOrdersList']),
    showVendorProfile() {
      this.showVendorOrdersList(this.vendor);
    },
    goBack() {
      this.hideVendorOrdersList();
      this.hideOrderDetails();
    },
    toggleDetails() {
      if (!this.collapse) {
        // set currentSetOfOrders to orders
        const previousOrders = this.currentSetOfOrders;
        this.set(['currentSetOfOrders', [...previousOrders, ...this.orders]]);
        this.collapse = true;
      } else {
        // remove vendor's orders from currentSetOfOrders
        const filteredSetOfOrders = this.$store.getters.currentSetOfOrders.filter(order => order.vendor !== this.vendor);

        // Find index of the first and the last order
        this.collapse = false;

        if (filteredSetOfOrders.length === 0) this.hideOrderDetails();
        this.set(['currentSetOfOrders', filteredSetOfOrders]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.vendorOrders {
  padding: 0.7rem 0;

  &:not(:last-child) {
    border-bottom: 2px solid $color-grey-light;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    border: 1px solid map-get($theme-colors, 'primary');
    display: inline-block;
    padding: 0.5rem 0.7rem;
  }

  &__vendor {
    background-color: $color-grey-light;
    border-radius: 3px;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    transition: background-color 0.5s;
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    color: $color-dark-blue;
    margin: 0;
    line-height: 1.5;
    display: inline-block;

    @include respond('tab-port') {
      font-size: 1.75rem;
    }
  }

  &__vendor:hover {
    background-color: $color-blue-light;
  }

  &__id {
    font-size: 1.4rem;
    color: $color-blue-light;
    margin-right: 1rem;
  }

  &__arrow {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__icon {
    height: 1.4rem;
    width: 1.4rem;
  }

  &__container {
    overflow-x: auto;
    padding-top: 2rem;
  }
}
</style>
