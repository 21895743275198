<template>
  <div :class="['bar', extraClass]" :style="extraStyle">
    <h4 class="bar__title">{{ steps[active].title }}</h4>
    <ul v-if="steps.length > 1" class="bar__list">
      <li
        v-for="(step, index) in steps"
        :key="index"
        :class="['bar__step', { 'bar__step--completed': index <= active && !step.disabled }, { 'bar__step--clickable': step.completed && !step.disabled }]"
        @click="handleClick(index)"
      >
        <span v-if="index !== steps.length - 1">{{ index + 1 }}</span> <BaseIcon v-else extraClass="bar__icon" icon="icon-checkmark"></BaseIcon>
      </li>
    </ul>
  </div>
</template>
<script>
/**
 * This component loads a top bar on which we can see the
 *
 * @displayName BaseProgressBar
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseProgressBar',
  props: {
    /**
     * This prop is used to pass an array of steps to complete
     * Each step is an object containg the step's title and information about
     * the status of the step - if it has been completed or not
     */
    steps: { type: Array, required: true },
    /**
     * This prop is used to pass the index of active step
     */
    active: { type: Number, default: 0 },
    /**
     * This prop is used to pass an extra class that should be applied to the search input
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  components: {
    BaseIcon
  },
  methods: {
    handleClick(index) {
      if (this.steps[index].completed) {
        this.$emit('select-step', index);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_mixins.scss';
@import '@s/_variables.scss';
@import '@s/_functions.scss';

.bar {
  width: 100%;
  font-family: 'Apercu Pro Bold', sans-serif;
  font-weight: 700;
  @include flex-parent-space-between;

  @include respond('tab-port') {
    flex-direction: column-reverse;
  }

  &__title {
    @include default-font-size;
    color: $color-white;
    background-color: $color-dark-blue;
    flex-grow: 1;
    padding: 0.8rem;
    border-radius: 4px;

    @include respond('tab-port') {
      width: 100%;
      margin-top: calculateMobRem(26px);
      padding: calculateMobRem(8px);
    }
  }

  &__list {
    list-style: none;
    display: flex;
    margin-left: 2.5rem;
    margin-bottom: 0;
  }

  &__step {
    background-color: rgba($color-dark-blue, 0.3);
    border-radius: 50%;
    height: 2.6rem;
    width: 2.6rem;
    color: $color-white;
    @include small-font-size;
    @include flex-parent-center-childs;
    margin-left: 0.5rem;

    @include respond('tab-port') {
      height: calculateMobRem(26px);
      width: calculateMobRem(26px);
    }

    &--completed {
      background-color: $color-dark-blue;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__icon {
    color: $color-white !important;
    height: 1.4rem;
    width: 1.4rem;
  }
}
</style>
<docs>
### BaseTopBar  
```js
<BaseProgressBar title="Inserimento lotto" :steps: [
        { title: 'Selezione del produttore', completed: true },
        { title: 'Dettagli prodotti', completed: true },
        { title: 'Collegamento agli ordini ricevuti', completed: false },
        { title: 'Ordini pronti per la spedizione', completed: false }
      ], :active="2"></BaseProgressBar>  
```
 
 
</docs>
