<template>
  <div :class="['pagination', extraClass]" :style="extraStyle">
    <p v-if="currentPage > 1" :class="['pagination__back', { 'pagination__back--disabled': disablePreviousPage }]" @click="$emit('go-back-fn')">
      <BaseIcon icon="icon-chevron-left"></BaseIcon>Indietro
    </p>
    <p v-if="currentPage < pages" @click="$emit('go-next-fn')" :class="['pagination__next', { 'pagination__next--enabled': enableNextPage }]">
      <span v-if="!isLoading"> Avanti <BaseIcon extraClass="pagination__icon" icon="icon-arrow-right"></BaseIcon></span>
      <BaseIcon v-else extraClass="pagination__icon pagination__icon--loading" icon="icon-spinner"></BaseIcon>
    </p>
    <p v-if="currentPage === pages" @click="$router.push({ path: '/' })" class="pagination__next pagination__next--enabled">Fine</p>
  </div>
</template>
<script>
/**
 * This component loads a pagination buttons
 *
 * @displayName BasePagination
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BasePagination',
  props: {
    /**
     * This prop is used to pass the number of pages
     */
    pages: { type: Number, required: true },
    /**
     * This prop is used to pass the number of current page
     */
    currentPage: { type: Number, default: 1 },
    /**
     * If set to true the next page button will be enabled
     */
    enableNextPage: { type: Boolean, default: false },
    /**
     * If set to true the next page button will be enabled
     */
    disablePreviousPage: { type: Boolean, default: false },
    /**
     * This prop is used to handle the next page button loading state
     */
    isLoading: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the search input
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  components: {
    BaseIcon
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_animations.scss';
@import '@s/_mixins.scss';
@import '@s/_variables.scss';

.pagination {
  width: 100%;
  @include flex-parent-space-between;
  @include small-font-size;
  font-family: 'Apercu Pro Bold', sans-serif;
  font-weight: 700;

  &__back {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    @include small-font-size;
    transition: all 0.5s;

    &:hover {
      color: map-get($theme-colors, 'primary-900');
    }

    &--disabled {
      color: $color-grey;
      cursor: not-allowed;

      &:hover {
        color: $color-grey;
      }
    }
  }

  &__next {
    background-color: $color-blue-light;
    color: $color-white;
    padding: 1.4rem 0;
    border-radius: 4px;
    margin-bottom: 0;
    margin-left: auto;
    @include small-font-size;
    @include bold-text;
    align-self: flex-end;
    width: 9.5rem;
    text-align: center;

    &--enabled {
      background-color: $color-primary;
      transition: all 0.5s;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        background-color: map-get($theme-colors, 'primary-900');
      }
    }
  }

  &__icon {
    height: 1.4rem;
    width: 1.4rem;

    &--loading {
      animation: rotate 1s linear infinite;
    }
  }
}
</style>
<docs>
### BasePagination
```js
<BasePagination></BasePagination>  
```
 
 
</docs>
