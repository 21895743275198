<template>
  <form
    :class="['search', extraClass]"
    :style="extraStyle"
    @submit.prevent
    @click="openOptions"
    v-closable="{
      handler: 'closeOptions'
    }"
  >
    <input class="search__input" type="text" :placeholder="placeholder" v-model="searchQuery" @keyup.enter="e => submitForm(e.target.value)" />
    <BaseIcon @handle-click="submitForm(searchQuery)" icon="icon-search-icon" extraClass="search__icon"></BaseIcon>

    <div v-if="searchOptions && optionsDisplayed" class="search__radioGroup">
      <h4 class="search__ricercaPer">Ricerca per:</h4>
      <div v-for="(option, index) in searchOptions" :key="index" class="search__option">
        <input type="radio" :id="option" class="search__radioInput" :value="option" name="searchOption" v-model="selectedSearchOption" />
        <label :for="option" class="search__label"><span class="search__radioBtn"></span>{{ option }}</label>
      </div>
    </div>
  </form>
</template>
<script>
import BaseIcon from './BaseIcon';
/**
 * This component loads a search input field
 *
 * @displayName BaseSearch
 */
export default {
  name: 'BaseSearch',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass an array of search options (ex search by product name, order id etc)
     */
    searchOptions: { type: Array },
    /**
     * This prop is used to pass an extra class that should be applied to the search input
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      searchQuery: '',
      selectedSearchOption: [],
      optionsDisplayed: false,
      placeholder: 'Cerca'
    };
  },
  computed: {
    expandedPlaceholder() {
      const optionsString = this.searchOptions && this.searchOptions.length > 0 ? this.searchOptions.map(option => option).join(', ') : '';
      return optionsString ? `Cerca per ${optionsString}...` : 'Cerca';
    }
  },

  methods: {
    openOptions() {
      if (!this.optionsDisplayed) {
        this.optionsDisplayed = true;
        this.placeholder = this.expandedPlaceholder;
        this.$emit('handle-collapse-btns', true);
      }
    },
    closeOptions() {
      if (this.optionsDisplayed) {
        this.optionsDisplayed = false;
        this.placeholder = 'Cerca';
        this.$emit('handle-collapse-btns', false);
      }
    },
    submitForm(query) {
      // If there are search options, make sure the user selected one
      if (this.searchOptions && this.selectedSearchOption.length === 0) {
        // eslint-disable-next-line quotes
        this.$store.commit('addAlert', { msg: "Seleziona l'opzione di ricerca." });
        this.optionsDisplayed = true;
      } else {
        this.$emit('search-data', query, this.selectedSearchOption || '');
        this.optionsDisplayed = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.search {
  position: relative;
  flex-grow: 1;

  @include respond('tab-port') {
    margin-right: 0;
    align-self: stretch;
  }

  &__input {
    @include default-font-size;
    font-family: inherit;
    color: $color-text;
    padding: 0.5rem 1rem;
    background-color: $color-white;
    outline: none;
    border: 2px solid $color-grey-light;
    border-radius: 3px;
    width: 100%;
    transition: all 0.3s;

    @include respond('tab-port') {
      padding: 1.45rem 1.67rem;
    }

    @include respond('tab-port') {
      padding: 1.625rem 1.875rem;
    }

    &:focus {
      outline: none;
      border: 1px solid $color-primary;
    }

    &::-webkit-input-placeholder {
      color: $color-blue-light;
      @include default-font-size;
    }
  }

  &__icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0.2rem;
    color: $color-blue-light;
    z-index: 10;
    cursor: pointer;
  }

  &__input:focus ~ &__icon {
    color: $color-primary;
  }

  &__radioGroup {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 2px;
    box-shadow: $primary-shadow;
    padding: 1rem;
    color: $color-dark-blue;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 30;

    @include respond('tab-port') {
      top: 6.5rem;
    }
  }

  &__ricercaPer {
    @include bold-text;
    @include small-font-size;
    color: $color-dark-blue;
  }

  &__label {
    font-family: inherit;
    @include small-font-size;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-dark-blue;

    @include respond('tab-land') {
      margin-right: 2rem;
    }

    @include respond('tab-port') {
      margin-right: 2.5rem;
    }
  }

  &__radioBtn {
    height: 1.6rem;
    width: 1.6rem;
    flex-shrink: 0;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 1.2rem;
    @include respond('tab-port') {
      height: calculateModRem(16px);
      width: calculateModRem(16px);
    }
    @include respond('phone') {
      margin-right: 1rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 0.8rem;
      width: 0.8rem;
      background-color: $color-dark-blue;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: calculateModRem(8px);
        width: calculateModRem(8px);
      }
    }
  }
  &__radioInput {
    display: none;
  }

  &__radioInput:checked ~ &__label &__radioBtn::after {
    opacity: 1;
  }
}
</style>
<docs>
### BaseSearch simple
```js
<BaseSearch></BaseSearch>
```
 ### BaseSearch with options
```js
<BaseSearch :searchOptions="['option 1', 'option 2', 'option 3']"></BaseSearch>
```
 
</docs>
