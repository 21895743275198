<template>
  <div>
    <div class="base-modal__backdrop" @click="$emit('close-fn')"></div>
    <div :class="['base-modal', { 'base-modal--high': customCta }]">
      <h2 class="base-modal__heading">{{ heading }}</h2>
      <BaseSelect
        v-if="dropdownOptions && !hideDropdownSelection"
        :options="dropdownOptions"
        isBig
        :isDisabled="disableDropdownSelection"
        extraClass="base-modal__dropdown"
        :label="dropdownLabel"
        :selected="dropdownSelectedOption"
        :defaultDisabledOption="dropdownDefaultOption"
        @handle-change="handleDropdownSelection"
      ></BaseSelect>
      <p class="base-modal__text">
        <slot></slot>
      </p>
      <div v-if="!customCta" :class="['base-modal__buttons', { 'base-modal__buttons--column-reverse': columnBtns }]">
        <button :class="['base-modal__button', { 'base-modal__button--outline': !columnBtns, 'base-modal__button--text': columnBtns }]" @click="$emit('close-fn')">
          {{ cancelTxt }}
        </button>
        <button class="base-modal__button" @click="$emit('confirm-fn')">
          <BaseIcon v-if="isLoading" icon="icon-spinner" :extraClass="iconClasses"></BaseIcon>
          {{ confirmTxt }}
        </button>
      </div>
      <div v-else class="base-modal__buttons base-modal__buttons--column">
        <button class="base-modal__button u-margin-bottom-mini" @click="$emit('confirm-fn', dropdownSelectedOption)">
          <BaseIcon v-if="isLoading" icon="icon-spinner" :extraClass="iconClasses"></BaseIcon>
          {{ confirmTxt }}
        </button>
        <button class="base-modal__button base-modal__button--outline u-margin-bottom-mini" @click="$emit('custom-fn', dropdownSelectedOption)">
          {{ customCta }}
        </button>
        <button class="base-modal__button base-modal__button--text" @click="$emit('close-fn')">
          {{ cancelTxt }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@bc/BaseIcon';
import BaseSelect from '@bc/BaseSelect';
/**
 * This component loads a modal with black, blurred background.
 * The modal has a heading, text and two buttons (one to confirm the action and one to cancel it)
 *
 * @displayName BaseModal
 */

export default {
  name: 'BaseModal',
  components: {
    BaseIcon,
    BaseSelect
  },
  props: {
    /**
     * This prop is used to pass the heading of the modal
     */
    heading: { type: String, required: true },
    /**
     * This prop is used to pass the text of the button used to cancel the action
     */
    cancelTxt: { type: String, default: 'Annulla' },
    /**
     * This prop is used to pass the text of the button used to confirm the action
     */
    confirmTxt: { type: String, default: 'Conferma' },
    /**
     * This prop is used to pass the text of the button used to perform a custom action
     */
    customCta: { type: String },
    /**
     * If set to true, show button loading spinner
     */
    isLoading: { type: Boolean, default: false },
    /**
     * This prop is used to pass the label of the modal's dropdown
     */
    dropdownLabel: { type: String },
    /**
     * This prop is used to pass the dropdown's options
     */
    dropdownOptions: { type: Array },
    /**
     * This prop is used to pass the dropdown's options
     */
    dropdownDefaultOption: { type: String, default: '' },
    /**
     * If set to true, the dropdown will be visible but disabled
     */
    disableDropdownSelection: { type: Boolean, default: false },
    /**
     * If set to true, the dropdown will be hidden
     */
    hideDropdownSelection: { type: Boolean, default: false },
    /**
     * If set to true, the buttons will be placed one below the other
     */
    columnBtns: { type: Boolean, default: false }
  },
  data() {
    return {
      dropdownSelectedOption: this.dropdownDefaultOption
    };
  },
  computed: {
    iconClasses() {
      let classes = ['base-modal__icon'];

      if (this.isLoading) {
        classes = [classes, 'base-modal__icon--loading'];
      }

      return classes.join(' ');
    }
  },
  methods: {
    handleDropdownSelection(value) {
      this.dropdownSelectedOption = value;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';
@import '@s/_animations.scss';

.base-modal {
  flex-direction: column;
  background-color: $color-white;
  border-radius: 8px;
  border: none;
  box-shadow: $primary-shadow;
  display: flex;
  left: 50%;
  min-height: 39rem;
  padding: 4rem 4.2rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%) rotateY(0);
  width: 57rem;
  z-index: 700;

  @include respond('phone') {
    padding-top: 15rem;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }

  &--high {
    min-height: 49rem;
  }

  &__backdrop {
    height: 100vh;
    width: 100vw;
    background-color: rgba($color-bg-blur, 0.4);
    backdrop-filter: blur(1px);
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 5.6rem;
    z-index: 600;
    animation: fadeIn 0.5s linear;
  }
  &__button {
    background-color: $color-dark-blue;
    border-radius: 3px;
    border: 2px solid $color-dark-blue;
    color: $color-white;
    min-width: 17rem;
    outline: none;
    padding: 1rem 1.5rem;
    transition: all 0.5s;
    @include bold-text;
    @include respond('miniphone') {
      margin-top: 2rem;
    }

    &:hover,
    &:focus {
      background-color: $color-dark-blue-2;
    }

    &:not(:last-child) {
      margin-right: 2rem;

      @include respond('miniphone') {
        margin-right: 0;
      }
    }

    &--outline {
      color: $color-dark-blue;
      background-color: $color-white;

      &:hover,
      &:focus {
        color: $color-dark-blue-2;
        background-color: $color-white;
      }
    }

    &--text {
      color: $color-dark-blue;
      background-color: transparent;
      border: none;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $color-dark-blue-2;
        background-color: transparent;
      }
    }
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;

    @include respond('miniphone') {
      flex-direction: column-reverse;
    }

    &--column {
      flex-direction: column;
    }

    &--column-reverse {
      flex-direction: column-reverse;
    }
  }

  &__buttons--column &__button,
  &__buttons--column-reverse &__button {
    margin-right: 0;
  }

  &__buttons--column-reverse &__button:not(:last-child) {
    margin-top: 2rem;
  }

  &__dropdown {
    margin-bottom: 4rem;
  }

  &__heading {
    font-size: 2.4rem;
    color: $color-dark-blue;
    margin-bottom: 2.3rem;
    @include bold-text;
    @include respond('tab-land') {
      font-size: calculateTabLanRem(24px);
      margin-bottom: calculateTabLanRem(23px);
    }
    @include respond('tab-port') {
      font-size: calculateMobRem(24px);
      margin-bottom: calculateMobRem(23px);
    }
  }

  &__icon {
    fill: currentColor;
    flex-shrink: 0;
    height: 2.2rem !important;
    margin-right: 0.6rem;
    width: 2.2rem !important;

    @include respond('tab-port') {
      margin-right: 0;
    }

    &--noText {
      margin-right: 0;
    }

    &--loading {
      animation: rotate 1s linear infinite;
    }
  }

  &__text {
    font-size: 1.8rem;
    margin-bottom: 3rem;

    @include respond('tab-port') {
      font-size: calculateMobRem(16px);
    }
  }
}
</style>
