export const notification = [
  '24 24',
  `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Icon/Notification"  fill="currentColor">
        <g id="bell" transform="translate(3.000000, 2.000000)">
            <path d="M9,-1 C12.8659932,-1 16,2.13400675 16,6 C16,8.89975389 16.5229652,11.0787755 17.3778956,12.6461479 C17.6641519,13.1709511 17.9644664,13.5813809 18.2572027,13.8903804 L18.3318566,13.9673696 L18.4529102,14.0835294 L18.5322227,14.1513653 L18.5547002,14.1679497 C19.3450079,14.6948215 19.0185515,15.898517 18.1158056,15.9939696 L18,16 L-2.22044605e-16,16 C-0.949831641,16 -1.34589362,14.8173794 -0.647711275,14.2372047 L-0.532222692,14.1513653 L-0.452910205,14.0835294 L-0.331856593,13.9673696 C-0.308473821,13.9438425 -0.283555277,13.918197 -0.257202684,13.8903804 C0.0355336247,13.5813809 0.335848107,13.1709511 0.622104427,12.6461479 C1.4342883,11.1571441 1.94687357,9.11612693 1.99610164,6.42957123 L2,6 C2,2.13400675 5.13400675,-1 9,-1 Z M9,1 C6.3112453,1 4.11818189,3.12230671 4.00461951,5.78311038 L4,6 C4,9.22524611 3.39796518,11.7337245 2.37789557,13.6038521 L2.23967931,13.8493814 L2.148,14 L15.851,14 L15.7603207,13.8493814 L15.6221044,13.6038521 C14.6506096,11.8227782 14.0582846,9.46270131 14.0040756,6.45588194 L14,6 L13.9953805,5.78311038 C13.8818181,3.12230671 11.6887547,1 9,1 Z" id="Path"></path>
            <path d="M9.865,18.4982281 C10.1421209,18.0205018 10.7540456,17.8578791 11.2317719,18.135 C11.7094982,18.4121209 11.8721209,19.0240456 11.595,19.5017719 C11.0583034,20.4269784 10.0696029,20.9964563 9,20.9964563 C7.93039709,20.9964563 6.94169664,20.4269784 6.405,19.5017719 C6.12787905,19.0240456 6.29050183,18.4121209 6.76822814,18.135 C7.21183114,17.8776734 7.77114594,17.9995136 8.07066331,18.4007267 L8.20004357,18.5965394 C8.38746888,18.8464703 8.68308062,18.9964563 9,18.9964563 C9.31691938,18.9964563 9.61253112,18.8464703 9.79995643,18.5965394 L9.865,18.4982281 Z" id="Path"></path>
        </g>
    </g>
</g>`
];
