<template>
  <th
    :class="['filter', extraClass]"
    :style="extraStyle"
    v-closable="{
      handler: 'closeFilters'
    }"
  >
    <div class="filter__details" @click="toggleFilters">
      <p class="filter__name">{{ name }}</p>
      <base-icon v-if="filters" :icon="icon" extraClass="filter__icon"></base-icon>
    </div>

    <ul class="filter__filters" v-if="filtersDisplayed">
      <li v-for="filter in filters" :key="filter.value">
        <input class="filter__input" type="checkbox" :value="filter.value" :checked="filter.isSelected" name="filtersSet" :id="filter.value" @change="handleChange(name, filter.value)" />
        <label class="filter__label" :for="filter.value"><span class="filter__btn"></span>{{ filter.value }}</label>
      </li>
    </ul>
  </th>
</template>
<script>
/**
 * This component loads a filter with an icon or without
 *
 * @displayName BaseFilter
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseFilter',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass a name of the filter
     */
    name: { type: String, required: true },
    /**
     * This prop is used to pass an icon
     */
    icon: { type: String, default: 'icon-filter' },
    /**
     * This prop is used to pass an array of filters
     */
    filters: { type: Array },
    /**
     * This prop is used to pass an array of filters
     */
    isOpen: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the filter
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      filtersDisplayed: this.isOpen
    };
  },
  methods: {
    // if filters array has been passed in props show/hide the list with available filters on icon click
    toggleFilters() {
      if (this.filters && this.filters.length !== 0) {
        this.filtersDisplayed = !this.filtersDisplayed;
      }
    },
    closeFilters() {
      if (this.filters && this.filters.length !== 0) {
        this.filtersDisplayed = false;
      }
    },
    handleChange(name, value) {
      this.$emit('handle-change', name, value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.filter {
  padding: 1rem;
  @include small-font-size;
  color: $color-blue-light;
  position: relative;
  vertical-align: top;
  height: 100%;

  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    height: 90%;
    width: 1px;
    background-color: $color-blue-light;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  &__name {
    margin-right: 1rem;
    vertical-align: top;
    font-weight: 400;
  }

  &__icon {
    color: $color-blue-light;
    transition: color 0.5s;
    display: inline-block;
    flex-shrink: 0;
  }

  &:hover &__icon {
    color: $color-dark-blue;
  }

  &__filters {
    position: absolute;
    z-index: 1;
    top: 3.5rem;
    right: 0;
    background-color: $color-white;
    list-style: none;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: $primary-shadow;
    padding: 1rem;
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-dark-blue;
    align-items: center;
  }

  &__btn {
    height: 1.6rem;
    width: 1.6rem;
    border: 1px solid $color-dark-blue;
    border-radius: 3px;
    margin-right: 1rem;
    @include flex-parent-center-childs;

    &::after {
      content: '';
      display: block;
      height: 1.4rem;
      width: 1.4rem;
      background-image: url('~@svg/checkmark.svg');
      background-size: 85%;
      background-repeat: no-repeat;
      background-position: 2px center;
      opacity: 0;
      transition: opacity 0.2s;
      border-radius: 2px;
    }
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }
}
</style>
<docs>
### BaseFilter simple
```js
  <BaseFilter name="Detaglio pagamento" icon="icon-filter" :filters="['Non pagato', 'Pagato']" ></BaseFilter>
```
 
 
</docs>
