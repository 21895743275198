<template>
  <div class="selection">
    <div class="selection__selected" @click="itemsDisplayed = !itemsDisplayed">
      <BaseIcon icon="icon-package" extraClass="selection__icon"></BaseIcon> <span class="selection__item">{{ currentItemName }}</span>
      <BaseIcon :icon="itemsDisplayed ? 'icon-arrow-up' : 'icon-arrow-down'" :extraClass="itemsDisplayed ? 'selection__arrow' : 'selection__arrow selection__arrow--up'"></BaseIcon>
    </div>
    <ul v-if="itemsDisplayed" class="selection__items">
      <li v-for="item in items" :key="item.id" class="selection__itemsLi" @click="handleClick(item.id)">{{ item.name }}</li>
    </ul>
  </div>
</template>
<script>
/**
 * This component loads a dropdown with the items' names passed in props
 *
 * @displayName BaseItemSelection
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseItemSelection',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass the id of the item that has been selected
     */
    currentItem: { type: String, required: true },
    /**
     * This prop is used to pass an array with all the items. Each item should have a name and an id
     */
    items: { type: Array, required: true }
  },
  data() {
    return {
      itemsDisplayed: false
    };
  },
  computed: {
    currentItemName() {
      return this.items.find(item => item.id === this.currentItem).name;
    }
  },
  methods: {
    // Set current item and close the dropdown
    handleClick(id) {
      this.$emit('handle-selection', id);
      this.itemsDisplayed = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.selection {
  position: relative;
  margin: 1rem 0;

  &__selected {
    @include flex-parent-space-between;
    border: 1px solid $color-dark-blue;
    border-radius: 4px;
    background-color: rgba($color-blue-light, 0.15);
    color: $color-dark-blue;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;
    z-index: 15;
  }

  &__item {
    margin-right: auto;
    font-size: 1.75rem;
    font-family: 'Apercu Pro Bold', sans-serif;
    font-weight: 700;
  }

  &__icon {
    height: 2.7rem;
    width: 2.7rem;
    margin-right: 1.5rem;
  }

  &__arrow {
    display: inline-block;
    color: $color-dark-blue;
    height: 1.3rem;
    width: 1.3rem;
    transform: translateY(0);
    transition: transform 0.3s;
  }

  &:hover &__arrow {
    transform: translateY(-2px);
  }

  &:hover &__arrow--up {
    transform: translateY(2px);
  }

  &__items {
    list-style: none;
    background-color: $color-white;
    box-shadow: $primary-shadow;
    border-radius: 3px;
    padding: 1.5rem 0;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 10;
    width: 100%;
    transform-origin: top;
    animation: expand 0.3s;
  }

  &__itemsLi {
    font-size: 1.75rem;
    padding: 1rem 2.5rem;
    color: $color-dark-blue;
    transition: background-color 0.5s;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-light;
    }
  }
}

@keyframes expand {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
</style>

<docs>
### BaseItemSelection simple
```js
<BaseItemSelection currentItem="vendor 1" :items="[{name: 'vendor 1', id: '1'}, {name: 'vendor 2', id: '2'}, {name: 'vendor 3', id: '3'}]"></BaseItemSelection>
```
 
 
</docs>
