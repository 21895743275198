<template>
  <div class="home">
    <div class="page-scroll home__col1" v-if="!orderDetailsShown">
      <BaseCard hideMobile fullCard>
        <BaseLoadingSpinner v-if="!chartsFetchingEnabled || $apollo.loading || !fullyLoaded || !productsFullyLoaded || !allDataReady"></BaseLoadingSpinner>
        <div v-else>
          <BaseHeading :level="2" extraStyle="margin-bottom:4rem">Gli ordini della settimana</BaseHeading>

          <div class="home__heading">
            <BaseHeading :level="4" extraStyle="margin-bottom:4.2rem"
              ><span class="home__span">{{ totalNrOfOrders }}</span> ordini ricevuti</BaseHeading
            >
            <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
            <DownloadExcel v-else :data="json_orders_of_the_week" :fields="orders_json_fields" name="ordini-settimana.xls">
              <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
            </DownloadExcel>
          </div>

          <BaseLineChart extraStyle="height: 30rem" :labels="lineChartLabels" :options="lineChartOptions" :datasets="ordiniRicevuti"></BaseLineChart>

          <div style="margin: 4.2rem 0" class="home__heading">
            <BaseHeading :level="4" extraStyle="margin-bottom:1rem;"
              ><span class="home__span">{{ totalProductsSold }} kg</span> di prodotti venduti</BaseHeading
            >
            <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
            <DownloadExcel v-else :data="json_kg_sold_per_product" :header="kg_venduti_excel_header" :fields="kg_json_fields" name="kg-venduti.xls">
              <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
            </DownloadExcel>
          </div>
          <div v-if="!isMobileMode">
            <BaseBarChart extraStyle="height: 30rem; " :labels="productsOrderedLabels" :options="options" :datasets="kgVenduti"></BaseBarChart>
          </div>

          <div v-else>
            <BaseHorizontalBarChart extraStyle="height: 30rem; width: 100vw" :labels="productsOrderedLabels" :options="options" :datasets="kgVenduti"></BaseHorizontalBarChart>
          </div>
        </div>
      </BaseCard>
    </div>
    <div :class="['page-scroll home__col2', { 'u-hidden': orderDetailsShown && isTabMode }]">
      <BaseCard v-if="!vendorOrdersListShown" hideMobile fullCard>
        <BaseHeading :level="2" extraClass="home__title">Lista dei nuovi ordini</BaseHeading>
        <OrdersList :selectedTimePeriod="selectedTimeRange" :orderInputToBeArchived="orderInputToBeArchived" @enable-charts-fetching="chartsFetchingEnabled = true"></OrdersList>
        <div class="home__buttons">
          <BaseButton @handle-click="$router.push('/archivio-ordini')">Tutti gli ordini</BaseButton>
          <BaseButton @handle-click="$router.push('/prodotti-e-produttori')">Prodotti e produttori</BaseButton>
        </div>
      </BaseCard>
      <BaseCard v-else hideMobile fullCard>
        <AdminVendorOrdersList :selectedTimePeriod="selectedTimeRange" :orderInputToBeArchived="orderInputToBeArchived"></AdminVendorOrdersList>
      </BaseCard>
    </div>
    <div class="page-scroll home__col3" v-if="orderDetailsShown">
      <BaseCard hideMobile fullCard>
        <div class="home__heading">
          <BaseHeading :level="2" extraStyle="margin-bottom:4rem">Dettaglio ordine</BaseHeading>
          <p class="home__linkBack" @click="goBack"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
        </div>
        <SwiperOrders v-if="isTabMode"></SwiperOrders>
        <Order v-else :orderId="currentOrder"></Order>
      </BaseCard>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import JsonExcel from 'vue-json-excel';
import BaseCard from '@bc/BaseCard';
import BaseBarChart from '@bc/BaseBarChart';
import BaseHorizontalBarChart from '@bc/BaseHorizontalBarChart';
import BaseLineChart from '@bc/BaseLineChart';
import BaseHeading from '@bc/BaseHeading';
import BaseActionButton from '@bc/BaseActionButton';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import OrdersList from '@c/admin/OrdersList';
import AdminVendorOrdersList from '@c/admin/AdminVendorOrdersList';
import BaseButton from '@bc/BaseButton.vue';
import BaseIcon from '@bc/BaseIcon';
import SwiperOrders from '@c/common/SwiperOrders.vue';
import Order from '@c/common/Order';
import { generateBoxesSalesMixin } from '@c/mixins/boxesMixins.js';
import { mapGetters, mapActions } from 'vuex';
import { getShopProducts } from '@gq/getShopProducts.gql';
import {
  getDaysOfTheWeek,
  getNumericDate,
  getDatesArr,
  styleDate,
  calculateLastWeekPeriod,
  checkIfJsonIsValid,
  sortItemsFromZtoA,
  getNumericDateOfTheNextDate,
  convertWeightToKg
} from '@u/helperFunctions';
import { getOrdersStatsByTimePeriod } from '@gq/getOrdersStatsByTimePeriod.gql';
Vue.component('DownloadExcel', JsonExcel);

const boxTag = 'cassetta';

export default {
  name: 'HomeAdmin',
  components: {
    BaseCard,
    BaseBarChart,
    BaseHorizontalBarChart,
    BaseLineChart,
    BaseHeading,
    OrdersList,
    Order,
    AdminVendorOrdersList,
    BaseButton,
    SwiperOrders,
    BaseIcon,
    BaseLoadingSpinner,
    BaseActionButton
  },
  mixins: [generateBoxesSalesMixin],

  data() {
    return {
      options: {
        maintainAspectRatio: false
      },
      selectedTimeRange: [],
      kgVenduti: [
        {
          label: 'Kg',
          data: []
        }
      ],

      lineChartOptions: {
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      },
      ordiniRicevuti: [
        {
          label: 'Ordini',
          data: []
        }
      ],
      productsOrderedLabels: [],
      screenWidth: 0,
      fullyLoaded: false,
      productsFullyLoaded: false,
      chartsFetchingEnabled: false,
      allDataReady: false,
      products: {},
      orders: {},
      boxesProductsStats: [],
      orderInputToBeArchived: null,
      sortedProductsKgArrOfObjects: [],

      // EXCELS
      orders_json_fields: {
        Data: 'date',
        'Numero di ordini ricevuti': 'orders'
      },
      kg_json_fields: {
        Prodotto: 'product',
        'Kg venduti': 'kgSold',
        Dettagli: 'content'
      }
    };
  },

  computed: {
    ...mapGetters(['isMobileMode', 'orderDetailsShown', 'isTabMode', 'vendorOrdersListShown', 'currentOrder', 'currentVendor']),
    shopProducts() {
      return this.$store.getters.shopOrderedProducts;
    },
    btnsDisabled() {
      return this.kgVenduti[0].data.length == 0 && this.ordiniRicevuti[0].data.length === 0;
    },
    totalNrOfOrders() {
      let total = 0;

      if (this.ordiniRicevuti[0].data.length !== 0) {
        this.ordiniRicevuti[0].data.forEach(numberOfOrders => (total += numberOfOrders));
      }

      return total;
    },
    totalProductsSold() {
      let total = 0;
      if (this.kgVenduti[0].data.length !== 0) {
        this.kgVenduti[0].data.forEach(kgSold => (total += kgSold));
      }

      return Math.round(total);
    },
    // Get an array of days of the last 7 days
    lineChartLabels() {
      let daysArr = [];
      if (this.selectedTimeRange.length !== 0) {
        daysArr = getDaysOfTheWeek(this.selectedTimeRange);
      }
      return daysArr;
    },

    numericTimeRange() {
      const startDate = getNumericDate(this.selectedTimeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);
      return [startDate, endDate];
    },

    // EXCELS DATA
    json_orders_of_the_week() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.ordiniRicevuti[0].data.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        json = arrOfDays.map((date, index) => {
          return {
            date,
            orders: this.ordiniRicevuti[0].data[index]
          };
        });
      }

      return json;
    },

    kg_venduti_excel_header() {
      let string = '';
      if (this.selectedTimeRange.length !== 0) {
        string = 'Periodo di tempo: ' + styleDate(this.selectedTimeRange[0]) + ' - ' + styleDate(this.selectedTimeRange[1]);
      }
      return string;
    },

    json_kg_sold_per_product() {
      if (this.shopProducts.length === 0 || this.sortedProductsKgArrOfObjects.length === 0 || this.kgVenduti[0].data.length === 0) return [];

      return this.sortedProductsKgArrOfObjects.map(product => {
        let productStatsStr = '';

        // If the product is a box, include its content
        if (product.tags.includes(boxTag)) {
          const productStats = this.boxesProductsStats.find(boxProduct => boxProduct.id === product.id);
          productStatsStr = productStats ? productStats.boxProducts.map(product => product.title + ' - ' + product.totalKgSold).join('\n') : '';
        }

        return {
          product: product.title,
          kgSold: product.kg,
          content: productStatsStr
        };
      });
    }
  },
  apollo: {
    products() {
      return {
        query: getShopProducts,
        skip: true,
        variables: {
          namespace: 'product_info',
          key: 'type',
          boxInfo: 'box_info',
          boxContent: 'content'
        },
        error() {
          this.forceAllDataLoaded();
        }
      };
    },
    orders() {
      return {
        query: getOrdersStatsByTimePeriod,
        variables: {
          queryString: ''
        },
        // Disable the query
        skip: true,
        manual: true,
        result({ data, loading }) {
          if (data && !loading) {
            //this.orders = data.orders;
            if (data.orders.pageInfo.hasNextPage) {
              const lastCursor = data.orders.edges[data.orders.edges.length - 1].cursor;

              this.fetchMoreOrders(lastCursor);
            } else {
              this.orders = data.orders;

              this.fullyLoaded = true;
            }
          }
        },
        error() {
          this.forceAllDataLoaded();
        }
      };
    }
  },
  methods: {
    ...mapActions(['hideVendorOrdersList', 'hideOrderDetails', 'set']),

    goBack() {
      this.hideOrderDetails();
    },
    async fetchMoreOrders(lastCursor) {
      //   const lastCursor = this.orders.edges[this.orders.edges.length - 1].cursor;

      this.$apollo.queries.orders.fetchMore({
        variables: {
          queryString: `created_at:>=${this.numericTimeRange[0]} AND created_at:<=${this.numericTimeRange[1]}`,
          cursor: lastCursor
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.orders.edges.length === 0) {
            this.fullyLoaded = true;
            return previousResult;
          } else {
            this.fullyLoaded = !fetchMoreResult.orders.pageInfo.hasNextPage;
          }

          const newOrders = fetchMoreResult.orders;
          newOrders.edges = [...previousResult.orders.edges, ...newOrders.edges];

          return {
            orders: newOrders
          };
        }
      });
    },

    forceAllDataLoaded() {
      this.orders = {};
      this.fullyLoaded = true;
      this.allDataReady = true;
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.setOptions({ throttle: 2000 });
      this.$apollo.queries.products.fetchMore({
        variables: {
          namespace: 'product_info',
          key: 'type',
          cursor: lastCursor,
          boxInfo: 'box_info',
          boxContent: 'content'
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    },
    async loadProducts() {
      // Load products
      this.$apollo.queries.products.start();
      this.$apollo.queries.products.refetch({
        namespace: 'product_info',
        key: 'type'
      });
    },
    setOrderInputToBeArchived(input) {
      this.orderInputToBeArchived = input;
    }
  },

  watch: {
    chartsFetchingEnabled(val) {
      if (val) {
        this.loadProducts();
      }
    },

    orders(val) {
      if (val && this.fullyLoaded) {
        if (!val.edges || (val.edges && val.edges.length === 0)) {
          this.allDataReady = true;
          return;
        }

        // GET DATA FOR THE FIRST CHART - NR OF ORDERS PER DAY

        // 1. Split orders into arrays with the orders placed in the same day

        const arrOfDays = getDatesArr(this.selectedTimeRange);

        const arrOfDaysWithOrders = arrOfDays.map(date => {
          const result =
            val.edges.filter(item => {
              const numDate = getNumericDate(item.node.createdAt);
              return numDate === date;
            }) || [];
          return result;
        });

        // 2. Create an array with the total number of orders in the selected time period
        const nrOfOrdersArr = arrOfDaysWithOrders.map(dayArr => dayArr.length) || [];

        // GET DATA FOR THE SECOND CHART - KG SOLD PER PRODUCT
        // 1. Get an array of arrays containing orders of one product
        const productOrdersArr = this.shopProducts.map(product => {
          return val.edges.filter(order => order.node.tags.includes(product.id));
        });

        // 2. Create an array with the total weight sold of each product in the selected time period
        const weightArr = productOrdersArr.map((ordersArr, index) => {
          if (ordersArr.length === 0) {
            return 0;
          } else {
            let totalWeight = 0;
            ordersArr.forEach(order => {
              // 5. Filter lineItems by product id and get the sum (= total product earnings)

              order.node.lineItems.edges
                .filter(item => item.node.product && item.node.product.id === this.shopProducts[index].id && item.node.currentQuantity > 0)
                .forEach(filteredLineItem => {
                  let itemWeight = 0;
                  const itemVariant = filteredLineItem.node.variant;
                  if (itemVariant) {
                    itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);
                  }

                  totalWeight += Number(itemWeight) * filteredLineItem.node.currentQuantity;
                });
            });

            return totalWeight;
          }
        });

        const productsKgArrOfObjects = this.shopProducts.map((product, index) => {
          return {
            title: product.title,
            kg: weightArr[index],
            tags: product.tags
          };
        });

        const sortedProductsKgArrOfObjects = sortItemsFromZtoA({ arr: [...productsKgArrOfObjects], key: 'kg' });
        this.sortedProductsKgArrOfObjects = sortedProductsKgArrOfObjects;

        // Remove products with no sales
        const sortedProductsWithSales = sortedProductsKgArrOfObjects.filter(product => product.kg > 0);

        const sortedLabels = sortedProductsWithSales.map(product => product.title);
        this.productsOrderedLabels = sortedLabels;

        const sortedWeightArr = sortedProductsWithSales.map(product => Number(product.kg.toFixed(2)));

        this.kgVenduti = [
          {
            label: 'Kg',
            data: sortedWeightArr
          }
        ];

        this.ordiniRicevuti = [
          {
            label: 'Ordini',
            data: nrOfOrdersArr
          }
        ];

        this.allDataReady = true;

        this.generateBoxesSalesForExcels(val);
      }
    },
    products(val, oldVal) {
      if (val.edges) {
        if (val.edges.length === 0) {
          // If there are no products don't load the orders
          this.productsFullyLoaded = true;
          this.fullyLoaded = true;
        } else {
          this.productsFullyLoaded = false;

          // Check if there is the second page

          if (val.pageInfo.hasNextPage) {
            this.fetchMoreProducts(oldVal);
          } else {
            this.productsFullyLoaded = true;
          }

          let results = [];

          if (this.productsFullyLoaded) {
            val.edges.forEach(product => {
              // Select only collections containing products (exclude farmers)
              if (
                (product.node.type && product.node.type.value === 'product') ||
                (product.node.type && product.node.type.value === 'cassetta') ||
                (product.node.type && product.node.type.value === 'subscription')
              ) {
                results = [
                  ...results,
                  {
                    title: product.node.title,
                    id: product.node.id,
                    vendor: product.node.vendor,
                    tags: product.node.tags,
                    variants: product.node.variants.edges.map(variant => {
                      const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';
                      const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';
                      return {
                        id: variant.node.id,
                        content: jsonVariantContent
                      };
                    })
                  }
                ];
              }
            });

            this.set(['shopOrderedProducts', results]);
            // Load orders
            // When products are loaded, start fetching charts data
            this.$apollo.queries.orders.start();
            this.$apollo.queries.orders.refetch({
              queryString: `created_at:>=${this.numericTimeRange[0]} AND created_at:<=${this.numericTimeRange[1]}`
            });
          }
        }
      }
    }
  },

  provide() {
    return {
      goBack: this.goBack,
      handleArchiveStatus: this.setOrderInputToBeArchived
    };
  },

  mounted() {
    window.addEventListener('load', this.handleScreenWidth);

    window.addEventListener('resize', this.handleScreenWidth);

    this.$store.dispatch('hideOrderDetails');
  },
  created() {
    // Calculate last week period
    const lastWeekTimeRange = calculateLastWeekPeriod();
    // Set selectedTimeRange to the last week
    this.selectedTimeRange = lastWeekTimeRange;
  },
  beforeDestroy() {
    this.hideVendorOrdersList();
    this.hideOrderDetails();
    this.set('currentVendor', '');
    this.set('currentOrder', '');
    this.set('currentSetOfOrders', '');
    this.allDataReady = false;
    this.fullyLoaded = false;
    this.chartsFetchingEnabled = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.home {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2.5rem;

  @include respond('tab-port') {
    flex-direction: column;
    align-items: stretch;
    padding: 11rem 2.4rem 2.4rem 2.4rem;
  }

  @include respond('phone') {
    padding: 11rem 1rem 1rem 1rem;
  }

  &__col1 {
    margin-right: 0.5rem;
    padding: 4.5rem 2rem 4.5rem 2rem;
    text-align: left;
    flex-basis: 45rem;
    flex-grow: 0;
    height: 100vh;
    overflow-y: auto;

    @include respond('tab-port') {
      padding: 0 0 4.5rem 0;
      margin: 0 0 2.4rem 0;
      width: 100%;
      height: fit-content;
      overflow: visible;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      padding: 0 0 4.5rem 0;
      margin: 0 0 2.4rem 0;
      max-width: unset;
      height: fit-content;
    }
  }

  &__col3 {
    margin-left: 0.5rem;
    flex-grow: 0;
    width: 46rem;
    height: 100vh;
    overflow-y: auto;

    padding: 4.5rem 2rem 4.5rem 2rem;

    @include respond('tab-port') {
      margin-left: 0;
      width: 80%;
      align-self: center;
      height: fit-content;
      overflow-y: visible;
      padding: 0 2rem 4.5rem 2rem;
    }

    @include respond('phone') {
      width: 100%;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 4rem;

    @include respond('tab-land') {
      margin-bottom: 2rem;
    }
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }

  &__span {
    font-weight: 700;
    font-size: 3rem;

    @include respond('tab-port') {
      font-size: 3.75rem;
    }
  }

  &__card {
    min-height: 70vh;
    @include respond('tab-port') {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__buttons {
    display: none;
    margin-top: 3rem;
    @include respond('tab-port') {
      @include flex-parent-space-between;
    }
  }
}
</style>
