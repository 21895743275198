<template>
  <tr v-if="!isTabMode" class="lottoItem__row">
    <td class="lottoItem__cell">
      <span class="u-bold-text">{{ item.ddt_number }}</span>
    </td>
    <td class="lottoItem__cell">{{ item.vendor }}</td>
    <td class="lottoItem__cell">{{ item.quantity }} kg</td>
    <td class="lottoItem__cell">{{ item.remainingKg }} kg</td>
    <td class="lottoItem__cell">{{ batchDate }}</td>
    <td class="lottoItem__cell">
      <span v-if="isOpen" class="lottoItem__span" @click="assignRemainingKg(item.id)"
        ><BaseIcon extraClass="lottoItem__icon lottoItem__icon--plus" icon="icon-plus"></BaseIcon>Assegna kg rimanenti</span
      >
      <span v-if="isOpen" class="lottoItem__span" @click="$emit('close-lotto')"><BaseIcon extraClass="lottoItem__icon lottoItem__icon--success" icon="icon-pagato"></BaseIcon>Chiudi</span>
      <span v-if="!isOpen" class="lottoItem__span lottoItem__span--inactive"><BaseIcon extraClass="lottoItem__icon lottoItem__icon--closed" icon="icon-pagato"></BaseIcon>Lotto chiuso</span>
    </td>
  </tr>
  <li v-else class="lottoItem__li">
    <div class="lottoItem__header">
      <BaseHeading :level="6">{{ item.batchNr }}</BaseHeading>
      <span class="lottoItem__date">{{ batchDate }}</span>
    </div>
    <div :class="('lottoItem__info', { 'lottoItem__info--open': isOpen })">
      <p>{{ item.vendor }}, {{ item.quantity }} kg</p>

      <p v-if="isOpen" class="lottoItem__details">{{ item.remainingKg }} kg rimanenti</p>
    </div>
    <div v-if="isOpen" class="lottoItem__buttons">
      <span class="lottoItem__span" @click="assignRemainingKg(item.id)"><BaseIcon extraClass="lottoItem__icon lottoItem__icon--plus" icon="icon-plus"></BaseIcon>Assegna kg rimanenti</span>
      <span class="lottoItem__span" @click="$emit('close-lotto')"><BaseIcon extraClass="lottoItem__icon lottoItem__icon--success" icon="icon-pagato"></BaseIcon>Chiudi</span>
    </div>
  </li>
</template>
<script>
/**
 * This component loads a lotto item
 *
 * @displayName LottoItem
 */

import BaseIcon from '@bc/BaseIcon';
import BaseHeading from '@bc/BaseHeading';
import { mapGetters, mapActions } from 'vuex';
import { styleShortDate, getFullProductId } from '@u/helperFunctions';
import { getBatchDetailsById } from '@gq/getBatchDetailsById.gql';

export default {
  name: 'VendorLottoItem',
  components: {
    BaseIcon,
    BaseHeading
  },
  props: {
    /**
     * This prop is used to pass the lotto object to display it as a table row
     */
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDetails: true,
      batch: {}
    };
  },
  computed: {
    ...mapGetters(['isTabMode']),
    batchDate() {
      return styleShortDate(this.item.ddt_date);
    },
    isOpen() {
      return this.item.status === 'open';
    }
  },
  methods: {
    ...mapActions(['set', 'addRegisteredProduct', 'addRegisteredOrders', 'completeStep', 'updateProductionBatchProperty', 'handleDisableBatchEditing']),
    saveBatchDataToStore() {
      const { collection_date, compartment, province, sheet, current, ddt_date, ddt_number, id, location_name, orders, products } = this.batch;
      this.set(['selectedVendor', this.item.vendor]);
      this.handleDisableBatchEditing(true);
      this.completeStep(1);
      this.completeStep(2);
      this.set(['activeStep', 2]);

      let ordersCombainedIds = [];

      products.forEach(product => {
        const fullId = getFullProductId(id);

        this.addRegisteredProduct([
          this.item.vendor,
          {
            prodotto: fullId,
            id: fullId,
            name: product.name,
            quantita: product.grams / 1000,
            current: product.current / 1000,
            dataDDT: ddt_date,
            dataRaccolta: collection_date,
            numeroDDT: ddt_number,
            provincia: province,
            foglio: sheet,
            comune: location_name,
            particella: compartment
          },
          true
        ]);

        this.updateProductionBatchProperty([ddt_number, 'id', id]);

        // Filter orders containing product and push combained id to the combainedIds array

        const productOrders = [...orders].filter(order => order.lineItems.find(lineItem => lineItem.product_name === product.name));

        productOrders.forEach(productOrder => {
          // Find combined id
          const combainedId = `${fullId}_gid://shopify/Order/${productOrder.id}`;

          // Push id to ordersCombainedIds
          ordersCombainedIds = [...ordersCombainedIds, combainedId];
        });
      });

      this.set(['batchAssociatedOrders', ordersCombainedIds]);
      this.$emit('handle-item-loading', false);
      this.$router.push({ path: '/lotto' });
    },
    async assignRemainingKg(id) {
      this.$emit('handle-item-loading', true);

      this.$apollo.queries.batch.start();
      await this.$apollo.queries.batch.refetch({
        id: id,
        vendor: this.item.vendor
      });
      this.saveBatchDataToStore();
    }
  },
  apollo: {
    batch() {
      return {
        query: getBatchDetailsById,
        skip: true,
        variables: {
          id: this.item.id,
          vendor: this.item.vendor
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

$cell-padding: 0.7rem 1rem 1.4rem 1rem;

// cell padding if clip-path is not supported

$cell-padding-no-clip: 0.7rem;
.lottoItem {
  &__row {
    background-color: $color-grey-light;
    font-family: inherit;
    @include default-font-size;
    color: $color-dark-blue;
    border-bottom: 0.7rem solid $color-white;

    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      clip-path: inset(0 0 0.7rem round 3px);

      border-bottom: none;
    }
  }

  &__cell {
    padding: 1rem;
    vertical-align: middle;
    padding: $cell-padding-no-clip;
    position: relative;
    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      padding: $cell-padding;
    }
  }

  &__cell:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    height: 75%;
    width: 0.5px;
    background-color: $color-dark-blue;

    @include respond('tab-port') {
      display: none;
    }
  }

  &__span {
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s;

    @include respond('tab-port') {
      background-color: $color-white;
      padding: 0.5rem 1rem;
      display: inline-block;
      margin-top: 1rem;
    }

    &:hover {
      text-decoration: underline;
      color: $color-dark-blue-2;
    }

    &--inactive {
      color: $color-blue-light;
      cursor: unset;

      &:hover {
        color: $color-blue-light;
        text-decoration: none;
      }
    }
  }

  &__span:not(:last-child) {
    margin-right: 1rem;
  }

  &__icon {
    color: $color-flame-pea;
    transition: all 0.5s;
    cursor: pointer;
    width: 1.6rem !important;
    margin-right: 0.7rem;
    flex-shrink: 0;

    @include respond('tab-land') {
      width: 1.78rem !important;
    }

    @include respond('tab-port') {
      width: 2rem !important;
    }

    &--plus {
      color: $color-white;
      height: 1.6rem !important;
      background-color: $color-dark-blue;
      padding: 3px;
      border-radius: 50%;

      @include respond('tab-port') {
        height: 2rem !important;
      }
    }

    &--success {
      color: $color-primary;
    }

    &--closed {
      color: $color-blue-light;
      cursor: unset;
    }
  }

  &__span:hover &__icon {
    color: $color-flame-pea-dark;
  }

  &__span:hover &__icon--plus {
    background-color: $color-dark-blue-2;
    color: $color-white;
  }

  &__span:hover &__icon--success {
    color: map-get($theme-colors, 'primary-900');
  }

  &__span:hover &__icon--closed {
    color: $color-blue-light;
  }

  // Mobile

  &__li {
    list-style: none;
    background-color: $color-grey-light;
    border-radius: 3px;
    padding: 1rem 1.375rem;
    margin-bottom: 1.375rem;
  }

  &__header {
    padding-bottom: 1rem;
    @include flex-parent-space-between;
  }

  &__date {
    font-weight: 400;
  }

  &__info {
    @include default-font-size;
    line-height: 2.75rem;
    margin-top: 1rem;

    &--open {
      border-bottom: 1px solid $color-dark-blue;
      padding-bottom: 0.8rem;
    }
  }

  &__buttons {
    padding: 0.25rem 0 1.25rem 0;
  }
}
</style>
