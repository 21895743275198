import { archive } from './Archive.js';
import { hamburger } from './Hamburger.js';
import { home } from './Home.js';
import { logout } from './Logout.js';
import { notification } from './Notification.js';
import { settings } from './Settings.js';
import { prodotti } from './Prodotti';
import { profileHP } from './Profile_HP';
import { profileAMM } from './ProfileAMM.js';
import { logoOrteat } from './logo-orteat-2020.js';

export const iconsSet = Object.assign({}, { logoOrteat, archive, hamburger, home, logout, notification, settings, profileAMM, profileHP, prodotti });
