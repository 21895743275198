<template>
  <div :class="extraClass">
    <button :class="['ddt-btn', { 'ddt-btn--noText': !withTxt }, { 'ddt-btn--disabled': isDisabled }]" @click="printDDT" :style="extraStyle" :disabled="isDisabled">
      <template v-if="progress >= 0">
        <span class="ddt-btn__progress" :style="`width:${progress}%`"></span>
        <span class="ddt-btn__percentage">{{ progress }}%</span>
      </template>
      <BaseIcon v-else-if="btnIcon" :icon="btnIcon" :extraClass="iconClasses"></BaseIcon>
      <span v-if="!isShort" :class="['ddt-btn__span', { 'ddt-btn__span--noText': !withTxt }]"><slot></slot></span>
    </button>
    <Portal v-if="archiveModalDisplayed">
      <BaseModal
        :isLoading="isArchiveModalLoading"
        :confirmTxt="modalConfirmTxt"
        :heading="modalTitle"
        customCta="Continua senza archiviare"
        @close-fn="handleHideArchiveModal"
        @confirm-fn="confirmModalAction"
        @custom-fn="performCustomModalAction"
        dropdownLabel="Selezione del corriere:"
        :dropdownOptions="modalDropdownOptions"
        :dropdownDefaultOption="defaultCourier"
        :hideDropdownSelection="disableCourierSelection"
        >{{ modalTxt }}</BaseModal
      >
    </Portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseIcon from '@bc/BaseIcon';
import BaseModal from '@bc/BaseModal';
import { buttonIconMixin } from '@c/mixins/buttonsMixins.js';
import { downloadFileMixin } from '@c/mixins/downloadMixins.js';
import { ddtGeneratedMixin } from '@c/mixins/ddtMixins.js';
import { checkLabelsMixin } from '@c/mixins/checkLabelsMixins.js';
import { orderCurVendorsMixin } from '@c/mixins/orderCurVendorsMixin.js';
import { Portal } from '@linusborg/vue-simple-portal';
import { getCookie } from '@/util/cookies';
import { getOrdersNumberStringed, createChunks } from '@/util/helperFunctions';
import { getCouriers } from '@gq/getCouriers.gql';

/**
 * This component loads a button used to download ddt file
 * @displayName BaseDdtButton
 */
export default {
  name: 'BaseDdtButton',
  components: {
    BaseIcon,
    Portal,
    BaseModal
  },
  mixins: [buttonIconMixin, downloadFileMixin, ddtGeneratedMixin, orderCurVendorsMixin, checkLabelsMixin],
  props: {
    /**
     * This prop is used to pass an array of the selected orders
     */
    selectedOrders: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to disable the button
     */
    isDisabled: { type: Boolean, default: false },

    /**
     * If set to true, only the button icon will be displayed
     */
    isShort: { type: Boolean, default: false },

    /**
     * If set to true don't show archive modal but directly download DDT
     */
    isArchive: { type: Boolean }
  },
  data() {
    return {
      isLoading: true,
      archiveModalDisplayed: false,
      defaultCourier: 'Corriere di default',
      disableCourierSelection: false,
      couriers: [],
      modalDropdownOptions: [],
      currentChunk: 0,
      allChunks: 0,
      ordersReadyToBeArchived: []
    };
  },

  computed: {
    ...mapGetters(['archiveModalInput', 'isArchiveModalLoading', 'currentVendor', 'currentVendors', 'isUserAdmin']),
    // Check if any slots have been passed
    withTxt() {
      return this.$slots.default && !this.isShort;
    },
    btnIcon() {
      return !this.isLoading ? 'icon-Truck' : 'icon-spinner';
    },
    modalTitle() {
      return this.selectedOrdersIds.length === 1 ? 'Scarica la Lettera di Vettura' : 'Scarica le Lettere di Vettura';
    },
    modalConfirmTxt() {
      return this.selectedOrdersIds.length === 1 ? 'Scarica e archivia l’ordine' : 'Scarica e archivia ordini';
    },
    modalTxt() {
      let theFirstTime = !this.disableCourierSelection ? 'per la prima volta ' : '';

      // If courier selection is disabled, all the orders have ldv already generated
      return this.selectedOrdersIds.length === 1
        ? `Stai scaricando ${theFirstTime}la lettera di vettura di questo ordine. Se non vuoi archiviare l’ordine clicca su “Continua senza archiviare” o “Annulla”.`
        : `Stai scaricando ${theFirstTime}le lettere di vettura di questi ordini. Se non vuoi archiviare ordini clicca su “Continua senza archiviare” o “Annulla”.`;
    },
    selectedOrdersIds() {
      return this.selectedOrders.length !== 0 ? this.selectedOrders.map(order => order.id) : [];
    },
    // Calculate progress of downloading ddt files
    progress() {
      // If allChunks is equal to 0 return -1 which will hide the progress info
      if (this.allChunks === 0) return -1;
      return Math.round((this.currentChunk / this.allChunks) * 100);
    }
  },
  methods: {
    ...mapActions(['set', 'addAlert']),
    handleHideArchiveModal() {
      this.archiveModalDisplayed = false;
    },

    printDDT() {
      let ddtGenerated = this.checkIfDdtGenerated();

      // Disable courier selection if ddt has been generated for all the orders
      this.disableCourierSelection = ddtGenerated;

      if (this.isArchive) {
        // Download DDT
        this.isLoading = true;
        this.downloadDdtFiles();
      } else {
        // Show modal
        this.archiveModalDisplayed = true;
      }
    },

    /**
     * Method used to call the BE endpoint passing encoded vendor, orders (array of ids) and courier info to retrieve
     * an ldv pdf
     */
    async downloadDDTs(vendor, orders, courierParams = {}) {
      const queryParams = [];

      // Looping through every order to add the needed informations (vendor and courier)
      for (const order of orders) {
        queryParams.push({
          id: order.id,
          vendor: vendor,
          ...courierParams
        });
      }

      // Encoding in base64
      const base64EncodedParams = Buffer.from(JSON.stringify(queryParams)).toString('base64');

      const token = getCookie('token');
      const response = await fetch(`${process.env.VUE_APP_BASE_DOMAIN}/v1/download/labels?data=${base64EncodedParams}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'application/json'
        },
        responseType: 'blob'
      });

      if (response.status === 500) return 'error';

      const ordersNumbers = getOrdersNumberStringed(orders);
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'application/pdf' });

      this.downloadFile(blob, `_oms_${ordersNumbers}_${vendor}.pdf`);
      return 'ok';
    },

    async downloadDdtFiles(courier) {
      // 1. Courier selection
      let courierParams = {};
      // If there is the courier selected and it is not a default courier, updated courier params
      if (courier && courier !== 'Corriere di default') {
        const courierCode = courier.split('_')[0];
        const courierServiceCode = courier.split('_')[1];

        courierParams = {
          courier: courierCode
        };

        if (courierServiceCode)
          // If there is courier and courier service selected, pass both params
          courierParams.courier_service = courierServiceCode;
      }

      // 2. Orders selection (get rid of the orders on which ldv cannot be generated)
      let responses = [];
      // Array of orders on homepage without ldv generated or in archive with ldv generated
      let ordersWithLdvToDownload = [];
      // Array of orders in archive without ldv generated
      let archiveOrdersWithoutLdv = [];

      for (const order of this.selectedOrders) {
        const vendor = order.vendor || (this.currentVendors.length === 1 && this.currentVendor);
        if (vendor) {
          // Get orders data for the selected or current vendor
          const orderLabel = order.label.find(labelNode => labelNode.node.key === vendor);
          if (orderLabel && orderLabel.node.value === 'true') this.ordersReadyToBeArchived.push(order);

          if (this.isArchive && (!orderLabel || orderLabel.node.value === 'false')) {
            archiveOrdersWithoutLdv.push({ name: order.name, id: order.id, vendor: vendor });
          } else {
            ordersWithLdvToDownload.push({ name: order.name, id: order.id, vendor: vendor });
          }
        } else {
          //  Get orders data for all the vendors
          for (const vendor of order.vendors) {
            const orderLabel = order.label.find(labelNode => labelNode.node.key === vendor);
            if (orderLabel && orderLabel.node.value === 'true') this.ordersReadyToBeArchived.push(order);

            if (this.isArchive && (!orderLabel || orderLabel.node.value === 'false')) {
              archiveOrdersWithoutLdv.push({ name: order.name, id: order.id, vendor: vendor });
            } else {
              ordersWithLdvToDownload.push({ name: order.name, id: order.id, vendor: vendor });
            }
          }
        }
      }

      // 3. Notify about the orders for which ldv could not be downloaded
      if (archiveOrdersWithoutLdv.length > 0) {
        const alertMsg =
          archiveOrdersWithoutLdv.length === 1
            ? 'La lettera di vettura per il seguente ordine non è stata ancora creata: '
            : 'Le lettere di vettura per i seguenti ordini non sono state ancora create: ';
        const alertOrders = archiveOrdersWithoutLdv.map(order => `${order.vendor}: ${order.name}`).join(', ');
        this.addAlert({ msg: `${alertMsg}${alertOrders}` });
      }

      // 4. Download DDT files for each vendor
      if (ordersWithLdvToDownload.length > 0) {
        // Get all vendors from the selected orders
        const vendors = new Set([]);
        ordersWithLdvToDownload.forEach(order => vendors.add(order.vendor));

        const vendorsArr = [...vendors];

        // Get orders for each vendor
        const vendorsOrders = vendorsArr.map(vendor => {
          const orders = ordersWithLdvToDownload.filter(order => order.vendor === vendor);
          return {
            vendor,
            orders
          };
        });

        let nrOfChunks = 0;

        vendorsOrders.forEach(vendor => {
          nrOfChunks += Math.ceil(vendor.orders.length / 20);
        });

        this.allChunks = nrOfChunks;

        // For each vendor download files with max 20 results in each of them
        for (const vendorObj of vendorsOrders) {
          const { vendor, orders } = vendorObj;
          // Split orders in chunks of 20
          const ordersChunks = createChunks(orders, 20);

          for (const chunk of ordersChunks) {
            // Download DDT for the current chunk
            const response = await this.downloadDDTs(vendor, chunk, courierParams);
            responses = [...responses, response];
            this.currentChunk++;
          }
        }
        // Clear progress
        this.currentChunk = 0;
        this.allChunks = 0;
      }

      /* Check if orders labels have been updated after downloading ldv
      If the label metafield has been updated, push the order to ordersReadyToBeArchived (and archive later)
      Otherwise display an alert and don't archive the order */
      const ordersIdsToBeVerified = ordersWithLdvToDownload.map(order => order.id);
      const { labelsCreated, labelsNotCreated } = await this.getCurrentLabels(ordersIdsToBeVerified);

      // Push the orders with the created labels to the array with the orders ready to be archived
      if (labelsCreated.length > 0) {
        labelsCreated.forEach(orderWithLabel => {
          // Check if the order with created label has been selected
          const foundOrder = this.selectedOrders.find(order => order.id === orderWithLabel.id && (!order.vendor || order.vendor === orderWithLabel.vendor));
          if (!foundOrder) return;
          // Avoid duplicates
          const isAlreadyReadyToBeArchived = this.ordersReadyToBeArchived.find(order => order.id === orderWithLabel.id && (!order.vendor || order.vendor === orderWithLabel.vendor));
          if (!isAlreadyReadyToBeArchived) this.ordersReadyToBeArchived.push(foundOrder);
        });
      }

      // Get numbers of orders without the label and show one alert
      const ordersWithAlert = [];

      if (labelsNotCreated.length > 0) {
        labelsNotCreated.forEach(orderWithoutLabel => {
          // Check if the order without label has been selected
          const foundOrder = this.selectedOrders.find(order => order.id === orderWithoutLabel.id && (!order.vendor || order.vendor === orderWithoutLabel.vendor));
          if (foundOrder) ordersWithAlert.push({ ...foundOrder, labelVendor: orderWithoutLabel.vendor });
        });
      }

      if (ordersWithAlert.length > 0) {
        const ordersNumbers = ordersWithAlert.map(order => `${order.name} - produttore ${order.labelVendor}`).join(', ');
        this.addAlert({ msg: `Impossibile scaricare la lettera di vettura per i seguenti ordini: ${ordersNumbers}`, type: 'error' });
      }
      this.isLoading = false;
      this.set(['isArchiveModalLoading', false]);
      return responses;
    },
    async confirmModalAction(selectedCourier) {
      // Archive orders and download DDT
      this.isLoading = true;

      this.set(['isArchiveModalLoading', true]);

      // Download DDT
      const responses = await this.downloadDdtFiles(selectedCourier);

      // If all the files where downloaded correctly, archive orders
      if (responses.every(response => response === 'ok')) {
        this.$emit('archive-orders', this.ordersReadyToBeArchived);
      }
      this.handleHideArchiveModal();
      this.ordersReadyToBeArchived = [];
    },
    async performCustomModalAction(selectedCourier) {
      // Download DDT without archiving orders
      this.isLoading = true;
      this.handleHideArchiveModal();
      await this.downloadDdtFiles(selectedCourier);
    }
  },
  apollo: {
    couriers() {
      return {
        query: getCouriers
      };
    }
  },
  watch: {
    couriers(val) {
      let couriersServicesList = [];
      val.forEach(courier => {
        if (!courier.services || courier.services.length === 0) {
          couriersServicesList = [
            ...couriersServicesList,
            {
              name: courier.name,
              id: courier.code
            }
          ];
        } else {
          courier.services.forEach(service => {
            couriersServicesList = [
              ...couriersServicesList,
              {
                name: `${courier.name} - ${service.name}`,
                id: `${courier.code}_${service.code}`
              }
            ];
          });
        }
      });

      this.modalDropdownOptions = couriersServicesList;
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.currentChunk = 0;
    this.allChunks = 0;
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_animations.scss';
@import '@s/_mixins.scss';

.ddt-btn {
  &,
  &:link,
  &:visited {
    align-items: center;
    background-color: rgba($color-primary-light, 0.25);
    border-radius: 4px;
    border: none;
    color: $color-primary;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    outline: none !important;
    padding: 0.7rem 1rem 0.7rem 0.5rem;
    position: relative;
    text-decoration: none;
    transition: all 0.5s linear;
    width: fit-content;

    @include small-font-size;
    @include respond('tab-port') {
      padding: 1rem;
    }
  }

  &:hover,
  &:active {
    background-color: rgba($color-primary-light, 0.5);
  }

  &--noText {
    &,
    &:link,
    &:visited {
      padding: 1rem;
      height: fit-content;
    }
  }

  &--disabled {
    &,
    &:hover,
    &:focus {
      background-color: $color-grey-light;
      color: $color-blue-light;
      cursor: not-allowed;
      transition: all 0.2s linear;
    }
  }

  &__percentage {
    margin-right: 0.5rem;
    min-width: 3.4rem;
  }

  &__progress {
    background: $color-primary;
    height: 2px;
    width: 0%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__span {
    &--noText {
      display: none;
    }
    @include respond('tab-port') {
      display: none;
    }
  }

  &__hidden {
    display: none;
  }
}
</style>

<docs>
### BaseDdtButton simple
```js
<BaseDdtButton>Hello</BaseDdtButton>
```
### BaseActionButton without text
```js
<BaseDdtButton></BaseDdtButton>
```
</docs>
