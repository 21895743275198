<template>
  <div class="filters">
    <div class="filters__collapsed" v-if="initialCollapsed || (!initialCollapsed && isCollapsed)">
      <BaseHeading :level="2" extraClass="filters__title filters__title--collapsed">Filtri</BaseHeading>
      <BaseIcon v-if="isCollapsed" icon="icon-plus" extraClass="filters__toggleIcon" @handle-click="isCollapsed = false"></BaseIcon>
      <BaseIcon v-else icon="icon-cross" extraClass="filters__toggleIcon filters__toggleIcon--big" @handle-click="isCollapsed = true"></BaseIcon>
    </div>
    <transition name="expanding">
      <div v-if="!isCollapsed" :class="['filters__box', { 'filters__box--narrow': isNarrow }]">
        <div :class="['filters__col', 'filters__col--1', { 'filters__col--narrow': isNarrow }]">
          <BaseHeading v-if="!initialCollapsed" :level="2" extraClass="filters__title">Filtri</BaseHeading>
          <BaseIcon
            v-if="!initialCollapsed && !isCollapsed"
            icon="icon-cross"
            extraClass="filters__toggleIcon filters__toggleIcon--big filters__desktopClose"
            @handle-click="isCollapsed = true"
          ></BaseIcon>
          <BaseRadioGroup
            title="Visualiza gli ordini per:"
            :options="['Prodotto', 'Produttore']"
            :initialSelection="selectedFiltersView"
            @handle-selection-change="(groupName, selection) => $emit('handle-filters-selection', groupName, selection)"
            groupName="filters_view"
            extraClass="filters__option"
            :isNarrow="isNarrow"
          ></BaseRadioGroup>

          <DatePicker v-model="timeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>

          <div class="filters__otherFilters" v-if="isTabMode">
            Altri filtri <span class="filters__otherFiltersLine"></span>
            <span class="filters__otherFiltersBtn" v-if="!showAll" @click="toggleAllFilters"> <BaseIcon icon="icon-plus" extraClass="filters__otherFiltersIcon"></BaseIcon>Mostra</span>
            <span class="filters__otherFiltersBtn" v-else @click="toggleAllFilters"><BaseIcon icon="icon-minus" extraClass="filters__otherFiltersIcon"></BaseIcon>Nascondi</span>
          </div>
        </div>
        <transition name="expanding">
          <div v-if="showFilters" :class="['filters__col', { 'filters__col--narrow': isNarrow }]">
            <div class="filters__option">
              <h4 class="filters__optionLabel">Totale valore dell'ordine:</h4>
              <div :class="['filters__range', { 'filters__range--narrow': isNarrow }]">
                <VueSlider
                  :dot-options="dotOptions"
                  :rail-style="bgStyle"
                  v-model="orderValueRange"
                  :tooltip-formatter="formatter"
                  :enable-cross="enableCross"
                  :process-style="processStyle"
                  :max="max"
                  :min="min"
                  :dot-size="16"
                  :tooltip-style="tooltipStyle"
                ></VueSlider>
              </div>
            </div>
            <BaseCheckboxGroup
              title="Metodo di pagamento:"
              :options="paymentMethodsOptions"
              :initialSelections="selectedPaymentMethods"
              @handle-selections-change="(groupName, selections) => $emit('handle-filters-selection', groupName, selections)"
              groupName="payment_methods"
              extraClass="filters__option"
              :isNarrow="isNarrow"
            ></BaseCheckboxGroup>
          </div>
        </transition>
        <div :class="['filters__col', 'filters__col--3', { 'filters__col--narrow': isNarrow }]" v-if="showFilters">
          <BaseCheckboxGroup
            title="Stato dell'ordine:"
            :options="orderStatusesOptions"
            :initialSelections="selectedOrderStatuses"
            @handle-selections-change="(groupName, selections) => $emit('handle-filters-selection', groupName, selections)"
            groupName="order_statuses"
            extraClass="filters__option"
            :isNarrow="isNarrow"
          ></BaseCheckboxGroup>
          <BaseCheckboxGroup
            title="Tipologia di spedizione:"
            :options="shippingTypesOptions"
            :initialSelections="selectedShippingTypes"
            @handle-selections-change="(groupName, selections) => $emit('handle-filters-selection', groupName, selections)"
            groupName="shipping_types"
            extraClass="filters__option"
            :isNarrow="isNarrow"
          ></BaseCheckboxGroup>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseCheckboxGroup from '@bc/BaseCheckboxGroup';
import BaseRadioGroup from '@bc/BaseRadioGroup';
import DatePicker from 'vue2-datepicker';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

import 'vue-range-component/dist/vue-range-slider.css';

export default {
  name: 'Filters',
  components: {
    BaseHeading,
    DatePicker,
    BaseIcon,
    VueSlider,
    BaseCheckboxGroup,
    BaseRadioGroup
  },
  props: {
    /**
     * This prop is used to pass an array with the range
     * of order values that should be displayed
     */
    selectedValueRange: {
      type: Array,
      default() {
        return [0, 1000];
      }
    },

    /**
     * This prop is used to pass an array with the available payment methods
     */
    paymentMethodsOptions: {
      type: Array,
      default() {
        return ['Carta di credito', 'Paypal', 'Bonifico', 'Altro'];
      }
    },

    /**
     * This prop is used to pass an array with the available shipping types
     */
    shippingTypesOptions: {
      type: Array,
      default() {
        return ['Standard', 'Premium', 'Appuntamento'];
      }
    },

    /**
     * This prop is used to pass an array with the available shipping types
     */
    orderStatusesOptions: {
      type: Array,
      default() {
        return ['Pagato', 'Spedito', 'Non spedito'];
      }
    },

    /**
     * This prop is used to pass an array with the selected payment methods
     */
    selectedPaymentMethods: {
      type: Array,
      default() {
        return ['Carta di credito', 'Paypal', 'Bonifico', 'Altro'];
      }
    },

    /**
     * This prop is used to pass an array with the selected shipping types
     */
    selectedShippingTypes: {
      type: Array,
      default() {
        return ['Standard', 'Premium', 'Appuntamento'];
      }
    },

    /**
     * This prop is used to pass an array with the selected shipping types
     */
    selectedOrderStatuses: {
      type: Array,
      default() {
        return ['Pagato', 'Spedito', 'Non spedito'];
      }
    },

    /**
     * This prop is used to pass an array with the selected time range
     */
    selectedTimeRange: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * This prop is used to pass a value of selected view (produttore/prodotto)
     */
    selectedFiltersView: { type: String, default: 'Produttore' },

    /**
     * This prop is used to pass the value of the selected filters view
     */
    isNarrow: { type: Boolean, default: false },

    /**
     * This prop should be set to true if all the filters
     * should be collapsed when the component is loaded
     */
    initialCollapsed: { type: Boolean, default: false }
  },
  data() {
    return {
      dotOptions: {
        style: {
          'border-color': 'unset'
        }
      },
      showAll: false,
      isCollapsed: this.initialCollapsed,
      orderValueRange: this.selectedValueRange,
      timeRange: this.selectedTimeRange
    };
  },
  computed: {
    showFilters() {
      if (!this.isTabMode) {
        return true;
      } else {
        return this.showAll;
      }
    },
    isTabMode() {
      return this.$store.getters.isTabMode;
    }
  },
  methods: {
    toggleAllFilters() {
      this.showAll = !this.showAll;
    }
  },
  watch: {
    orderValueRange(val) {
      this.$emit('handle-filters-selection', 'value_range', val);
    },
    timeRange(val) {
      this.$emit('handle-filters-selection', 'time_range', val);
    }
  },

  created() {
    this.min = 0;
    this.max = 2000;
    this.bgStyle = {
      backgroundColor: 'rgba(180, 187, 208, 0.35)',
      // boxShadow: 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)'
      height: '2px'
    };
    this.tooltipStyle = {
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    };
    this.processStyle = {
      backgroundColor: '#ffffff'
    };

    this.enableCross = false;
    this.tooltipMerge = true;
    this.formatter = value => {
      if (value === 2000) return `${value}€ +`;
      return `${value}€`;
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.filters {
  background-color: $color-dark-blue;
  border-radius: 8px;
  color: $color-white;
  padding: 0;
  @include default-font-size;
  position: relative;

  &__collapsed {
    padding: 2.5rem;
    @include flex-parent-space-between;
  }

  &__box {
    @include flex-parent-space-between;
    //  transform-origin: top;

    @include respond('tab-port') {
      flex-direction: column;
      justify-content: center;
      padding: 4.5rem;
    }

    &--narrow {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 4.5rem;
    }
  }

  &__toggleIcon {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.2);
    }

    &--big {
      height: 2rem;
      width: 2rem;
    }
  }

  &__desktopClose {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
  }

  &__col {
    flex-basis: 33.3%;
    padding: 2.5rem 4rem;

    @include respond('tab-port') {
      width: 80%;
      padding: 0;
    }

    @include respond('phone') {
      width: 100%;
    }

    &--1 {
      border-right: 2px dashed $color-white;
      padding-left: 4.5rem;
      @include respond('tab-port') {
        border-right: none;
        padding-left: 0;
      }
    }

    &--3 {
      padding-right: 4.5rem;
    }

    &--narrow {
      width: 90%;
      padding: 0;
      border-right: none;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }

  &__col:not(:last-child) {
    @include respond('tab-port') {
      margin-bottom: 3rem;
    }
  }

  &__title {
    color: $color-white !important;
    margin-bottom: 2.5rem;

    &--collapsed {
      margin-bottom: 0;
    }
  }

  &__option:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__range {
    padding-top: 2rem;
    @include respond('tab-port') {
      padding-top: 3rem;
    }

    &--narrow {
      padding-top: 3rem;
    }
  }

  &__optionLabel {
    @include default-font-size;
    @include bold-text;
    margin-bottom: 2rem;
  }

  &__otherFilters {
    font-size: 1.75rem;
    margin: 3rem 0 0 0;
    @include flex-parent-center-childs;
    width: 100%;
  }

  &__otherFiltersLine {
    width: 27rem;
    margin: 0 1rem;
    display: inline-block;
    height: 2px;
    border-bottom: 2px dashed $color-white;

    @include respond('phone') {
      width: 14rem;
    }

    @include respond('miniphone') {
      width: 8rem;
    }
  }

  &__otherFiltersBtn {
    @include flex-parent-center-childs;
    padding: 0.5rem 1.3rem;
    background-color: $color-white;
    color: $color-dark-blue;
    border-radius: 17px;
    cursor: pointer;
  }

  &__otherFiltersIcon {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 1.5rem;
  }
}

// TRANSITIONS
.expanding-enter {
  transform: translateY(-10px);
  opacity: 0;
}

.expanding-enter-active {
  transition: all 0.3s;
  transform-origin: top;
}

.expanding-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.expanding-leave {
  transform: translateY(0);
  opacity: 1;
}

.expanding-leave-active {
  transition: all 0.3s;
  transform-origin: top;
}

.expanding-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
