<template>
  <div class="productsTab">
    <BaseLoadingSpinner v-if="!collectionsFullyLoaded"></BaseLoadingSpinner>
    <div v-else class="productsTab__tools">
      <BaseSearch :extraClass="!isNarrow ? 'productsTab__search' : ''" @search-data="handleSearch"></BaseSearch>
      <BaseCollectionFilter
        v-if="collectionsData.length !== 0 && !isNarrow"
        extraClass="productsTab__filters"
        :collections="collectionsData"
        :featuredCollections="featuredCollections"
        :selectedCollections="selectedCollections"
        @handle-selections="handleSelectedCollections"
      ></BaseCollectionFilter>
    </div>
    <ul :class="['productsTab__results', { 'productsTab__results--narrow': isNarrow }]">
      <BaseItemButton
        v-for="product in filteredProducts"
        :extraClass="!isNarrow ? 'productsTab__item' : 'productsTab__item productsTab__item--narrow'"
        :key="product.title"
        :title="product.title"
        :subtitle="product.vendor"
        :itemId="product.id"
        :isDisabled="!itemSwitchingEnabled"
        :isActive="selectedItemId === product.id"
        @handle-click="value => handleProductSelection(value)"
      ></BaseItemButton>
    </ul>
  </div>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseCollectionFilter from '@bc/BaseCollectionFilter';
import BaseSearch from '@bc/BaseSearch';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { getCollections } from '@gq/getCollections.gql';
import { mapActions, mapGetters } from 'vuex';
import { sortItemsFromZtoA, sortItemsFromAtoZ } from '@u/helperFunctions';
import { fetchMoreCollectionsMixin, collectionsWatchMixin } from '@c/mixins/collectionsMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

export default {
  name: 'ProductsTab',
  components: {
    BaseItemButton,
    BaseCollectionFilter,
    BaseSearch,
    BaseLoadingSpinner
  },
  mixins: [fetchMoreCollectionsMixin, collectionsWatchMixin, sleepMixin],
  props: {
    /**
     * This prop should be set to true if the products tab takes up 2/3
     * or less of the page width (on desktop)
     */
    isNarrow: { type: Boolean, default: false },
    /**
     * If set to false disable all the actions
     */
    itemSwitchingEnabled: { type: Boolean, default: true }
  },
  data() {
    return {
      products: [],
      collections: {},
      collectionsData: [],
      collectionsFullyLoaded: false,
      featuredCollections: ['Frutta', 'Ortaggi', 'Cassette'],
      selectedCollections: ['Frutta', 'Ortaggi', 'Cassette'],
      filteredProducts: this.$store.getters.shopProducts,
      sortedShopProducts: this.$store.getters.shopProducts
    };
  },

  apollo: {
    // Call when component is rendered
    collections() {
      return {
        query: getCollections,
        variables: {
          namespace: 'collection_info',
          key: 'type'
        }
      };
    }
  },

  computed: {
    ...mapGetters(['selectedItemId', 'shopProducts', 'sortedProductsData'])
  },

  methods: {
    ...mapActions(['set']),

    handleSelectedCollections(newCollections) {
      this.selectedCollections = newCollections;
    },
    handleProductSelection(itemId) {
      if (this.itemSwitchingEnabled && this.selectedItemId !== itemId) {
        this.setItemDataLoaded(false);
        this.set(['selectedItemId', itemId]);
        this.set(['currentOrder', itemId]);
      }
    },

    handleSearch(value) {
      const filteredResults = this.sortedShopProducts.filter(product => product.title.toLowerCase().startsWith(value.toLowerCase()));

      this.filteredProducts = [...filteredResults];
    },
    filterProducts() {
      const allProducts = this.sortedShopProducts;

      // Show only selectedCollections products
      const results = allProducts.filter(product => product.collections.find(collection => this.selectedCollections.includes(collection)));

      this.filteredProducts = results;
    }
  },
  watch: {
    selectedCollections(val) {
      if (val) this.filterProducts();
    },
    sortedProductsData(val) {
      if (val) {
        const allProducts = [...this.shopProducts];

        if (val.length === 0) {
          // Sort products from A to Z
          const productsSortedFromAtoZ = sortItemsFromAtoZ({ arr: allProducts, key: 'title' });
          this.sortedShopProducts = productsSortedFromAtoZ;
          this.filterProducts();
          return;
        }

        const allProductsWithEarnings = allProducts.map(product => {
          const foundSortedProduct = val.find(sortedProduct => sortedProduct.id === product.id);
          return {
            ...product,
            earnings: foundSortedProduct ? foundSortedProduct.earnings : 0
          };
        });

        const sortedProducts = sortItemsFromZtoA({ arr: [...allProductsWithEarnings], key: 'earnings' });

        this.sortedShopProducts = sortedProducts;
        this.filterProducts();
      }
    }
  },

  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded'],

  created() {
    // Show only products from the selected collections
    this.filterProducts();
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.productsTab {
  &__results {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;

    &--narrow {
      flex-direction: column;
    }
  }

  &__tools {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &__search {
    margin-right: 1.5rem;
  }

  &__filters {
    flex-grow: 1;
  }

  &__item {
    margin-bottom: 1.5rem;
    width: 48%;
    min-height: 9rem;

    &--narrow {
      width: 100%;
    }
  }
}
</style>
