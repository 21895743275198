<template>
  <button :class="['smart-btn', { 'smart-btn--active': isActive }, extraClass]" @click="$emit('handle-click')" :style="extraStyle" :disabled="disabled">
    <span class="smart-btn__span"><slot></slot></span>
  </button>
</template>

<script>
/**
 * This component loads a button used to add new document
 * @displayName BaseSmartButton
 */

export default {
  name: 'BaseSmartButton',

  props: {
    /**
     * This prop is used disable the button
     */
    disabled: { type: Boolean },
    /**
     * If set to true the active styles will be applied
     */
    isActive: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>

<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_functions.scss';
@import '@s/_mixins.scss';

.smart-btn {
  &,
  &:link,
  &:visited {
    color: $color-white;
    padding: 1.1rem 1.3rem;
    text-decoration: none;
    outline: none !important;
    background-color: $color-secondary;
    transition: all 0.5s linear;
    border: none;
    width: fit-content;
    border-radius: 3px;
    @include bold-text;
    @include default-font-size;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @include respond('tab-land') {
      padding: 1rem;
      font-size: calculateTabLandRem(18px);
    }

    @include respond('tab-port') {
      padding: 1rem;
      font-size: calculateMobRem(18px);
    }
  }

  &:hover,
  &:active {
    background-color: rgba($color-secondary, 0.8);
  }

  &--active {
    background-color: rgba($color-secondary, 0.15);
    border: 1px solid $color-secondary;
    color: $color-secondary;

    &:hover,
    &:active {
      background-color: rgba($color-secondary, 0.15);
    }
  }
}
</style>

<docs>
### BaseSmartButton simple
```js
<BaseSmartButton>Assegnazione Smart</BaseSmartButton>
```
 
</docs>
