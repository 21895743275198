<template>
  <div class="infoBlock" :class="['infoBlock', extraClass]" :style="extraStyle">
    <h4 class="infoBlock__title">{{ title }} <span v-if="isStarred" class="infoBlock__asterisk">*</span></h4>
    <h6 class="infoBlock__subtitle">{{ subtitle }}</h6>
    <p v-if="info" class="infoBlock__info">{{ info }}</p>
  </div>
</template>
<script>
/**
 * This component loads a div with the data passed in props
 *
 * @displayName BaseInfoBlock
 */
export default {
  name: 'BaseInfoBlock',
  props: {
    /**
     * This prop is used to pass the title of the div
     */
    title: { type: String, required: true },
    /**
     * This prop is used to pass the subtitle of the div
     */
    subtitle: { type: String },
    /**
     * This prop is used to pass additional information
     */
    info: { type: String },
    /**
     * If set to true, the item will marked with an asterisk
     */
    isStarred: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the block
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.infoBlock {
  background-color: $color-dark-blue;
  padding: 2rem 1.5rem;
  border: none;
  border-radius: 4px;
  color: $color-white;

  &__title {
    @include bold-text;
    font-size: 2rem;
    margin-bottom: 0;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(20px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(20px);
    }
  }

  &__subtitle {
    font-size: 1.8rem;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(18px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(18px);
    }
  }

  &__info {
    margin-top: 2.9rem;
    color: $color-blue-light;
    @include small-font-size;
  }

  &__asterisk {
    color: $color-blue-light;
    font-weight: 400;
    font-size: 1.7rem;
    @include respond('tab-land') {
      font-size: calculateTabLandRem(17px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(17px);
    }
  }
}
</style>

<docs>
 
### BaseInfoBlock  
```js
<BaseInfoBlock title="Title" subtitle="subtitle" info="info text" ></BaseInfoBlock>
```
</docs>
