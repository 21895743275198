<template>
  <div class="productsMobileTab">
    <div class="datepicker-dark productsMobileTab__datepicker">
      <DatePicker v-model="selectedTimeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
    </div>

    <div>
      <div class="productsMobileTab__charts">
        <ProductsCharts :timeRange="selectedTimeRange"></ProductsCharts>
      </div>
      <BaseSearch extraClass="productsMobileTab__search" @search-data="handleSearch"></BaseSearch>
      <BaseLoadingSpinner v-if="!collectionsFullyLoaded"></BaseLoadingSpinner>
      <div v-else>
        <BaseCollectionFilter
          v-if="collectionsData.length !== 0"
          extraClass="productsMobileTab__filters"
          :collections="collectionsData"
          :featuredCollections="featuredCollections"
          :selectedCollections="selectedCollections"
          @handle-selections="handleSelectedCollections"
          showMinimize
        ></BaseCollectionFilter>

        <ul class="productsMobileTab__results">
          <BaseItemButton
            v-for="product in filteredProducts"
            extraClass="productsMobileTab__item"
            :key="product.title"
            :title="product.title"
            :subtitle="product.vendor"
            :isDisabled="!itemSwitchingEnabled"
            :itemId="product.id"
            :isActive="selectedItemId === product.id"
            @handle-click="value => handleProductSelection(value)"
          ></BaseItemButton>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseCollectionFilter from '@bc/BaseCollectionFilter';
import BaseSearch from '@bc/BaseSearch';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import DatePicker from 'vue2-datepicker';
import ProductsCharts from '@c/admin/ProductsCharts';
import { getCollections } from '@gq/getCollections.gql';
import { mapActions, mapGetters } from 'vuex';
import { getXMonthsAgoDate, sortItemsFromZtoA, sortItemsFromAtoZ } from '@u/helperFunctions';
import { fetchMoreCollectionsMixin, collectionsWatchMixin } from '@c/mixins/collectionsMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

export default {
  name: 'ProductsMobileTab',
  components: {
    BaseItemButton,
    BaseCollectionFilter,
    BaseSearch,
    DatePicker,
    ProductsCharts,
    BaseLoadingSpinner
  },
  mixins: [fetchMoreCollectionsMixin, collectionsWatchMixin, sleepMixin],
  props: {
    /**
     * If set to false disable all the actions
     */
    itemSwitchingEnabled: { type: Boolean, default: true }
  },
  data() {
    return {
      collections: {},
      collectionsData: [],
      collectionsFullyLoaded: false,
      selectedCollections: ['Frutta', 'Ortaggi', 'Cassette'],
      featuredCollections: ['Frutta', 'Ortaggi', 'Cassette'],
      selectedTimeRange: [],
      filteredProducts: this.$store.getters.shopProducts,
      sortedShopProducts: this.$store.getters.shopProducts
    };
  },

  computed: {
    ...mapGetters(['selectedItemId', 'shopProducts', 'isMobileMode', 'sortedProductsData'])
  },

  apollo: {
    // Call when component is rendered
    collections() {
      return {
        query: getCollections,
        variables: {
          namespace: 'collection_info',
          key: 'type'
        }
      };
    }
  },

  methods: {
    ...mapActions(['set']),

    handleSelectedCollections(newCollections) {
      this.selectedCollections = newCollections;
    },
    handleProductSelection(itemId) {
      if (this.itemSwitchingEnabled && this.selectedItemId !== itemId) {
        this.setItemDataLoaded(false);
        this.set(['selectedItemId', itemId]);
        this.set(['currentOrder', itemId]);
      }
    },
    handleSearch(value) {
      this.filteredProducts = this.sortedShopProducts.filter(product => product.title.toLowerCase().startsWith(value.toLowerCase()));
    },
    filterProducts() {
      const allProducts = this.sortedShopProducts;

      // Show only selectedCollections products
      const results = allProducts.filter(product => product.collections.find(collection => this.selectedCollections.includes(collection)));

      this.filteredProducts = results;
    }
  },

  watch: {
    collections(val) {
      let results = [];
      if (val.edges.length !== 0) {
        val.edges.forEach(collection => {
          // Select only collections containing products (exclude farmers)
          if (
            collection.node.collectionType &&
            (collection.node.collectionType.value === 'products' || collection.node.collectionType.value === 'subscriptions') &&
            collection.node.productsCount > 0
          ) {
            results = [
              ...results,
              {
                title: collection.node.title,
                id: collection.node.id,
                productsCount: collection.node.productsCount
              }
            ];
          }
        });
      }

      this.collectionsData = results;
    },
    selectedCollections(val) {
      if (val) this.filterProducts();
    },
    sortedProductsData(val) {
      if (val) {
        const allProducts = [...this.shopProducts];

        if (val.length === 0) {
          // Sort products from A to Z
          const productsSortedFromAtoZ = sortItemsFromAtoZ({ arr: allProducts, key: 'title' });
          this.sortedShopProducts = productsSortedFromAtoZ;
          this.filterProducts();
          return;
        }

        const allProductsWithEarnings = allProducts.map(product => {
          const foundSortedProduct = val.find(sortedProduct => sortedProduct.id === product.id);
          return {
            ...product,
            earnings: foundSortedProduct ? foundSortedProduct.earnings : 0
          };
        });

        const sortedProducts = sortItemsFromZtoA({ arr: [...allProductsWithEarnings], key: 'earnings' });

        this.sortedShopProducts = sortedProducts;
        this.filterProducts();
      }
    }
  },
  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded'],
  created() {
    const initialTimeRange = [getXMonthsAgoDate(3), new Date()];
    this.selectedTimeRange = initialTimeRange;
    // Show only products from the selected collections
    this.filterProducts();
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.productsMobileTab {
  &__datepicker {
    margin: 4.87rem 0;
  }

  &__search {
    margin: 1.81rem 0;
    width: 50%;

    @include respond('phone') {
      width: 100%;
    }
  }

  &__filters {
    margin-bottom: 3.75rem;
  }

  &__item {
    width: 100%;
    min-height: 9rem;
  }

  &__item:not(:last-child) {
    margin-bottom: 1.9rem;
  }
}
</style>
