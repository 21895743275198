<template>
  <div class="gestioneLotti">
    <BaseCard hideMobile extraClass="gestioneLotti__card">
      <p v-if="!isTabMode" class="u-linkBack gestioneLotti__linkBack" @click="$router.push('/')"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
      <BaseProgressBar extraClass="gestioneLotti__progressBar" :steps="[{ title: 'Gestione dei lotti di produzione creati', completed: true }]" :active="0"></BaseProgressBar>
      <BaseLoadingSpinner v-if="!allDataReady"></BaseLoadingSpinner>
      <form v-else @submit.prevent class="gestioneLotti__form">
        <BaseLoadingSpinner v-if="itemLoading"></BaseLoadingSpinner>
        <table class="gestioneLotti__table" v-if="!isTabMode">
          <col />
          <col />
          <col />
          <col />
          <col />
          <col class="gestioneLotti__lastCol" />

          <thead>
            <tr class="gestioneLotti__head">
              <th class="gestioneLotti__th">ID Lotto</th>
              <th class="gestioneLotti__th">Produttore</th>
              <th class="gestioneLotti__th">Quantità (Kg)</th>
              <th class="gestioneLotti__th">Kg rimanenti</th>
              <th class="gestioneLotti__th">Data</th>
              <th class="gestioneLotti__th">Azioni</th>
            </tr>
          </thead>

          <tbody>
            <VendorLottoItem
              v-for="(item, index) in batchesData"
              :item="item"
              :key="index"
              @handle-item-loading="handleItemLoading"
              @close-lotto="handleShowModal(item.id, modalTypeClose)"
            ></VendorLottoItem>
          </tbody>
        </table>
        <ul v-else class="gestioneLotti__mobileList">
          <VendorLottoItem
            v-for="(item, index) in batchesData"
            :item="item"
            :key="index"
            @handle-item-loading="handleItemLoading"
            @close-lotto="handleShowModal(item.id, modalTypeClose)"
          ></VendorLottoItem>
        </ul>
      </form>
      <p v-if="isTabMode" class="u-linkBack" @click="$router.push('/')"><BaseIcon icon="icon-chevron-left"></BaseIcon> Indietro</p>
    </BaseCard>

    <BaseModal
      v-if="showModal"
      :isLoading="isModalLoading"
      :confirmTxt="modalInfo.confirmTxt"
      :heading="modalInfo.heading"
      @close-fn="handleHideModal"
      @confirm-fn="confirmModalAction(selectedBatchId)"
      >{{ modalInfo.txt }}</BaseModal
    >
  </div>
</template>
<script>
import BaseCard from '@bc/BaseCard';
import BaseProgressBar from '@bc/BaseProgressBar';
import BaseIcon from '@bc/BaseIcon';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import VendorLottoItem from '@c/vendor/VendorLottoItem';
import BaseModal from '@bc/BaseModal';
import { mapGetters } from 'vuex';
import { getBatches } from '@gq/getBatches.gql';
import { getClosedBatches } from '@gq/getClosedBatches.gql';
import { closeBatch } from '@gm/closeBatch.gql';
import { sortItemsFromAtoZ } from '@u/helperFunctions.js';

export default {
  name: 'BatchesManagement',
  components: {
    BaseCard,
    BaseProgressBar,
    BaseIcon,
    VendorLottoItem,
    BaseModal,
    BaseLoadingSpinner
  },
  data() {
    return {
      showModal: false,
      modalType: '',
      selectedBatchId: '',
      selectedBatchNr: '',
      modalTypeClose: 'close',
      batches: [],
      batchesData: [],
      closedBatches: [],
      closedBatchesData: [],
      allDataReady: false,
      currentVendorId: 0,
      isModalLoading: false,
      itemLoading: false
    };
  },
  computed: {
    ...mapGetters(['isTabMode', 'user']),
    modalInfo() {
      return this.modalType === this.modalTypeClose
        ? {
            confirmTxt: 'Chiudi lotto',
            heading: 'Questa operazione è irreversibile. Sei sicuro di voler chiudere questo lotto?',
            txt: `Stai chiudendo il lotto numero ${this.selectedBatchNr}. I kg rimanenti del prodotto indicato nel lotto non potranno essere più utilizzati.`
          }
        : {
            confirmTxt: 'Elimina',
            heading: 'Questa operazione è irreversibile. Sei sicuro di voler eliminare questo lotto?',
            txt: `Stai eliminando il lotto numero ${this.selectedBatchNr}. Gli ordini già assegnati a questo lotto dovranno essere ri-assegnati ad un nuovo lotto. Ricordati di stampare le nuove lettere di vettura con il lotto aggiornato.`
          };
    }
  },
  methods: {
    handleShowModal(id, type) {
      this.modalType = type;
      this.selectedBatchId = id;
      // Find production batch number
      const batchNumber = this.batchesData.find(batch => batch.id === id).ddt_number;
      this.selectedBatchNr = batchNumber;
      this.showModal = true;
    },
    handleHideModal() {
      this.modalType = '';
      this.selectedBatchId = '';
      this.selectedBatchNr = '';
      this.showModal = false;
    },
    confirmModalAction(id) {
      this.isModalLoading = true;
      this.handleCloseLotto(id);
    },
    async handleCloseLotto(id) {
      // Close lotto
      let foundBatch = this.batchesData.find(batch => batch.id === id);

      const response = await this.$apollo.mutate({
        // Query
        mutation: closeBatch,
        // Parameters
        variables: {
          id,
          vendor: foundBatch.vendor
        }
      });

      if (response.data.closeBatch.message === 'ok') {
        foundBatch.status = 'closed';
        this.handleHideModal();
        this.isModalLoading = false;
        this.$store.commit('addAlert', { msg: 'Il lotto di produzione è stato chiuso', type: 'success' });
      } else {
        this.$store.commit('addAlert', { msg: response.data.closeBatch.message, type: 'error' });
        this.isModalLoading = false;
      }
    },

    handleItemLoading(value) {
      this.itemLoading = value;
    }
  },
  apollo: {
    batches() {
      return {
        query: getBatches,
        variables: {
          vendor: this.user.vendors[this.currentVendorId]
        },
        fetchPolicy: 'no-cache',
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.batches = data.batches;
          }
        }
      };
    },
    closedBatches() {
      return {
        query: getClosedBatches,
        skip: true,
        variables: {
          vendor: this.user.vendors[this.currentVendorId]
        },
        fetchPolicy: 'no-cache',
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.closedBatches = data.closedBatches;
          }
        }
      };
    }
  },
  watch: {
    batches(val) {
      if (!val || val.length === 0 || !val[0].id) {
        this.$apollo.queries.closedBatches.start();
        this.$apollo.queries.closedBatches.refetch({
          vendor: this.user.vendors[this.currentVendorId]
        });
        return;
      }

      const batchesArr = val.map(batch => {
        const { ddt_number, ddt_date, id, products, vendor, current } = batch;

        let quantity = 0;

        products.forEach(product => (quantity += product.grams));
        const remainingKg = current ? (quantity - current) / 1000 : quantity / 1000;

        return {
          ddt_number,
          ddt_date,
          // Convert g to kg
          quantity: quantity / 1000,
          id,
          status: 'open',
          remainingKg,
          vendor
        };
      });

      const sortedBatchesData = sortItemsFromAtoZ({ arr: [...this.batchesData, ...batchesArr], key: 'ddt_date' });

      this.batchesData = sortedBatchesData;
      // Load closed batches
      this.$apollo.queries.closedBatches.start();
      this.$apollo.queries.closedBatches.refetch({
        vendor: this.user.vendors[this.currentVendorId]
      });
    },
    closedBatches(val) {
      if (!val || val.length === 0 || !val[0].id) {
        // Load data of the next vendor
        this.currentVendorId++;
        return;
      }

      const closedBatchesArr = val.map(batch => {
        const { ddt_number, ddt_date, id, products, vendor, current } = batch;
        let quantity = 0;

        products.forEach(product => (quantity += product.grams));

        const remainingKg = current ? (quantity - current) / 1000 : quantity / 1000;

        return {
          ddt_number,
          ddt_date,
          // Convert g to kg
          quantity: quantity / 1000,
          id,
          status: 'closed',
          remainingKg,
          vendor
        };
      });

      this.batchesData = [...this.batchesData, ...closedBatchesArr];
      // Load data of the next vendor
      this.currentVendorId++;
    },
    currentVendorId(val) {
      // Load data of the next vendor if exists

      if (val < this.user.vendors.length) {
        this.$apollo.queries.batches.refetch({
          vendor: this.user.vendors[val]
        });
      } else {
        this.allDataReady = true;
      }
    }
  },
  updated() {
    document.body.style.overflow = 'auto';
  },
  beforeDestroy() {
    document.body.style.overflow = 'hidden';
    this.handleHideModal();
    this.batches = [];
    this.closedBatches = [];
    this.batchesData = [];
    this.closedBatchesData = [];
    this.allDataReady = false;
    this.isModalLoading = false;
    this.itemLoading = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.gestioneLotti {
  padding: 4.5rem;

  @include respond('tab-port') {
    padding: 10rem 3rem 3rem 3rem;
  }

  &__card {
    min-height: calc(100vh - 9rem);
    margin-bottom: 0;

    @include respond('tab-port') {
      padding: 0;
      min-height: 90vh;
    }
  }

  &__progressBar {
    padding-right: 14rem;

    @include respond('tab-port') {
      padding-right: 0;
      margin-bottom: 0;
      margin-top: -2rem;
    }
  }

  &__linkBack {
    position: absolute;
    top: 4.5rem;
    right: 3.5rem;
  }

  &__form {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 5rem;

    @include respond('tab-port') {
      height: 100%;
      overflow-y: visible;
      max-height: unset;
    }
  }
  &__table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
  }

  &__lastCol {
    width: 38rem;

    @include respond('tab-land') {
      width: calculateTabLandRem(380px);
    }
  }

  &__th {
    color: $color-blue-light;
    background-color: $color-white;
    position: sticky;
    top: 0;
    font-weight: 400;
    padding: 1.3rem 1.5rem;
    vertical-align: middle;
    border-bottom: 1rem solid $color-white;
    @include small-font-size;
    z-index: 10;
  }

  &__th:not(:last-child) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: $color-blue-light;
      z-index: 300;
    }
  }
  &__mobileList {
    padding-bottom: 2rem;
  }
}
</style>
