import Vue from 'vue';
import App from './App.vue';
import CoreuiVue from '@coreui/vue';
import store from './store';
import directives from '@u/directives.js';
import { iconsSet as icons } from './assets/icons/CoreUIIcons.js';

import router from './routes';

Vue.use(CoreuiVue);

import VueAwesomeSwiper from 'vue-awesome-swiper';

// Styles
import '@/styles/main.scss';

// Graphql
import { apolloProvider } from '@/graphql/index';

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  icons,
  directives,
  VueAwesomeSwiper,
  render: h => h(App),
  apolloProvider
}).$mount('#app');
