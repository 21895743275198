<template>
  <div :class="['filters', extraClass]" :style="extraStyle">
    <div class="filters__heading">
      <BaseHeading :level="2" extraClass="filters__title">Filtri</BaseHeading>

      <div v-if="!isTabMode">
        <BaseIcon v-if="isCollapsed" icon="icon-plus" extraClass="filters__toggleIcon" @handle-click="isCollapsed = false"></BaseIcon>
        <BaseIcon v-else icon="icon-cross" extraClass="filters__toggleIcon filters__toggleIcon--big" @handle-click="isCollapsed = true"></BaseIcon>
      </div>
      <div v-else>
        <span class="filters__mobToggle" v-if="isCollapsed" @click="isCollapsed = false"> <BaseIcon icon="icon-plus" extraClass="filters__mobToggleIcon"></BaseIcon> Mostra</span>
        <span class="filters__mobToggle" v-else @click="isCollapsed = true"><BaseIcon icon="icon-minus" extraClass="filters__mobToggleIcon"></BaseIcon> Nascondi</span>
      </div>
    </div>
    <CCollapse :show="!isCollapsed">
      <div class="filters__details">
        <div class="filters__row filters__row--1">
          <div class="filters__col filters__col--1">
            <DatePicker v-model="timeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
          </div>
          <div class="filters__col">
            <BaseCheckboxGroup
              title="Tipologia di spedizione:"
              :options="shippingTypesOptions"
              :initialSelections="selectedShippingTypes"
              @handle-selections-change="(groupName, selections) => $emit('handle-filters-selection', groupName, selections)"
              groupName="shipping_types"
              :isNarrow="isTabMode"
              extraClass="filters__option"
            ></BaseCheckboxGroup>
          </div>
        </div>
        <div class="filters__row">
          <BaseCheckboxGroup
            title="Prodotto:"
            :options="productsOptions"
            :labels="productsLabels"
            :initialSelections="selectedVendorProducts"
            @handle-selections-change="(groupName, selections) => $emit('handle-filters-selection', groupName, selections)"
            groupName="filters_products"
            :isNarrow="isTabMode"
            extraClass="filters__option"
          ></BaseCheckboxGroup>
        </div>
      </div>
    </CCollapse>
  </div>
</template>
<script>
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseCheckboxGroup from '@bc/BaseCheckboxGroup';
import { CCollapse } from '@coreui/vue';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'VendorFilters',
  components: {
    CCollapse,
    BaseHeading,
    BaseIcon,
    DatePicker,
    BaseCheckboxGroup
  },
  props: {
    /**
     * This prop is used to pass an array of the vendor's products
     */
    products: {
      type: Array,
      required: true
    },

    /**
     * This prop is used to pass an array with the selected vendor's products ids
     */
    selectedProducts: {
      type: Array
    },

    /**
     * This prop is used to pass an array with the selected time range
     */
    selectedTimeRange: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * This prop is used to pass an array with the available shipping types
     */
    shippingTypesOptions: {
      type: Array,
      default() {
        return ['Standard', 'Premium', 'Appuntamento'];
      }
    },

    /**
     * This prop is used to pass an array with the selected shipping types
     */
    selectedShippingTypes: {
      type: Array,
      default() {
        return ['Standard', 'Premium', 'Appuntamento'];
      }
    },

    /**
     * This prop is used to pass an extra class that should be applied to the filters box
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      isCollapsed: true,
      dateType: 'ricezione',
      spedizione: ['standard', 'premium', 'appuntamento'],
      timeRange: this.selectedTimeRange,
      selectedVendorProducts: this.selectedProducts
    };
  },

  computed: {
    isTabMode() {
      return this.$store.getters.isTabMode;
    },
    productsOptions() {
      return this.products.map(product => product.id);
    },
    productsLabels() {
      return this.products.map(product => product.name);
    }
  },
  watch: {
    products(val) {
      if (val.length > 0) {
        this.selectedVendorProducts = val.map(product => product.id);
      }
    },
    timeRange(val) {
      this.$emit('handle-filters-selection', 'time_range', val);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.filters {
  background-color: $color-dark-blue;
  border-radius: 8px;
  color: $color-white;

  &__heading {
    padding: 2.5rem;
    @include flex-parent-space-between;
  }

  &__title {
    color: $color-white !important;
    margin-bottom: 0;
  }

  &__toggleIcon {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    transition: transform 0.3s;
    color: $color-white;

    &:hover {
      transform: scale(1.2);
    }

    &--big {
      height: 2rem;
      width: 2rem;
    }
  }

  &__mobToggle {
    @include flex-parent-center-childs;
    padding: 0.5rem 1.3rem;
    background-color: $color-white;
    color: $color-dark-blue;
    border-radius: 17px;
    cursor: pointer;
  }

  &__mobToggleIcon {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 1.5rem;
  }

  &__row {
    padding: 2.5rem;
    &--1 {
      display: flex;
      border-bottom: 2px dashed $color-blue-light;
      padding-top: 1.5rem;

      @include respond('tab-land') {
        flex-direction: column;
      }
    }
  }

  &__col {
    width: 50%;

    @include respond('tab-land') {
      width: 100%;
    }
    &--1 {
      margin-right: 6.7rem;

      @include respond('tab-land') {
        padding-bottom: 3rem;
        margin-right: 0;
      }

      @include respond('tab-port') {
        width: 100%;
      }
    }
  }

  &__option1 {
    margin-top: 3rem;
  }

  &__option:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__optionLabel {
    @include default-font-size;
    margin-bottom: 2rem;
  }

  &__radioGroup {
    @include flex-parent-space-between;

    @include respond('tab-port') {
      justify-content: flex-start;
    }
  }

  &__checkboxGroup {
    @include flex-parent-space-between;
    flex-wrap: wrap;

    @include respond('tab-port') {
      justify-content: flex-start;
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__checkboxInput,
  &__radioInput {
    display: none;
  }

  &__label {
    font-family: inherit;
    @include default-font-size;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include respond('tab-land') {
      margin-right: 2rem;
    }

    @include respond('tab-port') {
      margin-right: 2.5rem;
    }
  }

  &__radioBtn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 1.2rem;
    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }
    @include respond('phone') {
      margin-right: 1rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 1rem;
      width: 1rem;
      background-color: $color-dark-blue;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  &__checkboxBtn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 1.2rem;

    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 1.2rem;
      width: 1.2rem;
      background-image: url('../../assets/SVG/checkmark.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 2px center;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__checkboxInput:checked ~ &__label &__checkboxBtn::after {
    opacity: 1;
  }

  &__radioInput:checked ~ &__label &__radioBtn::after {
    opacity: 1;
  }
}
</style>
