<template>
  <div class="vendorsMobileTab">
    <div class="datepicker-dark vendorsMobileTab__datepicker">
      <DatePicker v-model="selectedTimeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
    </div>
    <div>
      <div class="vendorsMobileTab__charts">
        <VendorsCharts :timeRange="selectedTimeRange"></VendorsCharts>
      </div>
      <BaseSearch extraClass="vendorsMobileTab__search" @search-data="handleSearch"></BaseSearch>

      <ul class="vendorsMobileTab__results">
        <BaseItemButton
          v-for="vendor in filteredVendors"
          extraClass="vendorsMobileTab__item"
          :key="vendor"
          :title="vendor"
          :subtitle="vendor"
          :isDisabled="!itemSwitchingEnabled"
          :itemId="vendor"
          :isActive="selectedItemId === vendor"
          @handle-click="value => handleVendorSelection(value)"
        ></BaseItemButton>
      </ul>
    </div>
  </div>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseSearch from '@bc/BaseSearch';
import DatePicker from 'vue2-datepicker';
import VendorsCharts from '@c/admin/VendorsCharts';
import { mapActions, mapGetters } from 'vuex';
import { getXMonthsAgoDate } from '@u/helperFunctions';

export default {
  name: 'VendorsMobileTab',
  components: {
    BaseItemButton,
    BaseSearch,
    DatePicker,
    VendorsCharts
  },
  props: {
    /**
     * If set to false disable all the actions
     */
    itemSwitchingEnabled: { type: Boolean, default: true }
  },
  data() {
    return {
      selectedTimeRange: [],
      filteredVendors: this.$store.getters.shopVendors
    };
  },

  computed: {
    ...mapGetters(['selectedItemId', 'shopVendors'])
  },

  methods: {
    ...mapActions(['set']),
    handleVendorSelection(itemId) {
      if (this.itemSwitchingEnabled && this.selectedItemId !== itemId) {
        this.setItemDataLoaded(false);
        this.set(['selectedItemId', itemId]);
        this.set(['currentVendor', itemId]);
      }
    },
    handleSearch(value) {
      this.filteredVendors = this.shopVendors.filter(vendor => vendor.toLowerCase().startsWith(value.toLowerCase()));
    }
  },
  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded'],
  created() {
    const initialTimeRange = [getXMonthsAgoDate(3), new Date()];
    this.selectedTimeRange = initialTimeRange;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.vendorsMobileTab {
  &__datepicker {
    margin: 4.87rem 0;
  }

  &__search {
    margin: 1.81rem 0;
    width: 50%;

    @include respond('phone') {
      width: 100%;
    }
  }

  &__results {
    margin-top: 3.75rem;
  }

  &__item {
    width: 100%;
  }

  &__item:not(:last-child) {
    margin-bottom: 1.9rem;
  }
}
</style>
