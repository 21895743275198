export const home = [
  '24 24',
  `<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Icon/Home" fill="currentColor"  >
        <g id="home" transform="translate(3.000000, 2.000000)">
            <path d="M8.38605939,-0.789352217 L-0.613940614,6.21064778 C-0.857527156,6.40010398 -1,6.69140957 -1,7 L-1,18 C-1,19.6568542 0.343145751,21 2,21 L16,21 C17.6568542,21 19,19.6568542 19,18 L19,7 C19,6.69140957 18.8575272,6.40010398 18.6139406,6.21064778 L9.61394061,-0.789352217 C9.25283013,-1.07021593 8.74716987,-1.07021593 8.38605939,-0.789352217 Z M9,1.266 L17,7.489 L17,18 C17,18.5522847 16.5522847,19 16,19 L2,19 L1.88337887,18.9932723 C1.38604019,18.9355072 1,18.5128358 1,18 L1,7.488 L9,1.266 Z" id="Path"></path>
            <path d="M12,9 C12.5128358,9 12.9355072,9.38604019 12.9932723,9.88337887 L13,10 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4871642,21 11.0644928,20.6139598 11.0067277,20.1166211 L11,20 L11,11 L7,11 L7,20 C7,20.5128358 6.61395981,20.9355072 6.11662113,20.9932723 L6,21 C5.48716416,21 5.06449284,20.6139598 5.00672773,20.1166211 L5,20 L5,10 C5,9.48716416 5.38604019,9.06449284 5.88337887,9.00672773 L6,9 L12,9 Z" id="Path"></path>
        </g>
    </g>
</g>`
];
