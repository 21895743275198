<template>
  <div :class="['fileUpload', extraClass]" :style="extraStyle">
    <h6 class="fileUpload__label">{{ label }}<span v-if="isRequired" class="fileUpload__required">*</span></h6>
    <label for="uploadedFile" :class="['fileUpload__btn', { 'fileUpload__btn--disabled': isDisabled }]">Seleziona file </label>
    <input type="file" :required="isRequired" id="uploadedFile" :disabled="isDisabled" @change="handleFileChange" class="fileUpload__input" accept=".pdf" />
    <BaseIcon v-if="fileName" class="fileUpload__icon" icon="icon-pagato"></BaseIcon>
    <p class="fileUpload__fileName">{{ fileName }}</p>
  </div>
</template>
<script>
import BaseIcon from '@bc/BaseIcon';
import { getURIString, getNumericDate } from '@u/helperFunctions.js';
import { getCookie } from '@/util/cookies';

export default {
  /**
   * This component loads a div with a button to upload a file
   *
   * @displayName BaseFileUpload
   */
  name: 'BaseFileUpload',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass the initial selected file name
     */
    initialFile: { type: File },
    /**
     * This prop is used to pass the label of the file upload input
     */
    label: { type: String },
    /**
     * This prop is used to pass the name of the vendor
     */
    vendor: { type: String },
    /**
     * This prop is used to pass the number of DDT
     */
    DDTnumber: { type: String },
    /**
     * This prop is used to pass the date of DDT
     */
    DDTdate: { type: [Date, String] },
    /**
     * If set to true this field will be required
     */
    isRequired: { type: Boolean, default: false },

    /**
     * If set to true this field will be disabled
     */
    isDisabled: { type: Boolean, default: false },

    /**
     * This prop is used to pass an extra class that should be applied to the component
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      fileName: this.initialFile ? this.initialFile.name : ''
    };
  },
  methods: {
    async handleFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      this.fileName = files[0].name;

      if (files[0].type !== 'application/pdf') {
        this.$store.commit('addAlert', { msg: 'Il tipo di file caricato non è supportato. Selezionare un file pdf', type: 'error' });
        this.fileName = '';
      } else {
        // Send file to backend
        const token = getCookie('token');
        const vendorName = getURIString(this.vendor);
        const dateString = getNumericDate(this.DDTdate);
        const fileBlob = new Blob([files[0]]);

        let response = await fetch(`${process.env.VUE_APP_BASE_DOMAIN}/v1/upload/ddt/${vendorName}/${this.DDTnumber}/${dateString}`, {
          method: 'POST',
          body: fileBlob,
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          this.$store.commit('addAlert', { msg: 'Caricamento file non riuscito.', type: 'error' });
        }

        this.$emit('handle-change', files[0]);
      }
    }
  }
};
</script>
<style lang="scss">
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.fileUpload {
  &__label {
    @include default-font-size;
    margin-bottom: 1.2rem;
  }

  &__btn {
    @include default-font-size;
    @include bold-text;
    color: $color-white;
    background-color: $color-dark-blue;
    padding: 0.4rem 0.7rem;
    border: none;
    outline: none;
    border-radius: 3px;
    transition: all 0.5s;
    text-align: center;

    @include respond('tab-port') {
      width: 100%;
      padding: calculateMobRem(4px) calculateMobRem(7px);
      margin-top: calculateMobRem(20px);
    }

    &:hover {
      background-color: $color-dark-blue-2;
    }

    &:focus {
      background-color: $color-dark-blue-2;
      border: none;
      outline: none;
    }

    &--disabled {
      background-color: $color-blue-light;
    }

    &--disabled:hover,
    &--disabled:focus {
      background-color: $color-blue-light;
      cursor: not-allowed;
    }
  }

  &__input {
    display: none;
  }

  &__fileName {
    @include mini-font-size;
    font-style: italic;

    @include respond('tab-port') {
      display: inline-block;
    }
  }

  &__required {
    color: $color-flame-pea;
  }

  &__icon {
    color: $color-primary;
    height: 1.6rem !important;
    width: 1.6rem !important;
    margin-left: 1.1rem;

    @include respond('tab-port') {
      display: inline-block;
      margin-left: 0;
      margin-right: 1.5rem;
      height: calculateMobRem(16px) !important;
      width: calculateMobRem(16px) !important;
    }
  }
}
</style>
