<template>
  <form @submit.prevent="$emit('start-smart-assignment', smartAssignmentType, selectedTimeRange)" class="smartAssignment">
    <div class="smartAssignment__container">
      <h6 class="smartAssignment__question">A cosa vuoi dare la priorità nell’assegnazione?</h6>

      <div class="smartAssignment__radioGroup">
        <div class="smartAssignment__option">
          <input type="radio" id="cronologico" class="smartAssignment__radioInput" value="cronologico" name="smartAssignmentType" v-model="smartAssignmentType" />
          <label for="cronologico" class="smartAssignment__label"><span class="smartAssignment__radioBtn"></span>Ordine cronologico</label>
        </div>
        <div class="smartAssignment__option smartAssignment__option--datepicker datepicker-dark">
          <input type="radio" id="intervalloDiTempo" class="smartAssignment__radioInput" value="intervalloDiTempo" name="smartAssignmentType" v-model="smartAssignmentType" />
          <label for="intervalloDiTempo" class="smartAssignment__label smartAssignment__label--datepicker"><span class="smartAssignment__radioBtn"></span>Intervallo di tempo</label>
          <DatePicker v-model="selectedTimeRange" :clearable="false" range-separator=" -> " placeholder="Seleziona una data" range prefix-class="xmx"></DatePicker>
        </div>
        <div class="smartAssignment__option">
          <input type="radio" id="spedizione" class="smartAssignment__radioInput" value="spedizione" name="smartAssignmentType" v-model="smartAssignmentType" />
          <label for="spedizione" class="smartAssignment__label"><span class="smartAssignment__radioBtn"></span>Spedizione Premium</label>
        </div>
        <div class="smartAssignment__option">
          <input type="radio" id="otherAssignment" class="smartAssignment__radioInput" value="otherAssignment" name="smartAssignmentType" v-model="smartAssignmentType" />
          <label for="otherAssignment" class="smartAssignment__label"><span class="smartAssignment__radioBtn"></span>Ordini che si completano con questa assegnazione</label>
        </div>
      </div>

      <div class="smartAssignment__buttons">
        <span @click="$emit('close-smart-assignment')" class="smartAssignment__link">Anulla</span>
        <BaseButton isSmall>Conferma</BaseButton>
      </div>
    </div>
  </form>
</template>
<script>
import BaseButton from '@bc/BaseButton';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'SmartAssignment',
  components: {
    BaseButton,
    DatePicker
  },
  data() {
    return {
      smartAssignmentType: [],
      selectedTimeRange: []
    };
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.smartAssignment {
  background-color: rgba($color-white, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &__container {
    background-color: $color-white;
    border: 1px solid rgba($color-blue-light, 0.15);
    border-radius: 8px;
    box-shadow: $primary-shadow;
    padding: 3rem;
    margin-right: 5rem;
    overflow: auto;

    @include respond('tab-port') {
      height: auto;
      max-height: unset;
      margin-right: 0;
      border: none;
      box-shadow: none;
      padding: 0 0 3rem 0;
    }
  }

  &__question {
    @include default-font-size;
    color: $color-dark-blue;
    margin-bottom: 3rem;
  }

  &__radioGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.2rem;
  }

  &__option {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__label {
    font-family: inherit;
    @include default-font-size;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include respond('tab-land') {
      margin-right: 2rem;
    }

    @include respond('tab-port') {
      margin-right: 2.5rem;
    }

    &--datepicker {
      margin-bottom: 1rem;
    }
  }

  &__radioBtn {
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 1.2rem;
    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }
    @include respond('phone') {
      margin-right: 1rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 1rem;
      width: 1rem;
      background-color: $color-dark-blue;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
  &__radioInput {
    display: none;
  }

  &__radioInput:checked ~ &__label &__radioBtn::after {
    opacity: 1;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__link {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    text-decoration: underline;
    @include bold-text;
    @include small-font-size;
  }

  &__link:hover {
    color: map-get($theme-colors, 'primary-900');
  }
}
</style>
