<template>
  <li :class="`alert alert--${type}`">
    <BaseIcon icon="icon-cross" extraClass="alert__icon" @handle-click="$store.commit('removeAlert', id)"></BaseIcon>

    <p class="alert__text">
      {{ msg }}
    </p>
  </li>
</template>
<script>
/**
 * This component loads a notification with the text passed in props
 *
 * @displayName BaseAlert
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseAlert',
  components: {
    BaseIcon
  },
  props: {
    /**
     * This prop is used to pass the message that should be displayed
     * in a notification alert
     */
    msg: { type: String, required: true },

    /**
     * This prop is used to pass the type of the alert (success/error/info)
     */
    type: { type: String, default: 'info' },

    /**
     * This prop is used to pass the id of the alert
     */
    id: { type: Number, required: true }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_animations.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.alert {
  border-radius: 8px;
  box-shadow: $shadow-right;
  list-style: none;
  padding: 1.7rem 5.1rem 1.7rem 1.7rem;
  position: relative;
  max-width: 35rem;

  &--info {
    background-color: rgba($color-dark-blue, 0.9);
  }

  &--success {
    background-color: rgba(map-get($theme-colors, 'primary-900'), 0.9);
  }

  &--error {
    background-color: rgba($color-flame-pea-dark, 0.9);
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 1.2rem !important;
    width: 1.2rem !important;
    color: $color-white;
    cursor: pointer;
    transition: color 0.5s;

    &:hover {
      color: $color-flame-pea;
    }

    @include respond('tab-land') {
      height: calculateTabLandRem(12px) !important;
      width: calculateTabLandRem(12px) !important;
    }

    @include respond('tab-port') {
      height: calculateMobRem(12px) !important;
      width: calculateMobRem(12px) !important;
    }
  }

  &__text {
    color: $color-white;
    margin-bottom: 0;
    padding-bottom: 0;
    @include default-font-size;
  }
}
</style>
