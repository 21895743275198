<template>
  <div class="settings">
    <transition name="backdrop">
      <div v-if="areSettingsDisplayed" class="settings__backdrop" @click="handleCloseSettings"></div>
    </transition>
    <transition name="settings">
      <div v-if="areSettingsDisplayed" :class="['settings__container', { 'settings__container--padding-left': !minimize }]">
        <p class="settings__linkBack" @click="handleCloseSettings"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
        <BaseHeading :level="2" extraClass="settings__title">Impostazioni</BaseHeading>
        <BaseHeading :level="5" extraClass="settings__subtitle">Abilita o disabilita le seguenti mail di notifica: </BaseHeading>
        <BaseLoadingSpinner v-if="$apollo.loading"></BaseLoadingSpinner>
        <ul class="settings__list">
          <li class="settings__listItem">
            <span>Ricezione di un nuovo ordine</span>
            <CSwitch class="mx-1" color="primary" variant="3d" value="notifiche_nuovo_ordine" size="sm" :checked.sync="notificationNewOrder" @update:checked="editUserSettings" />
          </li>
          <li class="settings__listItem">
            <span>Chiusura di un ordine</span>
            <CSwitch class="mx-1" color="primary" variant="3d" value="notifiche_chiusura_ordine" size="sm" :checked.sync="notificationClosedOrder" @update:checked="editUserSettings" />
          </li>
          <li class="settings__listItem">
            <span>Creazione di un nuovo lotto di produzione</span>
            <CSwitch class="mx-1" color="primary" variant="3d" value="notifiche_lotto" size="sm" :checked.sync="notificationBatch" @update:checked="editUserSettings" />
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
/**
 * This component loads the settings page
 *
 * @displayName Settings
 */

import { CSwitch } from '@coreui/vue';
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { mapActions, mapGetters } from 'vuex';
import { getUserSettings } from '@gq/getUserSettings.gql';
import { updateUserSettings } from '@gm/updateUserSettings.gql';

export default {
  name: 'Settings',
  components: {
    CSwitch,
    BaseHeading,
    BaseIcon,
    BaseLoadingSpinner
  },
  data() {
    return {
      notificationNewOrder: true,
      notificationClosedOrder: true,
      notificationBatch: false,
      settings: {}
    };
  },
  computed: {
    ...mapGetters(['areSettingsDisplayed', 'minimize', 'areSettingsDisplayed'])
  },
  methods: {
    ...mapActions(['set', 'closeSettings', 'addAlert']),
    editUserSettings() {
      this.$apollo.mutate({
        // Query
        mutation: updateUserSettings,
        // Parameters
        variables: {
          orders_fulfilled_mails_enabled: this.notificationClosedOrder,
          orders_create_mails_enabled: this.notificationNewOrder,
          batches_create_mails_enabled: this.notificationBatch
        }
      });
    },

    handleCloseSettings() {
      this.addAlert({ msg: 'Impostazioni aggiornate con successo.', type: 'success' });
      this.closeSettings();
    }
  },
  apollo: {
    settings() {
      return {
        query: getUserSettings,
        skip: true
      };
    }
  },
  watch: {
    settings(val) {
      if (val) {
        this.notificationNewOrder = val.orders_create_mails_enabled;
        this.notificationClosedOrder = val.orders_fulfilled_mails_enabled;
        this.notificationBatch = val.batches_create_mails_enabled;
      }
    },
    areSettingsDisplayed(val) {
      if (val) {
        this.$apollo.queries.settings.start();
        this.$apollo.queries.settings.refetch();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
@import '@s/_functions.scss';

.settings {
  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba($color-bg-blur, 0.4);
    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    @include respond('phone') {
      display: none;
    }
  }

  &__container {
    background-color: $color-white;
    padding: 4.5rem 4.5rem 4.5rem 10.5rem;
    width: 50%;
    height: 100%;
    box-shadow: $shadow-right;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;

    @include respond('tab-port') {
      width: 80%;
      padding: calculateMobRem(99px) calculateMobRem(45px) calculateMobRem(45px) calculateMobRem(45px);
    }

    @include respond('phone') {
      width: 100%;
      padding: calculateMobRem(80px) calculateMobRem(24px) calculateMobRem(24px) calculateMobRem(24px);
    }

    &--padding-left {
      padding-left: 36.1rem;
    }
  }

  &__title {
    margin-bottom: 4.6rem;
  }

  &__subtitle {
    font-size: 1.6rem !important;

    @include respond('tab-land') {
      font-size: 1.77rem !important;
    }

    @include respond('tab-port') {
      font-size: 2rem !important;
    }
    margin-bottom: 6rem;
  }

  &__list {
    list-style: none;
    width: 100%;
  }

  &__listItem {
    @include flex-parent-space-between;
    @include default-font-size;
    color: $color-dark-blue;
    padding: 1.6rem 0;
    border-bottom: 2px solid $color-grey-light;
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
    @include small-font-size;
    position: absolute;
    right: 24px;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }
}
</style>
<style lang="scss">
// TRANSITIONS
.backdrop-enter {
  opacity: 0;
}

.backdrop-enter-active {
  transition: all 0.5s;
}

.backdrop-enter-to {
  opacity: 1;
}

.backdrop-leave {
  opacity: 1;
}

.backdrop-leave-active {
  transition: all 0.5s;
}

.backdrop-leave-to {
  opacity: 0;
}

.settings-enter {
  transform: translateX(-100px);
  opacity: 0;
}

.settings-enter-active {
  transition: all 0.3s;
  transform-origin: top;
}

.settings-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.settings-leave {
  transform: translateX(0);
  opacity: 1;
}

.settings-leave-active {
  transition: all 0.3s;
  transform-origin: top;
}

.settings-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
</style>
