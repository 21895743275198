<template>
  <div class="legend">
    <p class="legend__item"><span class="legend__symbol"></span>Ordine pagato</p>
    <p class="legend__item"><span class="legend__symbol legend__symbol--2"></span>Ordine NON pagato</p>
  </div>
</template>
<script>
export default {
  name: 'BaseLegend'
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.legend {
  padding: 2rem;
  display: flex;
  align-items: center;

  &__item {
    font-size: 1.2rem;
    @include flex-parent-center-childs;

    @include respond('tab-land') {
      font-size: 1.33rem;
    }
    @include respond('tab-port') {
      font-size: 1.5rem;
    }
  }

  &__item:not(:last-child) {
    margin-right: 2rem;
  }

  &__symbol {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $color-primary;
    border-radius: 3px;
    margin-right: 1rem;

    &--2 {
      background-color: $color-dark-blue;
    }
  }
}
</style>
<docs>
### BaseLegend simple
```js
<BaseLegend></BaseLegend>
```
 
 
</docs>
