<template>
  <div :class="['bar', extraClass]" :style="extraStyle">
    <p class="bar__link" @click="$emit('go-back-fn')"><BaseIcon icon="icon-chevron-left"></BaseIcon> Indietro</p>
    <p class="bar__subtitle">{{ subtitle }}</p>
    <p class="bar__title">{{ title }}</p>
  </div>
</template>
<script>
/**
 * This component loads a top bar with the title, subtitle and a link to go back to the previous page
 *
 * @displayName BaseTopBar
 */

import BaseIcon from '@bc/BaseIcon';

export default {
  name: 'BaseTopBar',
  props: {
    /**
     * This prop is used to pass the title of displayed data
     */
    title: { type: String },
    /**
     * This prop is used to pass the subtitle of displayed data
     */
    subtitle: { type: String },
    /**
     * This prop is used to pass an extra class that should be applied to the search input
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  components: {
    BaseIcon
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_mixins.scss';
@import '@s/_variables.scss';

.bar {
  width: 100%;
  @include flex-parent-space-between;
  @include small-font-size;
  font-family: 'Apercu Pro Bold', sans-serif;
  font-weight: 700;
  border-bottom: 1px solid $color-grey-light;
  padding-bottom: 1rem;

  &__link {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__link:hover {
    color: map-get($theme-colors, 'primary-900');
  }

  &__subtitle {
    font-size: 1.2rem;
    color: $color-blue-light;
    font-family: 'Apercu Pro', sans-serif;
    font-weight: 400;

    @include respond('tab-port') {
      font-size: 1.5rem;
    }
  }

  &__title {
    color: $color-dark-blue;
  }
}
</style>
<docs>
### BaseTopBar  
```js
<BaseTopBar title="New orders" subtitle="19 orders"></BaseTopBar>  
```
 
 
</docs>
