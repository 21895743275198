<template>
  <div class="sidebar">
    <CSidebar breakpoint="lg" fixed colorScheme="light" :minimize="minimize" :show="isSidebarShown" @update:show="value => set(['sidebarShow', value])">
      <div class="sidebar__toggler" v-if="isTabMode">
        <Toggler></Toggler>
      </div>
      <CRenderFunction flat :content-to-render="currentNav" />

      <CSidebarBrand class="d-md-down-none" to="/">
        <CIcon class="c-sidebar-brand-full" name="logoOrteat" size="custom-size" :height="30" :width="80" viewBox="35 0 40 40" />
        <CIcon class="c-sidebar-brand-minimized" name="logoOrteat" size="custom-size" :height="160" :width="70" viewBox="0 0 110 30" />
      </CSidebarBrand>
      <CSidebarMinimizer class="d-sm-down-none" @click.native="set(['sidebarMinimize', !minimize])" />
      <div v-if="isTabMode" @click="$router.push('/logout')" class="sidebar__logout"><BaseIcon icon="icon-logout" extraClass="sidebar__logoutIcon" />Logout</div>
    </CSidebar>
  </div>
</template>
<script>
/**
 * This component uses Core UI Sidebar to display side navigation menu. Icons must be converted to js and imported in assets/icons/CoreUIIcons
 * The first argument of JS array is two last values of svg viewBox, the second argument is the SVG content stripped of SVG tags export
 *
 * @displayName Sidebar
 */
import { mapGetters, mapActions } from 'vuex';
import { CSidebar, CRenderFunction, CSidebarMinimizer, CSidebarBrand, CIcon } from '@coreui/vue';
import Toggler from './Toggler.vue';
import BaseIcon from '@bc/BaseIcon.vue';

const nav = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Profilo Amministratore',
        icon: 'profileAMM'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'logout'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Notifiche',
        icon: 'notification',
        to: '/notifiche',
        badge: {
          color: 'primary',
          text: 'Coming soon'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/',
        icon: 'home'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Prodotti e Produttori',
        to: '/prodotti-e-produttori',
        icon: 'prodotti'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Archivio Ordini',
        to: '/archivio-ordini',
        icon: 'archive'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Impostazioni',
        to: '/impostazioni',
        icon: 'settings'
      }
    ]
  }
];

const navMobile = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Profilo Amministratore',
        icon: 'profileAMM'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/',
        icon: 'home'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Prodotti e Produttori',
        to: '/prodotti-e-produttori',
        icon: 'prodotti'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Archivio Ordini',
        to: '/archivio-ordini',
        icon: { name: 'archive' }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Impostazioni',
        to: '/impostazioni',
        icon: { name: 'settings' }
      }
    ]
  }
];

const navVendor = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Profilo Produttore',
        icon: 'profileHP'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'logout'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Notifiche',
        to: '/notifiche',
        icon: 'notification',
        badge: {
          color: 'primary',
          text: 'Coming soon'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/',
        icon: 'home'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Prodotti',
        to: '/prodotti',
        icon: 'prodotti'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Archivio Ordini',
        to: '/archivio-ordini',
        icon: 'archive'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Impostazioni',
        to: '/impostazioni',
        icon: 'settings'
      }
    ]
  }
];

const navMobileVendor = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Profilo Produttore',
        icon: 'profileHP'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/',
        icon: 'home'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Prodotti',
        to: '/prodotti',
        icon: 'prodotti'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Archivio Ordini',
        to: '/archivio-ordini',
        icon: { name: 'archive' }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Impostazioni',
        to: '/impostazioni',
        icon: { name: 'settings' }
      }
    ]
  }
];

export default {
  name: 'Sidebar',
  nav,
  navMobile,
  navVendor,
  navMobileVendor,
  components: {
    CSidebar,
    CRenderFunction,
    CSidebarMinimizer,
    CSidebarBrand,
    CIcon,
    Toggler,
    BaseIcon
  },

  computed: {
    ...mapGetters(['isUserAdmin', 'isTabMode', 'isSidebarShown', 'minimize', 'userRole']),
    currentNav() {
      if (this.isUserAdmin) {
        return this.isTabMode ? this.$options.navMobile : this.$options.nav;
      } else {
        return this.isTabMode ? this.$options.navMobileVendor : this.$options.navVendor;
      }
    }
  },
  methods: {
    ...mapActions(['set', 'openSettings']),
    toggleSettings() {
      // ADD SETTINGS POPUP
      const settingsLinks = document.querySelectorAll('a[href="/impostazioni"]');
      const settingsLinksArr = Array.from(settingsLinks);
      const appThis = this;

      settingsLinksArr[0].addEventListener('click', function (e) {
        const settingsItem = e.target.closest('.c-sidebar-nav-link');

        if (settingsItem) {
          appThis.$store.dispatch('openSettings');
        }
      });
    },
    addNotificationsComingSoon() {
      // ADD NOTICATIONS COMING SOON
      if (!this.isTabMode) {
        const notificationLinks = document.querySelectorAll('a[href="/notifiche"]');
        const notificationLinksArr = Array.from(notificationLinks);

        if (notificationLinksArr.length !== 0) {
          notificationLinksArr[0].addEventListener('mouseover', function (e) {
            const notificationItem = e.target.closest('.c-sidebar-nav-link');

            if (notificationItem) {
              const alert = document.createElement('div');
              alert.classList.add('sidebar-alert');
              const alertContent = document.createTextNode('Coming soon');
              alert.appendChild(alertContent);
              notificationItem.parentNode.appendChild(alert);
            }
          });

          notificationLinksArr[0].addEventListener('mouseout', function (e) {
            const notificationItem = e.target.closest('.c-sidebar-nav-link');
            const alert = document.querySelector('.sidebar-alert');
            notificationItem.parentNode.removeChild(alert);
          });
        }
      }
    },
    resetHomePage() {
      // RESET HOME PAGE
      const homeLinks = document.querySelectorAll('a[href="/"]');
      const homeLinksArr = Array.from(homeLinks);
      const appThis = this;

      homeLinksArr.forEach(link =>
        link.addEventListener('click', function (e) {
          const homeItem = e.target.closest('.c-sidebar-nav-link');

          if (homeItem) {
            appThis.set(['orderDetailsShown', false]);
            appThis.set(['vendorOrdersListShown', false]);
          }
        })
      );
    },
    resetProductsAndVendorsPage() {
      // RESET PRODUCTS AND VENDORS PAGE
      const productsAndVendorsLinks = document.querySelectorAll('a[href="/prodotti-e-produttori"]');
      const productsAndVendorsLinksArr = Array.from(productsAndVendorsLinks);
      const appThis = this;

      productsAndVendorsLinksArr.forEach(link =>
        link.addEventListener('click', function (e) {
          const productsAndVendorsItem = e.target.closest('.c-sidebar-nav-link');

          if (productsAndVendorsItem) {
            appThis.set(['selectedItemId', '']);
            appThis.set(['selectedView', 'Prodotti']);
          }
        })
      );
    },
    resetProductsPage() {
      // RESET PRODUCTS PAGE
      const productsLinks = document.querySelectorAll('a[href="/prodotti"]');
      const productsLinksArr = Array.from(productsLinks);
      const appThis = this;

      productsLinksArr.forEach(link =>
        link.addEventListener('click', function (e) {
          const productsItem = e.target.closest('.c-sidebar-nav-link');

          if (productsItem) {
            appThis.set(['selectedItemId', '']);
          }
        })
      );
    },
    resetArchive() {
      // RESET ADMIN ARCHIVE PAGE
      const archiveLinks = document.querySelectorAll('a[href="/archivio-ordini"]');
      const archiveLinksArr = Array.from(archiveLinks);
      const appThis = this;

      archiveLinksArr.forEach(link =>
        link.addEventListener('click', function (e) {
          const archiveItem = e.target.closest('.c-sidebar-nav-link');

          if (archiveItem) {
            appThis.set(['orderDetailsShown', false]);
          }
        })
      );
    }
  },

  updated() {
    this.addNotificationsComingSoon();
    this.toggleSettings();
    this.resetHomePage();
    this.resetProductsAndVendorsPage();
    this.resetProductsPage();
    if (this.isUserAdmin) {
      this.resetArchive();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.sidebar {
  font-size: 1.6rem;

  @include respond('phone') {
    font-size: 1.75rem;
  }

  &__toggler {
    padding: 1rem 0rem 5rem 2.5rem;
  }

  &__logout {
    margin-bottom: 2.2rem;
    padding: 2rem 2.9rem;
    cursor: pointer;
    border-left: 4px solid transparent;
    transition: all 0.3s;
  }

  &__logout:hover {
    background-color: map-get($theme-colors, 'primary-500');
    border-left: 4px solid $color-primary;
    color: $color-primary;
  }

  &__logoutIcon {
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 2rem;
  }

  &__logout:hover &__logoutIcon {
    color: $color-primary;
  }
}
</style>
