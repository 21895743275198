<template>
  <div :class="('horizontal-scroll', { 'table--scroll': scrollTable }, extraClass)">
    <table :class="['table', { 'table--loading': isLoading }]">
      <col class="table__column table__column--1" />
      <col v-for="(column, index) in columns" :key="index" :class="['table__column', { 'table__column--short': column === 'col-short' }]" />
      <thead>
        <tr v-if="filters && filters.length !== 0" class="table__row">
          <th colspan="3" :class="['table__cell__group', { 'table__cell__group--removePadding': removeTheadPadding }]">
            <p v-if="!isTabMode || (isTabMode && !hideMobSelectAll)" class="u-selectAll table__selectAll" @click="$emit('toggle-select-all')">
              <span :class="['u-selectAllBtn', { 'u-selectAllBtn u-selectAllBtn--selected': allOrdersSelected }]"></span><span>Seleziona tutto</span>
            </p>
          </th>
          <BaseFilter
            v-for="filter in filters"
            :key="filter.name"
            @handle-change="(name, value) => $emit('handle-filter-change', name, value)"
            :name="filter.name"
            :extraClass="!removeTheadPadding ? 'table__cell table__filter' : 'table__cell table__filter table__cell--removePadding'"
            :filters="filter.filters"
          ></BaseFilter>
        </tr>
      </thead>
      <BaseLoadingSpinner v-if="isLoading"></BaseLoadingSpinner>
      <tbody v-if="!isLoading && orders.length === 0">
        <tr>
          <td colspan="3" class="u-text-warning table__noResults"><span v-if="!noOrdersInfoDisabled">Nessun risultato corrisponde ai filtri selezionati.</span></td>
        </tr>

        <tr>
          <td v-for="(filter, index) in filters" :key="`a_${index}`" width="10"></td>
        </tr>
      </tbody>

      <tbody v-else-if="!isLoading && orders.length !== 0 && isAdminView">
        <OrderPreview v-for="order in orders" :key="`${name}_${order.id}`" :order="order" :name="name" :showMethodOfPayment="showMethodOfPayment"></OrderPreview>
      </tbody>
      <tbody v-else>
        <VendorOrderPreview
          v-for="order in orders"
          :key="`${name}_${order.id}`"
          :keepOrder="keepOrder"
          :hideShipping="hideShipping"
          :shortDate="shortDate"
          :order="order"
          :name="name"
        ></VendorOrderPreview>
      </tbody>

      <tr v-if="showLoadMoreBtn && orders.length !== 0" id="load-more-btn" class="table__loadBtn">
        ...
      </tr>
    </table>
    <span v-if="fetchingMore" class="table__loader">Caricamento ordini...</span>
  </div>
</template>
<script>
/**
 * This component loads a table with orders and order filters
 *
 * @displayName TableOrders
 */

import BaseFilter from '@bc/BaseFilter';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import OrderPreview from '@c/admin/OrderPreview';
import VendorOrderPreview from '@c/vendor/VendorOrderPreview';

export default {
  name: 'TableOrders',
  components: {
    BaseFilter,
    OrderPreview,
    VendorOrderPreview,
    BaseLoadingSpinner
  },
  props: {
    /**
     * This prop is used to pass an array of orders
     */
    orders: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * This prop is used to pass an array to determine the number of columns and their width
     * example: [col, col, col, col-short, col, col] - will render 6 columns, the 4th will be short
     * Additionally there will be the 1st column with the checkbox that should not be counted
     * in the columns array
     */
    columns: { type: Array },

    /**
     * This prop is used to pass the filters that will be shown in the first table row
     */
    filters: { type: Array },
    /**
     * If this prop is set to true there will be a button with the id "load-more-btn"
     * at the end of the results. It can be used to load more results
     * if the user reaches the button offset.
     */
    showLoadMoreBtn: { type: Boolean, default: false },
    /**
     * If this prop is set to true the payment method will be shown instead of the payment status
     */
    showMethodOfPayment: { type: Boolean },
    /**
     * This prop is used to pass the value of 'selectAll' button
     */
    allOrdersSelected: { type: Boolean },
    /**
     * This prop is used to pass the name of the group of checkboxes (orders)
     */
    name: { type: String, default: 'filteredOrders' },

    /**
     * If set to true, the selectAll button won't be displayed on mobile
     */

    hideMobSelectAll: { type: Boolean, default: false },

    /**
     * If set to true, the order won't be closed when clicking twice on it
     */

    keepOrder: { type: Boolean, default: false },

    /**
     * If set to true, the table will take up 80vh and there will be a scrollbar to show more results
     */

    scrollTable: { type: Boolean, default: false },

    /**
     * If set to true, top of the thead will be set to negative value to remove page padding
     */

    removeTheadPadding: { type: Boolean, default: false },

    /**
     * If set to true, the information about the shipping won't be displayed
     */

    hideShipping: { type: Boolean, default: false },

    /**
     * If set to true, the date will be displayed in shorter format - without the time
     */

    shortDate: { type: Boolean, default: false },

    /**
     * If set to true, show loading spinner instead of table data
     */

    isLoading: { type: Boolean, default: false },

    /**
     * If set to true, show loading dots at the end of the table
     */

    fetchingMore: { type: Boolean, default: false },

    /**
     * If set to true, the information about no results won't be displayed
     */
    noOrdersInfoDisabled: { type: Boolean, default: false },

    /**
     * This prop is used to pass an extra class that should be applied to the table
     */
    extraClass: { type: String }
  },
  computed: {
    isAdminView() {
      return this.$store.getters.isUserAdmin;
    },
    isTabMode() {
      return this.$store.getters.isTabMode;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.table {
  border-collapse: collapse;
  width: 100%;
  position: relative;
  // table-layout: fixed;

  &--loading {
    min-height: 20rem;
  }

  &--scroll {
    max-height: 80vh;
    min-height: 50vh;
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;

    @include respond('tab-port') {
      max-height: unset;
    }
  }

  &__column {
    width: 10rem;

    &--1 {
      width: 3rem;
      @include respond('phone') {
        width: 3.5rem;
      }
    }

    &--short {
      width: 9rem;
      @include respond('phone') {
        width: unset;
      }
    }
  }

  &__cell {
    border-bottom: 0px solid transparent;
    border-top: 0px solid transparent;

    &--removePadding {
      top: -47px !important;
    }

    &--1 {
      width: 3.6rem;
    }
  }

  &__cell__group {
    vertical-align: middle;
    background-color: $color-white;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    position: sticky;
    top: -2px;
    transform: translateX(-1px);
    z-index: 10;

    @include respond('phone') {
      display: none;
    }

    &--removePadding {
      top: -47px;
    }
  }

  &__noResults {
    vertical-align: middle;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    width: 30rem;
  }

  &__selectAll {
    margin-bottom: 2rem;

    @include respond('tab-port') {
      margin-top: -3.5rem;
    }
  }

  &__filter {
    background-color: $color-white;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    position: sticky;
    top: -2px;
    z-index: 10;
    @include respond('phone') {
      display: none;
    }
  }

  &__loadBtn {
    color: $color-white;
  }

  &__fetchMore {
    width: 100%;
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
  }

  &__loader {
    @include default-font-size;
    letter-spacing: 0.2rem;
    overflow: hidden;
    background: linear-gradient(90deg, $color-primary, $color-primary-light);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 4s linear infinite;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }
}
</style>
