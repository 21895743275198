<template>
  <BaseLoadingSpinner v-if="$apollo.loading || !allDataReady"></BaseLoadingSpinner>
  <form v-else @submit.prevent>
    <BaseOrdersTools
      extraClass="ordersList__tools"
      :showSelectAll="isTabMode"
      @search-data="searchOrders"
      :searchOptions="searchOptions"
      :allOrdersSelected="allOrdersSelected"
      :isDisabled="selectedOrders.length === 0"
      @toggle-select-all="toggleSelectAll"
      :selectedOrders="selectedOrders"
      @handle-archive-status="handleArchiveStatus"
    ></BaseOrdersTools>
    <TableOrders
      :orders="filteredOrdersData"
      :columns="['col', 'col', 'col', 'col']"
      :allOrdersSelected="allOrdersSelected"
      @toggle-select-all="toggleSelectAll"
      @handle-filter-change="handleFilterChange"
      :filters="topFilters"
      hideMobSelectAll
      name="ordersList"
      extraClass="ordersList__table"
    ></TableOrders>
  </form>
</template>

<script>
/**
 * This component loads a container used to display the list of orders
 *
 * @displayName VendorOrdersList
 */
import { mapGetters, mapActions } from 'vuex';
import BaseOrdersTools from '@bc/BaseOrdersTools';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import TableOrders from '@c/common/TableOrders';
import { sortItemsByDateFromPresentToPast, styleShortDate, getFullProductId } from '@u/helperFunctions';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

// Filters titles
const shippingFilter = 'Spedizione';
const dateFilter = 'Data ordine';

export default {
  name: 'VendorOrdersList',

  components: {
    BaseOrdersTools,
    TableOrders,
    BaseLoadingSpinner
  },
  mixins: [sleepMixin],
  props: {
    /**
     * This prop is used to pass an array of all the orders of the week
     */
    orders: { type: Array, required: true }
  },

  data() {
    return {
      isLoading: true,
      error: null,
      allDataReady: false,
      allOrdersSelected: false,
      ordersData: [],
      order: {},
      ordersDetails: [],
      filteredOrdersData: [],
      selectedOrders: [],
      allProducts: [],
      searchOptions: ['Ordine', 'ID del prodotto', 'Lotto'],
      topFilters: [
        {
          name: shippingFilter,
          filters: [
            {
              value: 'Standard',
              isSelected: true
            },
            {
              value: 'Premium',
              isSelected: true
            },
            {
              value: 'Appuntamento',
              isSelected: true
            }
          ]
        },
        {
          name: dateFilter,
          filters: []
        }
      ]
    };
  },

  computed: {
    ...mapGetters(['isTabMode', 'isTabLandMode', 'throttledErrors'])
  },
  methods: {
    ...mapActions(['set', 'setDataIsLoadingMsg', 'removeThrottledError']),
    calculateOrdersData() {
      if (this.orders) {
        this.allDataReady = false;

        if (this.orders.length === 0) {
          this.allDataReady = true;
          return;
        }

        // Sort results by date
        const sortedResults = sortItemsByDateFromPresentToPast({ arr: this.orders, key: 'date' });

        this.ordersData = sortedResults;
        this.filteredOrdersData = sortedResults;

        this.set(['currentSetOfOrders', sortedResults]);

        // Get all the orders dates
        const datesArr = sortedResults.map(order => styleShortDate(order.date));
        const uniqueDates = [...new Set(datesArr)];
        const uniqueDateFilters = uniqueDates.map(date => {
          return {
            value: date,
            isSelected: true
          };
        });

        // Update topFilters
        this.topFilters.find(filters => filters.name === dateFilter).filters = uniqueDateFilters;
        this.allDataReady = true;
      }
    },
    toggleSelectAll() {
      const selectedOrders = this.filteredOrdersData;

      selectedOrders.forEach(order => (order.isChecked = !this.allOrdersSelected));
      this.allOrdersSelected = !this.allOrdersSelected;

      this.filteredOrdersData = selectedOrders;
      this.getSelectedOrders();
    },

    toggleChecked(orderId) {
      const updatedOrdersData = this.filteredOrdersData;

      // Find the selected order
      const selectedOrder = updatedOrdersData.find(order => order.id === orderId);

      selectedOrder.isChecked = !selectedOrder.isChecked;

      this.filteredOrdersData = [...updatedOrdersData];
      this.getSelectedOrders();
    },
    handleFilterChange(name, value) {
      let updatedTopFilters = this.topFilters;
      let selectedFilters = updatedTopFilters.find(filter => filter.name === name);
      let selectedFilter = selectedFilters.filters.find(filter => filter.value === value);
      selectedFilter.isSelected = !selectedFilter.isSelected;
      this.topFilters = updatedTopFilters;
    },

    searchOrders(value, option) {
      if (!value) {
        this.filteredOrdersData = this.ordersData;
        return;
      }
      let filteredResults = this.ordersData;

      const check = (order, value) => {
        const { tags } = order;
        return tags.some(tag => {
          const val = getFullProductId(value);
          return tag.indexOf(val) !== -1;
        });
      };

      switch (option) {
        case 'Ordine':
          // Adding hashtag if not added by the user
          if (`${value}`.charAt(0) !== '#') value = `#${value}`;
          filteredResults = filteredResults.filter(order => order.name.startsWith(value));
          break;
        case 'ID del prodotto':
          filteredResults = filteredResults.filter(order => check(order, value));
          break;
        case 'Lotto':
          filteredResults = filteredResults.filter(order => order.orderBatchesNumbers.includes(value));
          break;

        default:
          filteredResults = [];
      }

      this.filteredOrdersData = filteredResults;
    },

    getSelectedOrders() {
      let selections = [];

      if (this.filteredOrdersData.length !== 0) {
        selections = this.filteredOrdersData.filter(order => order.isChecked);
      }

      this.selectedOrders = selections;
      return selections;
    },

    handleArchiveStatus(archiviationInput) {
      let updatedOrdersData = this.ordersData;
      let updatedFilteredOrdersData = this.filteredOrdersData;

      archiviationInput.forEach(input => {
        // Remove archive orders from ordersData
        updatedOrdersData = updatedOrdersData.filter(order => {
          return !(order.id === input.id);
        });

        // Remove archive orders from filteredOrdersData
        updatedFilteredOrdersData = updatedFilteredOrdersData.filter(order => {
          return !(order.id === input.id);
        });
      });

      this.ordersData = [...updatedOrdersData];
      this.filteredOrdersData = [...updatedFilteredOrdersData];
      this.set(['currentSetOfOrders', [...updatedFilteredOrdersData]]);

      if ([...updatedFilteredOrdersData].length !== 0) {
        const currentOrderId = [...updatedFilteredOrdersData][0].id;
        this.set(['currentOrder', currentOrderId]);
      } else {
        this.set(['currentOrder', '']);
        this.set(['orderDetailsShown', false]);
      }

      this.getSelectedOrders();
      this.$emit('refetch-orders');
    }
  },
  watch: {
    ordersData() {
      if (this.ordersData.length > 0) {
        this.set(['currentSetOfOrders', this.ordersData]);
      }
    },

    allOrdersSelected() {
      let results;

      if (this.ordersData.length !== 0) {
        results = this.ordersData.map(order => {
          // Add isChecked and value to each order from the database
          return {
            ...order,
            isChecked: this.allOrdersSelected
          };
        });
      }

      this.ordersData = results;
      this.set(['currentSetOfOrders', results]);
    },

    topFilters: {
      deep: true,
      handler(val) {
        let filteredOrders = this.ordersData;

        /**
         * 1. valore dei filtri applicati
         * 2. ciclare ordini e prendere solo quelli con il valore del filtro
         * 3. aggiornare elenco
         */

        // SHIPPING FILTERS
        // 1.
        const shippingFilters = val.find(item => item.name === shippingFilter).filters;
        const selectedShippingFilters = shippingFilters.filter(filter => filter.isSelected);
        const selectedShippingFiltersValues = selectedShippingFilters.length !== 0 ? selectedShippingFilters.map(filter => filter.value) : [];
        // 2.
        filteredOrders = filteredOrders.filter(order => {
          if (
            // if all there are no filters selected or all are selected, show all the results
            selectedShippingFiltersValues.length === shippingFilters.length
          ) {
            return order;
          } else {
            return selectedShippingFiltersValues.includes(order.spedizione);
          }
        });

        // DATE FILTERS
        // 1.
        const dateFilters = val.find(item => item.name === dateFilter).filters;

        const selectedDateFilters = dateFilters.filter(filter => filter.isSelected);

        const selectedDateFiltersValues = selectedDateFilters.length !== 0 ? selectedDateFilters.map(filter => filter.value) : [];

        // 2.
        filteredOrders = filteredOrders.filter(order => {
          // if all there are no filters selected or all are selected, show all the results
          if (selectedDateFiltersValues.length === dateFilters.length) {
            return order;
          } else {
            return selectedDateFiltersValues.includes(styleShortDate(order.date));
          }
        });

        // 3.
        this.filteredOrdersData = filteredOrders;
        this.set(['currentSetOfOrders', filteredOrders]);
      }
    }
  },

  provide() {
    return {
      handleChange: this.toggleChecked
    };
  },

  beforeDestroy() {
    this.allDataReady = false;
  },
  created() {
    this.calculateOrdersData();
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_mixins.scss';
@import '@s/_variables.scss';

.ordersList {
  &__legend {
    @include flex-parent-space-between;
    flex-wrap: wrap;
  }

  &__tools {
    margin-bottom: 0;
    position: sticky;
    top: -4.5rem;
    z-index: 500;
    background-color: $color-white;
    padding: 0.5rem 0;
  }

  &__table {
    margin-top: 2rem;
  }
}
</style>
