<template>
  <div>
    <div class="popup__backdrop" @click="$emit('close-fn')"></div>
    <div class="popup">
      <slot></slot>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a popup with black, blurred background
 * that takes up the whole page
 *
 * @displayName Popup
 */
export default {
  name: 'Popup'
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.popup {
  background-color: $color-white;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  border: none;
  min-height: 30rem;
  width: 95rem;
  padding: 4rem 4.2rem;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 700;

  &__backdrop {
    height: 100vh;
    width: 100vw;
    background-color: rgba($color-bg-blur, 0.4);
    backdrop-filter: blur(1px);
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 5.6rem;
    z-index: 600;
  }
}
</style>
<docs>
### Popup 
```js
<Popup></Popup>
```
</docs>
