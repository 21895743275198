<template>
  <BaseLoadingSpinner v-if="$apollo.loading || !fullyLoaded"></BaseLoadingSpinner>
  <p v-else-if="!batchData.ddt_number" class="u-text-warning">{{ errorMsg }}</p>
  <div v-else class="lotto">
    <div v-if="!isTabMode" class="lotto__header">
      <BaseHeading :level="2">{{ `Lotto di produzione: ${batchData.ddt_number} ${batchData.capacity} Kg` }}</BaseHeading>
      <p class="u-linkBack" @click="$emit('close-fn')"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
    </div>
    <div v-else class="lotto__mobileHeader">
      <BaseTopBar :title="`Lotto di produzione: ${batchData.ddt_number} ${batchData.capacity} Kg`" @go-back-fn="$emit('close-fn')"></BaseTopBar>
      <div class="lotto__buttons">
        <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': allSelected }]"></span><span>Seleziona tutto</span></p>
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_data" :fields="json_fields" :header="excel_header" :name="`Lotto-${batchData.ddt_number}.xls`">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>
    </div>
    <div class="lotto__buttons" v-if="!isTabMode">
      <p class="u-selectAll" @click="toggleSelectAll"><span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': allSelected }]"></span><span>Seleziona tutto</span></p>
      <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
      <DownloadExcel v-else :data="json_data" :fields="json_fields" :header="excel_header" :name="`Lotto-${batchData.ddt_number}.xls`">
        <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
      </DownloadExcel>
    </div>
    <form v-if="batchData.products.length !== 0" @submit.prevent class="lotto__form">
      <table class="lotto__table" v-if="!isTabMode">
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />

        <thead>
          <tr class="lotto__head">
            <th class="lotto__th1"></th>
            <th class="lotto__th">Prodotto</th>
            <th class="lotto__th">Quantità (Kg)</th>
            <th class="lotto__th">Produttore</th>
            <th class="lotto__th">Ordine</th>
            <th class="lotto__th">Data</th>
            <th class="lotto__th">DDT</th>
          </tr>
        </thead>
        <tbody>
          <LottoItem v-for="item in batchData.products" :item="item" :batchNr="batchData.ddt_number" :key="item.id" @handle-change="toggleChecked"></LottoItem>
        </tbody>
      </table>
      <ul v-else class="lotto__mobileList">
        <LottoItem v-for="item in batchData.products" :item="item" :key="item.id" :batchNr="batchData.ddt_number" @handle-change="toggleChecked"></LottoItem>
      </ul>
    </form>
    <p class="u-text-warning" v-else>Nessun ordine trovato.</p>
  </div>
</template>
<script>
/**
 * This component loads the current production's batch details
 * and the table with the associated line items
 *
 * @displayName Lotto
 */
import { mapGetters, mapActions } from 'vuex';
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseActionButton from '@bc/BaseActionButton';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import LottoItem from '@c/common/LottoItem';
import BaseTopBar from '@bc/BaseTopBar';
import { getBatchDetailsById } from '@gq/getBatchDetailsById.gql';
import { getNumericDate } from '@u/helperFunctions.js';

export default {
  name: 'Lotto',
  components: {
    BaseHeading,
    BaseIcon,
    BaseActionButton,
    LottoItem,
    BaseTopBar,
    BaseLoadingSpinner
  },

  data() {
    return {
      batch: {},
      batchData: {},
      products: [],
      allSelected: false,
      fullyLoaded: false,
      lottoProductsNames: [],
      errorMsg: '',
      // EXCELS
      json_fields: {
        Prodotto: 'product',
        'Quantità (Kg)': 'quantity',
        'Kg assegnati': 'current',
        Produttore: 'vendor',
        Ordine: 'ordersNr',
        Data: 'date',
        DDT: 'batchNr'
      }
    };
  },
  computed: {
    ...mapGetters(['isTabMode', 'currentLotto', 'currentLottoVendor']),
    selectedItems() {
      return this.batchData.products ? this.batchData.products.filter(product => product.isChecked) : [];
    },
    btnsDisabled() {
      return this.selectedItems.length === 0;
    },
    // EXCELS

    json_data() {
      let json = [];

      if (this.fullyLoaded) {
        json = this.selectedItems.map(productData => {
          const { product, quantity, current, vendor, ordersNr, date } = productData;

          return {
            product,
            quantity,
            current,
            vendor,
            ordersNr,
            date: getNumericDate(date),
            batchNr: this.batchData.ddt_number
          };
        });
      }

      return json;
    },
    excel_header() {
      return `Lotto di produzione: ${this.batchData.ddt_number} - ${this.batchData.capacity} Kg`;
    }
  },
  apollo: {
    batch() {
      const variables = this.$store.getters.isUserAdmin ? { id: this.currentLotto } : { id: this.currentLotto, vendor: this.currentLottoVendor };
      return {
        query: getBatchDetailsById,
        fetchPolicy: 'no-cache',
        variables,
        error() {
          this.errorMsg = 'Qualcosa è andato storto. Riprova più tardi.';
          this.fullyLoaded = true;
        }
      };
    }
  },
  methods: {
    ...mapActions(['set']),
    toggleChecked(itemId) {
      const updatedBatchProductsData = this.batchData.products;

      // Find selected input
      const selectedInput = updatedBatchProductsData.find(item => item.id === itemId);

      // Toggle selected input isChecked value
      selectedInput.isChecked = !selectedInput.isChecked;

      this.batchData.products = [...updatedBatchProductsData];
    },
    toggleSelectAll() {
      const selectedBatchProducts = this.batchData.products;
      selectedBatchProducts.forEach(item => (item.isChecked = !this.allSelected));
      this.allSelected = !this.allSelected;

      this.batchData.products = selectedBatchProducts;
    },

    calculateBatchData(productionBatch) {
      let capacity = 0;

      productionBatch.products.forEach(product => (capacity += product.grams));

      const batchProducts = productionBatch.products.map(product => {
        let productOrders = [];

        productionBatch.orders.forEach(batchOrder => {
          const foundLineItem = batchOrder.lineItems.find(lineItem => lineItem.product_name === product.name);
          if (foundLineItem) productOrders = [...productOrders, batchOrder.name];
        });

        return {
          id: product.id,
          isChecked: false,
          product: product.name,
          quantity: product.grams / 1000,
          current: product.current / 1000,
          vendor: productionBatch.vendor,
          date: productionBatch.ddt_date,
          ordersNr: productOrders.join(', ')
        };
      });

      this.batchData = {
        ddt_number: productionBatch.ddt_number,
        id: productionBatch.id,
        capacity: capacity / 1000,
        products: batchProducts
      };
      this.fullyLoaded = true;
    }
  },
  watch: {
    batch(val) {
      if (!val) return;
      if (val.errors && val.errors.length !== 0) {
        this.errorMsg = 'Lotto di produzione non trovato.';
        this.fullyLoaded = true;
        return;
      }
      this.calculateBatchData(val);
    }
  },
  beforeDestroy() {
    this.fullyLoaded = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

$cell-padding: 1rem 1rem 1.7rem 1rem;

// cell padding if clip-path is not supported

$cell-padding-no-clip: 1rem 1rem 1rem 1rem;

.lotto {
  @include respond('tab-port') {
    background-color: $color-white;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 24rem 3.125rem 3.125rem 3.125rem;
    overflow-y: auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1.45rem;
    border-bottom: 2px solid $color-grey-light;
  }

  &__mobileHeader {
    position: fixed;
    top: 0;
    padding: 9rem 3.125rem 0 3.125rem;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 200;
  }

  &__buttons {
    @include flex-parent-space-between;
    padding: 3rem 1.375rem;
  }

  &__form {
    max-height: 60vh;
    overflow-y: auto;

    @include respond('tab-port') {
      height: 100%;
      overflow-y: visible;
      max-height: unset;
    }
  }
  &__table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
  }

  &__th {
    color: $color-blue-light;
    background-color: $color-white;
    position: sticky;
    top: 0;
    font-weight: 400;
    padding: 1.3rem 1.5rem;
    vertical-align: middle;
    border-bottom: 1rem solid $color-white;
    @include small-font-size;
    z-index: 10;
  }

  &__th:not(:last-child) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: $color-blue-light;
      z-index: 300;
    }
  }

  &__th1 {
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1rem solid $color-white;
  }

  &__mobileList {
    padding-bottom: 2rem;
  }
}
</style>
