<template>
  <div class="header">
    <Toggler></Toggler>
    <div class="header__logo">
      <img src="@/assets/SVG/logo-orteat-2020.svg" alt="Logo" style="width: auto; height: 100%" />
    </div>
    <!-- <div class="header__icon">
      <BaseIcon icon="icon-Notification" extraClass="header__notification" />
      <BaseIcon icon="icon-Notification-circle" extraClass="header__circle" />
    </div> -->
  </div>
</template>
<script>
/**
 * This component loads a header for mobile devices
 *
 * @displayName Header
 */

import Toggler from './Toggler.vue';

export default {
  name: 'Header',
  components: {
    Toggler
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.header {
  background-color: $color-white;
  box-shadow: $primary-shadow;
  display: none;
  height: 7rem;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 2000;

  @include respond('tab-port') {
    padding: 1rem 3rem;
    @include flex-parent-space-between;
    width: 100%;
  }

  &__logo {
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    height: 70%;
    justify-content: center;
    margin-right: 1.6rem;
    @include respond('phone') {
      height: 55%;
    }
  }

  &__icon {
    position: relative;
  }

  &__notification {
    height: 3rem;
    width: 3rem;
    @include respond('phone') {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__circle {
    position: absolute;
    top: -3px;
    right: 0;
    height: 1.5rem;
    width: 1.5rem;

    @include respond('phone') {
      top: -2px;
      height: 1rem;
      width: 1rem;
    }
  }
}
</style>
