<template>
  <svg :class="['icon', extraClass]" :style="extraStyle" @click="$emit('handle-click')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- using my graphical objects -->
    <use :href="`${require('@/assets/symbol-defs.svg')}#${icon}`" />
  </svg>
</template>

<script>
/**
 * This component uses the svg sprite pattern to load icons
 *
 * @displayName BaseIcon
 */
export default {
  name: 'BaseIcon',
  props: {
    /**
     * This prop is used to pass the icon we want to be displayed
     */
    icon: { default: '', type: String },
    /**
     * This prop is used to pass an extra class that should be applied to the icon
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>
<style lang="scss" scoped>
.icon {
  height: 2rem;
  width: 2rem;
  fill: currentColor;

  &__rotate {
    animation: rotate 1.2s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<docs>
### BaseIcon  
```js
<BaseIcon icon="icon-search-icon" />
```

### BaseIcon with extraClass
```js
<BaseIcon icon="icon-arrow-up" extraClass="icon__rotate" />
```

### BaseIcon with extraStyle
```js
<BaseIcon icon="icon-search-icon" extraStyle="fill: #27ae60" />
```
 
</docs>
