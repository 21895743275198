<template>
  <div :class="['tools', extraClass, { 'tools--fullWidth': isFullWidth }]" :style="extraStyle">
    <BaseSearch
      :extraClass="isFullWidth ? 'tools__search--fullWidth' : 'tools__search'"
      :searchOptions="searchOptions"
      @search-data="(value, searchOption) => $emit('search-data', value, searchOption)"
      @handle-collapse-btns="handleCollapseBtns"
    ></BaseSearch>

    <div :class="['tools__buttons', { 'tools__buttons--shown': showSelectAll }]">
      <p v-if="showSelectAll" class="u-selectAll" @click="$emit('toggle-select-all')">
        <span :class="['u-selectAllBtn', allOrdersSelected && 'u-selectAllBtn u-selectAllBtn--selected']"></span><span>Seleziona tutto</span>
      </p>
      <div class="tools__icons">
        <BaseOrdersExcelBtn :isSmall="collapseBtns" :isDisabled="isDisabled" :selectedOrdersIds="selectedOrdersIds" :excel_filename="excel_filename" :excel_header="excel_header"></BaseOrdersExcelBtn>
        <BasePdfButton btnIcon="icon-file-text" :isShort="collapseBtns" :isDisabled="isDisabled" extraClass="tools__icon" :ordersIds="selectedOrdersIds">Scarica PDF ordine</BasePdfButton>
        <BaseDdtButton
          :isShort="collapseBtns"
          :isArchive="isArchive"
          :isDisabled="isDisabled"
          btnIcon="icon-Truck"
          extraClass="tools__icon"
          :selectedOrders="selectedOrders"
          @archive-orders="archiveOrders"
          >Lettera di Vettura</BaseDdtButton
        >
        <BaseActionButton
          v-if="isArchive"
          :isLoading="isArchiveLoading"
          :isShort="collapseBtns"
          :isDisabled="isDisabled"
          btnIcon="icon-Unarchive"
          isBlue
          @handle-click="reactivateOrders(selectedOrdersNumbers, selectedOrdersIds)"
          >Ri-attiva ordine</BaseActionButton
        >
        <BaseActionButton v-else :isShort="collapseBtns" :isLoading="isArchiveLoading" :isDisabled="isDisabled" btnIcon="icon-Archive" isBlue @handle-click="checkDdtAndArchiveOrders"
          >Archivia ordine</BaseActionButton
        >
      </div>
    </div>

    <Portal v-if="archiveWarningDisplayed">
      <BaseModal confirmTxt="Vai al download della Lettera di Vettura" heading="Attenzione!" columnBtns @close-fn="archiveWarningDisplayed = false" @confirm-fn="downloadDdtAndArchive">{{
        warningTxt
      }}</BaseModal>
    </Portal>
  </div>
</template>
<script>
/**
 * This component loads a bar with search input and action buttons
 *
 * @displayName BaseOrdersTools
 */

import BaseSearch from '@bc/BaseSearch';
import BasePdfButton from '@bc/BasePdfButton';
import BaseDdtButton from '@bc/BaseDdtButton';
import BaseOrdersExcelBtn from '@bc/BaseOrdersExcelBtn';
import BaseActionButton from '@bc/BaseActionButton';
import BaseModal from '@bc/BaseModal';
import { archiveOrdersMixin, unarchiveOrdersMixin } from '@c/mixins/archiveMixins.js';
import { orderCurVendorsMixin } from '@c/mixins/orderCurVendorsMixin.js';
import { ddtGeneratedMixin } from '@c/mixins/ddtMixins.js';
import { mapActions, mapGetters } from 'vuex';
import { Portal } from '@linusborg/vue-simple-portal';

export default {
  name: 'BaseOrdersTools',
  components: {
    BaseSearch,
    BasePdfButton,
    BaseOrdersExcelBtn,
    BaseActionButton,
    BaseDdtButton,
    Portal,
    BaseModal
  },
  mixins: [archiveOrdersMixin, unarchiveOrdersMixin, ddtGeneratedMixin, orderCurVendorsMixin],
  props: {
    /**
     * This prop should be set to true if the toolbar takes up almost
     * entire screen width
     */
    isFullWidth: { type: Boolean, default: false },

    /**
     * If this prop is set to true additional button "select all" is shown
     */
    showSelectAll: { type: Boolean, default: false },

    /**
     * If showSelectAll is set to true, additional propp allOrdersSelected should be passed
     * and the function to toggle its value
     */

    allOrdersSelected: { type: Boolean, default: false },

    /**
     * This prop is used to disable all the buttons
     */
    isDisabled: { type: Boolean },

    /**
     * This prop is used to show "ri-attiva ordine" button instead of "archivia ordine"
     */
    isArchive: { type: Boolean },

    /**
     * This prop is used to pass an array of search options (ex search by product name, order id etc)
     */
    searchOptions: { type: Array },

    /**
     * This prop is used to pass the selected orders array.
     */
    selectedOrders: { type: Array },

    /**
     * This prop is used to pass the excel file name
     */
    excel_filename: { type: String },
    /**
     * This prop is used to pass the excel file header
     */
    excel_header: { type: String },

    /**
     * This prop is used to pass an extra class that should be applied to the icon
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      searchStyles: {},
      orders: [],
      collapseBtns: false,
      isArchiveLoading: false,
      archiveWarningDisplayed: false
    };
  },
  computed: {
    ...mapGetters(['isUserVendor', 'isUserAdmin', 'currentVendor', 'currentVendors']),
    selectedOrdersIds() {
      return this.selectedOrders.map(order => order.id);
    },
    selectedOrdersNumbers() {
      return this.selectedOrders.map(order => order.name);
    },

    warningTxt() {
      return this.selectedOrders.length === 1
        ? // eslint-disable-next-line quotes
          "Non è mai stata scaricata la lettera di vettura dell'ordine selezionato. Procedi con la creazione ed il download della Lettera di Vettura per poter archiviare l'ordine"
        : 'Non è mai stata scaricata la lettera di vettura degli ordini selezionati. Procedi con la creazione ed il download della Lettera di Vettura per poter archiviare ordini';
    }
  },
  methods: {
    ...mapActions(['set', 'addAlert']),

    handleCollapseBtns(value) {
      this.collapseBtns = value;
    },

    checkDdtAndArchiveOrders() {
      // Check if all the selected orders have ddt already generated
      const ddtGenerated = this.checkIfDdtGenerated();

      if (this.isUserVendor && !ddtGenerated) {
        this.archiveWarningDisplayed = true;
      } else {
        this.archiveOrders(this.selectedOrders);
      }
    },
    downloadDdtAndArchive() {
      this.archiveWarningDisplayed = false;
      document.querySelector('.ddt-btn').click();
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_mixins.scss';
@import '@s/_variables.scss';

.tools {
  @include flex-parent-space-between;
  margin-bottom: 4.2rem;

  @include respond('tab-land') {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  @include respond('phone') {
    width: 100%;
    align-items: stretch;
  }

  &--fullWidth {
    @include respond('tab-land') {
      flex-direction: row;
      align-items: center;
      margin-bottom: 4.5rem;
    }

    @include respond('tab-port') {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__search {
    margin-right: 2rem;
    @include respond('tab-land') {
      margin-right: 0;
      align-self: stretch;
      margin-bottom: 2rem;
    }

    &--fullWidth {
      margin-right: 2rem;
      @include respond('tab-land') {
        margin-right: 2rem;
        align-self: unset;
        margin-bottom: 0;
      }
      @include respond('tab-port') {
        margin-right: 0;
        align-self: stretch;
        margin-bottom: 2rem;
      }
    }
  }

  &__buttons {
    &--shown {
      @include flex-parent-space-between;
      width: 100%;
    }
  }

  &__icons {
    display: flex;
    align-self: flex-end;
  }
  &__icon {
    margin-right: 0.7rem;
  }
}
</style>
<docs>
### BaseOrdersTools  
```js
  <BaseOrdersTools></BaseOrdersTools>
```
 
 
</docs>
