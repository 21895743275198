<template>
  <DownloadExcel
    v-if="!isDisabled"
    :before-generate="startDownload"
    :before-finish="finishDownload"
    :meta="json_meta"
    :fetch="fetchExcelData"
    :fields="excel_fields"
    :name="filename"
    :header="excel_header"
  >
    <BaseActionButton btnIcon="icon-Group" :isShort="isSmall" :isLoading="isExcelLoading" extraClass="baseOrdersExcelBtn baseOrdersExcelBtn--high">Excel</BaseActionButton>
  </DownloadExcel>
  <BaseActionButton v-else :isShort="isSmall" btnIcon="icon-Group" isDisabled extraClass="baseOrdersExcelBtn">Excel</BaseActionButton>
</template>
<script>
/**
 * This component loads a button used to download the excels with the orders details
 * @displayName BaseOrdersExcelBtn
 */
import Vue from 'vue';
import JsonExcel from 'vue-json-excel';
import BaseActionButton from '@bc/BaseActionButton';
import { getNumericDate, sortArrayElementsFromAtoZ, convertWeightToKg, convertNrToExcelNr } from '@u/helperFunctions.js';
import { getAdminExcelOrdersDetails } from '@gq/getAdminExcelOrdersDetails.gql';
import { getVendorExcelOrdersDetails } from '@gq/getVendorExcelOrdersDetails.gql';

Vue.component('DownloadExcel', JsonExcel);

export default {
  name: 'BaseOrdersExcelBtn',
  components: {
    BaseActionButton
  },
  props: {
    /**
     * This prop is used disable all the buttons
     */
    isDisabled: { type: Boolean },

    /**
     * This prop is used to pass the ids of the selected orders. The details of these orders
     * will be downloaded in pdf file if someone clicks "Riepilogo ordine" button
     */
    selectedOrdersIds: { type: Array },

    /**
     * If set to true, the button text won't be displayed on desktop
     */
    isSmall: { type: Boolean, default: false },

    /**
     * This prop is used to pass the excel file name
     */
    excel_filename: { type: String },
    /**
     * This prop is used to pass the excel file header
     */
    excel_header: { type: String }
  },
  data() {
    return {
      nodes: [],
      // EXCELS DATA
      isExcelLoading: false,
      excelLineItems: [],

      excel_fields_admin: {
        'Order number': 'orderNr',
        'Shipping details': 'shippingName',
        'Product name': 'productName',
        'Product quantity': 'productQuantity',
        'Peso totale kg': 'productWeight',
        SKU: 'productSku',
        'Order date': 'orderDate'
      },
      excel_fields_vendor: {
        'Order number': 'orderNr',
        'Shipping details': 'shippingName',
        'Shipping city': 'shippingCity',
        'Product name': 'productName',
        'Product quantity': 'productQuantity',
        'Peso totale kg': 'productWeight',
        'Shipping method': 'shippingMethod',
        'Order date': 'orderDate'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ]
    };
  },
  computed: {
    currentVendor() {
      // This property returns the name of the logged in user on vendor's account
      // or the name of the selected vendor on admin account
      return this.$store.getters.currentVendor;
    },
    currentVendors() {
      // This property returns the array of the logged in users on vendor's account
      return this.$store.getters.currentVendors;
    },
    excel_fields() {
      return this.$store.getters.isUserAdmin ? this.excel_fields_admin : this.excel_fields_vendor;
    },
    // This function returns a name that should be applied to the downloaded file
    filename() {
      if (this.excel_filename) return this.excel_filename;
      // There is just one order
      if (this.excelLineItems && this.excelLineItems.length > 0 && this.uniqueSelectedOrdersIds && this.uniqueSelectedOrdersIds.length === 1)
        return `Ordine_${this.excelLineItems[0].orderNr.split('#')[1]}.xls`;
      // Find the smallest order number
      const ordersNames = this.excelLineItems.map(order => Number(order.orderNr.split('#')[1]));
      const sortedOrdersNames = sortArrayElementsFromAtoZ(ordersNames);

      // Find the highest order number
      return `Ordini_${sortedOrdersNames[0]}-${sortedOrdersNames[sortedOrdersNames.length - 1]}.xls`;
    },
    // Get an array of unique selected ids to avoid duplicates
    uniqueSelectedOrdersIds() {
      return [...new Set(this.selectedOrdersIds)];
    }
  },
  apollo: {
    // Call when component is rendered
    nodes() {
      const excelQuery = this.$store.getters.isUserAdmin ? getAdminExcelOrdersDetails : getVendorExcelOrdersDetails;
      return {
        query: excelQuery,
        skip: true,
        variables: {
          ids: this.uniqueSelectedOrdersIds
        }
      };
    }
  },
  methods: {
    async fetchExcelData() {
      this.$apollo.queries.nodes.start();
      const response = await this.$apollo.queries.nodes.refetch({
        ids: this.uniqueSelectedOrdersIds
      });

      const uniqueNodes = [];
      response.data.nodes.forEach(node => {
        const existingNode = uniqueNodes.find(uniqueNode => uniqueNode.id === node.id);
        if (!existingNode) uniqueNodes.push(node);
      });

      let excelLineItems = [];
      const regex = new RegExp(/ €( \S+|\S+)?/gm);

      uniqueNodes.forEach(order => {
        let orderLineItems = [];

        if (this.$store.getters.isUserAdmin) {
          // Filter line items to get only the current vendor's line items

          const filteredLineItems = this.currentVendors.length > 0 ? order.lineItems.edges.filter(lineItem => this.currentVendors.includes(lineItem.node.vendor)) : order.lineItems.edges;

          orderLineItems = filteredLineItems
            .filter(item => item.node.vendor && item.node.variant && item.node.currentQuantity > 0)
            .map(lineItem => {
              const itemVariant = lineItem.node.variant;
              const itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);

              let fullProductName = lineItem.node.variant.displayName || lineItem.node.name;

              if (lineItem.node.customAttributes && lineItem.node.customAttributes.length !== 0) {
                const customAttributesString = lineItem.node.customAttributes.map(attr => attr.value).join(' | ');
                fullProductName = !customAttributesString ? fullProductName : customAttributesString;
              }

              return {
                orderDate: getNumericDate(order.createdAt),
                orderNr: order.name,
                shippingName: order.shippingAddress ? order.shippingAddress.firstName + ' ' + order.shippingAddress.lastName : '',
                productName: fullProductName.replace(regex, ''),
                productQuantity: lineItem.node.currentQuantity,
                productSku: lineItem.node.sku,
                productWeight: convertNrToExcelNr(itemWeight * lineItem.node.currentQuantity)
              };
            });
        } else {
          orderLineItems = order.lineItems.edges
            .filter(item => item.node.vendor && item.node.variant && item.node.currentQuantity > 0)
            .map(lineItem => {
              const itemVariant = lineItem.node.variant;

              const itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);

              let fullProductName = lineItem.node.variant ? lineItem.node.variant.displayName : lineItem.node.name;

              if (lineItem.node.customAttributes && lineItem.node.customAttributes.length !== 0) {
                const customAttributesString = lineItem.node.customAttributes.map(attr => attr.value).join(' | ');
                fullProductName = !customAttributesString ? fullProductName : customAttributesString;
              }

              return {
                orderDate: getNumericDate(order.createdAt),
                orderNr: order.name,
                productName: fullProductName.replace(regex, ''),
                productQuantity: lineItem.node.currentQuantity,
                productWeight: convertNrToExcelNr(itemWeight * lineItem.node.currentQuantity),
                shippingCity: order.shippingAddress ? order.shippingAddress.city : '',
                shippingName: order.shippingAddress ? order.shippingAddress.firstName + ' ' + order.shippingAddress.lastName : '',
                shippingMethod: order.shippingLine ? order.shippingLine.code : ''
              };
            });
        }

        excelLineItems = [...excelLineItems, ...orderLineItems];
      });
      this.excelLineItems = excelLineItems;
      return excelLineItems;
    },
    startDownload() {
      this.isExcelLoading = true;
    },
    finishDownload() {
      this.isExcelLoading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.baseOrdersExcelBtn {
  margin-right: 0.7rem;

  &--high {
    height: 100%;
  }
}
</style>
