<template>
  <div>
    <BaseHeading :level="2" extraClass="weekOrders__heading">Gli ordini della settimana</BaseHeading>

    <BaseButtonSecondary v-if="!isTabMode" @handle-click="$router.push('/lotto')" btnIcon="icon-plus" extraStyle="margin-bottom: 1rem">Inserisci un nuovo lotto di produzione</BaseButtonSecondary>
    <BaseButtonSecondary v-if="!isTabMode" @handle-click="$router.push('/gestione-lotti')" btnIcon="icon-settings" isLight extraStyle="margin-bottom: 3.9rem"
      >Gestisci i lotti di produzione creati
    </BaseButtonSecondary>
    <BaseLoadingSpinner v-if="$apollo.loading || !productsFullyLoaded || !allDataReady"></BaseLoadingSpinner>

    <div v-else>
      <p v-if="!productsData" class="u-text-warning">Nessun prodotto trovato.</p>
      <div v-else>
        <div class="weekOrders__block" v-for="product in orderedProductsStats" :key="product.id">
          <BaseInfoBlock
            extraClass="weekOrders__info"
            :title="`${product.totalKg || 0} kg`"
            :subtitle="product.name"
            :info="`* di cui ${product.kgFromBoxes || 0} kg destinati alle cassette miste`"
          ></BaseInfoBlock>
          <ul class="weekOrders__list">
            <BaseVariantItem
              v-for="(variant, index) in getNonZeroVariants(product.variants, 'itemsSold')"
              :key="index"
              :number="variant.itemsSold || 0"
              :title="`${variant.weight} kg - ${variant.title}`"
            ></BaseVariantItem>
          </ul>
        </div>
        <div v-if="boxesStats.length !== 0">
          <div v-for="box in orderedBoxesStats" :key="box.id" class="weekOrders__block">
            <div class="weekOrders__info weekOrders__cassetta">
              <h6 class="weekOrders__titleCassetta">{{ box.name }}</h6>
              <BaseInfoBlock
                v-for="(product, index) in box.productsStats"
                :key="index"
                extraClass="weekOrders__itemCassetta"
                :title="`${product.kgSold} kg`"
                :subtitle="product.name"
                :isStarred="product.isStarred"
              ></BaseInfoBlock>
            </div>
            <ul class="weekOrders__list">
              <BaseVariantItem v-for="(variant, index) in getNonZeroVariants(box.variants, 'itemsSold')" :key="index" :number="variant.itemsSold" :title="`${variant.weight} kg`"></BaseVariantItem>
            </ul>
          </div>
        </div>
        <BaseButtonSecondary v-if="isTabMode" btnIcon="icon-plus" extraStyle="margin-bottom: 1.25rem" @handle-click="$router.push('/lotto')"
          >Inserisci un nuovo lotto di produzione</BaseButtonSecondary
        >
        <BaseButtonSecondary v-if="isTabMode" @handle-click="$router.push('/gestione-lotti')" btnIcon="icon-settings" isLight>Gestisci i lotti di produzione creati </BaseButtonSecondary>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a summary of the orders from the last week
 *
 * @displayName WeekOrders
 */
import BaseHeading from '@bc/BaseHeading';
import BaseButtonSecondary from '@bc/BaseButtonSecondary';
import BaseInfoBlock from '@bc/BaseInfoBlock';
import BaseVariantItem from '@bc/BaseVariantItem';
import { mapActions } from 'vuex';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { getProductsOfTheWeekByVendor } from '@gq/getProductsOfTheWeekByVendor.gql';
import { checkIfJsonIsValid, sortItemsFromZtoA, convertWeightToKg } from '@u/helperFunctions.js';

export default {
  name: 'WeekOrders',
  components: {
    BaseHeading,
    BaseButtonSecondary,
    BaseInfoBlock,
    BaseVariantItem,
    BaseLoadingSpinner
  },
  props: {
    /**
     * This prop is used to pass an array of all the orders of the week
     */
    orders: { type: Array, required: true }
  },
  data() {
    return {
      products: [],
      productsData: [],
      boxesStats: [],
      productsStats: [],
      productsFullyLoaded: false,
      allDataReady: false,
      weekOrders: []
    };
  },
  computed: {
    isTabMode() {
      return this.$store.getters.isTabMode;
    },
    orderedBoxesStats() {
      // Remove boxes without sales
      const boxesWithSales = this.boxesStats.filter(_element => _element.totalKg > 0);
      return sortItemsFromZtoA({ arr: boxesWithSales, key: 'totalKg' });
    },
    orderedProductsStats() {
      // Remove products without sales
      const productWithSales = this.productsStats.filter(_element => _element.totalKg > 0);
      return sortItemsFromZtoA({ arr: productWithSales, key: 'totalKg' });
    }
  },

  methods: {
    ...mapActions(['set']),
    forceAllDataLoaded() {
      this.boxesStats = [];
      this.productsFullyLoaded = true;
      this.allDataReady = true;
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.fetchMore({
        variables: {
          cursor: lastCursor,
          key: 'content',
          namespace: 'box_info',
          productInfo: 'product_info',
          type: 'type'
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            this.productsFullyLoaded = true;
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    },

    /**
     * Method used to remove from the provided array
     * the elements with the 0 value in the property with the provided key
     * @param {Array} variants
     * @param {String} key
     */
    getNonZeroVariants(variants, key) {
      return variants.filter(_variant => _variant.itemsSold && _variant[key] > 0);
    }
  },

  apollo: {
    // Call when component is rendered
    products() {
      return {
        query: getProductsOfTheWeekByVendor,
        variables: {
          key: 'content',
          namespace: 'box_info',
          productInfo: 'product_info',
          type: 'type'
        }
      };
    }
  },

  watch: {
    products(val, oldVal) {
      if (val && val.edges) {
        if (val.edges.length === 0) {
          this.forceAllDataLoaded();
          return;
        }
        this.productsFullyLoaded = false;

        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreProducts(oldVal);
        } else {
          this.productsFullyLoaded = true;
        }

        let results = [];

        if (this.productsFullyLoaded) {
          results = val.edges.map(product => {
            return {
              id: product.node.id,
              name: product.node.title,
              tags: product.node.tags,
              type: product.node.type ? product.node.type.value : '',
              variants: product.node.variants
                ? product.node.variants.edges.map(variant => {
                    const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';
                    const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';

                    return {
                      name: variant.node.displayName,
                      title: variant.node.title,
                      id: variant.node.id,
                      weight: convertWeightToKg(variant.node.weight, variant.node.weightUnit),
                      weightUnit: 'Kg',
                      content: jsonVariantContent
                    };
                  })
                : []
            };
          });

          const productsWithoutFarmers = results.filter(result => result.type !== 'farmer');

          this.productsData = productsWithoutFarmers;
          this.set(['vendorProducts', productsWithoutFarmers]);

          this.productsStats = productsWithoutFarmers.map(product => {
            return {
              ...product,
              orders: []
            };
          });

          // Calculate orders data
          this.weekOrders = this.orders;
        } else if (!this.$apollo.queries.products.loading) {
          this.productsFullyLoaded = true;
          // If there are no products don't calculate the orders
          this.weekOrders = [];
          this.allDataReady = true;
        }
      }
    },

    weekOrders(val) {
      if (val) {
        if (val.length === 0) {
          this.forceAllDataLoaded();
          return;
        }

        // CALCULATE STATS DATA
        /**
         * Looping through all the vendor products.
         * For every product, extract the order linked to it
         */
        // 1. Assign orders to each product
        let productsOrdersArr = this.productsData.map(product => {
          return {
            ...product,
            orders: val.filter(order => order.tags.includes(product.id))
          };
        });

        /**
         * For each product extract its orders and variants.
         * For every product variant, extract the orders that contains that product variant.
         */
        // 2. Assign lineItems to each variant and calculate the total kg and number of items sold
        productsOrdersArr.forEach(product => {
          const { orders, variants } = product;
          product.variants = variants.map(variant => {
            let variantLineItems = [];
            orders.forEach(order => (variantLineItems = [...variantLineItems, ...order.lineItems.filter(item => item.variantId === variant.id)]));

            let variantTotalKg = 0;
            let itemsSold = 0;
            if (variantLineItems.length !== 0) {
              variantLineItems.forEach(variantItem => {
                variantTotalKg += variantItem.weight * variantItem.quantity;
                itemsSold += variantItem.quantity;
              });
            }

            return {
              ...variant,
              totalKg: variantTotalKg,
              lineItems: variantLineItems,
              itemsSold
            };
          });
        });

        // 3. Find cassette (must include tag "cassetta")
        const boxesArr = productsOrdersArr.filter(product => product.tags.includes('cassetta'));

        // 4. Find other products
        const individualProductsArr = productsOrdersArr.filter(product => !product.tags.includes('cassetta'));

        // 5. Add kg from boxes to each individual product
        const individualProductsWithBoxKg = individualProductsArr.map(product => {
          let kgFromBoxes = 0;

          boxesArr.forEach(box =>
            box.orders.forEach(order => {
              // Check if the tags include product id. We are checking if, for the current cassetta, there is an order
              // associated to the product currently looped
              if (order.tags.includes(product.id)) {
                // Filter line items that include the box
                const orderLineItemsWithBox = order.lineItems.filter(lineItem => lineItem.productId === box.id);

                orderLineItemsWithBox.forEach(lineItemWithBox => {
                  // Find variant content
                  const variantContent = box.variants.find(boxVariant => boxVariant.id === lineItemWithBox.variantId).content;

                  const variantWithProduct = variantContent && variantContent.find(item => item.productId === product.id);

                  // If product is part of the box content push kg to kgFromBoxes
                  if (variantWithProduct && variantWithProduct.quantityInKg > 0) {
                    // Get kg of the current product and push it to kgFromBox
                    kgFromBoxes += variantWithProduct.quantityInKg * lineItemWithBox.quantity;
                  }
                });
              }
            })
          );

          // Calculate total kg sold
          let totalKg = kgFromBoxes;

          product.orders.forEach(order =>
            order.lineItems.filter(lineItem => lineItem.productId === product.id).forEach(filteredLineItem => (totalKg += filteredLineItem.weight * filteredLineItem.quantity))
          );

          return {
            ...product,
            kgFromBoxes: Number(kgFromBoxes.toFixed(2)),
            totalKg: Number(totalKg.toFixed(2))
          };
        });

        // 6. Add kg sold of each product to all the boxes
        const boxesArrWithKg = boxesArr.map(box => {
          let productsStats = [];

          // Add all variants names with 0kg (to display box content even if there are no orders)
          box &&
            box.variants.forEach(_boxVariant => {
              _boxVariant &&
                _boxVariant.content &&
                _boxVariant.content.forEach(_boxVariantProduct => {
                  const existingProduct = productsStats.find(({ productId }) => productId === _boxVariantProduct.productId);
                  if (!existingProduct)
                    productsStats.push({
                      productId: _boxVariantProduct.productId,
                      name: _boxVariantProduct.productName,
                      kgSold: 0
                    });
                });
            });

          let totalKg = 0;

          box.orders.forEach(order =>
            order.lineItems
              .filter(lineItem => lineItem.productId === box.id)
              .forEach(filteredLineItem => {
                const variantContent = box.variants.find(variant => variant.id === filteredLineItem.variantId).content;

                variantContent &&
                  variantContent.forEach(variantItem => {
                    let selectedProductsStatsItem = productsStats.find(product => product.productId === variantItem.productId);

                    const identifiedProduct = this.productsData.find(product => product.productId === variantItem.productId);

                    const variantItemTotalKg = Number((variantItem.quantityInKg * filteredLineItem.quantity).toFixed(2));

                    // Add kg to the total kg of the box
                    totalKg += variantItemTotalKg;

                    // If the product exists add the kg
                    if (selectedProductsStatsItem) {
                      selectedProductsStatsItem.kgSold = Number((selectedProductsStatsItem.kgSold + variantItemTotalKg).toFixed(2));
                    } else {
                      // Else add the product with kg
                      productsStats.push({
                        productId: variantItem.productId,
                        name: variantItem.productName,
                        kgSold: variantItemTotalKg,
                        isStarred: identifiedProduct ? true : false
                      });
                    }
                  });
              })
          );

          return {
            ...box,
            totalKg: Number(totalKg.toFixed(2)),
            productsStats
          };
        });

        this.boxesStats = boxesArrWithKg;
        this.productsStats = individualProductsWithBoxKg;
        this.allDataReady = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.weekOrders {
  &__heading {
    margin-bottom: 4.1rem;
  }

  &__block {
    display: flex;
    margin-bottom: 3rem;

    @include respond('tab-port') {
      justify-content: center;
    }

    @include respond('phone') {
      justify-content: flex-start;
    }
  }

  &__info {
    flex-basis: 17.3rem;
    flex-shrink: 0;

    @include respond('tab-port') {
      flex-basis: 25rem;
    }

    @include respond('phone') {
      flex-basis: calculateMobRem(170px);
    }

    @include respond('miniphone') {
      flex-basis: calculateMobRem(150px);
    }
  }

  &__list {
    flex-grow: 1;
    margin-left: 2rem;

    @include respond('tab-port') {
      flex-grow: 0;
    }
  }

  &__cassetta {
    background-color: rgba($color-dark-blue, 0.15);
    border-radius: 4px;
    padding: 2rem 1.7rem;
  }

  &__itemCassetta {
    margin-bottom: 1rem;
  }

  &__titleCassetta {
    @include default-font-size;
    margin-bottom: 8rem;
  }
}
</style>
