<template>
  <div class="lottoCreation">
    <div v-if="activeStep === 0 || !isTabMode" class="lottoCreation__col1">
      <h5 v-if="!isTabMode" class="lottoCreation__title">A quale produttore appartiene il prodotto da registrare?</h5>
      <BaseSearch v-if="activeStep === 0" extraClass="lottoCreation__search" @search-data="searchVendors"> </BaseSearch>
      <BaseItemButton
        v-for="(vendor, index) in hubVendors"
        :key="index"
        extraClass="lottoCreation__button"
        :itemId="vendor"
        :title="vendor"
        :isClickable="activeStep === 0"
        @handle-click="selectVendor"
        :isActive="selectedVendor === vendor"
      ></BaseItemButton>
    </div>
    <div v-if="selectedVendor && activeStep === 1" class="lottoCreation__col2">
      <LottoProduct
        v-for="(product, index) in registeredProducts"
        :key="index"
        :products="[product]"
        initialCollapse
        isRegistered
        :isEditMode="isEditMode"
        :editedProductId="editedProductId"
        :productData="product"
        :vendor="product.vendor"
        @handle-edit-mode="handleEditMode"
        @handle-product-data-change="handleProductDataChange"
      ></LottoProduct>
      <LottoProduct
        :products="notRegisteredProducts"
        :productData="selectedProductData"
        :vendor="selectedVendor"
        :editedProductId="selectedProduct"
        @reset-data="resetData"
        @select-product="handleSelectProduct"
        @handle-product-data-change="handleProductDataChange"
      ></LottoProduct>
      <p v-if="!isTabMode" class="lottoCreation__required">* dato obbligatorio</p>
      <BaseButtonSecondary extraClass="lottoCreation__plusBtn" btnIcon="icon-plus" isSmall @handle-click="addProduct">Aggiungi prodotto</BaseButtonSecondary>
    </div>
  </div>
</template>
<script>
import BaseItemButton from '@bc/BaseItemButton';
import BaseSearch from '@bc/BaseSearch';
import BaseButtonSecondary from '@bc/BaseButtonSecondary';
import LottoProduct from '@c/vendor/LottoProduct';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LottoCreation1',
  components: {
    BaseItemButton,
    BaseButtonSecondary,
    LottoProduct,
    BaseSearch
  },
  props: {
    /**
     * This prop is used to pass the name of the selected vendor
     */
    selectedVendor: { type: String, required: true },

    /**
     * This prop is used to pass the id of the selected product
     */
    selectedProduct: { type: String, required: true },

    /**
     * This prop is used to pass the selected product object
     */
    selectedProductData: { type: Object, required: true },
    /**
     * This prop is used to pass an array of the vendor's products
     */
    vendorProducts: { type: Array, required: true },
    /**
     * This prop is used to pass an array of the vendors of currently logged in hub
     */
    hubVendors: { type: Array, required: true }
  },
  data() {
    return {
      isEditMode: false,
      editedProductId: ''
    };
  },
  computed: {
    ...mapGetters(['isTabMode', 'registeredProducts', 'activeStep']),

    notRegisteredProducts() {
      // Find vendor's products that have not been registered yet
      return this.vendorProducts.filter(product => !this.registeredProducts.find(registeredProduct => registeredProduct.id === product.id));
    }
  },
  methods: {
    ...mapActions(['completeStep', 'addRegisteredProduct', 'addAlert', 'productionBatches']),

    addProduct() {
      this.isEditMode = false;
      if (!this.selectedProduct) {
        this.addAlert({ msg: 'Seleziona un prodotto' });
      } else if (
        // Check if the current product form has been completed
        this.checkIfRequiredFieldsCompleted(this.selectedProductData)
      ) {
        this.selectedProductData.id = this.selectedProductData.prodotto;
        this.selectedProductData.name = this.vendorProducts.find(product => product.id === this.selectedProductData.prodotto).name;
        this.selectedProductData.completed = true;

        this.addRegisteredProduct([this.selectedVendor, this.selectedProductData]);
        this.resetData();
      } else {
        this.addAlert({ msg: 'Seleziona prodotto, quantità, data di raccolta, data del DDT, comune e carica il file del DDT per poter aggiungere un nuovo prodotto.' });
      }
    },

    handleEditMode(productId) {
      if (productId) {
        this.isEditMode = true;
        this.editedProductId = productId;
      } else {
        this.isEditMode = false;
        this.editedProductId = '';
      }
    },
    searchVendors(value) {
      const filteredVendor = this.hubVendors.filter(vendor => vendor === value);
      this.hubVendors = filteredVendor;
    }
  },
  inject: ['handleProductDataChange', 'handleSelectProduct', 'selectVendor', 'resetData', 'checkIfRequiredFieldsCompleted']
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.lottoCreation {
  display: flex;

  &__col1 {
    flex-basis: 30%;

    @include respond('tab-port') {
      flex-basis: 100%;
    }
  }

  &__col2 {
    flex-grow: 1;
    margin-left: 11rem;

    @include respond('tab-port') {
      margin-left: 0;
    }
  }
  &__title {
    @include default-font-size;
    margin-bottom: 4.9rem;
  }

  &__search {
    margin-bottom: 2rem;
  }

  &__required {
    color: $color-flame-pea;
    @include default-font-size;
    margin-top: 1.5rem;
  }

  &__button {
    width: 38rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @include respond('tab-port') {
      width: 100%;
    }
  }

  &__plusBtn {
    @include respond('tab-port') {
      width: 100%;
    }
  }
}
</style>
