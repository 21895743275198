<template>
  <div class="toggler" @click="toggleSidebarMobile"><BaseIcon icon="icon-Hamburger-menu" extraClass="toggler__icon" /></div>
</template>
<script>
import BaseIcon from '@bc/BaseIcon';

/**
 * This component loads a hamburger icon for mobile devices
 *
 * @displayName Toggler
 */

export default {
  name: 'Toggler',
  components: {
    BaseIcon
  },
  methods: {
    toggleSidebarMobile() {
      return this.$store.dispatch('toggleSidebarMobile');
    }
  }
};
</script>
<style lang="scss">
@import '@s/_mixins.scss';
@import '@s/_variables.scss';
.toggler {
  display: none;

  @include respond('tab-port') {
    display: block;

    cursor: pointer;
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    color: $color-dark-blue;

    @include respond('phone') {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
</style>
