<template>
  <div :class="extraClass" :style="extraStyle">
    <h4 class="radioGroup__title">{{ title }}</h4>
    <div :class="['radioGroup', { 'radioGroup--narrow': isNarrow }]">
      <div v-for="(option, index) in options" :key="index" class="radioGroup__option">
        <input type="radio" :id="option" class="radioGroup__input" :value="option" :name="groupName" v-model="selection" />
        <label :for="option" :class="['radioGroup__label', { 'radioGroup__label--narrow': isNarrow }]"><span class="radioGroup__btn"></span>{{ option }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseRadioGroup',
  props: {
    /**
     * This prop is used to pass the title of the checkbox group
     */
    title: { type: String },

    /**
     * This prop is used to pass an array of available options
     */
    options: { type: Array, required: true },

    /**
     * This prop is used to pass the name of the checkbox group
     */
    groupName: { type: String, required: true },

    /**
     * This prop is used to pass an array of the options that should be selected on component render
     */
    initialSelection: {
      type: String,
      default: ''
    },
    /**
     * If this prop is set to true the options will take the full container's width
     */
    isNarrow: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the radio group
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  },
  data() {
    return {
      selection: this.initialSelection
    };
  },
  watch: {
    selection() {
      this.$emit('handle-selection-change', this.groupName, this.selection);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';

.radioGroup {
  @include flex-parent-space-between;

  @include respond('tab-port') {
    justify-content: flex-start;
  }

  @include respond('phone') {
    justify-content: space-between;
  }

  &--narrow {
    justify-content: flex-start;
  }

  &__title {
    @include default-font-size;
    @include bold-text;
    margin-bottom: 2rem;
  }

  &__option {
    width: 50%;
    padding-right: 1rem;
  }

  &__input {
    display: none;
  }

  &__label {
    font-family: inherit;
    @include default-font-size;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include respond('tab-port') {
      margin-right: 2rem;
    }

    &--narrow {
      margin-right: 2rem;
    }
  }

  &__btn {
    height: 2rem;
    width: 2rem;
    border: 1px solid $color-dark-blue;
    background-color: $color-white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 1rem;
    flex-shrink: 0;

    @include respond('tab-port') {
      height: 2.5rem;
      width: 2.5rem;
    }
    @include respond('phone') {
      margin-right: 1rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 1rem;
      width: 1rem;
      background-color: $color-dark-blue;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s;

      @include respond('tab-port') {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  &__input:checked ~ &__label &__btn::after {
    opacity: 1;
  }
}
</style>
