<template>
  <div class="vendorSheet">
    <BaseLoadingSpinner v-if="isVendorDataLoading" isTopMobile></BaseLoadingSpinner>

    <BaseTopBar v-if="isTabMode && !isVendorDataLoading" extraClass="vendorSheet__topbar" :title="selectedItemId" @go-back-fn="set(['selectedItemId', ''])"></BaseTopBar>
    <div v-if="!isVendorDataLoading" class="vendorSheet__header">
      <BaseHeading :level="2">{{ selectedItemId }}</BaseHeading>
      <p class="vendorSheet__linkBack" @click="set(['selectedItemId', ''])"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
    </div>
    <div v-if="!isVendorDataLoading" class="vendorSheet__details">
      <div class="vendorSheet__products">
        <span class="u-margin-bottom-big u-display-inline-block">Prodotti:</span>
        <ul v-if="productsData && productsData.length !== 0">
          <BaseItemButton
            v-for="product in productsData"
            :key="product.id"
            :title="product.name"
            extraClass="vendorSheet__productBtn"
            :itemId="product.id"
            @handle-click="handleProductSelection(product.id)"
          ></BaseItemButton>
        </ul>
        <p v-else class="u-text-warning vendorSheet__products">Nessun prodotto trovato.</p>
      </div>
      <BaseMap v-if="productsData && productsData.length !== 0" :region="productsData[0].provenienza" extraClass="vendorSheet__map"></BaseMap>
    </div>
    <div :class="['vendorSheet__filter', { 'datepicker-dark': isTabMode }]">
      <span v-if="!isTabMode" class="vendorSheet__filterTxt">Visualizzazione dati sul produttore nel tempo:</span>
      <DatePicker v-model="selectedTimeRange" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
    </div>
    <BaseLoadingSpinner v-if="$apollo.queries.orders.loading || !fullyLoaded || !allDataReady" isTopMobile></BaseLoadingSpinner>
    <div class="vendorSheet__charts">
      <div v-if="isTabMode" class="vendorSheet__legend">
        <BaseChartLegend extraClass="vendorSheet__legendItems" :items="productsLegend" :colors="colors"></BaseChartLegend>
      </div>
      <div class="vendorSheet__heading">
        <BaseHeading :level="4">Prodotti spediti</BaseHeading>
        <BaseActionButton v-if="isTabMode && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-if="isTabMode && !btnsDisabled" :data="json_products_sold" :fields="json_fields" header="Numero di prodotti spediti/venduti " :name="`prodotti-spediti.xls`">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>
      <div class="vendorSheet__data">
        <div class="vendorSheet__chart">
          <BaseLineChart :extraStyle="chartStyle" :labels="vendorLabels" :options="isTabMode ? optionsMobile : options" :datasets="spedizioni"></BaseLineChart>
        </div>

        <div v-if="!isTabMode" class="vendorSheet__legend">
          <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
          <DownloadExcel v-else :data="json_products_sold" :fields="json_fields" header="Numero di prodotti spediti/venduti " :name="`prodotti-spediti.xls`">
            <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
          </DownloadExcel>
          <BaseChartLegend :items="productsLegend" :colors="colors"></BaseChartLegend>
        </div>
      </div>

      <div class="vendorSheet__heading">
        <BaseHeading :level="4">Fatturato medio</BaseHeading>
        <BaseActionButton v-if="isTabMode && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-if="isTabMode && !btnsDisabled" :data="json_products_earnings" :fields="json_fields" header="Totale fatturato per prodotto " :name="`totale-fatturato-per-prodotto.xls`">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>

      <div class="vendorSheet__data">
        <div class="vendorSheet__chart">
          <BaseLineChart :extraStyle="chartStyle" :labels="vendorLabels" :options="isTabMode ? optionsMobile : options" :datasets="fatturato"></BaseLineChart>
        </div>

        <div v-if="!isTabMode" class="vendorSheet__legend">
          <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
          <DownloadExcel v-else :data="json_products_earnings" :fields="json_fields" header="Totale fatturato per prodotto " :name="`totale-fatturato-per-prodotto.xls`">
            <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
          </DownloadExcel>
          <BaseChartLegend :items="productsLegend" :colors="colors"></BaseChartLegend>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a sheet with vendor's products statistics
 *
 * @displayName AdminVendorSheet
 */
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseActionButton from '@bc/BaseActionButton';
import BaseItemButton from '@bc/BaseItemButton';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseLineChart from '@bc/BaseLineChart';
import BaseMap from '@bc/BaseMap';
import BaseChartLegend from '@bc/BaseChartLegend';
import BaseTopBar from '@bc/BaseTopBar';
import { getProductsByVendor } from '@gq/getProductsByVendor.gql';
import { getDaysOfTheWeek, getNumericDate, getDatesArr, getNumericDateOfTheNextDate } from '@u/helperFunctions';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
import { getVendorProductsOrders } from '@gq/getVendorProductsOrders.gql';
import { fetchMoreOrdersMixin } from '@c/mixins/graphqlMixins.js';

import 'vue-range-component/dist/vue-range-slider.css';

export default {
  name: 'AdminVendorSheet',
  components: {
    BaseHeading,
    BaseIcon,
    DatePicker,
    BaseActionButton,
    BaseLineChart,
    BaseItemButton,
    BaseMap,
    BaseTopBar,
    BaseLoadingSpinner,
    BaseChartLegend
  },
  mixins: [fetchMoreOrdersMixin],
  data() {
    return {
      products: {},
      productsData: [],
      fullyLoaded: false,
      productsFullyLoaded: false,
      allDataReady: false,
      // time range selected in datepicker (initially set to the last 7 days)
      selectedTimeRange: [],
      disactivateFirstAlert: true,
      options: {
        maintainAspectRatio: false,
        // Charts labels styling
        legend: {
          display: false
        }
      },
      optionsMobile: {
        maintainAspectRatio: false,
        // Charts legend styling for mobile version
        legend: {
          display: false
        }
      },
      // Colors of the chart's lines
      colors: [
        '#F39C12',
        '#27AE60',
        '#3475E0',
        '#987FD5',
        '#E15A39',
        '#FDD75E',
        '#8CE7F1',
        '#7BE5A2',
        '#FFF073',
        '#87FF72',
        '#F3C7FB',
        '#FF6A6E',
        '#6AB8FE',
        '#A86AFE',
        '#DAF7A6',
        '#EE1A6D',
        '#F1FE71',
        '#FEDE71',
        '#5553CB',
        '#CB53A5'
      ],

      dataFatturato: [],
      dataSpedizioni: [],
      orders: {},
      productsWithOrdersArr: []
    };
  },

  computed: {
    ...mapGetters(['isTabMode', 'selectedItemId']),

    btnsDisabled() {
      return !this.orders || this.orders.length === 0;
    },

    chartStyle() {
      return !this.isTabMode ? 'height: 30rem; width: 100%' : 'width: 100%; height: 40rem;';
    },

    // Find products names for the legend
    productsLegend() {
      let productsLegend = [];
      if (this.productsWithOrdersArr && this.productsWithOrdersArr.length !== 0) {
        productsLegend = this.productsWithOrdersArr.map(productData => productData.product.name);
      }
      return productsLegend;
    },

    // Find days of the week of the selected time period
    vendorLabels() {
      let labels = [];
      if (this.selectedTimeRange && this.selectedTimeRange[0] !== null) {
        labels = getDaysOfTheWeek(this.selectedTimeRange);
      }
      return labels;
    },

    isVendorDataLoading() {
      return this.$apollo.queries.orders.loading || !this.productsFullyLoaded;
    },
    // Charts data ('label' and 'data'should be replaced with the real data from the database)
    fatturato() {
      let results = [];
      if (this.dataFatturato.length !== 0 && this.productsWithOrdersArr.length !== 0) {
        results = this.productsWithOrdersArr.map((product, index) => ({
          label: product.product.name,
          data: this.dataFatturato[index],
          borderColor: this.colors[index],
          backgroundColor: this.colors[index]
        }));
      }
      return results;
    },
    spedizioni() {
      let results = [];
      if (this.dataSpedizioni.length !== 0 && this.productsWithOrdersArr.length !== 0) {
        results = this.productsWithOrdersArr.map((product, index) => ({
          label: product.product.name,
          data: this.dataSpedizioni[index],
          borderColor: this.colors[index],
          backgroundColor: this.colors[index]
        }));
      }
      return results;
    },

    queryString() {
      const startDate = getNumericDate(this.selectedTimeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);

      return `vendor:${this.selectedItemId} AND created_at:>=${startDate} AND created_at:<=${endDate}`;
    },

    // EXCELS
    json_fields() {
      const productsArr = this.productsLegend.map((product, index) => {
        const obj = {};
        obj[product] = 'product' + index;

        return obj;
      });

      let mergedObj = { Data: 'date' };

      productsArr.forEach(product => {
        mergedObj = { ...mergedObj, ...product };
      });

      return mergedObj;
    },

    json_products_sold() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.dataSpedizioni.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        const productsCodes = this.productsLegend.map((product, index) => 'product' + index);

        json = arrOfDays.map((date, dayIndex) => {
          const obj = { date: date };

          productsCodes.forEach((code, productIndex) => {
            obj[code] = this.dataSpedizioni[productIndex][dayIndex];
          });

          return obj;
        });
      }

      return json;
    },

    json_products_earnings() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.dataFatturato.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        const productsCodes = this.productsLegend.map((product, index) => 'product' + index);

        json = arrOfDays.map((date, dayIndex) => {
          const obj = { date: date };

          productsCodes.forEach((code, productIndex) => {
            obj[code] = this.dataFatturato[productIndex][dayIndex];
          });

          return obj;
        });
      }

      return json;
    }
  },

  methods: {
    ...mapActions(['set', 'addAlert']),
    // Select the product and switch to the products view
    handleProductSelection(itemId) {
      if (itemId && itemId !== this.selectedItemId) {
        this.set(['selectedItemId', itemId]);
        this.set(['selectedView', 'Prodotti']);
      }
    },

    forceAllDataLoaded() {
      this.productsFullyLoaded = true;
      this.fullyLoaded = true;
      this.allDataReady = true;
      this.clearChartsData();
      this.setItemDataLoaded(true);
      this.orders = {};
    },

    clearChartsData() {
      this.dataFatturato = [];
      this.dataSpedizioni = [];
      this.orders = {};
      this.productsWithOrdersArr = [];
    },

    async fetchMoreProducts() {
      const lastCursor = this.products.edges[this.products.edges.length - 1].cursor;

      this.$apollo.queries.products.fetchMore({
        variables: {
          query: 'vendor:' + this.selectedItemId,
          provenienza: 'provenienza',
          namespace: 'product_info',
          cursor: lastCursor,
          type: 'type'
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.products.edges.length === 0) {
            return previousResult;
          } else {
            this.productsFullyLoaded = !fetchMoreResult.products.pageInfo.hasNextPage;
          }

          const newProducts = fetchMoreResult.products;
          newProducts.edges = [...previousResult.products.edges, ...newProducts.edges];
          return {
            products: newProducts
          };
        }
      });
    }
  },

  apollo: {
    // Call when component is rendered

    orders() {
      return {
        query: getVendorProductsOrders,
        variables: {
          queryString: this.queryString
        },
        // Disable the query
        skip: true,
        error() {
          this.forceAllDataLoaded();
        }
      };
    },
    products() {
      return {
        query: getProductsByVendor,
        variables: {
          query: 'vendor:' + this.selectedItemId,
          provenienza: 'provenienza',
          namespace: 'product_info',
          type: 'type'
        },
        manual: true,
        result({ data, loading }) {
          if (!loading && data) {
            this.products = data.products;
          }
        },
        error() {
          this.forceAllDataLoaded();
        }
      };
    }
  },

  watch: {
    products(val, oldVal) {
      if (val && val.edges) {
        // If there are no products, unblock other actions and show empty sheet
        // (if there are no products, don't check the orders)

        if (val.edges.length == 0) {
          this.forceAllDataLoaded();
        } else {
          this.productsFullyLoaded = false;
          // Check if there is the second page

          if (val.pageInfo.hasNextPage) {
            this.fetchMoreProducts(oldVal);
          } else {
            let results = [];

            results = val.edges.map(product => {
              return {
                id: product.node.id,
                name: product.node.title,
                provenienza: product.node.provenienza ? product.node.provenienza.value : '',
                type: (product.node.type && product.node.type.value) || ''
              };
            });

            this.productsData = results.filter(({ type }) => type !== 'farmer');

            this.setItemDataLoaded(true);
            this.productsFullyLoaded = true;
            // Get orders
            const startDate = getNumericDate(this.selectedTimeRange[0]);
            const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);

            this.$apollo.queries.orders.start();
            this.$apollo.queries.orders.refetch({
              queryString: `vendor:${this.selectedItemId} AND created_at:>=${startDate} AND created_at:<=${endDate}`
            });
          }
        }
      }
    },
    // Call apollo if selectedItemId changes
    selectedItemId(val) {
      if (val) {
        this.fullyLoaded = false;
        this.productsFullyLoaded = false;
        this.allDataReady = false;
        this.disactivateFirstAlert = true;
        this.setItemDataLoaded(false);
        // Changing orders to {} to force the orders update (otherwise it won't get
        // to watch if the value of new product's orders is the same and the loading will be infinite)
        this.orders = {};
        this.products = {};
        this.productsData = [];
        this.$apollo.queries.products.refetch({
          query: 'vendor:' + val,
          provenienza: 'provenienza',
          namespace: 'product_info',
          type: 'type'
        });
      }
    },

    // Call apollo when selectedTimeRange changes
    selectedTimeRange(val) {
      if (this.productsData.length === 0) return;
      if (val && val[0] !== null) {
        // Changing orders to [] to force the orders update (otherwise it won't get
        // to watch if the new orders are the same and the loading will be infinite)
        this.orders = {};
        this.fullyLoaded = false;
        this.setItemDataLoaded(false);
        // Get orders
        const startDate = getNumericDate(this.selectedTimeRange[0]);
        const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);

        this.$apollo.queries.orders.start();
        this.$apollo.queries.orders.refetch({
          queryString: `vendor:${this.selectedItemId} AND created_at:>=${startDate} AND created_at:<=${endDate}`
        });
      } else {
        // Clear chart data
        this.forceAllDataLoaded();
      }
    },

    orders(val) {
      if (val && val.edges) {
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          // If there is no selected time period show empty charts
          if (this.selectedTimeRange[0] === null || val.edges.length === 0) {
            this.forceAllDataLoaded();
          } else {
            this.fullyLoaded = true;
            // 1. Split orders into arrays with the orders of one product
            if (this.productsData && this.productsData.length !== 0) {
              this.allDataReady = false;
              const productsOrdersArr = this.productsData.map(product => {
                return {
                  product,
                  orders: val.edges.filter(order => order.node.lineItems.edges.filter(item => item.node.product && item.node.product.id === product.id && item.node.currentQuantity > 0).length !== 0)
                };
              });

              // Get only products with orders
              const productsWithOrdersArr = productsOrdersArr.filter(product => product.orders.length !== 0);
              this.productsWithOrdersArr = productsWithOrdersArr;

              // 2. Split orders into arrays with the orders placed in the same day
              const arrOfDays = getDatesArr(this.selectedTimeRange);
              const arrOfProductsOrdersDividedByDay = productsWithOrdersArr.map(product => {
                return arrOfDays.map(date => {
                  const result =
                    product.orders.length !== 0
                      ? product.orders.filter(item => {
                          const numDate = getNumericDate(item.node.createdAt);
                          return numDate === date;
                        })
                      : [];
                  return result;
                });
              });

              // 3. Create an array of arrays with the total earnings in the selected time period

              const earningsArrOfArrays = arrOfProductsOrdersDividedByDay.map((productOrders, index) => {
                return productOrders.map(dayArr => {
                  if (dayArr.length === 0) {
                    return 0;
                  } else {
                    let totalEarnings = 0;
                    dayArr.forEach(order => {
                      order.node.lineItems.edges
                        .filter(item => item.node.product && item.node.product.id === productsWithOrdersArr[index].product.id && item.node.currentQuantity > 0)
                        .forEach(
                          filteredLineItem => (totalEarnings += Number(filteredLineItem.node.originalTotalSet.shopMoney.amount) - Number(filteredLineItem.node.totalDiscountSet.shopMoney.amount))
                        );
                    });

                    return totalEarnings;
                  }
                });
              });

              // 3. Create an array of arrays with the total number of sold products in the selected time period

              const salesArrOfArrays = arrOfProductsOrdersDividedByDay.map((productOrders, index) => {
                return productOrders.map(dayArr => {
                  if (dayArr.length === 0) {
                    return 0;
                  } else {
                    let totalSales = 0;
                    dayArr.forEach(order => {
                      order.node.lineItems.edges
                        .filter(item => item.node.product && item.node.product.id === productsWithOrdersArr[index].product.id && item.node.currentQuantity > 0)
                        .forEach(filteredLineItem => (totalSales += filteredLineItem.node.currentQuantity));
                    });

                    return totalSales;
                  }
                });
              });

              this.dataFatturato = earningsArrOfArrays;
              this.dataSpedizioni = salesArrOfArrays;
            }
            this.allDataReady = true;
            this.setItemDataLoaded(true);
          }
        }
      }
    }
  },
  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded'],
  mounted() {
    const dayToday = new Date();
    const dayOneWeekBefore = new Date(dayToday.setDate(dayToday.getDate() - 7));
    const initialTimeRange = [dayOneWeekBefore, new Date()];
    this.selectedTimeRange = initialTimeRange;
    if (this.$store.getters.selectedItemId.startsWith('gid://shopify/Product')) {
      this.set(['selectedItemId', this.$store.getters.shopVendors[0]]);
    }
  },
  beforeDestroy() {
    this.fullyLoaded = false;
    this.productsFullyLoaded = false;
    this.allDataReady = false;
    this.disactivateFirstAlert = true;
    this.productsWithOrdersArr = [];
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.vendorSheet {
  position: relative;
  min-height: 50rem;

  @include respond('tab-port') {
    padding: 0;
  }

  &__topbar {
    padding-top: 3.125rem;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $color-white;
    z-index: 1;

    @include respond('tab-port') {
      top: 6rem;
      margin-left: -3.125rem;
      padding-left: 3.125rem;
      width: calc(100% + 3.125rem);
    }
  }

  &__header {
    position: sticky;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    top: -4.5rem;
    display: flex;
    width: 100%;
    background-color: $color-white;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4.5rem 4.5rem 3rem 4.5rem;
    z-index: 1;

    @include respond('tab-port') {
      padding: 2.5rem 0;
      position: static;
    }
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }

  &__details {
    line-height: 3.5rem;
    padding: 0 4.5rem 4.5rem 4.5rem;
    display: flex;
    justify-content: space-between;

    @include respond('tab-port') {
      padding: 0 0 3.75rem 0;
      border-bottom: 2px solid $color-grey-light;
    }
  }

  &__products {
    list-style: none;
    width: 50%;
  }

  &__map {
    width: 50%;
    margin-left: 4rem;
  }

  &__productBtn:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &__filter {
    background-color: $color-dark-blue;
    padding: 2.4rem 4.5rem;
    position: sticky;
    top: 6.4rem;
    left: 0;
    z-index: 1;
    @include flex-parent-space-between;

    @include respond('tab-port') {
      background-color: $color-white;
      top: 7.875rem;
      padding: 3.75rem 0;
    }
  }

  &__filterTxt {
    color: $color-white;
    flex-basis: 60%;
  }

  &__charts {
    padding: 0.5rem 4.5rem 4.5rem 4.5rem;

    @include respond('tab-port') {
      padding: 0;
    }
  }

  &__heading {
    margin: 4rem 0;
    @include flex-parent-space-between;
  }

  &__data {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__chart {
    margin-right: 2rem;
    flex-grow: 1;
    @include respond('tab-port') {
      margin-right: 0;
      width: 100%;
    }
  }

  &__legend {
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__legendItems {
    padding-top: 0;
  }
}
</style>
