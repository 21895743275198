<template>
  <div class="productSheet">
    <BaseLoadingSpinner v-if="isProductDataLoading" isTopMobile></BaseLoadingSpinner>

    <BaseTopBar v-if="isTabMode && !isProductDataLoading" extraClass="productSheet__topbar" :title="mobileTitle" @go-back-fn="set(['selectedItemId', ''])"></BaseTopBar>
    <div v-if="!isProductDataLoading" class="productSheet__header">
      <BaseHeading :level="2">{{ productData.name }} </BaseHeading>
      <p v-if="!isTabMode" class="productSheet__linkBack" @click="set(['selectedItemId', ''])"><BaseIcon icon="icon-cross"></BaseIcon>Chiudi</p>
    </div>

    <div v-if="!isProductDataLoading" class="productSheet__details">
      <div :class="['productSheet__info', { 'productSheet__info--2': !isAdmin }]">
        <p class="productSheet__col productSheet__col--1">
          Periodo di raccolta: {{ productData.periodoRaccolta }} <br />
          Calibro: {{ productData.calibro }} <br />
          <span v-if="!isAdmin">Provenienza: {{ productData.provenienza }} <br /></span>
          <span>Id prodotto: {{ productShortId }} </span>
        </p>
        <p v-if="isAdmin" class="productSheet__col">
          Provenienza: {{ productData.provenienza }} <br />
          Produttore: <span class="productSheet__vendor">{{ productData.vendor }}</span>
        </p>
      </div>
      <div v-if="!isProductDataLoading && isAdmin">
        <div v-if="!isTabMode" class="productSheet__table">
          <div class="productSheet__tr">
            <div class="productSheet__th productSheet__th--1">Confezioni</div>
            <div class="productSheet__th u-italic-text">Prezzo di vendita</div>
            <div class="productSheet__th u-italic-text">Prezzo di acquisto</div>
          </div>
          <div v-for="(confezione, index) in productVariantsData" :key="index" class="productSheet__tr">
            <div class="productSheet__cell productSheet__cell--1">
              <span>{{ confezione.kgScatola }} kg ({{ confezione.scatole }} {{ confezione.scatole === 1 ? 'scatola' : 'scatole' }})</span>
              <p class="productSheet__pricePackage">
                <span class="u-bold-text">{{ confezione.priceString }}</span> €
              </p>
            </div>
            <div class="productSheet__cell">
              <span class="u-bold-text">{{ confezione.pricePerKg }} € </span> /kg
            </div>
            <div class="productSheet__cell">
              <span class="u-bold-text">{{ confezione.purchasePricePerKg }} € </span> /kg
            </div>
          </div>
        </div>
        <p v-if="!isTabMode" class="productSheet__packaging u-selectAll" @click="confezionamentoIncluso = !confezionamentoIncluso">
          <span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': confezionamentoIncluso }]"></span><span>Confezionamento incluso</span>
        </p>
        <ul v-else class="productSheet__packages">
          <h6 class="productSheet__h6">Confezioni:</h6>
          <p class="productSheet__packaging productSheet__packaging--mobile u-selectAll" @click="confezionamentoIncluso = !confezionamentoIncluso">
            <span :class="['u-selectAllBtn', { 'u-selectAllBtn--selected': confezionamentoIncluso }]"></span><span>Confezionamento incluso nel prezzo di acquisto</span>
          </p>

          <li v-for="(confezione, index) in productVariantsData" :key="index" class="productSheet__package">
            <div class="productSheet__packageDetail productSheet__packageDetail--title">
              <span>- {{ confezione.kgScatola }} kg ({{ confezione.scatole }} {{ confezione.scatole === 1 ? 'scatola' : 'scatole' }})</span>
              <p class="productSheet__pricePackage">
                <span class="u-bold-text">{{ confezione.priceString }}</span> €
              </p>
            </div>
            <div class="productSheet__packageDetail">
              <span>Prezzo di vendita</span>
              <span class="productSheet__pricePackage">{{ confezione.pricePerKg }} € </span> /kg
            </div>
            <div class="productSheet__packageDetail">
              <span>Prezzo di acquisto</span>
              <span class="productSheet__pricePackage">{{ confezione.purchasePricePerKg }} € </span> /kg
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div :class="['productSheet__filter', { 'datepicker-dark': isTabMode }]">
      <span v-if="!isTabMode" class="productSheet__filterTxt">Visualizzazione dati sul prodotto nel tempo:</span>
      <DatePicker v-model="selectedTimeRange" range-separator=" -> " placeholder="Seleziona una data o un periodo" range prefix-class="xmx"></DatePicker>
    </div>

    <div class="productSheet__charts">
      <BaseLoadingSpinner v-if="$apollo.queries.nodes.loading || $apollo.queries.orders.loading || !fullyLoaded || !chartsDataCalculated" isTopMobile></BaseLoadingSpinner>
      <div v-else>
        <div v-if="isTabMode && showChartLegend" class="productSheet__legend">
          <BaseChartLegend extraClass="productSheet__legendItems" :items="chartLegend" :colors="colors"></BaseChartLegend>
        </div>
        <div class="productSheet__heading">
          <BaseHeading :level="4">Totale Kg venduti </BaseHeading>
          <BaseActionButton v-if="(isTabMode || !showChartLegend) && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
          <DownloadExcel
            v-if="(isTabMode || !showChartLegend) && !btnsDisabled"
            :data="json_kg_sold_per_product"
            :fields="kg_json_fields"
            :header="`Prodotto: ${productData.name}`"
            :name="`kg-venduti-${productData.name}.xls`"
          >
            <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
          </DownloadExcel>
        </div>

        <div class="productSheet__data">
          <div class="productSheet__chart">
            <BaseLineChart :extraStyle="chartStyle" :labels="productLabels" :options="options" :datasets="kgSold"></BaseLineChart>
          </div>

          <div v-if="!isTabMode && showChartLegend" class="productSheet__legend">
            <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
            <DownloadExcel v-else :data="json_kg_sold_per_product" :fields="earnings_json_fields" :header="`Prodotto: ${productData.name}`" name="kg-venduti-per-prodotto.xls">
              <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
            </DownloadExcel>
            <BaseChartLegend :items="chartLegend" :colors="colors"></BaseChartLegend>
          </div>
        </div>

        <div class="productSheet__heading">
          <BaseHeading :level="4">Totale fatturato </BaseHeading>
          <BaseActionButton v-if="(isTabMode || !showChartLegend) && btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
          <DownloadExcel
            v-if="(isTabMode || !showChartLegend) && !btnsDisabled"
            :data="json_earnings_per_product"
            :fields="earnings_json_fields"
            :header="`Prodotto: ${productData.name}`"
            :name="`totale-fatturato-${productData.name}.xls`"
          >
            <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
          </DownloadExcel>
        </div>

        <div class="productSheet__data">
          <div class="productSheet__chart">
            <BaseLineChart :extraStyle="chartStyle" :labels="productLabels" :options="options" :datasets="earnings"></BaseLineChart>
          </div>

          <div v-if="!isTabMode && showChartLegend" class="productSheet__legend">
            <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
            <DownloadExcel v-else :data="json_kg_sold_per_product" :fields="earnings_json_fields" :header="`Prodotto: ${productData.name}`" name="kg-venduti-per-prodotto.xls">
              <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
            </DownloadExcel>
            <BaseChartLegend :items="chartLegend" :colors="colors"></BaseChartLegend>
          </div>
        </div>
        <!-- <div v-if="isAdmin" class="productSheet__heading">
          <BaseHeading :level="4">Totale costo del prodotto</BaseHeading>
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </div>
        <BaseLineChart v-if="isAdmin" extraStyle="height: 30rem" :labels="productLabels" :options="options" :datasets="costoProdotto"></BaseLineChart> -->
      </div>
    </div>
  </div>
</template>
<script>
/**
 * This component loads a sheet with product's statistics
 *
 * @displayName ProductSheet
 */

import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import BaseActionButton from '@bc/BaseActionButton';
import BaseLineChart from '@bc/BaseLineChart';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseTopBar from '@bc/BaseTopBar';
import BaseChartLegend from '@bc/BaseChartLegend';
import { getProductById } from '@gq/getProductById.gql';
import { getBoxesContent } from '@gq/getBoxesContent.gql';
import { getDaysOfTheWeek, getNumericDate, getDatesArr, shortenTxt, checkIfJsonIsValid, getNumericDateOfTheNextDate, convertWeightToKg } from '@u/helperFunctions';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
import { getProductOrders } from '@gq/getProductOrders.gql';
import { fetchMoreOrdersMixin } from '@c/mixins/graphqlMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

import 'vue-range-component/dist/vue-range-slider.css';

const boxTag = 'cassetta';

export default {
  name: 'ProductSheet',
  components: {
    BaseHeading,
    BaseIcon,
    DatePicker,
    BaseActionButton,
    BaseLineChart,
    BaseTopBar,
    BaseLoadingSpinner,
    BaseChartLegend
  },
  mixins: [sleepMixin, fetchMoreOrdersMixin],
  data() {
    return {
      product: {},
      productData: {},
      nodes: {},
      showChartLegend: false,
      disactivateFirstAlert: true,
      // Colors of the chart's lines
      colors: [
        '#F39C12',
        '#27AE60',
        '#3475E0',
        '#987FD5',
        '#E15A39',
        '#FDD75E',
        '#8CE7F1',
        '#7BE5A2',
        '#FFF073',
        '#87FF72',
        '#F3C7FB',
        '#FF6A6E',
        '#6AB8FE',
        '#A86AFE',
        '#DAF7A6',
        '#EE1A6D',
        '#F1FE71',
        '#FEDE71',
        '#5553CB',
        '#CB53A5'
      ],
      // if set to true , the displayed purchase price will include the price of packing
      confezionamentoIncluso: true,
      // time range selected in datepicker (initially set to the last 7 days)
      selectedTimeRange: [],
      fullyLoaded: false,
      chartsDataCalculated: false,
      options: {
        maintainAspectRatio: false
      },
      // Charts data
      earnings: [
        {
          label: '€',
          data: []
        }
      ],
      kgSold: [
        {
          label: 'Kg',
          data: []
        }
      ],

      // EXCELS
      earnings_json_fields: {
        Data: 'date',
        'Totale fatturato (€)': 'earnings'
      },
      kg_json_fields: {
        Data: 'date',
        'Kg venduti': 'kgSold'
      },
      orders: []
    };
  },

  computed: {
    ...mapGetters(['isTabMode', 'isUserAdmin', 'selectedItemId']),

    btnsDisabled() {
      return !this.orders || this.orders.length === 0;
    },

    chartLegend() {
      return [this.productData.name, 'Cassette miste', 'Totale'];
    },

    chartStyle() {
      return !this.isTabMode ? 'height: 30rem; width: 100%' : 'width: 100%; height: 37rem';
    },
    mobileTitle() {
      return shortenTxt(this.productData.name, 28);
    },
    productVariantsData() {
      return this.productData.variants;
    },
    // Check if the logged in user has admin permissions
    isAdmin() {
      return this.isUserAdmin;
    },

    productShortId() {
      return this.productData.id.split('Product/')[1];
    },

    // Get an array of days of the week from the selected time range
    productLabels() {
      let labels = [];
      if (this.selectedTimeRange && this.selectedTimeRange[0] !== null) {
        labels = getDaysOfTheWeek(this.selectedTimeRange);
      }
      return labels;
    },

    isProductDataLoading() {
      return this.$apollo.queries.product.loading || !this.productData.name;
    },

    numericTimeRange() {
      const startDate = getNumericDate(this.selectedTimeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.selectedTimeRange[1]);
      return [startDate, endDate];
    },
    queryString() {
      return `tag:'${this.selectedItemId}' AND created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]}`;
    },

    // EXCELS DATA
    json_kg_sold_per_product() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.kgSold[0].data.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        json = arrOfDays.map((date, index) => {
          return {
            date,
            kgSold: this.kgSold[0].data[index]
          };
        });
      }

      return json;
    },

    json_earnings_per_product() {
      let json = [];

      if (this.selectedTimeRange.length !== 0 && this.selectedTimeRange[0] !== null && this.earnings[0].data.length !== 0) {
        const arrOfDays = getDatesArr(this.selectedTimeRange);
        json = arrOfDays.map((date, index) => {
          return {
            date,
            earnings: this.earnings[0].data[index]
          };
        });
      }

      return json;
    }
  },

  methods: {
    ...mapActions(['set', 'addAlert']),
    clearChartsData() {
      this.showChartLegend = false;
      this.orders = [];

      this.earnings = [
        {
          label: '€',
          data: []
        }
      ];
      this.kgSold = [
        {
          label: 'Kg',
          data: []
        }
      ];
    },
    forceAllDataLoaded() {
      this.fullyLoaded = true;
      this.clearChartsData();
      this.chartsDataCalculated = true;
      this.setItemDataLoaded(true);
    },

    async calculateBoxSales(boxesOrders, arrOfDays) {
      let earningsFromBoxesArr = [];
      let weightFromBoxesArr = [];
      // There are no boxes sales or the product is a box
      if (boxesOrders.length === 0 || this.productData.tags.includes(boxTag)) {
        this.showChartLegend = false;
        return {
          earningsFromBoxesArr,
          weightFromBoxesArr
        };
      } else {
        // There are some boxes sales
        // Find the boxes ids
        let boxesIds = [];

        boxesOrders.forEach(order =>
          order.node.lineItems.edges.forEach(lineItem => {
            if (lineItem.node.product && lineItem.node.currentQuantity > 0 && lineItem.node.product.tags.includes(boxTag)) {
              boxesIds = [...boxesIds, lineItem.node.product.id];
            }
          })
        );

        const uniqBoxesIds = [...new Set(boxesIds)];

        if (uniqBoxesIds.length !== 0) {
          // Call apollo to get the boxes content
          this.$apollo.queries.nodes.start();
          const response = await this.$apollo.queries.nodes.refetch({
            ids: uniqBoxesIds,
            boxInfo: 'box_info',
            boxContent: 'content'
          });

          let boxesContents = response.data.nodes.map(node => {
            if (!node) return;
            const variantsEdges = node.variants.edges;
            return {
              id: node.id,
              title: node.title,
              variants:
                variantsEdges && variantsEdges.length > 0
                  ? variantsEdges.map(variant => {
                      const variantContent = variant.node.content !== null ? variant.node.content.value.replace(/&quot;/g, '"').replace(/&nbsp;/g, '') : '';

                      const jsonVariantContent = checkIfJsonIsValid(variantContent) ? JSON.parse(variantContent) : '';
                      return {
                        id: variant.node.id,
                        content: jsonVariantContent
                      };
                    })
                  : []
            };
          });
          // Remove the products/boxes without metafields
          boxesContents = boxesContents.filter(box => box !== undefined && box.variants && box.variants.length !== 0);

          if (boxesContents.length === 0) {
            this.showChartLegend = false;
            return {
              earningsFromBoxesArr,
              weightFromBoxesArr
            };
          }

          //  Split the box orders into arrays with the orders placed in the same day
          const arrOfDaysWithBoxOrders = arrOfDays.map(date => {
            const result = boxesOrders.filter(item => {
              const numDate = getNumericDate(item.node.createdAt);
              return numDate === date;
            });
            return result;
          });

          // Check if there are boxes sales. If so, show charts legend
          let thereAreBoxesSales = false;

          //  Create an array with the total earnings from the boxes sales in the selected time period
          earningsFromBoxesArr = arrOfDaysWithBoxOrders.map(dayArr => {
            let totalEarningsFromBoxes = 0;
            if (dayArr.length !== 0) {
              dayArr.forEach(order => {
                order.node.lineItems.edges.forEach(lineItem => {
                  // Find only lineItems that includes a box
                  if (lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.variant && lineItem.node.currentQuantity > 0) {
                    // Find the content of this variant
                    const item = boxesContents.find(product => product.id === lineItem.node.product.id);

                    if (!item) return;
                    const variantContent = item.variants.find(variant => variant.id === lineItem.node.variant.id);

                    if (!variantContent.content || variantContent.content.length === 0) return;
                    // Find the part of the variant content that includes our product
                    const lineItemWithProduct = variantContent.content.find(content => content.productId === this.productData.id);

                    if (lineItemWithProduct) {
                      // get the kg sold and multiply it by price per kg (productData.prezzoVendita) and by the number of items sold
                      totalEarningsFromBoxes += lineItemWithProduct.quantityInKg * this.productData.prezzoVendita * lineItem.node.currentQuantity;
                    }
                  }
                });
              });

              if (totalEarningsFromBoxes > 0) thereAreBoxesSales = true;
            }
            return totalEarningsFromBoxes;
          });

          //  Create an array with the total kg of the product sold in the boxes in the selected time period
          weightFromBoxesArr = arrOfDaysWithBoxOrders.map(dayArr => {
            if (dayArr.length === 0) {
              return 0;
            } else {
              let totalWeightFromBoxes = 0;
              dayArr.forEach(order => {
                order.node.lineItems.edges.forEach(lineItem => {
                  // Find only lineItems that includes a box
                  if (lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.variant && lineItem.node.currentQuantity > 0) {
                    // Find the content of this variant
                    const item = boxesContents.find(product => product.id === lineItem.node.product.id);
                    if (!item) return;
                    const variantContent = item.variants.find(variant => variant.id === lineItem.node.variant.id);

                    if (!variantContent.content || variantContent.content.length === 0) return;
                    // Find the part of the variant content that includes our product

                    const lineItemWithProduct = variantContent.content.find(content => content.productId === this.productData.id);

                    if (lineItemWithProduct) {
                      // get the kg sold and multiply it by the quantity of lineItem sold
                      totalWeightFromBoxes += lineItemWithProduct.quantityInKg * lineItem.node.currentQuantity;
                    }
                  }
                });
              });
              if (totalWeightFromBoxes > 0) thereAreBoxesSales = true;

              return totalWeightFromBoxes;
            }
          });

          // If there are no boxes sales, return empty arrays
          if (!thereAreBoxesSales) {
            earningsFromBoxesArr = [];
            weightFromBoxesArr = [];
          } else {
            // Else show chart legend
            this.showChartLegend = true;
          }

          return {
            earningsFromBoxesArr,
            weightFromBoxesArr
          };
        } else {
          return {
            earningsFromBoxesArr,
            weightFromBoxesArr
          };
        }
      }
    },

    async getChartsData(orders) {
      let earningsArr = [];
      let weightArr = [];
      let earningsFromBoxesArr = [];
      let weightFromBoxesArr = [];
      let totalEarningsArr = [];
      let totalWeightArr = [];

      // 1. Split orders into arrays with the orders placed in the same day

      const arrOfDays = getDatesArr(this.selectedTimeRange);

      const arrOfDaysWithOrders = arrOfDays.map(date => {
        const result = orders.edges.filter(item => {
          const numDate = getNumericDate(item.node.createdAt);
          return numDate === date;
        });
        return result;
      });

      // 2. Create an array with the total earnings in the selected time period

      earningsArr = arrOfDaysWithOrders.map(dayArr => {
        if (dayArr.length === 0) {
          return 0;
        } else {
          let totalEarnings = 0;

          dayArr.forEach(order => {
            // 3. Filter lineItems by product id and get the sum (= total earnings per day)
            order.node.lineItems.edges
              .filter(item => item.node.product && item.node.product.id === this.selectedItemId && item.node.currentQuantity > 0)
              .forEach(filteredLineItem => (totalEarnings += Number(filteredLineItem.node.originalTotalSet.shopMoney.amount) - Number(filteredLineItem.node.totalDiscountSet.shopMoney.amount)));
          });

          return totalEarnings;
        }
      });

      // 4. Create an array with the total weight of the items sold in the selected time period

      weightArr = arrOfDaysWithOrders.map(dayArr => {
        if (dayArr.length === 0) {
          return 0;
        } else {
          let totalWeight = 0;
          dayArr.forEach(order => {
            // 3. Filter lineItems by product id and get the sum (= total weight per day)

            order.node.lineItems.edges
              .filter(item => item.node.product && item.node.product.id === this.selectedItemId && item.node.currentQuantity > 0)
              .forEach(filteredLineItem => {
                let itemWeight = 0;
                const itemVariant = filteredLineItem.node.variant;
                if (itemVariant) {
                  itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);
                }

                totalWeight += Number(itemWeight) * filteredLineItem.node.currentQuantity;
              });
          });

          return totalWeight;
        }
      });

      // If any order includes boxTag, calculate extra data from boxes sales

      // Find the orders that include at least one box
      const boxesOrders = orders.edges.filter(
        order => order.node.lineItems.edges.filter(lineItem => lineItem.node.product && lineItem.node.product.tags.includes(boxTag) && lineItem.node.currentQuantity > 0).length !== 0
      );

      try {
        const response = await this.calculateBoxSales(boxesOrders, arrOfDays);

        earningsFromBoxesArr = response.earningsFromBoxesArr;
        weightFromBoxesArr = response.weightFromBoxesArr;

        // Update earnings chart data

        if (earningsFromBoxesArr.length === 0) {
          this.earnings = [
            {
              label: '€',
              data: earningsArr
            }
          ];
        } else {
          totalEarningsArr = earningsArr.map((earning, index) => {
            return earning + earningsFromBoxesArr[index];
          });

          this.earnings = [
            {
              label: this.productData.name + ' (€)',
              data: earningsArr,
              borderColor: this.colors[0],
              backgroundColor: this.colors[0]
            },
            {
              label: 'Cassette (€)',
              data: earningsFromBoxesArr,
              borderColor: this.colors[1],
              backgroundColor: this.colors[1]
            },
            {
              label: 'Totale (€)',
              data: totalEarningsArr,
              borderColor: this.colors[2],
              backgroundColor: this.colors[2]
            }
          ];
        }

        // Update weight chart data

        if (weightFromBoxesArr.length === 0) {
          this.kgSold = [
            {
              label: 'kg',
              data: weightArr
            }
          ];
        } else {
          totalWeightArr = weightArr.map((weight, index) => {
            return weight + weightFromBoxesArr[index];
          });

          this.kgSold = [
            {
              label: this.productData.name + ' (kg)',
              data: weightArr,
              borderColor: this.colors[0],
              backgroundColor: this.colors[0]
            },
            {
              label: 'Cassette (kg)',
              data: weightFromBoxesArr,
              borderColor: this.colors[1],
              backgroundColor: this.colors[1]
            },
            {
              label: 'Totale (kg)',
              data: totalWeightArr,
              borderColor: this.colors[2],
              backgroundColor: this.colors[2]
            }
          ];
        }
        // Show chart data
        this.chartsDataCalculated = true;
        this.setItemDataLoaded(true);
      } catch (err) {
        this.forceAllDataLoaded();
      }
    },

    async refetchProduct() {
      // Retry in few seconds
      await this.sleep(2500 + Math.random() * 500);

      this.$apollo.queries.product.refetch({
        id: this.selectedItemId,
        namespace: 'product_info',
        provenienza: 'provenienza',
        raccolta: 'periodoRaccolta',
        calibro: 'calibro',
        prezzoSenzaConf: 'prezzoSenzaConf'
      });
    }
  },

  apollo: {
    // Call when component is rendered
    product() {
      return {
        query: getProductById,
        fetchPolicy: 'no-cache',
        variables: {
          id: this.selectedItemId,
          namespace: 'product_info',
          provenienza: 'provenienza',
          raccolta: 'periodoRaccolta',
          calibro: 'calibro',
          prezzoSenzaConf: 'prezzoSenzaConf'
        },
        error() {
          this.refetchProduct();
        }
      };
    },
    nodes() {
      return {
        query: getBoxesContent,
        skip: true,
        variables: {
          boxInfo: 'box_info',
          boxContent: 'content',
          ids: []
        },
        error() {
          // Unblock other actions and show empty charts

          this.forceAllDataLoaded();
        }
      };
    },
    orders() {
      return {
        query: getProductOrders,
        variables: {
          queryString: this.queryString
        },
        skip: true,
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.orders = data.orders;
          }
        },
        error() {
          this.forceAllDataLoaded();
        }
      };
    }
  },

  watch: {
    product(val) {
      let result;

      if (val && val.title) {
        result = {
          id: val.id,
          name: val.title,
          vendor: val.vendor,
          periodoRaccolta: val.periodoRaccolta && val.periodoRaccolta.value ? val.periodoRaccolta.value.split('|').join(', ') : '---',
          calibro: val.calibro ? val.calibro.value : '---',
          tags: val.tags,
          variants: val.variants.edges.map(variant => {
            const itemWeight = variant.node.weight ? convertWeightToKg(variant.node.weight, variant.node.weightUnit) : 0;
            const purchasePrice = variant.node.inventoryItem && variant.node.inventoryItem.unitCost ? variant.node.inventoryItem.unitCost.amount : 0;
            // Return price per kg
            const pricePerKg = Math.round((variant.node.price / itemWeight) * 100) / 100;
            const purchasePricePerKg = Math.round((purchasePrice / itemWeight) * 100) / 100;

            return {
              scatole: 1,
              kgScatola: itemWeight,
              price: variant.node.price,
              priceString: Number(variant.node.price).toString(),
              purchasePrice,
              pricePerKg,
              purchasePricePerKg,
              weightUnit: 'Kg'
            };
          }),
          provenienza: val.provenienza ? val.provenienza.value : '---',
          prezzoAcquistoSenzaConfezionamento: val.prezzoSenzaConf ? val.prezzoSenzaConf.value : '---'
        };

        this.productData = result;

        // Get orders
        this.$apollo.queries.orders.start();
        this.$apollo.queries.orders.refetch({
          queryString: this.queryString
        });

        this.setItemDataLoaded(true);
      }
    },

    // Call apollo if selectedItemId changes
    selectedItemId(val, oldVal) {
      if (val && val !== oldVal) {
        // Clear charts data
        this.fullyLoaded = false;
        this.chartsDataCalculated = false;
        this.disactivateFirstAlert = true;
        this.setItemDataLoaded(false);

        // Changing orders to [] to force the orders update (otherwise it won't get
        // to watch if the value of new product's orders is the same and the loading will be infinite)
        this.clearChartsData();

        this.$apollo.queries.product.refetch({
          id: val,
          namespace: 'product_info',
          provenienza: 'provenienza',
          raccolta: 'periodoRaccolta',
          calibro: 'calibro',
          prezzoSenzaConf: 'prezzoSenzaConf'
        });
      }
    },

    // Call apollo when selectedTimeRange changes
    selectedTimeRange(val) {
      if (val && this.productData.name) {
        if (val[0] !== null) {
          this.fullyLoaded = false;
          this.chartsDataCalculated = false;
          this.setItemDataLoaded(false);
          this.clearChartsData();
          // Refetch orders with new query
          this.$apollo.queries.orders.start();
          this.$apollo.queries.orders.refetch({
            queryString: this.queryString
          });
        } else {
          // Clear charts and unblock other actions
          this.forceAllDataLoaded();
        }
      }
    },

    orders(val) {
      if (val && val.edges) {
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          // If there is no selected time period show empty charts
          if (this.selectedTimeRange[0] === null || val.edges.length === 0) {
            this.forceAllDataLoaded();
          } else {
            this.fullyLoaded = true;
            this.getChartsData(val);
          }
        }
      }
    }
  },

  /**
   * This component inject the setItemDataLoaded function defined in ProductsAndVendors component
   */

  inject: ['setItemDataLoaded'],

  mounted() {
    const dayToday = new Date();
    const dayOneWeekBefore = new Date(dayToday.setDate(dayToday.getDate() - 7));
    const initialTimeRange = [dayOneWeekBefore, new Date()];
    this.selectedTimeRange = initialTimeRange;

    if (!this.$store.getters.selectedItemId.startsWith('gid://shopify/Product')) {
      this.set(['selectedItemId', this.$store.getters.shopProducts[0].id]);
    }
  },

  beforeDestroy() {
    // Clear charts data
    this.fullyLoaded = false;
    this.chartsDataCalculated = false;
    this.clearChartsData();
    this.disactivateFirstAlert = true;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_functions.scss';

.productSheet {
  position: relative;
  min-height: 50rem;

  @include respond('tab-port') {
    padding: 0;
    max-height: unset;
    overflow-y: visible;
    margin-bottom: calculateMobRem(24px);
  }

  &__topbar {
    padding-top: 3.125rem;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $color-white;
    z-index: 1;

    @include respond('tab-port') {
      top: 6rem;
      margin-left: -3.125rem;
      padding-left: 3.125rem;
      width: calc(100% + 3.125rem);
    }
  }

  &__header {
    position: sticky;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    top: -4.5rem;
    left: 0;
    display: flex;
    width: 100%;
    background-color: $color-white;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4.5rem 4.5rem 3rem 4.5rem;
    z-index: 1;

    @include respond('tab-port') {
      padding: 2.5rem 0;
      position: static;
    }
  }

  &__linkBack {
    color: $color-primary;
    cursor: pointer;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }

  &__linkBack:hover {
    color: map-get($theme-colors, 'primary-900');
  }

  &__details {
    line-height: 3.5rem;
    padding: 0 4.5rem 4.5rem 4.5rem;

    @include respond('tab-port') {
      padding: 0 0 3.75rem 0;
      border-bottom: 2px solid $color-grey-light;
    }
  }

  &__info {
    display: flex;
    margin-bottom: 5rem;

    @include respond('tab-port') {
      flex-direction: column;
      margin-bottom: 4.5rem;
    }

    &--2 {
      margin-bottom: 0;
    }
  }

  &__col {
    width: 50%;

    @include respond('tab-port') {
      width: 100%;
      line-height: 5.5rem;
    }

    &--1 {
      @include respond('tab-port') {
        margin-bottom: 3.75rem;
        line-height: 3.5rem;
      }
    }

    &--2 {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__vendor {
    background-color: $color-grey-light;
    border-radius: 4px;
    padding: 0.5rem;
    @include small-font-size;
    @include bold-text;
  }

  &__filter {
    background-color: $color-dark-blue;
    padding: 2.4rem 4.5rem;
    position: sticky;
    top: 6.5rem;
    left: 0;
    z-index: 1;
    @include flex-parent-space-between;

    @include respond('tab-port') {
      background-color: $color-white;
      top: 0;
      padding: 3.75rem 0;
    }
  }

  &__filterTxt {
    color: $color-white;
    flex-basis: 60%;
  }

  &__charts {
    padding: 0.5rem 4.5rem 4.5rem 4.5rem;

    @include respond('tab-port') {
      padding: 0;
    }
  }

  &__heading {
    margin: 4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include respond('tab-port') {
      margin: 0 0 calculateMobRem(16px) 0;
    }
  }

  &__data {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      @include respond('tab-port') {
        margin-bottom: 4rem;
      }
    }
  }

  &__chart {
    margin-right: 2rem;
    flex-grow: 0;
    width: 100%;

    @include respond('tab-port') {
      margin-right: 0;
      width: 100%;
    }
  }

  &__legend {
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__legendItems {
    padding-top: 0;
  }

  // PACKAGES MOBILE
  &__packages {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__package {
    display: flex;
    flex-direction: column;
    padding: 1.2rem 0;

    &:not(:last-child) {
      border-bottom: 2px solid $color-grey-light;
    }
  }

  &__h6 {
    @include default-font-size;
  }

  &__packageDetail {
    font-style: italic;
    display: flex;
    justify-content: space-between;

    &--title {
      font-style: normal;
      @include bold-text;
    }
  }

  &__pricePackage {
    margin-left: auto;
    margin-bottom: 0;
  }

  &__packaging {
    margin-top: 2rem;
    margin-left: auto;

    @include respond('tab-port') {
      font-size: calculateMobRem(12px);
      margin-bottom: 3rem;
      margin-left: 0;
    }
  }

  // PACKAGES TABLE

  &__table {
    display: table;
    width: 100%;
  }

  &__tr {
    display: table-row;
  }

  &__th {
    display: table-cell;
    padding-bottom: 1.2rem;

    &--1 {
      width: 50%;
    }
  }

  &__cell {
    display: table-cell;
    border-bottom: 2px solid $color-grey-light;
    padding: 0.9rem 1.5rem 0.9rem 0;

    &--1 {
      display: flex;
      justify-content: space-between;
      padding-right: 6rem;
    }
  }
}
</style>
