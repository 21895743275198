<template>
  <div>
    <div v-for="(vendorOrder, index) in vendorOrders" :key="index" :ref="vendorCSSClass" class="pdf">
      <section class="pdf__header">
        <div class="pdf__logo"></div>
        <p class="pdf__orderNr">
          {{ vendorOrder.date }} {{ vendorOrder.time }} <br />
          Numero ordine: {{ vendorOrder.name }} <br />
          <!-- Produttore: {{ vendorOrder.vendor }} -->
        </p>
      </section>
      <section class="pdf__section">
        <h2 class="pdf__title">Dettagli dell'ordine</h2>
        <table class="pdf__table">
          <thead>
            <th class="pdf__th pdf__th--1">Quantità</th>
            <th class="pdf__th">Oggetto</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in vendorOrder.filteredLineItems" :key="index" class="pdf__tr">
              <td class="pdf__td">{{ item.quantity }} x</td>
              <td class="pdf__td">
                <span class="pdf__span"><strong>Nome prodotto:</strong> {{ item.name }}</span>
                <span class="pdf__span"><strong>Variante:</strong> {{ item.variantName }}</span>
                <span v-if="item.customAttributesString" class="pdf__span"><strong>Contenuto:</strong> {{ item.customAttributesString }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section class="pdf__section">
        <h2 class="pdf__title">Informazioni sulla spedizione</h2>
        <p class="pdf__clientData">
          <strong>{{ vendorOrder.clientData.firstName }} {{ vendorOrder.clientData.lastName }}</strong> <span v-if="vendorOrder.clientData.company">({{ vendorOrder.clientData.company }})</span><br />
          {{ vendorOrder.clientData.address }}
        </p>
      </section>
      <section class="pdf__footer">
        <p class="pdf__paragraph">Per qualsiasi informazione o chiarimento contattaci a <strong>info@orteat.com</strong></p>
        <p class="pdf__paragraph">Orteat srl - P.IVA: 10527750961</p>
        <div class="pdf__images">
          <div v-for="img of ['frutta', 'avocado', 'limone', 'verdure']" :key="img" :data-type="img"></div>
        </div>
      </section>

      <div class="html2pdf__page-break"></div>
    </div>
  </div>
</template>
<script>
/**
 * This component prepares and downloads a pdf document for the orders of one vendor.
 *
 * @displayName VendorOrdersPDF
 */

import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { sortArrayElementsFromAtoZ } from '@u/helperFunctions.js';

export default {
  name: 'VendorOrdersPDF',
  props: {
    /**
     * This prop is used to pass the order id / number
     */
    vendorOrders: {
      type: Array,
      required: true
    },
    /**
     * This prop is used to pass the information if the component is the last one on the list
     * if so, set loading to false when the pdf is created
     */
    isLast: {
      type: Boolean
    }
  },
  computed: {
    // This function returns a class that should be applied
    // to each page with the vendor's orders. This class is used to select
    // the pages and print their content
    vendorCSSClass() {
      return this.vendorOrders[0].vendorCSSClass;
    },
    // This function returns a name that should be applied to the downloaded file
    filename() {
      // There is just one order
      if (this.vendorOrders.length === 1) return `${this.vendorOrders[0].vendor}_${this.vendorOrders[0].name.split('#')[1]}`;
      // Find the smallest order number
      const ordersNames = this.vendorOrders.map(order => Number(order.name.split('#')[1]));
      const sortedOrdersNames = sortArrayElementsFromAtoZ(ordersNames);

      // Find the highest order number
      return `${this.vendorOrders[0].vendor}_${sortedOrdersNames[0]}-${sortedOrdersNames[sortedOrdersNames.length - 1]}.pdf`;
    }
  },
  methods: {
    /**
     * Method used to export one pdf file.
     * Every page of the pdf will contains details about one vendor order
     */
    async exportHTMLToPDF() {
      await this.$nextTick();

      // Retrieving all the orders template from dom using the referenc
      let els = this.$refs[this.vendorCSSClass];

      // Array in which every dom-to-image promise will be stored
      const promises = [];

      // Creating the jsPDF doc
      const doc = new jsPDF({ unit: 'cm', format: 'a4', orientation: 'p' });

      // Retrieving information about the pdf page size
      const pageSize = jsPDF.getPageSize({ unit: 'cm', format: 'a4', orientation: 'p' });

      /**
       * For every element of the dom (= every vendor's order)
       * convert it to jpeg using the dom-to-image library.
       *
       * Since domToImage.toJpeg, returns a promise, push it into the
       * array of all the dom-to-image promises
       */
      for (const el of els) {
        el.style.height = `${pageSize.height}cm`;
        el.style.width = `${pageSize.width}cm`;

        promises.push(domtoimage.toJpeg(el));
      }

      // Waiting for all the promises to be completed
      const res = await Promise.all(promises);

      // For every image genereated from dom-to-image, create a pdf page and push it into the pdf created above
      for (const [page, img] of Object.entries(res)) {
        if (page > 0) doc.addPage();

        doc.addImage(img, 'jpeg', 0, 0, pageSize.width, pageSize.height);
      }

      this.$emit('handle-pdf-downloaded', this.vendorOrders[0].vendor);

      // Download the pdf
      // window.open(doc.output('datauristring'));
      doc.setProperties({
        title: this.filename
      });

      window.open(doc.output('bloburl'), this.filename);
    }
  },

  mounted() {
    this.exportHTMLToPDF();
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';

.pdf {
  padding: 1.5cm 2cm 0 2cm;
  background-color: $color-white;
  width: 100%;

  &__header {
    background-image: url('../../assets/images/striscia.png');
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 100% 0.3cm;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1cm;
  }

  &__logo {
    background-image: url('../../assets/SVG/logo-orteat-2020.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 4.5cm;
  }

  &__orderNr {
    font-size: 0.4cm;
    color: $color-text;
    text-align: right;
  }

  &__span {
    width: 100%;
    display: block;
  }

  &__title {
    font-size: 0.5cm;
    color: $color-dark-blue;
    font-weight: 700;
    margin-bottom: 0.6cm;
  }

  &__section {
    background-image: url('../../assets/images/striscia.png');
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 100% 0.3cm;
    padding: 1cm 0 2cm 0;
  }

  &__table {
    border: 0.5mm solid $color-text;
    color: $color-text;
    font-size: 0.4cm;
    width: 100%;
  }

  &__tr {
    border-bottom: 0.5mm solid $color-text;
  }

  &__th {
    padding: 2mm 5mm 2mm 2mm;
    border-bottom: 0.5mm solid $color-text;

    &--1 {
      width: 2cm;
    }
  }

  &__td {
    padding: 2mm 5mm 2mm 2mm;
  }

  &__clientData {
    color: $color-text;
    font-size: 0.4cm;
    text-transform: uppercase;
    max-width: 10cm;
  }

  &__footer {
    padding-top: 0.8cm;
  }

  &__images {
    height: 1.5cm;
    margin-top: 3cm;
    @include flex-parent-center-childs;

    > div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 2cm;
      width: 1.5cm;

      &[data-type='avocado'] {
        background-image: url('../../assets/images/avocado.png');
        height: 1.5cm;
        width: 1cm;
      }
      &[data-type='frutta'] {
        background-image: url('../../assets/images/frutta.png');
      }
      &[data-type='limone'] {
        background-image: url('../../assets/images/limone.png');
      }
      &[data-type='verdure'] {
        background-image: url('../../assets/images/verdure.png');
      }

      ~ div {
        margin-left: 1cm;
      }
    }
  }

  &__paragraph {
    color: $color-text;
    font-size: 0.4cm;
  }
}
</style>
