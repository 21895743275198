<template>
  <div class="productsCharts">
    <BaseLoadingSpinner v-if="$apollo.loading || !fullyLoaded || !allDataReady" isTopMobile></BaseLoadingSpinner>
    <div v-else>
      <div class="productsCharts__heading">
        <BaseHeading :level="4">Totale fatturato per prodotto</BaseHeading>
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_earnings_per_product" :header="time_range_excel_header" :fields="earnings_json_fields" name="totale-fatturato-per-prodotto.xls">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>

      <BaseBarChart v-if="!isTabMode" extraStyle="height: 30rem" :labels="productsEarningsLabels" :options="options" :datasets="fatturato"></BaseBarChart>
      <BaseHorizontalBarChart v-else :labels="productsEarningsLabels" :options="optionsMobile" :datasets="fatturato"></BaseHorizontalBarChart>
      <div class="productsCharts__heading">
        <BaseHeading :level="4">Totale Kg venduti per prodotto</BaseHeading>
        <BaseActionButton v-if="btnsDisabled" btnIcon="icon-Group" isDisabled>Excel</BaseActionButton>
        <DownloadExcel v-else :data="json_kg_sold_per_product" :header="time_range_excel_header" :fields="kg_json_fields" name="kg-venduti-per-prodotto.xls">
          <BaseActionButton btnIcon="icon-Group">Excel</BaseActionButton>
        </DownloadExcel>
      </div>
      <BaseBarChart v-if="!isTabMode" extraStyle="height: 30rem" :labels="productsWeightLabels" :options="options" :datasets="kgVenduti"></BaseBarChart>
      <BaseHorizontalBarChart v-else :labels="productsWeightLabels" :options="optionsMobile" :datasets="kgVenduti"></BaseHorizontalBarChart>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import BaseHeading from '@bc/BaseHeading';
import BaseActionButton from '@bc/BaseActionButton';
import BaseBarChart from '@bc/BaseBarChart';
import BaseHorizontalBarChart from '@bc/BaseHorizontalBarChart';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import { getNumericDate, styleDate, getNumericDateOfTheNextDate, sortItemsFromZtoA, convertWeightToKg } from '@u/helperFunctions';
import { getProductOrders } from '@gq/getProductOrders.gql';
import { generateBoxesSalesMixin } from '@c/mixins/boxesMixins.js';
import { fetchMoreOrdersMixin } from '@c/mixins/graphqlMixins.js';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

const boxTag = 'cassetta';

export default {
  name: 'ProductsCharts',
  props: {
    timeRange: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    BaseHeading,
    BaseActionButton,
    BaseBarChart,
    BaseHorizontalBarChart,
    BaseLoadingSpinner
  },
  mixins: [generateBoxesSalesMixin, sleepMixin, fetchMoreOrdersMixin],
  data() {
    return {
      boxesProductsStats: [],
      options: {
        maintainAspectRatio: false
      },
      optionsMobile: {
        maintainAspectRatio: true
      },
      fatturato: [
        {
          label: '€',
          data: []
        }
      ],
      kgVenduti: [
        {
          label: 'Kg',
          data: []
        }
      ],
      productsEarningsLabels: [],
      productsWeightLabels: [],
      sortedProductsKgArrOfObjects: [],
      sortedProductsEarningsArrOfObjects: [],
      fullyLoaded: false,
      allDataReady: false,
      orders: {},

      // EXCELS
      earnings_json_fields: {
        Prodotto: 'product',
        'Totale fatturato (€)': 'earnings'
      },
      kg_json_fields: {
        Prodotto: 'product',
        'Kg venduti': 'kgSold',
        Dettagli: 'content'
      }
    };
  },
  computed: {
    ...mapGetters(['shopProducts', 'isTabMode']),

    btnsDisabled() {
      return !this.orders || this.orders.length === 0;
    },

    numericTimeRange() {
      const startDate = getNumericDate(this.timeRange[0]);
      const endDate = getNumericDateOfTheNextDate(this.timeRange[1]);
      return [startDate, endDate];
    },

    queryString() {
      return `created_at:>=${this.numericTimeRange[0]} AND created_at:<${this.numericTimeRange[1]}`;
    },

    // EXCELS DATA
    json_earnings_per_product() {
      if (this.sortedProductsEarningsArrOfObjects.length === 0 || this.fatturato[0].data.length === 0) return [];

      return this.sortedProductsEarningsArrOfObjects.map(product => {
        return {
          product: product.title,
          earnings: product.earnings
        };
      });
    },

    time_range_excel_header() {
      return this.timeRange.length === 0 ? '' : `Periodo di tempo: ${styleDate(this.timeRange[0])} - ${styleDate(this.timeRange[1])}`;
    },

    json_kg_sold_per_product() {
      if (this.sortedProductsKgArrOfObjects.length === 0 || this.kgVenduti[0].data.length === 0) return [];

      return this.sortedProductsKgArrOfObjects.map(product => {
        let productStatsStr = '';

        // If the product is a box, include its content
        if (product.tags.includes(boxTag)) {
          const productStats = this.boxesProductsStats.find(boxProduct => boxProduct.id === product.id);
          productStatsStr = productStats ? productStats.boxProducts.map(boxProduct => boxProduct.title + ' - ' + boxProduct.totalKgSold).join('\n') : '';
        }
        return {
          product: product.title,
          kgSold: product.kg,
          content: productStatsStr
        };
      });
    }
  },
  methods: {
    ...mapActions(['set']),
    clearChartsData() {
      this.fatturato = [
        {
          label: '€',
          data: []
        }
      ];
      this.kgVenduti = [
        {
          label: 'Kg',
          data: []
        }
      ];

      this.orders = {};
    }
  },
  apollo: {
    orders() {
      return {
        query: getProductOrders,
        variables: {
          queryString: this.queryString
        },
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            this.orders = data.orders;
          }
        },
        error() {
          this.fullyLoaded = true;
          this.orders = [];
        }
      };
    }
  },

  watch: {
    // Call apollo when timeRange changes
    timeRange(val) {
      if (val) {
        if (val[0] !== null) {
          // Force orders update
          this.orders = {};
          this.fullyLoaded = false;
          this.$apollo.queries.orders.start();
          this.$apollo.queries.orders.refetch({
            queryString: this.queryString
          });
        } else {
          this.clearChartsData();
        }
      }
    },

    orders(val) {
      if (val && val.edges) {
        // Check if there is the second page
        if (val.pageInfo.hasNextPage) {
          this.fetchMoreOrders();
        } else {
          this.fullyLoaded = true;

          if (this.timeRange[0] === null || val.edges.length === 0) {
            this.clearChartsData();
            this.allDataReady = true;
            this.set(['sortedProductsData', []]);
            return;
          }

          const productOrdersArr = this.shopProducts.map(product => {
            return val.edges.filter(order => order.node.tags.includes(product.id));
          });

          // 2. Create an array with the total earnings of each product in the selected time period
          const earningsArr = productOrdersArr.map((ordersArr, index) => {
            if (ordersArr.length === 0) {
              return 0;
            } else {
              let totalEarnings = 0;
              ordersArr.forEach(order => {
                // 3. Filter lineItems by product id and get the sum (= total product earnings)

                order.node.lineItems.edges
                  .filter(item => item.node && item.node.product && item.node.product.id === this.shopProducts[index].id && item.node.currentQuantity > 0)
                  .forEach(filteredLineItem => (totalEarnings += Number(filteredLineItem.node.originalTotalSet.shopMoney.amount) - Number(filteredLineItem.node.totalDiscountSet.shopMoney.amount)));
              });

              return totalEarnings;
            }
          });

          // 4. Create an array with the total weight sold of each product in the selected time period
          const weightArr = productOrdersArr.map((ordersArr, index) => {
            if (ordersArr.length === 0) {
              return 0;
            } else {
              let totalWeight = 0;
              ordersArr.forEach(order => {
                // 5. Filter lineItems by product id and get the sum (= total product earnings)

                order.node.lineItems.edges
                  .filter(item => item.node && item.node.product && item.node.product.id === this.shopProducts[index].id && item.node.currentQuantity > 0)
                  .forEach(filteredLineItem => {
                    let itemWeight = 0;
                    const itemVariant = filteredLineItem.node.variant;
                    if (itemVariant) {
                      itemWeight = convertWeightToKg(itemVariant.weight, itemVariant.weightUnit);
                    }

                    totalWeight += Number(itemWeight) * filteredLineItem.node.currentQuantity;
                  });
              });

              return totalWeight;
            }
          });

          // Sort the results from the largest to the smallest

          const productsArrOfObjects = this.$store.getters.shopProducts.map((product, index) => {
            return {
              id: product.id,
              title: product.title,
              kg: weightArr[index],
              earnings: earningsArr[index],
              tags: product.tags
            };
          });

          // Sort weight charts data
          const sortedProductsKgArrOfObjects = sortItemsFromZtoA({ arr: [...productsArrOfObjects], key: 'kg' });
          this.sortedProductsKgArrOfObjects = sortedProductsKgArrOfObjects;
          // Remove products with no earnings
          const sortedProductsWithSales = sortedProductsKgArrOfObjects.filter(product => product.kg > 0);

          this.productsWeightLabels = sortedProductsWithSales.map(product => product.title);
          const sortedWeightArr = sortedProductsWithSales.map(product => product.kg);

          // Sort earnings charts data
          const sortedProductsEarningsArrOfObjects = sortItemsFromZtoA({ arr: [...productsArrOfObjects], key: 'earnings' });
          this.sortedProductsEarningsArrOfObjects = sortedProductsEarningsArrOfObjects;

          // Remove products with no earnings
          const sortedProductsWithEarnings = sortedProductsEarningsArrOfObjects.filter(product => product.earnings > 0);

          this.productsEarningsLabels = sortedProductsWithEarnings.map(product => product.title);
          const sortedEarningsArr = sortedProductsWithEarnings.map(product => product.earnings);

          // Save sorted products to store and order the list of available products
          this.set(['sortedProductsData', sortedProductsEarningsArrOfObjects]);

          this.fatturato = [
            {
              label: '€',
              data: sortedEarningsArr
            }
          ];
          this.kgVenduti = [
            {
              label: 'Kg',
              data: sortedWeightArr
            }
          ];
          this.allDataReady = true;
          this.$emit('set-charts-data-loaded');
          this.generateBoxesSalesForExcels(val);
        }
      }
    }
  },

  beforeDestroy() {
    this.fullyLoaded = false;
    this.allDataReady = false;
  }
};
</script>
<style lang="scss" scoped>
@import '@s/_variables.scss';
@import '@s/_mixins.scss';
@import '@s/_animations.scss';
.productsCharts {
  min-height: 70vh;

  &__heading {
    margin: 4rem 0;
    @include flex-parent-space-between;

    @include respond('tab-port') {
      margin: 2.4rem 0;
    }
  }
}
</style>
